export const cargoAFTLayout = [
  { value: "C1", pdu: "1440MY12", fin: 1440,box: 1 , topic_extension:"/aft/can/fin/1440/proxy/0/long_trans/1/PDU_covered"},
  { value: "C2", pdu: "1439MY12", fin: 1439,box: 2 , topic_extension:"/aft/can/fin/1439/proxy/0/long_trans/1/PDU_covered"},
  { value: "C3" ,pdu: "1438MY12", fin: 1438,box: 3 , topic_extension:"/aft/can/fin/1438/proxy/0/long_trans/1/PDU_covered"},
  { value: "C4", pdu: "1437MY12", fin: 1437 ,box:4 , topic_extension:"/aft/can/fin/1437/proxy/0/long_trans/1/PDU_covered"},
  { value: "C5", pdu: "1436MY12", fin: 1436,box: 5 , topic_extension:"/aft/can/fin/1436/proxy/0/long_trans/1/PDU_covered"},
  { value: "C6", pdu: "1435MY12", fin: 1435 ,box: 6, topic_extension:"/aft/can/fin/1435/proxy/0/long_trans/1/PDU_covered"},
  { value: "C7", pdu: "1408MY12", fin: 1408,box: 7 , topic_extension:"/aft/can/fin/1408/proxy/0/long_trans/1/PDU_covered"},
  { value: "C8", pdu: "1407MY12", fin: 1407 ,box: 8, topic_extension:"/aft/can/fin/1407/proxy/0/long_trans/1/PDU_covered"},
  { value: "C9", pdu: "1406MY12", fin: 1406 ,box: 9, topic_extension:"/aft/can/fin/1406/proxy/0/long_trans/1/PDU_covered"},
  { value: "C10", pdu: "1405MY12", fin: 1405 ,box: 10, topic_extension:"/aft/can/fin/1405/proxy/0/long_trans/1/PDU_covered"},

  { value: "C11", pdu: "1403MY121", fin: 1403 ,box: 11, topic_extension:"/aft/can/fin/1403/proxy/0/long_trans/0/PDU_covered"},
  { value: "C12", pdu: "1402MY121", fin: 1402,box: 12 , topic_extension:"/aft/can/fin/1402/proxy/0/long_trans/0/PDU_covered"},
  { value: "C13", pdu: "1401MY121", fin: 1401,box: 13, topic_extension:"/aft/can/fin/1401/proxy/0/long_trans/0/PDU_covered"},


  { value: "C14", pdu: "1403MY221", fin: 1403,box: 14 , topic_extension:"/aft/can/fin/1403/proxy/0/long_trans/1/PDU_covered"},
  { value: "C15", pdu: "1402MY221", fin: 1402,box: 15, topic_extension:"/aft/can/fin/1402/proxy/0/long_trans/1/PDU_covered"},
  { value: "C16", pdu: "1401MY221", fin: 1401,box: 16 , topic_extension:"/aft/can/fin/1401/proxy/0/long_trans/1/PDU_covered"},

  { value: "C17", pdu: "1540MY12", fin: 1540 ,box: 17, topic_extension:"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_covered"},
  { value: "C18", pdu: "1539MY12", fin: 1539 ,box: 18, topic_extension:"/aft/can/fin/1539/proxy/0/long_trans/1/PDU_covered"},
  { value: "C19", pdu: "1538MY12", fin: 1538 ,box: 19 , topic_extension:"/aft/can/fin/1538/proxy/0/long_trans/1/PDU_covered"},
  { value: "C20", pdu: "1537MY12", fin: 1537 ,box: 20 , topic_extension:"/aft/can/fin/1537/proxy/0/long_trans/1/PDU_covered"},
  { value: "C21", pdu: "1536MY12", fin: 1536 ,box: 21 , topic_extension:"/aft/can/fin/1536/proxy/0/long_trans/1/PDU_covered"},
  { value: "C22", pdu: "1535MY12", fin: 1535 ,box: 22 , topic_extension:"/aft/can/fin/1535/proxy/0/long_trans/1/PDU_covered"},
  { value: "C23", pdu: "1508MY12", fin: 1508 ,box: 23 , topic_extension:"/aft/can/fin/1508/proxy/0/long_trans/1/PDU_covered"},
  { value: "C24", pdu: "1507MY12", fin: 1507 ,box: 24 , topic_extension:"/aft/can/fin/1507/proxy/0/long_trans/1/PDU_covered"},
  { value: "C25", pdu: "1506MY12", fin: 1506 ,box: 25 , topic_extension:"/aft/can/fin/1506/proxy/0/long_trans/1/PDU_covered"},
  { value: "C26", pdu: "1505MY12", fin: 1505 ,box: 26, topic_extension:"/aft/can/fin/1505/proxy/0/long_trans/1/PDU_covered"},

  
 
  { value: "C27", pdu: "1503MY121", fin: 1503,box: 27 , topic_extension:"/aft/can/fin/1503/proxy/0/long_trans/0/PDU_covered"},
  { value: "C28", pdu: "1502MY121", fin: 1502,box: 28 , topic_extension:"/aft/can/fin/1502/proxy/0/long_trans/0/PDU_covered"},
  { value: "C29", pdu: "1501MY121", fin: 1501 ,box: 29, topic_extension:"/aft/can/fin/1501/proxy/0/long_trans/0/PDU_covered"},
  { value: "C30", pdu: "1503MY221", fin: 1503 ,box: 30 , topic_extension:"/aft/can/fin/1503/proxy/0/long_trans/1/PDU_covered"},
  { value: "C31", pdu: "1502MY221", fin: 1502 ,box: 31 , topic_extension:"/aft/can/fin/1502/proxy/0/long_trans/1/PDU_covered"},
  { value: "C32", pdu: "1501MY221", fin: 1501 ,box: 32 , topic_extension:"/aft/can/fin/1501/proxy/0/long_trans/1/PDU_covered"},

]

export const cargoAFTLayoutLatch = [
  { value: "L1", latch: "1840MY12", pdu: "1440MY12" , fin: 1440,box: 1 , topic_extension:"/aft/can/fin/1840/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L2", latch: "1839MY12", pdu: "1439MY12", fin: 1439,box: 2 , topic_extension:"/aft/can/fin/1839/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L3" ,latch: "1838MY12",pdu: "1438MY12", fin: 1438,box: 3 , topic_extension:"/aft/can/fin/1838/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L4", latch: "1837MY12", pdu: "1437MY12", fin: 1437 ,box:4 , topic_extension:"/aft/can/fin/1837/proxy/0/long_trans/0/Proxy_covered"},
  // { value: "L5", pdu: "1836MY12", fin: 1436,box: 5 , topic_extension:"/aft/can/fin/1436/proxy/1/long_trans/1/PDU_covered"},
  { value: "L5", latch: "1835MY121",pdu: "1435MY12",  fin: 1435 ,box: 6, topic_extension:"/aft/can/fin/1835/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L6", latch: "1835MY122", pdu: "1435MY12", fin: 1435 ,box: 6, topic_extension:"/aft/can/fin/1835/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L7", latch: "1808MY12", pdu: "1408MY12", fin: 1408,box: 7, topic_extension:"/aft/can/fin/1808/proxy/0/long_trans/0/Proxy_covered"},
  // { value: "L8", pdu: "1807MY12", fin: 1407 ,box: 8, topic_extension:"/aft/can/fin/1407/proxy/1/long_trans/1/PDU_covered"},
  { value: "L8", latch: "1806MY12", pdu: "1406MY12", fin: 1406 ,box: 9, topic_extension:"/aft/can/fin/1806/proxy/0/long_trans/0/Proxy_covered"},
  // { value: "L8", latch: "1805MY12",pdu: "1405MY12",  fin: 1405 ,box: 9, topic_extension:"/aft/can/fin/1805/proxy/1/long_trans/1/PDU_covered"},
  { value: "L9", latch: "1803MY121",pdu: "1403MY12",  fin: 1403 ,box: 14, topic_extension:"/aft/can/fin/1803/proxy/0/long_trans/0/Proxy_covered"},

  { value: "L10", latch: "1940MY12", pdu: "1540MY12", fin: 1540 ,box: 17, topic_extension:"/aft/can/fin/1940/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L11", latch: "1939MY12", pdu: "1539MY12", fin: 1539 ,box: 18, topic_extension:"/aft/can/fin/1939/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L12", latch: "1938MY12",pdu: "1538MY12",  fin: 1538 ,box: 19 , topic_extension:"/aft/can/fin/1938/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L13", latch: "1937MY12", pdu: "1537MY12", fin: 1537 ,box: 20 , topic_extension:"/aft/can/fin/1937/proxy/0/long_trans/0/Proxy_covered"},

  { value: "L14", latch: "1935MY121", pdu: "1535MY12", fin: 1535 ,box: 22 , topic_extension:"/aft/can/fin/1935/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L15", latch: "1935MY122", pdu: "1535MY12", fin: 1535 ,box: 22 , topic_extension:"/aft/can/fin/1935/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L16", latch: "1908MY12", pdu: "1508MY12", fin: 1508 ,box: 23 , topic_extension:"/aft/can/fin/1908/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L17", latch: "1906MY12",pdu: "1506MY12",  fin: 1506 ,box: 25 , topic_extension:"/aft/can/fin/1906/proxy/0/long_trans/0/Proxy_covered"},
  // { value: "L17", latch: "1905MY12", pdu: "1505MY12", fin: 1505 ,box: 25, topic_extension:"/aft/can/fin/1905/proxy/1/long_trans/1/PDU_covered"},
  { value: "L18", latch: "1903MY121",pdu: "1503MY221",  fin: 1503,box: 27 , topic_extension:"/aft/can/fin/1903/proxy/0/long_trans/0/Proxy_covered"},
  { value: "L19", latch: "1901MY121", pdu: "1501MY221", fin: 1501 ,box: 30, topic_extension:"/aft/can/fin/1901/proxy/0/long_trans/0/Proxy_covered"},
  

]
