import { Button, Inline, Tabs, Tab, ColorModeProvider, RangeSlider, Rating, Stack, Card, CardActions, CardContent, CardHeader, CardMedia, CardActionArea, CardTitle, IconButton, Container, Col, Content, Row, Toast, Flex, ButtonGroup } from "@airbus/components-react"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../components/Cargo/cargo.css'
import Select from "react-select";
import { Formik } from "formik";
import axios from 'axios'
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
} from "date-fns";
import { useState } from "react";
import _ from 'lodash';

const customStyles = {
  option:() => ({
 
    height:'100%',
    color:'black',
    paddingLeft:'10px',
  }),
  
}

const Filters = ({ filters = [], onSubmit, tabValue,selectedChartsSelectionValue }) => {
	const [highDate, setHighDate] = useState(null);
	const getEndpointx =`https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&created_at=2023&topic_extension=/statistic`

	
	const asyncGetDates = async ()=>{

		const apiDatax = [];
		const { data: response } = await axios.get(getEndpointx);
		console.log(...response)

		apiDatax.push(...response || []);
		var axxx = apiDatax.filter(obj => obj.payload && obj.payload.includes( "{'flightNo': 'AIB8888'" ));
		console.log('axxx :>> ', axxx);
		const uniqueCreatedDates = _.uniqBy(axxx, 'created_at').map(obj => obj.created_at);
		console.log(uniqueCreatedDates);
		const dateObjects = uniqueCreatedDates.map(dateString => new Date(dateString));
		console.log('dateObjects :>> ', dateObjects);
		setHighDate(dateObjects);
		return dateObjects;
	}
console.log(tabValue)

const initialValues = filters.reduce((acc, filter) => {
	return {
  	...acc,
  	[filter.name]:
    filter.initialValue
    ||(	filter.type === "daterange" ? [new Date(), new Date()] : null),
	};
  }, {});

  return (
	<Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
  	{({ values, handleChange, submitForm }) => {
      for(const key in values){
   
        if( !values[key]){

const filter = filters.find(f => f.name===key && f.type === "dropdown");
if(filter){
const options = typeof filter?.options === "function" ? filter.options(values) : filter.options;
if(options.length===1){
  handleChange({ target: { name: key, value: options[0].value } });
}
}
        }
      }
    	return (
      	<div style={{ display: "flex", gap: "0.5rem" }}>

{ filters.slice(0, 2).map((filter) => {
          	const { name, options: o } = filter;

          	const options = typeof o === "function" ? o(values) : o;

          	const value = values[name];
			  {console.log(value)}
			// console.log(value)
          	const details =
            	filter.type === "dropdown" ? (
                <div style={{width: '150px'}}>
                   {	console.log(value)}
              	<Select 
                styles={customStyles}
          holder={filter.label}
                	{...filter}
                	options={options}
                	value={options?.find((o) => o.value === value) || null}
                	onChange={(v) => {
                  	handleChange({ target: { name, value: v.value } });
                    filter.onChange?.(handleChange)
                	}}
              	/>
       
                </div>
            	):(filter.type==='date' )?
				(

					<DatePicker 
					
		selected={Array.isArray(value)?value[0]:value}
		onCalendarOpen={asyncGetDates}
		highlightDates={highDate? highDate: []}	
			  isSingleDate
					  onChange={(v) => {
						console.log(name,value)
						const newValue = [v, v];
						handleChange({ target: { name, value: newValue} });
					  }}
					/>
					 
				  ) : (
            
              	<DatePicker 
                	startDate={value[0]}
                	endDate={value[1]} 
                	selectsRange
				

                	onChange={(v) => {
console.log(name,value)
                  	handleChange({ target: { name, value: v } });
                	}}
              	/>
                   
            	);
          	return (
            	<div key={name}>
              	{/* <p>{filter.label}</p> */}
              	{details}
            	</div>
          	);
        	})}
        
            <ColorModeProvider mode="light">
        	<Button style={{width:"5rem",height:"2.5rem", backgroundColor: "#1C2747"}} variant="primary" onClick={submitForm}>Search</Button>
          </ColorModeProvider>
      	</div>
    	);
  	}}
	</Formik>
  );
};



export const TopFilters =({filters,onSubmit, tabValue, selectedChartsSelectionValue})=>{


 
  


    return(
<div style={{ maxWidth: "80%", paddingBottom: "0.8rem" }}>
<Row justify='start' style={{ paddingTop: "1rem" }}>
<Filters filters={filters} onSubmit={onSubmit} tabValue={tabValue} selectedChartsSelectionValue={selectedChartsSelectionValue} />
</Row>
</div>
    )

    }