import React, { useState,useEffect } from "react";
import Box from '@material-ui/core/Box';
import { Grid,Typography, makeStyles, useTheme, useMediaQuery,Badge } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { MdEventSeat} from 'react-icons/md';
import DialogContent from '@material-ui/core/DialogContent';
import ReactEcharts from "echarts-for-react";
import Exit from './Exit'
import mainLogo1 from '../../images/bg_fleet.svg';
import mainLogo from'../../images/cabin-bg.png';
// import mainLogo from'../../images/lopa.svg';
// import mainLogo from'../../images/finalseatslopa.png';
import mainLogo2 from'../../images/Seats/seat-issue.svg';
import mainLogo3 from'../../images/Seats/seat-not-connected.svg';
import "./lopa.css"
import { Toast, Tab, ColorModeProvider,  IconButton, Container, Col, Row ,Chip,Inline} from "@airbus/components-react"
import { CheckCircle,Cancel}from '@airbus/icons/react'
import { AirlineSeatLegroomNormal, AirlineSeatReclineNormal, SettingsPhoneTwoTone } from "@material-ui/icons";
import EventSeatIcon from '@material-ui/icons/EventSeat';
import {  Error} from '@airbus/icons/react';

import armRestLeft from '../../images/Seats/seats-issue/armRestLeft.PNG'
import armRestRight from '../../images/Seats/seats-issue/armRestRight.PNG'
import seatBack from '../../images/Seats/seats-issue/seatBack.PNG'
import seatPan from '../../images/Seats/seats-issue/seatPan.PNG'
import seatTable from '../../images/Seats/seats-issue/seatTable.PNG'
import armRestLeftok from '../../images/Seats/seats-issue/armRestLeftok.PNG'
import armRestRightok from '../../images/Seats/seats-issue/armRestRightok.PNG'
import seatBackok from '../../images/Seats/seats-issue/seatBackok.PNG'
import seatPanok from '../../images/Seats/seats-issue/seatPanok.PNG'
import seatTableok from '../../images/Seats/seats-issue/seatTableok.PNG'



const SeatsLOPA2 = props => {

const totalNopercent  = (props?.totalNumberOfOnes/ 8) *100
  var cargoDoughnut  = { 
    graphic: [
      // {
      //   type: 'text',
      //   left: 'center',
      //   top: '40%', // Adjust vertical position
      //   style: {
      //     text: datax.name,
      //     fontSize: 16,
      //     fontWeight: 'bold'
      //   }
      // },
      {
        type: 'text',
        left: 'center',
        top: '45%', // Adjust vertical position
        style: {
          text: totalNopercent + '%',
          fontSize: 10,
          fontWeight: 'bold',
          fill:"white"
        }
      }
    ],
    series: [{
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        on: 'outside',
        formatter: '{b}: {c} ({d}%)'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '18',
          fontWeight: 'bold'
        }
      },
      data: [
        {
          name: '',
          value: totalNopercent,
          itemStyle: { color:"lightgreen" },
          label: {
            formatter: '{b}: {c} ({d}%)'
          }
        },
        // Add an empty data point to create the doughnut shape
        { name: '', value: 100 - totalNopercent, itemStyle: { color:"pink" } }
      ]
    }]
  };
  const topWasteWeightOverview = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 60,
      containLabel: true
    },
    legend: {
      // inactiveColor:"white",
      data: ['Weight'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['time', 'usage'],
      source:
      props.seatsUsage.length !== 0?props.seatsUsage:[{
        "time": "0800-0900",
        "usage": 0
    }]
    },
  
    ],
    yAxis: {
      name: 'Usage',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    xAxis: {
      type: 'category', name: 'Time',
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      },
      axisLabel: {
        interval: 0,
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'line' }]
  }


  // const [selectedSeat, setSelectedSet] = useState();
  const [seats, setSeats] = useState([
    [
      { id: "K1" },
      { id: "K2" },
      { id: "K3" },
      { id: "K4" },
      { id: "K5" },
      { id: "K6" },
      { id: "K7" },
      {},
      {},
      { id: "K8" },
      { id: "K9" },
      { id: "K10" },
      { id: "K11" },
    ],
    [], //space between each row
    [],
    [
      { id: "G1" },
      { id: "G2" },
      { id: "G3" },
      { id: "G4" },
      { id: "G5" },
      { id: "G6" },
      { id: "G7" },
      {},
      {},
      { id: "G8" },
      { id: "G9" },
      { id: "G10" },
      { id: "G11" },
    ], 
    [],
    [{ id: "D1" },
      { id: "D2" },
      { id: "D3" },
      { id: "D4" },
      { id: "D5" },
      { id: "D6" },
      { id: "D7" },
      {},
      {},
      { id: "D8" },
      { id: "D9" },
      { id: "D10" },
      { id: "D11" },
    ]
      , [],
      [],
      [{ id: "A1"  },
        { id: "A2" },
        { id: "A3" },
        { id: "A4" },
        { id: "A5" },
        { id: "A6" },
        { id: "A7" },
        {},
        {},
        { id: "A8" },
        { id: "A9" },
        { id: "A10" },
        { id: "A11" },
      ]
  ]);

  const [seats1, setSeats1] = useState([
    [
      { id: "K21" },
      { id: "K22" },
  
      { id: "K23" },
      { id: "K24" },
      { id: "K25" },
      //space between each seat
      { id: "K26" },
 
    ],[],
    [
      { id: "J21" },
      { id: "J22" },
      { id: "J23" },
      { id: "J24"},
      { id: "J25" },
      //space between each seat
      { id: "J26" },
 
    ],[],[{ id: "H21" },
      { id:"H22" },
  
      { id: "H23" },
      { id: "H24" },
      { id: "H25" },
      //space between each seat
      { id: "H26" },],

    [], //space between each row
    [],
    [
      { id: "G21" },
      { id: "G22" },
      { id: "G23" },
      { id: "G24" },
      { id: "G25" },
      { id: "G26" },
      { id: "G27" },
      { id: "G28" },
      { id: "G29" },

      
    ],[],
    [
      { id: "D21" },
      { id: "D22" },
      { id: "D23" },
      { id: "D24" },
      { id: "D25" },
      { id: "D26" },
      { id: "D27" },
      { id: "D28" },
      { id: "D29" },
      {},
      {},
      {},
      { id: "D30" },
      [],
      
 
    ],
    [],
    [
      { id: "E21" },
      { id: "E22" },
      { id: "E23" },
      { id: "E24" },
      { id: "E25" },
      { id: "E26" },
      { id: "E27" },
      { id: "E28" },
      { id: "E29" },
    
    ],
      [],[],
      [
        { id: "C21" },
        { id: "C22" },
    
        { id: "C23" },
        { id: "C24" },
        { id: "C25" },
        //space between each seat
        { id: "C26" },
        
      ],[],
      [
        { id: "b21" },
      { id: "B22" },
  
      { id: "B23" },
      { id: "B24" },
      { id: "B25" },
      //space between each seat
      { id: "B26" },
        [],
        
   
      ],
      [],
      [ 
        { id: "A21" },
        { id: "A22" },
    
        { id: "A23" },
        { id: "A24" },
        { id: "A25" },
        //space between each seat
        { id: "A26" },
      ]
  ]);

  const useStyles = makeStyles(theme => ({
    seat: {
  
      backgroundColor: 'blue',
      [theme.breakpoints.up('sm')]: {
        backgroundColor: 'red',
      },
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'green',
      },
      [theme.breakpoints.up('lg')]: {
        backgroundColor: 'orange',
      },
      [theme.breakpoints.up('xl')]: {
        backgroundColor: 'cyan',
      },
    },
  }));


  const firstHandle = { x: 250, y:10 };
  const secondHandle = { x: 250, y: 10 };
  const width = 800;
  const height = 350;
  const [selectedS, setS] = useState(null);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedBITETab, setSelectedBITETab] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const testfunction=()=>{
    setS(true)
    console.log("aa")
      return 1
    }

  const handleClickClose = () => {
    return setOpen(false);
    // setSelectedSeat(null)
  };

  const handleClickOpen = (seat, seatInfo) => {
console.log('seat :>> ', seat);
console.log('seatInfo :>> ', seatInfo);
    // console.log('seat :>> ', seatInfo);
    // console.log(seat)
     setOpen(true);
    //   setSeats((prev) =>
    //     prev.map((r) =>
    //       r.map((s) => ({
    //         ...s,
    //         taken: s.id === seat.id ? !s.taken : s.taken,
    //       }))
    //     )
    //   );
    //   console.log(seat.id)
      setSelectedSeat({"selectedSeat":seat.id, "time":seatInfo.time,"seatObject":seatInfo})
    }
 
    useEffect(() => {
      if (!open) {
        setSelectedSeat(null)
      }
    }, [open])

    const handleClickOpen1 = (seat) => {
      setOpen(true);
        setSeats1((prev) =>
          prev.map((r) =>
            r.map((s) => ({
              ...s,
              taken: s.id === seat.id ? !s.taken : s.taken,
            }))
          )
        );
        console.log(seat.id)
        setSelectedSeat(seat.id)
      }
   
      useEffect(() => {
        if (!open) {
          setSelectedSeat(null)
        }
      }, [open])
  
  const path = (
    <path
      d={`M20, ${height / 2}
        C${firstHandle.x},${firstHandle.y} ${secondHandle.x},${
        secondHandle.y
      } ${width / 2},20
        C${width - firstHandle.x},${firstHandle.y} ${width - secondHandle.x},${
        secondHandle.y
      } ${width - 20},${height / 2}
        C${width - firstHandle.x},${height - firstHandle.y} ${
        width - secondHandle.x
      },${height - secondHandle.y} ${width / 2},${height - 20}
        C${firstHandle.x},${height - firstHandle.y} ${secondHandle.x},${
        height - secondHandle.y
      } 20,${height / 2}
        `}
      stroke="black"
      fill="none"
    />
  );

  return (
    <div>
    <div> 

      <Dialog open={open} onClose={handleClickClose} fullWidth={true} maxWidth="lg" hideBackdrop={false}>   
      <DialogContent style={{backgroundColor:"#1C2747", color:"#1C2747"}}> 
      <Inline>
      <ReactEcharts
                option={topWasteWeightOverview}
                style={{
                  width: matches ? 1000: 1000,
                  height: matches ? 510 : 350,
                }} />
               
                    <ReactEcharts
                option={cargoDoughnut}
                style={{
                  width: matches ? 100: 100,
                  height: matches ? 100 : 100,
                }} />
                    </Inline>
                </DialogContent>
          </Dialog>
      
          </div>
       
                
         
          <div >
     
          { props.showEconomy=="Filters" &&     

      <Box style={{width:"57rem", height:"47rem"}}>
                                          <img  src={mainLogo1} style={{width:"53rem",height:"20rem" ,  position: "absolute"}} />

        <Row justify="center" style={{marginLeft:"2.5rem"}}> 

        <Col md={6}>  
            <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ marginTop: "5rem",marginLeft:"8rem" }}>{
                            [...Array(
                           
                              { id: "K1" },
                              { id: "K2" },
                              { id: "K3" },
                              { id: "K4" },
                              { id: "K5" },
                              { id: "K6" },
                              { id: "K7" },
                              { id: "K8" },
                              { id: "K9" },
                              { id: "K10" },
                              { id: "K11" },
                        
                            )].map((i) =><div>              <Box key={i} 
                            onClick= {()=>{  handleClickOpen(i)}}
                            style={{
                              backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                     

                            >      <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{i.id}</div></Box>
                             {/* <Error style={{color:"red" }}/> */}
            </div>
                            )}
                          
                    
                          </Row>

                          
                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "2rem",paddingLeft:"8rem" }}>{
                            [...Array(
                           
                              { id: "G1" },
                              { id: "G2" },
                              { id: "G3" },
                              { id: "G4" },
                              { id: "G5" },
                              { id: "G6" },
                              { id: "G7" },
                              { id: "G8" },
                              { id: "G9" },
                              { id: "G10" },
                              { id: "G11" },
                           
                        
                            )].map((i) => <Box  style={{
                              backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            onClick= {()=>{  handleClickOpen(i)}}

                            >   <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{i.id}</div></Box>)}
                          </Row>
                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop:"0.5rem" ,paddingLeft:"8rem" }}>{
                            [...Array(
                           
                              { id: "D1" },
                              { id: "D2" },
                              { id: "D3"},
                              { id: "D4" },
                              { id: "D5" },
                              { id: "D6" },
                              { id: "D7" },
                              { id: "D8" },
                              { id: "D9" },
                              { id: "D10" },
                              { id: "D11" },
                        
                            )].map((id) => <Box  style={{
                              backgroundColor: id.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            onClick= {()=>{  handleClickOpen(id)}}

                            >   <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{id.id}</div></Box>)}
                          </Row>

                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "2rem",paddingLeft:"8rem" }}>{
                            [...Array(
                           
                              { id: "A1" },
                              { id: "A2" },
                              { id: "A3" },
                              { id: "A4" },
                              { id: "A5" },
                              { id: "A6" },
                              { id: "A7" },
                              { id: "A8" },
                              { id: "A9" },
                              { id: "A10" },
                              { id: "A11" },
                           
                        
                            )].map((id) => <Box  style={{
                              backgroundColor: id.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            onClick= {()=>{  handleClickOpen(id)}}

                            >   <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{id.id}</div></Box>)}
                          </Row>
                          </Col>
                          <Col md={4} style={{paddingLeft:"2rem"}}> 
    
                     
                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "7.5rem"}}>{
                            [...Array(
                 
                              { id: "01" },
                            )].map((id) => <Box key={id} style={{
                              backgroundColor: id.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            onClick= {()=>{   handleClickOpen(id,props.mockData["0101"])
                            console.log(props.mockData["0101"]);
                              
                              }}

                            >  <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{id.id}</div></Box>)}
                          </Row>
                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop:"0.3rem"  }}>{
                            [...Array(
                           
                       
                              { id: "02" },
                           
                        
                            )].map((id) => <Box key={id} style={{
                              backgroundColor: id.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            
                            onClick= {()=>{  handleClickOpen(id,props.mockData["0102"])
                            console.log(props.mockData["0102"]);
                              
                              }}
                            >  <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{id.id}</div></Box>)}
                          </Row>
                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop:"0.3rem" }}>{
                            [...Array(
                           
                      
                              { id: "03" },
                   
                           
                        
                            )].map((id) => <Box key={id} style={{
                              backgroundColor: id.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            
                            onClick= {()=>{ console.log('props.mockData[0] :>> ', props.mockData);
                              handleClickOpen(id,props.mockData["0103"])
                              console.log(props.mockData["0103"]);
                              
                              }}
                            >  <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{id.id}</div></Box>)}
                          </Row>

                        
                  
                          </Col>
                          </Row>
                          </Box>}


                         
                        
                          {/* <Box style={{ width:"55rem", height:"15rem"}}> */}

    
   



 </div>
   
{/* { props.showEconomy=='Business'&& 
     <div>
    <Box style={{backgroundColor:"grey", width:"40rem", height:"15rem"}}>
<div style={{marginLeft:"6rem"}}>  
<Row  sm={12} md={12} lg={5} xs={12} style={{ paddingTop:"1rem"}}>{
                [...Array(
               
                  { id: "K1" },
                  { id: "K2" },
                  { id: "K3", error: 1 },
                  { id: "K4" },
                  { id: "K5" },
                  { id: "K6" },
                  { id: "K7" },
                  { id: "K8" },
                  { id: "K9" },
                  { id: "K10" },
                  { id: "K11" },
            
                  )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                    backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                  }}
                  
  
                  >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
              
        
              </Row>

              
              <Row sm={12} md={12} lg={5} xs={12} style={{ marginTop: "2rem" }}>{
                [...Array(
               
                  { id: "G1" },
                  { id: "G2" },
                  { id: "G3", error: 1 },
                  { id: "G4" },
                  { id: "G5" },
                  { id: "G6" },
                  { id: "G7" },
                  { id: "G8" },
                  { id: "G9" },
                  { id: "G10" },
                  { id: "G11" },
               
            
                )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                  backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                }}
                
                onClick= {()=>{  handleClickOpen(i)}}
                >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
              </Row>
              <Row  sm={12} md={12} lg={5} xs={12} style={{ paddingTop:"0.5rem"  }}>{
                [...Array(
               
                  { id: "D1" },
                  { id: "D2" },
                  { id: "D3", error: 1 },
                  { id: "D4" },
                  { id: "D5" },
                  { id: "D6" },
                  { id: "D7" },
                  { id: "D8" },
                  { id: "D9" },
                  { id: "D10" },
                  { id: "D11" },
            
                  )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                    backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                  }}
                  
                  onClick= {()=>{  handleClickOpen(i)}}
                  >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
              </Row>

              <Row  sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "2rem" }}>{
                [...Array(
               
                  { id: "A1" },
                  { id: "A2" },
                  { id: "A3", error: 1 },
                  { id: "A4" },
                  { id: "A5" },
                  { id: "A6" },
                  { id: "A7" },
                  { id: "A8" },
                  { id: "A9" },
                  { id: "A10" },
                  { id: "A11" },
               
            
                  )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                    backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                  }}
                  
                  onClick= {()=>{  handleClickOpen(i)}}
                  >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
              </Row>
              </div>
    


   

 

 

  </Box>  </div>} */}




  { props.showEconomy=='Economy'&& 
   <Box style={{width:"57rem", height:"50rem"}}>
   <img  src={mainLogo1} style={{width:"55rem",height:"18rem" ,  position: "absolute"}} />

     <Container style={{paddingLeft:"7rem",paddingTop:"3rem"}}>
    <Row justify="center">
        <Col xxs={4} md= {4}>
        <Box style={{   display: 'flex', marginTop:"2rem", backgroundColor:"blue", width:"15rem", height:"7rem",  justifyContent: 'center',
    alignItems: 'center', textAlign:"center"}}><p> BC</p></Box>
        </Col>
        <Col md={4}>
        {/* <Row justify='start' sm={2} md={2} lg={2} xs={2} style={{marginLeft:"2rem", marginTop:"2.2rem"}}>{
                            [...Array(
                           
                              // { id: "G21" },
                              // { id: "G22" },
                              // { id: "G23" },
                              // { id: "G24" },
                              // { id: "G25" },
                              // { id: "G26" },
                              // { id: "G27" },
                              // { id: "G28" },
                              // { id: "0101" },
                              )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                                backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                              }}
                              onClick= {()=>{  handleClickOpen(i,props?.formattedDataArray[0])
                              console.log('formattedDataArray[0] :>> ', props?.formattedDataArray[0]);
                              
                              }}
              
                              >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
                          </Row> */}
                          {/* <Row justify='start' sm={2} md={2} lg={2} xs={2} style={{ paddingTop:"0.3rem", marginLeft:"2rem"  }}>{
                            [...Array(
                           
                              // { id: "D21" },
                              // { id: "D22" },
                              // { id: "D23"},
                              // { id: "D24" },
                              // { id: "D25" },
                              // { id: "D26" },
                              // { id: "D27" },
                              // { id: "D28" },
                              // { id: "0102" },
                           
                              )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                                backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                              }}
                              onClick= {()=>{
                                
                                console.log('formattedDataArray[1] :>> ', props?.formattedDataArray[1]);
                                handleClickOpen(i)}}
              
                              >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
                          </Row> */}
                          {/* <Row justify='start' sm={2} md={2} lg={2} xs={2} style={{ paddingTop:"0.3rem" , marginLeft:"2rem"}}>{
                            [...Array(
                           
                              // { id: "E21" },
                              // { id: "E22" },
                              // { id: "E23", error: 1 },
                              // { id: "E24" },
                              // { id: "E25" },
                              // { id: "E26" },
                              // { id: "E27" },
                              // { id: "E28" },
                              // { id: "0103" },
               
                              )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                                backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                              }}
                              
                              onClick= {()=>{ 
                               
                                handleClickOpen(i,props.mockData[2])}}
                              >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
                          </Row> */}
        </Col>
      </Row>

      </Container>
      </Box>
      
      }








      </div>
  );
};

// const Seat1 = ({ seat, rowIndex, seatIndex, height, width, fill, onClick ,fill1,matches},props) => {
//   // console.log(matches)
//   // console.log(props.showEconomy)
//   const initialX = ( matches)?250:250;
//   const initialY = 28;
//   const xOffset = seatIndex * 30;
//   const yOffset = rowIndex * 13;
//   return !seat.id ? null : (
//     <>
//       <svg
      
//         x={initialX + xOffset}
//         y={initialY + yOffset}
//         height={30}
//         width={45}
//         onClick={onClick}
//         style={{ cursor: "pointer", position:"absolute" }}
//       >
//        {/* <MdEventSeat className={`seat-icon-${fill}`}size={14} y={"0.4em"} x={"0.2em"}  style={{ transform:'rotate(90deg) translate(20% ,-65%)'}} /> */}
//         <rect x={0} y={0} width={24} height={24} stroke={fill1} fill="grey"  >
 
//  </rect>
 

//         <text
//         fill={fill1}
//           x={0}
//           y={8}
//           textAnchor="start"
//           style={{ fontSize: "1.1rem", fontWeight: "bold" }}

//         >
//           {seat.id}
//         </text>
//       </svg>

//       {/* <rect x = '2' y = "5" width = "50" height = "100" fill = "none" stroke = "lightgreen" stroke-width = "2" transform = "rotate(90 90 90)"/> */}
      
//     </>
//   );
// };


// const Seat = ({ seat, rowIndex, seatIndex, height, width, fill, onClick ,fill1,matches}) => {
//   const initialX = ( matches)?250:200
//   const initialY = 50;
//   const xOffset = seatIndex * 30;
//   const yOffset = rowIndex * 15;
//   return !seat.id ? null : 
  
//   (
//     <>
//       <svg
      
//         x={initialX + xOffset}
//         y={initialY + yOffset}
//         height={200}
//         width={80}
//         onClick={onClick}
//         style={{ cursor: "pointer" }}
//       >
//        {/* <MdEventSeat className={`seat-icon-${fill}`}size={14} y={"0.4em"} x={"0.2em"}  style={{ transform:'rotate(90deg) translate(20% ,-65%)'}} /> */}
      
//         <rect x={0} y={0} width={24} height={24} stroke={fill1} fill="grey" >
 
//  </rect>
//         <text
//         fill={fill1}
//           x={12}
//           y={15}
//           textAnchor="middle"
//           style={{ fontSize: "1.1rem", fontWeight: "bold" }}

//         >
        
//           {seat.id}
//                </text>
//       </svg>

     
      
//     </>
//   );
// };

export default SeatsLOPA2;
