export const mockMainCargoData = Array.from({length:50},(element,index)=>
{
  return{id:index+1,cargoError:0 ,cargoWeight:37185 ,cargoMessage:"Offload Container", movement:5}
}
)

export const mockLowerCargoData = Array.from({length:50},(element,index)=>
{
  return{id:index+1,cargoError:1 ,cargoWeight:37185 ,cargoMessage:"Offload Container", movement: 5}
}
)


export const cargoMovement1 = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    right: 100,
    containLabel: true
  },
  legend: {
    // inactiveColor:"white",
    data: ['No. of Movements'],
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['censusFoodName', 'quantity'],
    source: [
      {
        "censusFoodName": "AFT_Or_In",
        "censusCategoryName": "AFT_Or_In",
        "quantity": 30
      }, {
        "censusFoodName": "FWD_Or_Out",
        "censusCategoryName": "FWD_Or_Out",
        "quantity": 40
      },
      {
        "censusFoodName": "PDU_Hold",
        "censusCategoryName": "PDU_Hold",
        "quantity": 10
      },
    ]
  }, {
    transform: [
      {
        type: 'sort',
        config: { dimension: 'quantity', order: 'desc' }
      }
    ],
  }
  ],
  xAxis: {
    type: 'value', name: 'No. of Movements',
    nameLocation: 'center',
    nameTextStyle: {
      align: 'center',
      verticalAlign: 'top',
      /**
       * the top padding will shift the name down so that it does not overlap with the axis-labels
       * t-l-b-r
       */
      padding: [20, 0, 0, 0],
    },
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  yAxis: {
    type: 'category', name: 'Movement',
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  series: [{ type: 'bar', name: "No. of Movements" }]
}


export const cargoPDU = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    right: 50,
    containLabel: "true"
  },
  legend: {
    // inactiveColor:"white",
    data: ['Usage'],
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['flightDate', 'cost'],
    source:
      [
        {
          "cost": 21.2,
          "equivalencePerDay": 0.0527,
          "weight": 0.559,
          "flightDate": "2022-12-07",
        },
        {
          "cost": 30,
          "equivalencePerDay": 0.0527,
          "weight": 0.559
          ,
          "flightDate": "2022-12-08",
        },
        {
          "cost": 30,
          "equivalencePerDay": 0.0527,
          "weight": 0.559
          ,
          "flightDate": "2022-12-13",
        }
      ],
  },
    // {fromDatasetIndex: 0,
    //   transform: {
    //     type: 'sort',
    //     config: { dimension:"servedQuantity", order: 'desc' }
    //   }
    // }
  ],
  yAxis: {
    name: 'Usage',
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  xAxis: {
    type: 'category', name: 'Flight Date',
    nameLocation: 'center',
    nameTextStyle: {
      align: 'center',
      verticalAlign: 'top',
      /**
       * the top padding will shift the name down so that it does not overlap with the axis-labels
       * t-l-b-r
       */
      padding: [20, 0, 0, 0],
    },
    axisLabel: {
      interval: 0,
    },
    axisLine: {
      lineStyle: {
        color: 'white'
      },
    }
  },
  series: [{ type: 'line', name: "Usage" }]
}
export const mockCargoPayload = 
[
  {
    "id":"47256",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Aft_or_In",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 00:00:00.000000"
  },
  {
    "id":"47255",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Hold",
    "header":"{}",
    "payload":"1",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:52.301383"
  },
  {
    "id":"47254",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_covered",
    "header":"{}",
    "payload":"1",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:52.288997"
  },
  {
    "id":"47253",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_uncovered",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:52.268939"
  },
  {
    "id":"47252",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Forward_or_Out",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:51.940808"
  },
  {
    "id":"47251",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Aft_or_In",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:51.919978"
  },
  {
    "id":"47250",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Hold",
    "header":"{}",
    "payload":"1",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:51.829160"
  },
  {
    "id":"47249",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_covered",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:51.805321"
  },
  {
    "id":"47248",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_uncovered",
    "header":"{}",
    "payload":"1",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:51.785058"
  },
  {
    "id":"47247",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Forward_or_Out",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:51.115827"
  },
  {
    "id":"47246",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Aft_or_In",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:51.101703"
  },
  {
    "id":"47245",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Hold",
    "header":"{}",
    "payload":"1",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:51.088975"
  },
  {
    "id":"47244",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_covered",
    "header":"{}",
    "payload":"1",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:51.073733"
  },
  {
    "id":"47243",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_uncovered",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:51.059990"
  },
  {
    "id":"47242",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Forward_or_Out",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:50.873055"
  },
  {
    "id":"47241",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Aft_or_In",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:50.860312"
  },
  {
    "id":"47240",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Hold",
    "header":"{}",
    "payload":"1",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:50.847134"
  },
  {
    "id":"47239",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_covered",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:50.834191"
  },
  {
    "id":"47238",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_uncovered",
    "header":"{}",
    "payload":"1",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 15:22:50.819723"
  },
  {
    "id":"47237",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Forward_or_Out",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-08 00:00:00.000000"
  },
  {
    "id":"47236",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Aft_or_In",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-10 15:22:50.394552"
  },
  {
    "id":"47235",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_Hold",
    "header":"{}",
    "payload":"1",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-10 15:22:50.382114"
  },
  {
    "id":"47234",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_covered",
    "header":"{}",
    "payload":"1",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-10 15:22:50.360360"
  },
  {
    "id":"47233",
    "topic_prefix":"v1/crew/cargo",
    "topic_action":"data",
    "topic_extension":"/aft/can/fin/1540/proxy/0/long_trans/1/PDU_uncovered",
    "header":"{}",
    "payload":"0",
    "tag_no_send_to_ground":"False",
    "transfer_status":"transfer_status_enum.not_transferred",
    "tag_no_store":"False",
    "tag_user_timestamp":"\"\"",
    "tag_private_data":"0",
    "dump_file_name":"None",
    "tag_custom":"None",
    "created_at":"2023-02-10 15:22:50.343849"
  }
]