const awsExports = {
    Auth : {
    userNameAlias : "email",
    userPoolId : "eu-west-1_wL36NFZ1s",
    region: "eu-west-1",
    userPoolWebClientId : "315pil0744mo42oknpnrbpjtum",
    identityProvider: 'onelogin'
 ,
 oauth: {
  domain: 'hof-sso-staging.auth.eu-west-1.amazoncognito.com',
  scope: ['email', 'profile', 'openid','aws.cognito.signin.user.admin'],
  redirectSignIn: 'https://aeiot-dashboard.airbus-v.com',
  redirectSignOut: 'https://aeiot-dashboard.airbus-v.com/signedout',
  // redirectSignIn: 'https://aeiot-dashboard.airbus-v.com',
  // redirectSignOut: 'https://aeiot-dashboard.airbus-v.com/signedout',
  responseType: 'code'
}
// oauth: {
//     domain: 'hof-sso-staging.auth.eu-west-1.amazoncognito.com',
//     scope: ['email', 'profile', 'openid','aws.cognito.signin.user.admin'],
//     redirectSignIn: 'http://localhost:3000',
//     redirectSignOut: 'http://localhost:3000',
//     // redirectSignIn: 'https://aeiot-dashboard.airbus-v.com',
//     // redirectSignOut: 'https://aeiot-dashboard.airbus-v.com/signedout',
//     responseType: 'code'
//   }
 
  }}


  export default awsExports