import React, { useEffect, useState, useRef } from 'react'
// import { SparkLine } from '../../components//SparkLine'
// import { SparkLine2 } from '../../components/backend/dashboard/SparkLine2'
import 'echarts/lib/component/grid';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Grid, Typography,  Backdrop ,  CircularProgress,} from '@material-ui/core';
import { BorderColor, Power, CheckCircle, Refresh, Wifi, WifiOff, Error, AirplaneModeActive, LocalCafe, AirlineSeatReclineNormal, AllInbox, Kitchen, SearchIcon, NotificationsIcon, HelpIcon, Notification, AirplanemodeActive, Chevronleft, Chevronright } from '@airbus/icons/react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ReactEcharts from "echarts-for-react";
import { Select, Inline, Tabs, Tab, ColorModeProvider, RangeSlider, Rating, Stack, Card, CardActions, CardContent, CardHeader, CardMedia, CardActionArea, CardTitle, IconButton, Container, Col, Content, Row, Toast, Flex, ButtonGroup } from "@airbus/components-react"
import axios from 'axios'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useTheme, LinearProgress } from '@material-ui/core';
import {
  mealsServedByDate,
} from '../../../components/Catering/CateringData'
import _ from 'lodash';
import SeatsLOPA from '../SeatsLOPA';
import SeatsLOPA2 from '../SeatsLOPA2';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import jsonData from './mockseatsdata'; // Import JSON file
import seatsUsageData from './mockseatsusage0101.json'

import { GaugeChart, PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
  startOfDay,
  endOfDay,
} from "date-fns";
import { DatePicker, Divider } from "@airbus/components-react"
import { CheckCircle as Check,Cancel}from '@airbus/icons/react'
import { SeatsFilters } from "../../../components/Seats/SeatsFilters";

echarts.use([GaugeChart, CanvasRenderer]);
process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';






function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}
const seatsData = [{ "id": 1, "mealServed": 0, "class": "BC", "flightNumber": "FN-18611" }, { "id": 2, "mealServed": 1, "class": "BC", "flightNumber": "FN-18611" }, { "id": 3, "mealServed": 1, "class": "BC", "flightNumber": "FN-18612" }, { "id": 4, "mealsServed": 0, "class": "BC", "flightNumber": "FN-18611" }, { "id": 5, "mealsServed": 1, "class": "BC", "flightNumber": "FN-18611" }, { "id": 6, "mealsServed": 0, "class": "BC", "flightNumber": "FN-18612" }, { "id": 7, "mealsServed": 0, "class": "BC", "flightNumber": "FN-18611" }, { "id": 8, "mealsServed": 1, "class": "BC", "flightNumber": "FN-18611" }, { "id": 9, "mealsServed": 0, "class": "EC", "flightNumber": "FN-18611" }, { "id": 10, "mealsServed": 1, "class": "EC", "flightNumber": "FN-18612" }, { "id": 11, "mealsServed": 1, "class": "EC", "flightNumber": "FN-18611" }, { "id": 12, "mealsServed": 0, "class": "EC", "flightNumber": "FN-18611" }, { "id": 13, "mealsServed": 1, "class": "EC", "flightNumber": "FN-18611" }, { "id": 14, "mealsServed": 0, "class": "EC", "flightNumber": "FN-18611" }, { "id": 15, "mealsServed": 0, "class": "EC", "flightNumber": "FN-18611" }, { "id": 16, "mealsServed": 1, "class": "EC", "flightNumber": "FN-18611" },
{ "id": 17, "mealsServed": 1, "class": "EC", "flightNumber": "FN-18613" }, { "id": 18, "mealsServed": 1, "class": "EC", "flightNumber": "FN-18613" }, { "id": 19, "mealsServed": 1, "class": "EC", "flightNumber": "FN-18613" }, { "id": 20, "mealsServed": 1, "class": "EC", "flightNumber": "FN-18613" }]



const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
 
    opacity: 0.7,
  },
}));

const filters = [

  {
    name: "dateRange",
    // label: "Range",
    type: "daterange",
    initialValue: null,
    // initialValue:Array(2).fill(new Date('2023-02-08'))
  },
  {
    name: "flightNo",
    label: "Flight No.",
    type: "dropdown",
    initialValue: "AIB8888",
    options: [
      { label: "AIB8888", value: "AIB8888" },
      // { label: "MU5100", value: "MU5100" },
      // { label: "MU5101", value: "MU5101" },
    ],

    onChange: (handleChange) => {
      console.log('changing')
      handleChange({ target: { name: 'origin', value: null } })
      handleChange({ target: { name: 'destination', value: null } })

    }
  },

];


export function Seats({ handleListItemClick }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    new Date("2023-02-08"),
    new Date("2023-02-08"),
  ]);
  const data1 = mealsServedByDate
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const [open, setOpen] = useState(false);
  const [alignment, setAlignment] = React.useState('%');
  const [alignment1, setAlignment1] = React.useState('%');
  const [alignment2, setAlignment2] = React.useState('%');
  const [alignment3, setAlignment3] = React.useState('%');
  const [selectedFilterValue, setSelectedFilterValue] = useState("All");
  const [selectedSeatZoneValue, setSelectedSeatZoneValue] = useState("Filters");
  const [selectedFlightOption, setSelectedFlightOption] = useState("AIB8888");
  const [selectedOriginValue, setSelectedOriginValue] = useState("WBGG");
  const [name, setName] = useState("Food")
  const [selectedDestValue, setSelectedDestValue] = useState("WMKK");
  const [selectedChartsSelectionValue, setSelectedChartsSelectionValue] = useState("overview");
  const [selectedWasteChartsSelectionValue, setSelectedWasteChartsSelectionValue] = useState("cost");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [seatsData, setSeatsData] = useState([]);
  const[mockData,setMockData]=useState([]);
  const [seatsUsage, setSeatsUsage] = useState([]);
  const [totalNumberofOnes, setTotalNumberofOnes] = useState(0);
  const [intervalOption, setIntervalOption] = React.useState([
    "datemonthyear",
    "mealsServed",
    "Day",
  ]); 
/////
const getEndpoint = (createdOn) =>
` https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/compartment&created_at=${createdOn}&topic_action=data`
//  const getEndpoint2 = (createdOn) =>
//  ` https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/compartment&created_at=${createdOn}&topic_action=data`
//  //movelater




const onSubmit = async (values = { dateRange: [new Date(), new Date()] }) => {
  setLoading(true);

  try {
    const { dateRange } = values;
    setDateRange(dateRange);
    const [start, end] = dateRange;
    const [startYear, endYear] = [start, end].map((d) => d.getFullYear());
    const [startMonth, endMonth] = [start, end].map((d) => format(d, "MM"));
    const [startDay, endDay] = [start, end].map((d) => format(d, "dd"));
    const isSameMonth = startMonth === endMonth;
    const isSameDay = startDay === endDay;
    let createdOn = "";
    if (isSameMonth && isSameDay)
      createdOn = `${startYear}-${startMonth}-${startDay}`;
    else if (isSameMonth) createdOn = `${startYear}-${startMonth}`;
    else {
      const range =
        differenceInMonths(endOfMonth(end), startOfMonth(start)) + 1;
      createdOn = Array(range)
        .fill("")
        .map((_, index) => format(addMonths(start, index), "yyyy-MM"));
    }

    console.log(createdOn);

    // const { data: response } = await axios.get(getEndpoint(createdOn));
    // const apiData = response;
    const apiData = jsonData.data;
    console.log('mockSeatsData:>> ', apiData);
    const filteredData = apiData.filter((d) => {
      const date = new Date(d.created_at);
      return date >= startOfDay(start) && date <= endOfDay(end);
    });




// count usage acording to payload 0 to 1 or remain 1 to 1 for this date
const payloadData =seatsUsageData.data

console.log('payloadData :>> ', payloadData);

let currentHour = null;
let currentUsage = 0;
let result = [{}];

for (let hour = 8; hour < 18; hour++) {
  const startTime = hour < 10 ? `0${hour}00` : `${hour}00`;
  const endTime = hour + 1 < 10 ? `0${hour + 1}00` : `${hour + 1}00`;
  let usage = 0;

  payloadData.forEach(data => {
    const timestamp = new Date(data.created_at).getUTCHours();
    const dataUsage = parseInt(data.payload);

    if (timestamp === hour) {
      if (dataUsage === 1) {
        usage = 1;
      }
    }
  });

  result.push({ time: `${startTime}-${endTime}`, usage });
}

const totalNumberOfOnes = result.reduce((sum, obj) => sum + (obj.usage === 1 ? 1 : 0), 0);
console.log('totalNumberofOnes :>> ', totalNumberOfOnes);
console.log(result);
setTotalNumberofOnes(totalNumberOfOnes)
setSeatsUsage(result)

    /////////

   // Extract economy seats and count unique seat numbers
const economySeats = apiData[0].seats.economyClass;
const uniqueSeatNumbers = new Set(economySeats.map(seat => seat.seatNumber));
const numberOfUniqueEconomySeats = uniqueSeatNumbers.size;

console.log("Number of unique economy seats: " + numberOfUniqueEconomySeats + "/3");


    setSeatsData(filteredData);


////


const maxCounts = {
  armRest: 70,
  seatTable: 100,
  seatPan: 1000,
  seatBack: 200
};

const checkSeatParts = (seat) => {
  const errors = {};
  const seatNumber = seat.seatNumber;
  const seatTime = seat.timeStamp
  // Check each seat part against the max counts
  const partsToCheck = ['armRest', 'seatTable', 'seatPan', 'seatBack'];
  partsToCheck.forEach(part => {
    if (seat.parts[part] > maxCounts[part]) {
      errors[part] = 1;
      errors["time"] = seatTime;
    }
  });

  // If errors exist, return the seat number as the key in the errors object
  if (Object.keys(errors).length > 0) {
    return { [seatNumber]: errors};
  }

  // If no errors, return an empty object
  return {};
};

const checkEconomySeats = (economySeatsx) => {
  // Use reduce to merge the errors for all seats into a single object
  return economySeatsx.reduce((errors, seat) => {
    const seatErrors = checkSeatParts(seat);
    // Merge the current seat's errors into the existing errors object
    return { ...errors, ...seatErrors };
  }, {});
};

const economySeatsx = jsonData.data[0].seats.economyClass;
const seatErrors = checkEconomySeats(economySeatsx);
console.log('seatErrors :>> ', seatErrors);

///// count total number of errors per seat basis 


for (const key in seatErrors) {
  const seat = seatErrors[key];
  let total = 0;

  // Sum up all occurrences of the value 1
  for (const innerKey in seat) {
    if (seat[innerKey] === 1) {
      total++;
    }
  }

  // Add a new key-value pair 'total'
  seat.total = total;
}

console.log(seatErrors);
setMockData(seatErrors);

////// get seat usage from mockseatsdata.json directly///
const economySeatsDatausage = jsonData.data[0].seats.economyClass.map(seat => {
  const { seatNumber, seatUsage, timeStamp } = seat;
  return { seatNumber, usage: seatUsage, time: timeStamp };
});


console.log('object :>> ',economySeatsDatausage);

  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    // console.log('seatsData :>> ', seatsData);
    setLoading(false);
  }
};
const formattedDataArray=[]
//////

  // useEffect(() => {
  //   const fetchSeatsData = async () => {
  //     try {
  //       const response = await axios.get('https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=compartment&created_at=2023-06'
  //       )
    

  //       console.log('seatsresponse.data :>> ', response.data);
  //       setSeatsData(response.data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   fetchSeatsData();
  // }, []);



  // Use lodash to filter and group the data by the numeric part of "topic_extension"
  // const groupedData = _.chain(seatsData)
  //   .filter(item => {
  //     const numericPartMatch = item.topic_extension.match(/\/(\d+)\//);
  //     return numericPartMatch && numericPartMatch[1].length === 4; // Filter out non-4-digit numbers
  //   })
  //   .groupBy(item => {
  //     const numericPartMatch = item.topic_extension.match(/\/(\d+)\//);
  //     return numericPartMatch ? numericPartMatch[1] : '';
  //   })
  //   .value();

  // Convert the grouped data to an array of objects filter for only data containing "cycles"
  // const formattedDataArray = _.chain(groupedData)
  //   .map((group, key) => ({ key, data: group.filter(innerItem => innerItem.topic_extension.includes("cycles")) }))
  //   .value();
  //   console.log('Formatted Data Array:', formattedDataArray.sort((a, b) => parseInt(a.key, 10) - parseInt(b.key, 10)));
   


// Process the data (cycles only) - seat no, seat part 
// const transformedData = (formattedDataArray.sort((a, b) => parseInt(a.key, 10) - parseInt(b.key, 10))).map(item => {
//   const newData = {};
//   item.data.forEach(obj => {
//     const lastPartOfTopic = obj.topic_extension.split('/').pop();
//     if (!newData[lastPartOfTopic]) {
//       newData[lastPartOfTopic] = [];
//     }
//     newData[lastPartOfTopic].push(obj);
//   });

//   return { key: item.key, data: newData };
// });

// console.log(transformedData);







  { handleListItemClick(null, 2) }
  const [rangeValue, setRangeValue] = useState([new Date(2022, 11, 7), new Date(2022, 11, 13)])
  const options = [
    { label: "Flight Number", value: "flightNumber" },
    { label: "Class", value: "class" },
  ];

  const handleChange = (event, newValue) => {
    setTimeout(() => { setValue(newValue) }, 1)
  };

  const mainOption2 = {
    // title: {
    //   text: 'Seats Defects (BC) per flight'
    // },
    // legend: {},
    tooltip: {},
    dataset: {
      dimensions: ['flightNumber', 'mealsServed', 'class'],
      source: []
    },
    xAxis: { type: 'category' },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{ type: 'bar' },
    ]
  };
  // group by day
  const datemonthyearresult = data1.reduce((r, { timestamp }) => {
    let dateObj = new Date(timestamp);
    let datemonthyear = dateObj.toLocaleString("en-us", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    if (!r[datemonthyear]) r[datemonthyear] = { datemonthyear, mealsServed: 1 };
    else r[datemonthyear].mealsServed++;
    return r;
  }, {});

  const finalDateMonthYearResult = Object.values(datemonthyearresult);
  const [DSOption, setDSOption] = React.useState(finalDateMonthYearResult);
  const handleIntervalChange = (event) => {
    console.log(event.target.value);
    const ds = event.target.value;
    setIntervalOption(ds);
    setDSOption(ds[3]);
  };
  // group by year
  const yearresult = data1.reduce((r, { timestamp }) => {
    let dateObj = new Date(timestamp);
    let year = dateObj.toLocaleString("en-us", { year: "numeric" });
    if (!r[year]) r[year] = { year, mealsServed: 1 };
    else r[year].mealsServed++;
    return r;
  }, {});
  // console.log(data1);
  // console.log(Object.values(yearresult));
  const finalYearResult = Object.values(yearresult);
  // group by month
  const monthyearresult = data1.reduce((r, { timestamp }) => {
    let dateObj = new Date(timestamp);
    let monthyear = dateObj.toLocaleString("en-us", {
      month: "long",
      year: "numeric",
    });
    if (!r[monthyear]) r[monthyear] = { monthyear, mealsServed: 1 };
    else r[monthyear].mealsServed++;
    return r;
  }, {});
  // console.log(data1);
  // console.log(Object.values(monthyearresult));
  const finalMonthYearResult = Object.values(monthyearresult);
  // Seat defect interval - dropdown list options
  const flightOptions = [
    { label: "AIB8888", value: "AIB8888" },
    // { label: "Option 2", value: "option2" },
    // { label: "Option 3", value: "option3" },
  ];
  const filterOptions = [
    { label: "Aircraft View", value: "All" },
    { label: "Business Class", value: "Business" },
    { label: "Economy Class", value: "Economy" },

    // { label: "Option 2", value: "option2" },
    // { label: "Option 3", value: "option3" },
  ];
  const originOptions = [
    { label: "WBGG", value: "WBGG" },
    // { label: "Option 2", value: "option2" },
    // { label: "Option 3", value: "option3" },
  ];
  const destOptions = [
    { label: "WMKK", value: "WMKK" },
    // { label: "Option 2", value: "option2" },
    // { label: "Option 3", value: "option3" },
  ];
  const chartSelectionOptions = [
    { label: "Overview", value: "overview" },
    { label: "Food Served", value: "served" },
    { label: "Food Wastage", value: "wastage" },
    // { label: "Option 2", value: "option2" },
    // { label: "Option 3", value: "option3" },
  ];

  const seatZoneOptions = [
    { label: "Aircraft View", value: "Filters" },
    // { label: "Business Class", value: "Business" },
    { label: "Economy Class", value: "Economy" },

    // { label: "Option 2", value: "option2" },
    // { label: "Option 3", value: "option3" },
  ];






  const handleSetSelectedSeatZoneValue = (event, seatZoneValue) => {
    setSelectedSeatZoneValue(seatZoneValue)
    console.log(seatZoneValue)
  };


  const handleSetSelectedChartsSelectionValue = (event, chartsSelection) => {
    setSelectedChartsSelectionValue(chartsSelection)
  };
  const handleSetSelectedFilterValue = (event, filterValue) => {
    setSelectedFilterValue(filterValue)
  };
  const handleSetSelectedWasteChartsSelectionValue = (event, wasteChartsSelection) => {
    setSelectedWasteChartsSelectionValue(wasteChartsSelection)
  };
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleAlignment1 = (event, newAlignment1) => {
    setAlignment1(newAlignment1);
  };
  const handleAlignment2 = (event, newAlignment2) => {
    setAlignment2(newAlignment2);
  };
  const handleAlignment3 = (event, newAlignment3) => {
    setAlignment3(newAlignment3);
  };
  const onChartClick = (params) => {
    console.log('Chart clicked', params);
    console.log(params.name)
    setOpen(true)
    setName(params.name)
  };
  const onEvents = {
    click: onChartClick,
  };
  return (
    // <div style={{ color: '#f9f9f9', marginBottom: 20 }}>
    
    <div style={{ backgroundColor: 'black' }}>
   <Backdrop
        open={loading}
        className={classes.backdrop}
        style={{ opacity: "0.8" }}
        
      >
     <CircularProgress color="inherit" />

      </Backdrop>
      <Grid container xs={12} >
        <Grid item xs={12} sm={12} md={12} lg={12} >
          <ColorModeProvider mode="dark">
            <Tabs
              floating={true}
              // className="bg-primaryblue-90"
              size="xlarge"
              onChange={
                (e, val) => {
                  handleChange(null, val)
                  if (val === 0) {
                    handleSetSelectedChartsSelectionValue(null, "overview");

                  }
                }

              }
              aria-label="Tabs purpose label"
              value={value}
              scrollable={true}
              //  variant="fullWidth"
              style={{ borderBottom: '1px solid #D9D9D9' }
              }
            >  <Tab {...a11yProps(0)}>Seats Health Overview</Tab>
              <Tab {...a11yProps(1)}>Seats Reports</Tab>
 
       
            </Tabs>

            <Container style={{ maxWidth: "80%", paddingBottom: "0.8rem" }}>
        {/* <Row alignItems="end" justify="space-evenly"> */}
        <SeatsFilters
          filters={filters}
          onSubmit={onSubmit}
       

        />
      </Container>
            {/* <Divider style={{color:"#D9D9D9", width: matches ?"100%":"100%"}} /> */}
          </ColorModeProvider>
        </Grid>
      </Grid>
      <Container style={{ maxWidth: "80%", paddingBottom: "0.8rem" }}>


      </Container>
      <Inline wrap="true" style={{ paddingBottom: "0.2rem" }} spacing='1-x'>

      </Inline>
      <TabPanel value={value} index={1}>
      <Container>


<Row style={{ paddingTop: "1rem" }}>

  <Col xs={12} xxs={6} sm={12} md={12} style={{ paddingLeft: "0.5rem" }}>

    <Box style={{ backgroundColor: "#1C2747", width: matches ? "70rem" : "55rem", height: matches ? "25rem" : "25rem" }}><Row>
      <Col md={10}> <div style={{ padding: "1.5rem" }}> {"Seat Zone Status"}</div>  </Col>
      <Col md={2} alignSelf="center">
        <Select style={{ width: "8.8rem" }}
          options={seatZoneOptions}
          value={selectedSeatZoneValue}
          // defaultValue={"filters"}
          onChange={(event, value) => handleSetSelectedSeatZoneValue(event, value)}
        // onChange={(event,value) => setSelectedValue(value)}
        ></Select></Col></Row>
      <Row justify='start'>
        <TransformWrapper >
          <TransformComponent>

            <SeatsLOPA2 seatsUsage ={seatsUsage} totalNumberOfOnes={totalNumberofOnes} mockData={mockData} showEconomy={selectedSeatZoneValue} formattedDataArray={formattedDataArray?.sort((a, b) => parseInt(a.key, 10) - parseInt(b.key, 10))} />
          </TransformComponent>
        </TransformWrapper>
      </Row>
    </Box>
  </Col>






</Row>
</Container>



      </TabPanel>
      <TabPanel value={value} index={0} style={{ color: "white" }}>


        <Container>


          <Row style={{ paddingTop: "1rem" }}>

            <Col xs={12} xxs={6} sm={12} md={12} style={{ paddingLeft: "0.5rem" }}>

              <Box style={{ backgroundColor: "#1C2747", width: matches ? "70rem" : "55rem", height: matches ? "25rem" : "25rem" }}><Row>
                <Col md={10}> <div style={{ padding: "1.5rem" }}> {"Seat Zone Status"}</div>  </Col>
                <Col md={2} alignSelf="center">
                  <Select style={{ width: "8.8rem" }}
                    options={seatZoneOptions}
                    value={selectedSeatZoneValue}
                    // defaultValue={"filters"}
                    onChange={(event, value) => handleSetSelectedSeatZoneValue(event, value)}
                  // onChange={(event,value) => setSelectedValue(value)}
                  ></Select></Col></Row>
                <Row justify='start'>
                  <TransformWrapper >
                    <TransformComponent>

                      <SeatsLOPA mockData={mockData} showEconomy={selectedSeatZoneValue} formattedDataArray={formattedDataArray?.sort((a, b) => parseInt(a.key, 10) - parseInt(b.key, 10))} />
                    </TransformComponent>
                  </TransformWrapper>
                </Row>
              </Box>
            </Col>






          </Row>
        </Container>
      </TabPanel>
      <TabPanel value={value} index={2} style={{ color: "white" }}>

      </TabPanel>

    </div>
  )
}
