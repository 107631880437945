import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTable, useSortBy,usePagination } from 'react-table'
import {Accordion, Badge, Chip, Button, Collapsible, Checkbox, 
  Inline, Tabs, Tab, ColorModeProvider, RangeSlider, Rating, MenuList,MenuItem,Menu,MenuButton,
  Stack, Card, CardActions, CardContent, CardHeader, CardMedia, CardActionArea, CardTitle, IconButton, Container, Col, Content, 
  Row, Toast, Flex,Link,Input,DataTable, ProgressBar } 
  from "@airbus/components-react" 
  import { Visibility,Delete,Clear,Check,Chevronright, Chevronup,Chevrondown,RemoveCircle,CheckCircle } from '@airbus/icons/react';
  import { makeStyles } from '@material-ui/core/styles';
import { useTheme, LinearProgress, Backdrop,CircularProgress } from '@material-ui/core';
import { cateringPreOrderSampleList2 ,tunedPreorder, fetchData} from './CateringData'
  
export const ChipInputCellTable = ({ value }) => {
  const [tag, setTag] = React.useState(value);
  return value ==1 ? (
    <Chip size="medium" label={"Connected"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold",fontSize:"0.8rem"}}variant="success" >  </Chip>
  ) : (
    <div>
      <Chip size="medium" label={"Not Connected"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold",fontSize:"0.8rem"}}variant="error"></Chip>
    </div>
  );
};

export const ChipInputCellTop = ({ value }) => {
  const [tag, setTag] = React.useState(value);
  return value ==1 ? (
    <Chip size="medium" label={"Connected"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold", fontSize:"0.7rem"}}variant="success" >  </Chip>
  ) : (
    <div>
      <Chip size="medium" label={"Not Connected"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold", fontSize:"0.7rem"}}variant="error"></Chip>
    </div>
  );
};


export const ChipInputCell = ({ value }) => {
  const [tag, setTag] = React.useState(value);
  return value ==1 ? (
    <Chip size="small" label={"Connected"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold", fontSize:"0.5rem"}}variant="success" >  </Chip>
  ) : (
    <div>
      <Chip size="small" label={"Not Connected"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold", fontSize:"0.5rem"}}variant="error"></Chip>
    </div>
  );
};

  
  export const ProgressInputCell = ({ value }) => {
    const [tag, setTag] = React.useState(value);
    return  (<Row alignItems='center'>
      <Col sm={4}>
   <div> {value}{"%"}</div>
   </Col>
   <Col sm={8}>
    <ProgressBar value={value} style={{ width: "5rem", textColor: "white" }} ></ProgressBar>
    </Col>
    </Row>
   
    );
  };
  
  export const ChipInputCellGalley = ({ value }) => {
    const [tag, setTag] = React.useState(value);
    return value ==1 ? (
      <Chip size="small" label={"Healthy"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold", fontSize:"0.50rem"}}variant="success" >  </Chip>
    ) : (
      <div>
        <Chip size="small" label={"Possible Failure"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold", fontSize:"0.48rem"}}variant="error"></Chip>
      </div>
    );
  };

  export const ChipInputCellGalleyTable = ({ id, value }) => {
    // console.log("value", value)
    const [tag, setTag] = React.useState(value);
    return value ==="Pre-Ordered" ? (
      <Chip size="medium" label={"Yes"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold",fontSize:"0.8rem" }}variant="success" >  </Chip>
    ) : (
      <div>
        <Chip size="medium" label={"No"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold",fontSize:"0.8rem"}}variant="error"></Chip>
      </div>
    );
  };

  export const ChipInputCellGalleyTable2 = ({ id, value }) => {
    console.log("value", value)
    const [tag, setTag] = React.useState(value);
    return value ==="Special-Meal" ? (
      <Chip size="medium" label={"Yes"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold",fontSize:"0.8rem" }}variant="success" >  </Chip>
    ) : (
      <div>
        <Chip size="medium" label={"No"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold",fontSize:"0.8rem"}}variant="error"></Chip>
      </div>
    );
  };

  export const ChipInputCellGalleyTop= ({ value }) => {
    const [tag, setTag] = React.useState(value);
    return value ==1 ? (
      <Chip size="medium" label={"Healthy"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold",fontSize:"0.7rem" }}variant="success" >  </Chip>
    ) : (
      <div>
        <Chip size="medium" label={"Possible Failure"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold",fontSize:"0.7rem"}}variant="error"></Chip>
      </div>
    );
  };


const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;
   
    tr {

      :last-child {
        td {
   
        }
      }
    }
    th{border-bottom:0px;
      padding: 0.5rem;
      width:10rem
    
    },
    td {
      border-top:1px solid black;
      margin: 0;
      padding: 0.5rem;
      
      // border-bottom: 1px solid black;

      :last-child {
        border-bottom:0px
      }
    }
  }
`
const Table=({ columns, data })=> {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex,pageSize  },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0,pageSize:5 },
    },
    useSortBy,
    usePagination
    
  )
  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  // const firstPageRows = rows
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  return (
    <>

   <div style={{minHeight:"22rem"}}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps([{style: {
                  minWidth: 125,
                  width: 50,
                  textAlign:"left"
                
                }},column.getSortByToggleProps()])}
                
                >
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <Chevronup/>
                        : <Chevrondown/>
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps({ 
                        style: {
                          textAlign:'left',
                          minWidth:100,
                          width :100,
                      
                        }
                      })}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>
      </div>
<br/>
      <div className="pagination" style={{paddingLeft:"8rem", position: "absolute",
                bottom: "0px"}}>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px',color:"#90a4ae" }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[5, 10].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
   
      </>
  )
}
const CateringReactTable=({loading, handleChange, handleSetSelectedFilterValue, cateringPreOrderSampleList, cateringArray,selectedPaxInfo, }) =>{

  const useStyles = makeStyles((theme) => ({
  
  
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'black',
      opacity: 0.7
    },
  
  
  }));
  const classes = useStyles();
  console.log('cateringArray :>> ', cateringArray);

 
  const newArray = cateringArray.map(obj => {
    const newObj = {};
    for (let [key, value] of Object.entries(obj)) {
      newObj[key] = value.toString().replace(/_/g, " ");
    }
    return Object.fromEntries(Object.entries(newObj));
  });
  console.log('newArray :>> ', newArray);

  const datax = newArray.filter(d => selectedPaxInfo==='all'?true:  d.seatType === selectedPaxInfo )

  const data =React.useMemo(() => datax , [datax])
  const columns = React.useMemo(
    () =>  [
      

   
    { Header: "Name", 
    accessor: "PAXName", 
    width: 50 },

    // { Header: "Connectivity ", accessor: "connectivity", width: 100 , Cell: ({ value }) => (value ? value +"%" : "-"), },
    { Header: "VIP", 
    accessor: "vip", 
    width: 50,
    Cell: ({value}) => {
       
     
             // the value is 'this is a test'
            return (
             value==="true" ? <CheckCircle style={{color:"green"}}/>:<RemoveCircle style={{color:"white"}}/>
            )
    }
  },
    { Header: "Seat Class", 
    accessor: "seatType", 
    width: 50,
    //  Cell: ProgressInputCell,
    },
    { Header: "Seat No", 
    accessor: "seatName", 
    width: 50,
    //  Cell: ProgressInputCell,
    },
    { Header: "Order Type", 
    accessor: "orderType", 
    width: 80,
    //  Cell: ProgressInputCell,
    },
    { Header: "Food Category", 
    accessor: "foodCatergoryDescription", 
    width: 50,
    Cell: ({value}) => {
       
     
      // the value is 'this is a test'
     return (
      value ? value:"-" 
     )
}
    },
    { Header: "Food Name", 
    accessor: "fullName", 
    width: 50,
    Cell: ({value}) => {
       
     
      // the value is 'this is a test'
     return (
      value ? value:"-" 
     )
}
  
      
}
   ] ,[])

  return (
    
    <Styles>
           <Backdrop open={loading} className={classes.backdrop} style={{opacity:"0.8"}}>
  <CircularProgress color="inherit" /></Backdrop>
      <Table columns={columns} data={data} />
    </Styles>
  )
}
export default CateringReactTable