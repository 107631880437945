import React, { useEffect, useState } from "react";
import "echarts/lib/component/grid";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Inline,
  Tabs,
  Tab,
  ColorModeProvider,
  CardContent,
  CardTitle,
  Container,
  Col,
  Row,
} from "@airbus/components-react";
import axios from "axios";
import { useTheme } from "@material-ui/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ReactEcharts from "echarts-for-react";
// import { CargoBottomElements } from "../../components/Cargo/CargoBottomElements";
import { CargoFilters } from "../../components/Cargo/CargoFilters";
import StopIcon from "@material-ui/icons/Stop";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
  startOfDay,
  endOfDay,
  set,
} from "date-fns";
import _ from "lodash";
import { addSeconds } from "date-fns";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "./Cargocss.css";
import zones from "./zones";
import CargoCharts from "./CargoCharts";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "black",
    opacity: 0.7,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}
const THRESHOLD = 10;


const shapeEvent = (event) => ({
  ...event,
  time: new Date(event.created_at),
  indicator: event.topic_extension.toLowerCase().includes("pdu_covered")
    ? 1
    : 0,
});
export function Cargo({ handleListItemClick }) {
  const [transitionText, setTransitionText] = useState('');
  const [buttonColor, setButtonColor] = useState('green');
  const [buttonText, setButtonText] = useState('Open');
  const [currentIndex, setCurrentIndex] = useState(1);
  const [sloading, setsLoading] = useState(false);
  const [isAnimateMode, setIsAnimateMode] = useState(true);
  const [loading, setLoading] = useState(false);
  // const [open, setOpen] = useState(false)
  const [cargoProcesses, setCargoProcesses] = useState([]);
  const [lastStartTime, setLastStartTime] = useState("");
  const [lastEndTime, setLastEndTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [duration, setDuration] = useState("");
  const [selectedCheck, setSelectedCheck] = useState(2);
  const [tabValue, setTabValue] = React.useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const [selectedFilterValue, setSelectedFilterValue] = useState("all");
  const [dateRange, setDateRange] = useState([
    new Date("2023-02-08"),
    new Date("2023-02-08"),
  ]);
  const [chosenDate, setChosenDate] = useState(new Date());
  const [selectedChartsSelectionValue, setSelectedChartsSelectionValue] =
    useState("overview");
  const classes = useStyles();
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [cargoData, setCargoData] = useState();
  const [time, setTime] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [useRange, setUseRange] = useState(true);
  const [data, setData] = useState([
    {
      id: 316471,
      topic_prefix: "v1/crew/cargo",
      topic_action: "data",
      topic_extension: "/aft/can/fin/1401/proxy/0/long_trans/1/PDU_covered",
      header: "{}",
      payload: "1",
      tag_no_send_to_ground: "False",
      tag_transfer_status: "na",
      tag_no_store: "False",
      tag_user_timestamp: '""',
      tag_private_data: 0,
      sender: "na",
      dump_file_name: "None",
      tag_custom: "None",
      created_at: "2023-06-19T11:52:55.000Z",
      uid: "F-WWWW",
    },
  ]);



  let counter = 1
  const timeBottom = addSeconds(new Date(time || new Date()), -THRESHOLD);
  const timeTop = addSeconds(new Date(time || new Date()), THRESHOLD);
  // console.log(
  //   "timeBottom,timeTop",
  //   timeBottom.toISOString(),
  //   timeTop.toISOString()
  // );


  const getZoneData = (zone) => {
    if (!zone) {
      return {
        zoneEvents: [],
        zoneKey: "",
        isZoneLoadingAtTime: false,
        zoneEventsBefore: [],
        zoneEventsAfter: [],
        zoneId: "",
      };
    }
    const allZoneEvents = data.filter((d) => {
      const id = d.topic_extension.split("/")[4];
      const isId = id == zone.fin;
      return isId;
    });
    const zoneEventsBefore = allZoneEvents
      .filter((d) => {
        // console.log('startxxx :>> ', start);
        const date = new Date(d.created_at);
        const beforeRange = date <= start;
        const beforeTimestamp = date <= timeBottom;
        return useRange ? beforeRange : beforeTimestamp;
      })
      .map(shapeEvent);
    const zoneEventsAfter = allZoneEvents
      .filter((d) => {
        const date = new Date(d.created_at);
        const afterRange = date >= end;
        const afterTimestamp = date >= timeTop;
        return useRange ? afterRange : afterTimestamp;
      })
      .map(shapeEvent);
    const zoneEvents = allZoneEvents
      .filter((d) => {
        const date = new Date(d.created_at);
        const withinRange = date >= start && date <= end;
        const withinTimestamp = date >= timeBottom && date <= timeTop;
        return useRange ? withinRange : withinTimestamp;
      })
      .map(shapeEvent);
    const zoneKey = zoneEvents.map((ze) => ze.indicator).join("");
    const zoneKeyBefore = zoneEventsBefore.map((ze) => ze.indicator).join("");
    const zoneKeyAfter = zoneEventsAfter.map((ze) => ze.indicator).join("");

    const isZoneLoadingAtTime = zoneKeyAfter.startsWith("11");
    return {
      zoneId: zone.id,
      zoneEvents,
      zoneKey,
      zoneKeyBefore,
      zoneKeyAfter,
      isZoneLoadingAtTime,
      zoneEventsBefore,
      zoneEventsAfter,
    };
  };
  {
    handleListItemClick(null, 3);
  }

  const handleChange = (event, newValue) => {
    setTimeout(() => {
      setTabValue(newValue);
    }, 1);
  };

  const handleSetSelectedChartsSelectionValue = (event, chartsSelection) => {
    setSelectedChartsSelectionValue(chartsSelection);
  };

  // call for cargo data for a particular date
  const getEndpoint = (createdOn) =>
    `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=v1/crew/cargo&payload=1&topic_extension=long_trans/1/PDU_covered&created_at=${createdOn}`;
  const getEndpoint2 = (createdOn) =>
    `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=v1/crew/cargo&payload=1&topic_extension=long_trans/1/PDU_uncovered&created_at=${createdOn}`;
  //movelater

  const onSubmit = async (values = { dateRange: [new Date(), new Date()] }) => {
    setIsSubmitClicked(true)
    // console.log({ values });
    //
    // const isSame = oldData === JSON.stringify(values);
    // if (isSame) {
    //   return;
    // }
    // setOldData(JSON.stringify(values));
    // if (values.process != null) {
    //   // TODO: Call functionfor theprocess chagnes
    //   // Return;
    // }
    setStart(null)
    setEnd(null)
    counter = 0
    setLoading(true);
    setIsAnimateMode(true);
    try {
      const { dateRange } = values;
      setDateRange(dateRange);
      const [start, end] = dateRange;
      const [startYear, endYear] = [start, end].map((d) => d.getFullYear());
      const [startMonth, endMonth] = [start, end].map((d) => format(d, "MM"));
      const [startDay, endDay] = [start, end].map((d) => format(d, "dd"));
      const isSameMonth = startMonth === endMonth;
      const isSameDay = startDay === endDay;
      let createdOn = "";
      if (isSameMonth && isSameDay)
        createdOn = `${startYear}-${startMonth}-${startDay}`;
      else if (isSameMonth) createdOn = `${startYear}-${startMonth}`;
      else {
        const range =
          differenceInMonths(endOfMonth(end), startOfMonth(start)) + 1;
        createdOn = Array(range)
          .fill("")
          .map((_, index) => format(addMonths(start, index), "yyyy-MM"));
      }
      const apiData = [];

      console.log(createdOn);
      // for diff month
      if (Array.isArray(createdOn)) {
        for (const co of createdOn) {
          setChosenDate(co);
          const { data: response } = await axios.get(getEndpoint(co));
          const { data: response1 } = await axios.get(getEndpoint2(co));

          console.log(...response);
          console.log(...response1);
          apiData.push(...response, ...(response1 || []));
        }
      } else {
        const { data: response } = await axios.get(getEndpoint(createdOn));
        const { data: response1 } = await axios.get(getEndpoint2(createdOn));

        console.log("...response :>> ", ...response);
        console.log("...response1 :>> ", ...response1);
        apiData.push(...response, ...(response1 || []));
      }

      const filteredData = apiData.filter((d) => {
        const date = new Date(d.created_at);
        return date >= startOfDay(start) && date <= endOfDay(end);
      });
      console.log(filteredData);
      console.log(apiData);
      const cleanData = filteredData.map((d) => {
        let payload;
        try {
          payload = d.payload;
        } catch (e) { }
        return { ...d, payload };
      });

      console.log("cleanData :>> ", cleanData);
      setCargoData(cleanData ? cleanData : []);
      // setAFTConnectivity(true);
      let sortedCargoDataByTimeStamp =
        cleanData?.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        ) || [];


      setChosenDate(createdOn);
      setData(sortedCargoDataByTimeStamp);

      if (createdOn === "2023-06-19") {

        handleChildComponentData("19p1", "2023-06-19T11:58:00.000Z", "2023-06-19T12:11:00.000Z")
      }
      else if (createdOn === "2023-06-08") {
        handleChildComponentData("08p1", "2023-06-08T06:55:00.000Z", "2023-06-08T07:20:00.000Z")
      }
      else {
        handleChildComponentData(null, null, null)
      }
      ////
    } finally {
      setLoading(false);
    }
  };
  // console.log("cargoData :>> ", cargoData);
  // console.log("data :>> ", data);
  // console.log('chosenDate :>> ', new Date(chosenDate));
  const cargoProcessOptionsx = cargoProcesses.map((cargoProcess, index) => ({
    label: `Process ${index + 1}`,
    value: `Process ${index + 1}`,
  }));

  const cargoProcessOptions = [
    {
      label: "Process 1",
      value: "19p1",
      startTime: "2023-06-19T11:58:00.000Z",
      endTime: "2023-06-19T12:11:00.000Z",
      duration: "3 hours",
    },
    {
      label: "Process 2",
      value: "19p2",
      startTime: "2023-06-19T12:10:00.000Z",
      endTime: "2023-06-19T12:24:00.000Z",
      duration: "3 hours",
    },
    {
      label: "Process 3",
      value: "19p3",
      startTime: "2023-06-19T12:24:00.000Z",
      endTime: "2023-06-19T12:35:00.000Z",
      duration: "4 hours",
    },
  ];
  const cargoProcessOptions1 = [
    {
      label: "Process 1",
      value: "08p1",
      startTime: "2023-06-08T06:55:00.000Z",
      endTime: "2023-06-08T07:20:00.000Z",
      duration: "4 hours",
    }]
  const filters = [
    {
      name: "dateRange",
      // label: "Range",
      type: "daterange",
      initialValue: null,
      // initialValue:Array(2).fill(new Date('2023-02-08'))
    },

    {
      name: "process",
      label: "Cargo Process",
      type: "dropdown",
      options:
      chosenDate === "2023-06-19"
        ? [...cargoProcessOptions]
        : chosenDate === "2023-06-08"
        ? [...cargoProcessOptions1]
        : chosenDate// Default condition for when chosenDate is undefined
        ? [...cargoProcessOptionsx] // other options specific to cargoProcessX
        : [], // Empty array or other default options
    
      onChange: (handleChange,selectedProcessIndex) => {

        const selectedProcess = cargoProcesses[selectedProcessIndex];
        console.log("🚀 ~ file: Cargo.js:911 ~ Cargo ~ selectedProcess:", selectedProcess)
       
        const value = cargoProcessOptions[selectedProcessIndex].value;
        setCargoProcesses((prev) => {
          const newCargoProcesses = [...prev];
          newCargoProcesses[selectedProcessIndex] = {
            ...selectedProcess,
            value: value,
          };
        })

        if (selectedProcess) {
          const { startTime, endTime, duration } = selectedProcess;
          console.log("🚀 ~ file: Cargo.js:922 ~ Cargo ~ selectedProcess:", selectedProcess)
          // Set the start, end, and duration based on the selected cargo process
          setStartTime(startTime);
          setEndTime(endTime);
          setDuration(duration);
          setSelectedFilterValue(value);
        }

      }
    },
  ];

  const zoneIndexObject = {};
  const zoneData = zones.map((zone, zoneIndex) => {

    const currentZoneIndex = zoneIndex ;
    const prevZoneIndex = zoneIndex - 1;
    const prevZoneRowIndex = zoneIndex - 13;
    const prevZoneRowPrevIndex = prevZoneRowIndex - 1;
    const prevPrevZoneIndex = zoneIndex - 2;
    const {
      isZoneLoadingAtTime,
      zoneKey,
      zoneEventsAfter,
      zoneEventsBefore,
      zoneEvents,
      zoneKeyBefore,
      zoneKeyAfter,
    } = getZoneData(zone);
    // console.log("{ isZoneLoadingAtTime, zoneKey } ", {
    //   isZoneLoadingAtTime,
    //   zoneKey,
    // });

    let isLeftBox = true;
    const isGreenBox = (zoneKeyBefore.endsWith("0") && zoneKeyAfter.startsWith("11")) ||
    (zoneKeyBefore.endsWith("1") && zoneKeyAfter.startsWith("11"));

const isAdjacentGreen = isGreenBox && zoneIndex > 0 && zoneIndexObject[zoneIndex] === zoneIndexObject[zoneIndex - 1];

const shouldRenderULD = isLeftBox && isAdjacentGreen;

isLeftBox = (zoneIndex + 1) % 13 === 0; // Check if the next box will be on the left
    if (isZoneLoadingAtTime) {

      
      const prevIndexCounter = zoneIndexObject[prevZoneIndex];
      const prevPrevIndexCounter = zoneIndexObject[prevPrevZoneIndex];
      const prevRowIndexCounter = zoneIndexObject[prevZoneRowIndex];
      const prevRowPrevIndexCounter = zoneIndexObject[prevZoneRowIndex];
      //  console.log('prevZoneIndex :>> ', prevZoneIndex);
      if (prevZoneIndex > 12) {
        zoneIndexObject[zoneIndex] = counter;

        console.log("sss :>> ", counter);
      } else if (
        !prevIndexCounter &&
        !prevRowIndexCounter &&
        !prevRowPrevIndexCounter
      ) {
        zoneIndexObject[zoneIndex] = counter;
      } else if (
        prevIndexCounter &&
        !prevRowIndexCounter &&
        !prevRowPrevIndexCounter
      ) {
        zoneIndexObject[zoneIndex] = counter;
        counter++;
      }
    }
    let uldContent = null;


  
    return (

      <div
        key={zone.value}
        style={{
          color: "white",
          padding: "0.5rem",
          height: "6rem",
          width: "3.2rem",
         borderRight:( zoneIndexObject[prevZoneIndex]!== zoneIndexObject[currentZoneIndex ]  && ( !zoneIndexObject[prevZoneIndex ]))  ?  "none": "0.2px dashed darkgrey",
          borderLeft:zoneIndexObject[prevZoneIndex]=== zoneIndexObject[currentZoneIndex ]? "none":"1px solid black",
          borderBottom: "2px solid black",
          background:
            // :
            // zoneKeyBefore.endsWith("1") && zoneKeyAfter.startsWith("00")
            //   ? "yellow"
            (zoneKeyBefore.endsWith("0") && zoneKeyAfter.startsWith("11")) ||
              (zoneKeyBefore.endsWith("1") && zoneKeyAfter.startsWith("11"))
              ? "green"
              : "grey",
        }}
      >
{/* {console.log('zoneIndexObject[prevZoneIndex] :>> ', zoneIndexObject[prevZoneIndex])}
{console.log('zoneIndexObject[currentZoneIndex ] :>> ', zoneIndexObject[currentZoneIndex ])} */}
        {zone.value}
        {/* <p>BEFORE:{zoneKeyBefore.substring(zoneEventsBefore.length - 3)}</p> */}
        {/* <p> {zoneKey}</p> */}
        {/* <p>AFTER: {zoneKeyAfter.substring(0,3)}</p> */}
        {/* <p> {zoneKey}</p> */}
        {/* <p>Basic Index: {zoneIndex}</p> */}
<br/>
<br/>{    shouldRenderULD && 
                <span style={{  position: "relative", paddingLeft: counter !== 1 ?"0.02rem":"0.02rem", whiteSpace: "nowrap", zIndex:"5"}}>ULD: {zoneIndexObject[zoneIndex]}</span>
      }



      </div>
    );
    
  });

  const handleButtonClick = () => {
    if (buttonText === 'Open') {
      setButtonColor('green');
      setButtonText('Open');
  
      setTransitionText(`Process ${currentIndex + 1}`)

      setTimeout(() => {
        setButtonColor('red');
      setButtonText('Close');
        setCurrentIndex((currentIndex + 1) % cargoProcessOptions.length);
        setTransitionText('');
      }, 3000);

      setTimeout(() => {
        setButtonColor('green');
        setButtonText('Open');
      }, 5000); // 10000 milliseconds = 10 seconds
    }
  };

  useEffect(() => {
    if (currentIndex < cargoProcessOptions.length && cargoData?.length>0) {
      const process = cargoProcessOptions[currentIndex];
      setStart(new Date (process.startTime));
      setEnd(new Date (process.endTime));
    }
  }, [currentIndex])


  const handleChildComponentData = (v, x, y) => {
    // Perform actions with the received data in the parent component
    console.log("Data received in parent:", v, x, y);
    setStart(new Date(x))
    setEnd(new Date(y))
  };


  var datax = { name: 'Cargo used', value: (((Object.keys(zoneIndexObject).length)/26) *100).toFixed(1) };

  // Chart configuration
  var cargoDoughnut  = { 
    graphic: [
      // {
      //   type: 'text',
      //   left: 'center',
      //   top: '40%', // Adjust vertical position
      //   style: {
      //     text: datax.name,
      //     fontSize: 16,
      //     fontWeight: 'bold'
      //   }
      // },
      {
        type: 'text',
        left: 'center',
        top: '45%', // Adjust vertical position
        style: {
          text: datax.value + '%',
          fontSize: 10,
          fontWeight: 'bold',
          fill:"white"
        }
      }
    ],
    series: [{
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        on: 'outside',
        formatter: '{b}: {c} ({d}%)'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '18',
          fontWeight: 'bold'
        }
      },
      data: [
        {
          name: datax.name,
          value: datax.value,
          itemStyle: { color:"lightgreen" },
          label: {
            formatter: '{b}: {c} ({d}%)'
          }
        },
        // Add an empty data point to create the doughnut shape
        { name: '', value: 100 - datax.value, itemStyle: { color:"pink" } }
      ]
    }]
  };
  const calculateDuration = (start, end) => {
    const startTimeParts = start.split(":");
    const endTimeParts = end.split(":");
    
    const startSeconds = (+startTimeParts[0]) * 3600 + (+startTimeParts[1]) * 60 + (+startTimeParts[2]);
    const endSeconds = (+endTimeParts[0]) * 3600 + (+endTimeParts[1]) * 60 + (+endTimeParts[2]);
    
    const differenceSeconds = endSeconds - startSeconds;
    const differenceMinutes = (differenceSeconds / 60).toFixed(3)
    ;
    
    setDuration(differenceMinutes);
};

const selectedFilter = (selectedFilterValue) => {
  if (cargoData?.length==0 || cargoData==undefined) {
    console.log('cargoProcesses :>> ', cargoProcesses);
   console.log('selectedFilterValue :>> ', selectedFilterValue);
  const selectedFilter = cargoProcesses.find(process => {
    return process.value === selectedFilterValue
  })
  ;
  setStartTime(selectedFilter.startTime);
  setEndTime(selectedFilter.endTime);
  setDuration(selectedFilter.duration);
}

}

const handleTimeStart = () => {
  if (!sloading) {
    setButtonText('Close');
    const currentTime = new Date().toISOString();
    setStartTime(currentTime);
    setLastStartTime(currentTime);
  } else {
    setButtonText('Open');
    const currentTime = new Date().toISOString();
    setEndTime(currentTime);

    const date1 = new Date(startTime); // Use the updated startTime
    const date2 = new Date(currentTime); // Use the current time

    const timeDifferenceMilliseconds = Math.abs(date2 - date1);
    const durationMinutes = timeDifferenceMilliseconds / (1000 * 60);
    const durationRounded = Number(durationMinutes.toFixed(3));

    setDuration(durationRounded )

    const cargoProcess = {
      value: `Process ${cargoProcesses.length + 1}`,
      startTime,
      endTime: currentTime,
      duration
    };

    setCargoProcesses((prevCargoProcesses) => [
      ...prevCargoProcesses,
      cargoProcess,
    ]);
  }

  setsLoading(!sloading);
};

  // const handleTimeStart = () => {
  //   if (sloading=== false) {
  //     console.log('sloading :>> ', sloading);
  //     // Start the loading process
  //     setsLoading(true);

  //     const now = new Date();
  //     const timeString = now.toISOString().slice(11, 19);
  //     setStartTime(timeString);
    
  //     setEndTime("");
  //     setDuration("")
  //     setButtonText("close")

  //   } else {
  //     console.log('sloading :>> ', sloading);
  //     setButtonText("open")
  //     setsLoading(false);

  //     const now = new Date();
  //     const timeString = now.toISOString().slice(11, 19);
  //     setEndTime(timeString);

  //     calculateDuration(startTime, timeString);

   
  //   }
  //   console.log('startTime :>> ',startTime );
  //   console.log('endTime :>> ',endTime );


  //   if ( startTime.length!==0 && endTime.length!==0 ) {

  //     console.log('cccsc :>> ',startTime );
  //     // Add the cargo process to the array
  //     const cargoProcess = {
  //       value: `Process ${cargoProcesses.length+1}`,
  //       startTime,
  //       endTime,
  //       duration,
  //     };
  //     setCargoProcesses((prevCargoProcesses) => [
  //       ...prevCargoProcesses,
  //       cargoProcess,
  //     ]);
  //   }
  //   console.log(
  //     "🚀 ~ file: Cargo.js:1064 ~ handleTimeStart ~ cargoProcesses:",
  //     cargoProcesses
  //   );

    
  // };

  const buttonStyle = {
    backgroundColor: sloading ? "pink" : "green",
    color: "white",
    padding: "10px",
    marginLeft:"12px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };
  return (
    // <div style={{ color: '#f9f9f9', marginBottom: 20 }}>
    <div style={{ backgroundColor: "black" }}>
      <Backdrop
        open={loading}
        className={classes.backdrop}
        style={{ opacity: "0.8" }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        {/* {loading && <div>Loading</div>} */}
        {!loading && <div>{/* {data.map(item => (console.log(item)))} */}</div>}
      </div>

      <Grid container xs={12}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ColorModeProvider mode="dark">
            <Tabs
              floating={true}
              // className="bg-primaryblue-90"
              size="xlarge"
              onChange={(e, val) => {
                handleChange(null, val);
                if (val === 0) {
                  handleSetSelectedChartsSelectionValue(null, "overview");
                }
              }}
              aria-label="Tabs purpose label"
              value={tabValue}
              scrollable={true}
              //  variant="fullWidth"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <Tab {...a11yProps(0)}>Cargo Zone Status</Tab>
              <Tab {...a11yProps(1)}>Reports</Tab>
              <Tab {...a11yProps(2)}>Door Latches</Tab>
            </Tabs>
            {/* <Divider style={{color:"#D9D9D9", width: matches ?"100%":"100%"}} /> */}
          </ColorModeProvider>
        </Grid>
      </Grid>
      <Container style={{ maxWidth: "80%", paddingBottom: "0.8rem" }}>
        {/* <Row alignItems="end" justify="space-evenly"> */}
        <CargoFilters
          isSubmitClicked={isSubmitClicked}
          onDataReceived={handleChildComponentData}
          filters={filters}
          onSubmit={onSubmit}
          tabValue={tabValue}
          selectedFilterValue={selectedFilter}
        />
      </Container>

      <TabPanel
        value={tabValue}
        index={2}
        style={{ color: "white" }}
      ></TabPanel>
      <TabPanel value={tabValue} index={0} style={{ color: "white" }}>
        <Container>
          <Row justify="start" alignItems="center">
            <Col
              xs={12}
              xxs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "0.2rem" }}
            >
              {
                <Box
                  style={{
                    backgroundColor: "#1C2747",
                    width: matches ? "57rem" : "57rem",
                    height: matches ? "30rem" : "30rem",
                  }}
                >
                  <CardContent
                    style={{
                      color: "white",
                      width: matches ? "55rem" : "53rem",
                      height: matches ? "26rem" : "26rem",
                    }}
                  >
                    <CardTitle
                      style={{ fontSize: matches ? "1.5rem" : "1rem" }}
                    >
                      <Row justify="start" alignItems="center">
                        <Col xs={3} sm={2} md={7} lg={6}>
                          <div
                            style={{ fontSize: "1.2rem", paddingLeft: "2rem" }}
                          >
                            {" "}
                            {"CARGO ZONE STATUS"}
                          </div>
                        </Col>

                        <Col>
                          <Inline
                            style={{
                              marginRight: matches ? "0.1rem" : "0.02rem",
                            }}
                            align="end"
                            spacing="three-quarter-x"
                          >
                            <StopIcon style={{ color: "green" }} />
                            <div>{"Usage"} </div>
                            {/* <StopIcon style={{ color: "#ffd600" }} /><div>{" In process"}  {" "}</div> */}
                            <StopIcon
                              style={{ color: "grey", marginLeft: "0.5rem" }}
                            />
                            <div>{"Not in use"}</div>{" "}
                          </Inline>
                        </Col>
                      </Row>
                    </CardTitle>
                    <br></br>
                    <TransformWrapper>
                      <TransformComponent>
                        {selectedCheck == 2 && (
                          <Container md={12}>
                            <Row>
                              <div
                                style={{
                                  marginLeft: "40.5rem",
                                  backgroundColor: "blue",
                                  color: "white",
                                  width: "6.0rem",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                Door
                              </div>
                            </Row>
                            <div>
                              <div
                                style={{

                                  marginLeft: "5rem",
                                  display: "grid",
                                  color: "grey",
                                  gridTemplateColumns: "repeat(13, 0fr)",
                                  gap: 0,
                                  gridColumnGap: 0,
                                  gridRowGap: 0
                                }}
                              >
                                {zoneData}
                              </div>
                              {/* <div>USE RANGE</div>
      <input
        type="checkbox"
        checked={useRange}
        onChange={() => setUseRange((f) => !f)}
      /> */}
                              {/* <div>START</div>
      <DatePicker
  
        selected={start}
        onChange={(d) => {setStart(d)}}
        showTimeInput
        dateFormat="MMMM d, yyyy h:mm aa"
      />
      <div>END</div> */}
                              {/* <DatePicker
   
        selected={end}
        onChange={(d) => setEnd(d)}
        showTimeInput
        dateFormat="MMMM d, yyyy h:mm aa"
      /> */}

                              <br />
                              {/* <b> Cargo loading time: {(end - start)/60000} mins</b> */}

                            
                            </div>
                           
                          </Container>
                        )}
                      </TransformComponent>

                      <Container md={12} style={{ marginLeft: "5.0rem" }}>
                        <Row>
                          <Col md={2} xs={2} lg={2} sm={2}>
                          <Row justify="center">
                             <div>
                               <ReactEcharts
                   
                    option={cargoDoughnut}
                    style={{
                      width: matches ? 100 : 100,
                      height: matches ?100 : 100,
                    }}
                  />
                  </div>
                            </Row>
                            <Row>
                          
                              <div style={{ paddingLeft: "1rem" }}>
                                {"Cargo Usage"}
                              </div>
                            </Row>
                          </Col>
                          <Col md={2} xs={2} lg={2} sm={2} alignSelf="end">
                            <Row justify="center">
                              <div
                                style={{
                                  fontSize: "2rem",
                                  width: matches ? "6rem" : "6rem",
                                  height: matches ? "3rem" : "3rem",
                                }}
                              >
                                {Object.keys(zoneIndexObject).length === 0 && zoneIndexObject.constructor === Object ? "-" : counter}
                              </div>
                            </Row>
                            <Row>
                              <div>{"No. of ULD"}</div>
                            </Row>
                          </Col>
                          <Col md={2} xs={2} lg={2} sm={2} alignSelf="end">
                            <Row>
                              <div
                                style={{
                                  fontSize: "1rem",
                                  width: matches ? "6rem" : "6rem",
                                  height: matches ? "3rem" : "3rem",
                                }}
                              >
                                {" "}
                            
                                {
                               cargoData?.length>0 && isSubmitClicked
                                  ? new Date(start)
                                    .toUTCString()
                                    .substring(17, 25)
                                  : 
                                  startTime?   startTime.substring(11,19) :""}
                              </div>
                            </Row>
                            <Row>
                              {" "}
                              <div>{"Start time"}</div>
                            </Row>
                          </Col>
                          <Col md={2} xs={2} lg={2} sm={2} alignSelf="end">
                            <Row>
                              <div
                                style={{
                                  fontSize: "1rem",
                                  width: matches ? "6rem" : "6rem",
                                  height: matches ? "3rem" : "3rem",
                                }}
                              >
                              {console.log("endTime", endTime)}
                                {
                                cargoData?.length>0 && isSubmitClicked
                                  ? new Date(end)
                                    .toUTCString()
                                    .substring(17, 25)
                                  :
                               endTime?  endTime.substring(11, 19) :""}
                              </div>
                            </Row>
                            <Row>
                              {" "}
                              <div>{"End time"}</div>
                            </Row>
                          </Col>
                          <Col md={2} xs={2} lg={2} sm={2} alignSelf="end">
                            <Row>
                              <div
                                style={{
                                  fontSize: "1rem",
                                  width: matches ? "6rem" : "6rem",
                                  height: matches ? "3rem" : "3rem",
                                }}
                              >
                                {" "}
                                {isSubmitClicked && cargoData?.length>0
                                  ? Math.floor(
                                    (new Date(end) -
                                      new Date(start)) /
                                    60000
                                  ) + " mins"
                                  : (duration ? duration + " mins" : "")}
                              </div>
                            </Row>

                            <Row>
                              {" "}
                              <div>{"Duration"}</div>
                            </Row>
                          </Col>

                          <Col md={2} xs={2} lg={2} sm={2} alignSelf="center" >
                        
                          </Col>
                        </Row>
                      </Container>
                    </TransformWrapper>

                    <br></br>
                  </CardContent>
                </Box>
              }
            </Col>
          </Row>
        </Container>
      </TabPanel>
      <TabPanel value={tabValue} index={1} style={{ color: "white" }}>
        <CargoCharts cargoData={cargoData} />
      </TabPanel>
      <TabPanel value={tabValue} index={2} style={{ color: "white" }}>
      <Row>
                              {console.log('data.length :>> ', data)}
                            <Button  style={buttonStyle} 
                            onClick={ handleTimeStart}>Door {buttonText}
                            <br/>
                            {transitionText}
                             </Button>
                            </Row>
      </TabPanel>
    </div>
  );
}
