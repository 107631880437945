export const seatsDefectCountByDate= [
    { timestamp: "2021-05-06T11:41:00.000Z", value: 155, id: 1 },
    { timestamp: "2021-05-06T11:42:00.000Z", value: 310, id: 2 },
    { timestamp: "2021-05-09T10:18:00.000Z", value: 250, id: 3 },
    { timestamp: "2021-04-09T10:19:00.000Z", value: 286, id: 4 },
    { timestamp: "2021-04-09T10:23:00.000Z", value: 440, id: 5 },
    { timestamp: "2021-04-09T10:33:00.000Z", value: 365, id: 6 },
    { timestamp: "2021-04-09T10:34:00.000Z", value: 381, id: 7 },
    { timestamp: "2021-04-09T10:47:00.000Z", value: 399, id: 8 },
    { timestamp: "2021-04-09T10:26:00.000Z", value: 172, id: 9 },
    { timestamp: "2021-04-09T10:43:00.000Z", value: 72, id: 10 },
    { timestamp: "2021-04-09T10:54:00.000Z", value: 412, id: 11 },
    { timestamp: "2021-04-09T11:00:00.000Z", value: 391, id: 12 },
    { timestamp: "2021-04-09T11:14:00.000Z", value: 101, id: 13 },
    { timestamp: "2021-04-09T10:29:00.000Z", value: 86, id: 14 },
    { timestamp: "2021-04-09T10:39:00.000Z", value: 26, id: 15 },
    { timestamp: "2021-04-09T11:33:00.000Z", value: 228, id: 16 },
    { timestamp: "2021-04-09T10:53:00.000Z", value: 207, id: 17 },
    { timestamp: "2021-04-09T10:25:00.000Z", value: 195, id: 18 },
    { timestamp: "2021-04-09T11:06:00.000Z", value: 309, id: 19 },
    { timestamp: "2021-04-09T11:21:00.000Z", value: 23, id: 20 },
    { timestamp: "2021-04-09T10:42:00.000Z", value: 309, id: 21 },
    { timestamp: "2021-04-09T11:20:00.000Z", value: 35, id: 22 },
    { timestamp: "2021-04-09T10:36:00.000Z", value: 171, id: 23 },
    { timestamp: "2021-04-09T10:27:00.000Z", value: 108, id: 24 },
    { timestamp: "2021-04-09T10:44:00.000Z", value: 389, id: 25 },
    { timestamp: "2021-04-09T11:08:00.000Z", value: 259, id: 26 },
    { timestamp: "2021-04-09T11:23:00.000Z", value: 85, id: 27 },
    { timestamp: "2021-04-09T10:24:00.000Z", value: 313, id: 28 },
    { timestamp: "2021-04-09T11:01:00.000Z", value: 255, id: 29 },
    { timestamp: "2021-04-09T11:15:00.000Z", value: 444, id: 30 },
    { timestamp: "2021-04-09T11:24:00.000Z", value: 408, id: 31 },
    { timestamp: "2021-04-09T10:40:00.000Z", value: 442, id: 32 },
    { timestamp: "2021-04-09T11:34:00.000Z", value: 455, id: 33 },
    { timestamp: "2021-04-09T10:37:00.000Z", value: 346, id: 34 },
    { timestamp: "2021-04-09T10:48:00.000Z", value: 36, id: 35 },
    { timestamp: "2021-04-08T08:28:00.000Z", value: 353, id: 36 },
    { timestamp: "2021-04-08T08:40:00.000Z", value: 464, id: 37 },
    { timestamp: "2021-04-08T08:50:00.000Z", value: 255, id: 38 },
    { timestamp: "2021-04-08T09:08:00.000Z", value: 59, id: 39 },
    { timestamp: "2021-04-08T10:33:00.000Z", value: 173, id: 40 },
    { timestamp: "2021-04-08T10:39:00.000Z", value: 143, id: 41 },
    { timestamp: "2021-04-08T11:17:00.000Z", value: 439, id: 42 },
    { timestamp: "2021-04-08T11:19:00.000Z", value: 160, id: 43 },
    { timestamp: "2021-04-08T11:35:00.000Z", value: 28, id: 44 },
    { timestamp: "2021-04-08T12:01:00.000Z", value: 238, id: 45 },
    { timestamp: "2021-04-08T12:02:00.000Z", value: 264, id: 46 },
    { timestamp: "2021-04-08T12:09:00.000Z", value: 138, id: 47 },
    { timestamp: "2021-04-08T12:10:00.000Z", value: 226, id: 48 },
    { timestamp: "2021-04-08T12:11:00.000Z", value: 270, id: 49 },
    { timestamp: "2021-04-08T12:14:00.000Z", value: 170, id: 50 },
    { timestamp: "2021-04-08T12:18:00.000Z", value: 135, id: 51 },
    { timestamp: "2021-04-08T12:26:00.000Z", value: 37, id: 52 },
    { timestamp: "2021-04-08T12:46:00.000Z", value: 157, id: 53 },
    { timestamp: "2021-04-08T13:11:00.000Z", value: 203, id: 54 },
    { timestamp: "2021-04-08T13:12:00.000Z", value: 424, id: 55 },
    { timestamp: "2021-04-08T13:13:00.000Z", value: 200, id: 56 },
    { timestamp: "2021-04-08T13:44:00.000Z", value: 114, id: 57 },
    { timestamp: "2021-04-08T13:45:00.000Z", value: 305, id: 58 },
    { timestamp: "2021-04-08T13:46:00.000Z", value: 13, id: 59 },
    { timestamp: "2021-04-08T14:57:00.000Z", value: 410, id: 60 },
    { timestamp: "2021-04-08T15:09:00.000Z", value: 20, id: 61 },
    { timestamp: "2021-04-08T15:15:00.000Z", value: 493, id: 62 },
    { timestamp: "2021-04-09T10:52:00.000Z", value: 230, id: 63 },
    { timestamp: "2021-04-09T11:37:00.000Z", value: 416, id: 64 },
    { timestamp: "2021-04-08T11:18:00.000Z", value: 318, id: 65 },
    { timestamp: "2021-04-08T11:20:00.000Z", value: 453, id: 66 },
    { timestamp: "2021-04-08T12:15:00.000Z", value: 492, id: 67 },
    { timestamp: "2021-04-08T14:58:00.000Z", value: 393, id: 68 },
    { timestamp: "2021-04-09T11:02:00.000Z", value: 298, id: 69 },
    { timestamp: "2021-04-08T08:51:00.000Z", value: 435, id: 70 },
    { timestamp: "2021-04-08T12:13:00.000Z", value: 63, id: 71 },
    { timestamp: "2021-04-08T12:23:00.000Z", value: 35, id: 72 },
    { timestamp: "2021-04-08T14:59:00.000Z", value: 172, id: 73 },
    { timestamp: "2021-04-08T15:07:00.000Z", value: 107, id: 74 },
    { timestamp: "2021-04-08T15:08:00.000Z", value: 284, id: 75 },
    { timestamp: "2021-04-09T09:41:00.000Z", value: 258, id: 76 },
    { timestamp: "2021-04-09T09:42:00.000Z", value: 246, id: 77 },
    { timestamp: "2021-04-08T11:02:00.000Z", value: 303, id: 78 },
    { timestamp: "2021-04-08T11:34:00.000Z", value: 491, id: 79 },
    { timestamp: "2021-04-08T12:12:00.000Z", value: 85, id: 80 },
    { timestamp: "2021-04-08T12:21:00.000Z", value: 258, id: 81 },
    { timestamp: "2021-04-08T15:13:00.000Z", value: 290, id: 82 },
    { timestamp: "2021-04-09T11:25:00.000Z", value: 221, id: 83 },
    { timestamp: "2021-04-08T12:22:00.000Z", value: 153, id: 84 },
    { timestamp: "2021-04-09T11:03:00.000Z", value: 475, id: 85 },
    { timestamp: "2021-04-09T11:26:00.000Z", value: 362, id: 86 },
    { timestamp: "2021-04-08T15:14:00.000Z", value: 165, id: 87 },
    { timestamp: "2021-04-08T12:20:00.000Z", value: 340, id: 88 },
    { timestamp: "2021-04-08T12:17:00.000Z", value: 53, id: 89 },
    { timestamp: "2021-04-08T12:03:00.000Z", value: 135, id: 90 },
    { timestamp: "2021-04-08T12:24:00.000Z", value: 349, id: 91 },
    { timestamp: "2021-04-08T16:11:00.000Z", value: 299, id: 92 },
    { timestamp: "2021-04-08T09:51:00.000Z", value: 394, id: 93 },
    { timestamp: "2021-04-08T12:19:00.000Z", value: 30, id: 94 },
    { timestamp: "2021-04-08T10:56:00.000Z", value: 85, id: 95 },
    { timestamp: "2021-04-08T11:21:00.000Z", value: 171, id: 96 },
    { timestamp: "2021-04-08T12:27:00.000Z", value: 223, id: 97 },
    { timestamp: "2022-04-08T16:13:00.000Z", value: 433, id: 98 },
    { timestamp: "2022-04-08T12:47:00.000Z", value: 391, id: 99 },
    { timestamp: "2022-04-08T13:47:00.000Z", value: 410, id: 100 },
  ];

  export const mockbcSeatsData = Array.from({length:54},(element,index)=>
  {
    return{id:index+1,seatError:1 ,seatsMessage:"Backrest Issue"}
  }

  

  )

  export const mockecSeatsData = Array.from({length:165},(element,index)=>
  {
    return{id:index+1,seatError:0,seatsMessage:"Backrest Issue"}
  }

  

  )
