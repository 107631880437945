const zones = [
  {
    value: "C1",
    pdu: "1440MY12",
    fin: 1440,
    box: 1,
  },
  {
    value: "C2",
    pdu: "1439MY12",
    fin: 1439,
    box: 2,
  },
  {
    value: "C3",
    pdu: "1438MY12",
    fin: 1438,
    box: 3,
  },
  {
    value: "C4",
    pdu: "1437MY12",
    fin: 1437,
    box: 4,
  },
  {
    value: "C5",
    pdu: "1436MY12",
    fin: 1436,
    box: 5,
  },
  {
    value: "C6",
    pdu: "1435MY12",
    fin: 1435,
    box: 6,
  },
  {
    value: "C7",
    pdu: "1408MY12",
    fin: 1408,
    box: 7,
  },
  {
    value: "C8",
    pdu: "1407MY12",
    fin: 1407,
    box: 8,
  },
  {
    value: "C9",
    pdu: "1406MY12",
    fin: 1406,
    box: 9,
  },
  {
    value: "C10",
    pdu: "1405MY12",
    fin: 1405,
    box: 10,
  },

  {
    value: "C11",
    pdu: "1403MY121",
    fin: 1403,
    box: 11,
  },
  {
    value: "C12",
    pdu: "1402MY121",
    fin: 1402,
    box: 12,
  },
  {
    value: "C13",
    pdu: "1401MY121",
    fin: 1401,
    box: 13,
  },


  {
    value: "C14",
    pdu: "1540MY12",
    fin: 1540,
    box: 14,
  },
  {
    value: "C15",
    pdu: "1539MY12",
    fin: 1539,
    box: 15,
  },
  {
    value: "C16",
    pdu: "1538MY12",
    fin: 1538,
    box: 16,
  },
  {
    value: "C17",
    pdu: "1537MY12",
    fin: 1537,
    box: 17,
  },
  {
    value: "C18",
    pdu: "1536MY12",
    fin: 1536,
    box: 18,
  },
  {
    value: "C19",
    pdu: "1535MY12",
    fin: 1535,
    box: 19,
  },
  {
    value: "C20",
    pdu: "1508MY12",
    fin: 1508,
    box: 20,
  },
  {
    value: "C21",
    pdu: "1507MY12",
    fin: 1507,
    box: 21,
  },
  {
    value: "C22",
    pdu: "1506MY12",
    fin: 1506,
    box: 22,
  },
  {
    value: "C23",
    pdu: "1505MY12",
    fin: 1505,
    box: 23,
  },
  {
    value: "C24",
    pdu: "1503MY121",
    fin: 1503,
    box: 24,
  },
  {
    value: "C25",
    pdu: "1502MY121",
    fin: 1502,
    box: 25,
  },
  {
    value: "C26",
    pdu: "1501MY121",
    fin: 1501,
    box: 26,
  },
];

export default zones;
