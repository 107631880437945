import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Backdrop, Grid, Typography,CircularProgress } from '@material-ui/core';
import { CheckCircle, Error } from '@airbus/icons/react';
import { Divider, ProgressBar } from "@airbus/components-react"
import meat from '../../images/meat.png';
import vegetable from '../../images/veg.png';
import fruit from '../../images/fruit.png';
import special from '../../images/special.png';
import drink from '../../images/drink.png';
import AppsIcon from '@material-ui/icons/Apps';
import EcoIcon from '@material-ui/icons/Eco';
import { Select, Inline, Tabs, Tab, ColorModeProvider, RangeSlider, Rating, Stack, Card, CardActions, CardContent, CardHeader, CardMedia, CardActionArea, CardTitle, IconButton, Container, Col, Content, Row, Toast, Flex, ButtonGroup } from "@airbus/components-react"
import ReactEcharts from "echarts-for-react";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  
  
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'black',
    opacity: 0.7
  },


}));





const  CateringOverview=(props)=>{ 
  const summed = props.cateringData ? props.cateringData.payload.report.wastedCensus.materialNameWeights.reduce((acc, cur, i) => {
    const item = i > 0 && acc.find(({ categoryName }) => categoryName === cur.categoryName)
    if (item) item.materialWeight += cur.materialWeight;
    else acc.push({ categoryName: cur.categoryName, materialWeight: cur.materialWeight }); // don't push cur here
    return acc;
  }, []) : 0
  console.log(summed);

  const gaugeX = {
    title: {
      show: false,
    },
    series: [
      {
        min: 0,
        max: 10,
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        progress: {
          show: false,
          width: 5,
        },
        data: [
          {
            value: props.totalBCHours,
            // name: 'BC',
          },
        ],
        axisLine: {
          roundCap: false,
          lineStyle: {
            width: 12,
            color: [
              [0.3, '#F44336'],
              [0.66, '#FFD600'],
              [1, '#8BC34A'],
            ],
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          length: 15,
          lineStyle: {
            width: 0,
            color: '#999',
          },
        },
        axisLabel: {
          show: false,
          distance: -70,
          color: '#999',
          fontSize: 14,
          formatter: function (value) {
            if (value === 2) {
              return 'Slower';
            } else if (value === 5) {
              return 'Planned';
            } else if (value === 8) {
              return 'Faster';
            }
            return '';
          },
        },
        pointer: {
          icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
          length: '85%',
          width: 6,
          offsetCenter: [0, '0'],
        },
        anchor: {
          show: true,
          showAbove: true,
          size: 10,
          itemStyle: {
            borderWidth: 3,
          },
        },
        detail: {
          show: false,
          // valueAnimation: true,
          // fontSize: 30,
          // offsetCenter: [0, '20%'],
        },
      },
    ],
  };
   const gaugeY = {
    title: {
      left: 'center',
    },
    series: [
      {
        min: 0,
        max: 10,
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        progress: {
          show: false,
          width: 5,
        },
        data: [
          {
            value: props.totalECHours,
            // name: 'EC',
          },
        ],
        axisLine: {
          roundCap: false,
          lineStyle: {
            width: 12,
            color: [
              [0.3, '#F44336'],
              [0.66, '#FFD600'],
              [1, '#8BC34A'],
            ],
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          length: 15,
          lineStyle: {
            width: 0,
            color: '#999',
          },
        },
        axisLabel: {
          show: true,
          distance: -70,
          color: '#999',
          fontSize: 14,
          formatter: function (value) {
            if (value === 2) {
              return 'Slower';
            } else if (value === 5) {
              return 'Planned';
            } else if (value === 8) {
              return 'Faster';
            }
            return '';
          },
        },
        pointer: {
          icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
          length: '85%',
          width: 6,
          offsetCenter: [0, '0'],
        },
        anchor: {
          show: true,
          showAbove: true,
          size: 10,
          itemStyle: {
            borderWidth: 3,
          },
        },
        detail: {
          show: false,
          valueAnimation: true,
          fontSize: 30,
          offsetCenter: [0, '20%'],
        },
      },
    ],
  };


  
  const abcwastepercent1 = [{
    categoryName: "Drink",
    weight: summed[0] ? summed[0].materialWeight : 0.2
  }, {
    categoryName: "Fruit",
  
    weight: summed[1] ? summed[1].materialWeight : 0.2
  }, {
    categoryName: "Meat",
  
    weight: summed[2] ? summed[2].materialWeight : 0.2
  }, {
    categoryName: "Special",
  
    weight: summed[3] ? summed[3].materialWeight : 0.2
  },
  {
    categoryName: "Vegetables",
  
    weight: summed[4] ? summed[4].materialWeight : 0.2
  },
  
  ]
  
  
  
  const doughnutChart =
  {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      left: "2%",
      top: '17%',
      // left:"0.08%",
      orient: 'vertical',
      align: 'left',
      height: '200px', //example
      // backgroundColor: '#ccc',
      textStyle: {
        color: 'white'
        // ...
      }
    },
    dataset: {
  
      source: abcwastepercent1
    },
  
    series: [{
      type: 'pie',
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: true
      },
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
    }]
  };
  
  const classes = useStyles();
  console.log(props.totalHours)
  console.log('object :>> ',props.finalBCPercentage);
  return(
  <Container>
    <Backdrop open={props.loading} className={classes.backdrop} style={{opacity:"0.8"}}>
  <CircularProgress color="inherit" /></Backdrop>
<Row justify='start' >
  <Col xs={3} xxs={3} sm={5} md={3} lg={3} style={{ paddingTop: "1rem" }} >
    {/* {console.log(props)} */}
    
      <div onClick={() => { props.handleTabsChange(null, 1); props.handleSetSelectedChartsSelectionValue(null, 'overview') }} style={{ cursor: 'pointer' }} >
        <Card style={{ backgroundColor: "#1C2747", minWidth: props.matches ? "26rem" : "16rem", minHeight: props.matches ? "30rem" : "30rem" }}>
          <CardContent style={{ color: "white", width: props.matches ? "30rem" : "15rem", height: props.matches ? "26rem" : "26rem" }}>
            <CardTitle style={{ fontSize: props.matches ? "2rem" : "1.5rem" }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
                <span>Meals</span>
                { props.haveConnect ? <CheckCircle style={{ height: "1.5rem", color:"green" } }/>: <Error style={{ height: "1.5rem" }}/> }
              </div>              </CardTitle>
            <br></br>
              
            <Row alignItems='center'><Col lg={2} md={2}>  <div>{"BC"}</div> </Col><Col md={2}lg={2}><div> {props.bcTaken ? (props.bcTaken * props.totalUnits).toPrecision(4) :0}</div></Col><Col md={3}lg={2}><div >{props.bcTaken *100}%</div></Col><Col lg={2} md={2}><ProgressBar value={props.bcTaken} style={{ width: "5rem", textColor: "white" }} /> </Col>  
               </Row>
              <Divider style={{ width: props.matches ? "20rem" : "12rem" }} />
           
              <Row alignItems='center'><Col lg={2} md={2}>  <div>{"EC"}</div> </Col><Col lg={2}md={2}><div> {props.ecTaken ? (props.ecTaken * props.totalUnits).toPrecision(2) :0}</div></Col><Col lg={2}md={3}><div >{props.ecTaken *100}%</div></Col><Col lg={2}md={2}><ProgressBar value={props.ecTaken} style={{ width: "5rem", textColor: "white" }} /> </Col>  
               </Row>
              <Divider style={{ width: props.matches ? "20rem" : "12rem" }} />
              <br></br>
              <div> {"Meals Types"}</div>
              <br></br>

         
<Row alignItems='center'><Col md={3} lg={2}>                 <img src={meat} style={{ height: '25px', width: '25px', marginRight: "1rem" }} alt="fireSpot" /> </Col>  <Col md={6} lg={4}> 
                    {"Meat"} </Col>
<Col md={3}lg={3}>{props.meatTaken} </Col></Row>
<Divider style={{ width: props.matches ? "20rem" : "12rem" }} />
<Row alignItems='center'><Col md={3} lg={2}>                 <img src={ vegetable} style={{ height: '25px', width: '25px', marginRight: "1rem" }} alt="fireSpot" /> </Col>  <Col md={6} lg={4}> 
                    {"Vegetables"} </Col>
                    <Col md={3}lg={3}>{props.vegTaken} </Col></Row>
<Divider style={{ width: props.matches ? "20rem" : "12rem" }} />
<Row alignItems='center'><Col md={3} lg={2}>                 <img src={fruit} style={{ height: '25px', width: '25px', marginRight: "1rem" }} alt="fireSpot" /> </Col>  <Col md={6}lg={4}> 
                    {"Fruit"} </Col>
                    <Col md={3}lg={2}>{props.fruitTaken} </Col></Row>
<Divider style={{ width: props.matches ? "20rem" : "12rem" }} />
<Row alignItems='center'><Col md={3} lg={2}>                 <img src={special} style={{ height: '25px', width: '25px', marginRight: "1rem" }} alt="fireSpot" /> </Col>  <Col md={6}lg={4}> 
                    {"Special"} </Col>
                    <Col md={3}lg={2}>{props.specialTaken} </Col></Row>
<Divider style={{ width: props.matches ? "20rem" : "12rem" }} />

<Row alignItems='center'><Col md={3} lg={2}>                 <img src={drink} style={{ height: '25px', width: '25px', marginRight: "1rem" }} alt="fireSpot" /> </Col>  <Col md={6}lg={4}> 
                    {"Drink"} </Col>
                    <Col md={3}lg={2}>{props.drinkTaken} </Col></Row>
<Divider style={{ width: props.matches ? "20rem" : "12rem" }} />

        
          
          </CardContent>


          <Container>
            <Row justify="center" alignItems="center">
              {/* <Button onClick={() => {handleChange(null, 1); handleSetSelectedChartsSelectionValue(null,'wastage')}} style={{ backgroundColor: "white", marginTop: "1rem" }}> View Meals Details </Button> */}
            </Row>
          </Container>
        </Card>
      </div>
    
  </Col>
  <Col xs={3} xxs={3} sm={5} md={3} lg={3} style={{ marginLeft: props.matches ? "3rem" : "3rem", paddingTop: "1rem" }}>
  <div  >

     
        <Card style={{ backgroundColor: "#1C2747", minWidth: props.matches ? "26rem" : "16rem", minHeight: props.matches ? "30rem" : "30rem" }}>
          <CardContent style={{ color: "white" }}>
            <CardTitle style={{ fontSize: props.matches ? "2rem" : "1.5rem" }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
                <span>Service</span>
                { props.haveConnect ? <CheckCircle style={{ height: "1.25rem", color:"green" } }/>: <Error style={{ height: "1.5rem" }}/> }
              </div>              </CardTitle>
            <br></br>
              {/* <Inline spacing="3-x"> <div>{"BC"}</div><div>  65 </div> <div style={{ paddingLeft: "0.7rem" }}></div><Inline align="end" spacing='half-x'><div >65%</div><ProgressBar value={65} style={{ width: "5rem", textColor: "white" }} /></Inline> </Inline> */}
              {/* <Divider /> */}

              <Row >  <Col md={6} lg={7}> 
             <b>{"Total Time"}</b>  </Col>
<Col md={4}> {(props.totalHours)} Mins </Col></Row>

    
              <Divider style={{ width: props.matches ? "22rem" : "13rem", color: "#6d6d6d" }} />
              <br></br>
              {/* <div> {"Meals Types"}</div> */}
              <Row alignItems='center'>  <Col md={2}> 
              {"BC"} </Col>
              <Col md={5}> 
              {"ON TRACK"} </Col>
<Col md={2}> {props.totalBCHours} Mins </Col>

<Col md={2}> <div style={{marginTop:"1rem"}}>  {<ReactEcharts

                  option={gaugeX} style={{
         
                    width: props.matches ? 60 : 70,
                    height: props.matches ? 60 : 70,
                  }} />}
                  </div></Col>
</Row>
              
          <Divider style={{ width: props.matches ? "22rem" : "13rem", color: "#6d6d6d" }} />
          <Row alignItems='center'>  <Col md={2}> 
              {"EC"} </Col>
              <Col md={5}> 
              {"ON TRACK"} </Col>
<Col md={2}> {props.totalECHours} Mins </Col>

<Col md={2}> <div style={{marginTop:"1rem"}}>  {<ReactEcharts

                  option={gaugeY} style={{
         
                    width: props.matches ? 60 : 70,
                    height: props.matches ? 60 : 70,
                  }} />}
                  </div></Col>
</Row>
          </CardContent>

          <Container>
            <Row justify="center" alignItems="center">
              {/* <Button onClick={() => handleChange(null, 1)} style={{ backgroundColor: "white", marginTop: props.matches ? "7.2rem" : "6rem" }}> View Service Details </Button> */}
            </Row>
          </Container>
        </Card>
      </div>
    
  </Col>
  <Col xs={3} xxs={3} sm={10} md={3} lg={3} style={{ paddingLeft: props.matches ? "3rem" : "3rem", paddingTop: "1rem" }}>
  
      <div onClick={() => {  /*props.handleTabsChange(null, 1); props.handleSetSelectedChartsSelectionValue(null, 'wastage') */}} style={{ cursor: "pointer" }} >
        <Card style={{ backgroundColor: "#1C2747", minWidth: props.matches ? "26rem" : "16rem", minHeight: props.matches ? "30rem" : "30rem" }}>
          <CardContent style={{ color: "white" }}>
            <CardTitle style={{ fontSize: props.matches ? "2rem" : "1.5rem" }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
                <span>Waste</span>
                { props.haveConnect ? <CheckCircle style={{ height: "1.25rem", color:"green" } }/>: <Error style={{ height: "1.5rem" }}/> }
              </div>              </CardTitle>
            <Container >
              <Row alignItems="start">

                <Col>
                  <ReactEcharts
                    option={doughnutChart} style={{
                      width: props.matches ? "25rem" : "20rem",
                      height: props.matches ? "12rem" : "8rem",
                    }} />

                </Col>
              </Row>
            </Container>
            <br></br>
            <br></br>

            <Row alignItems='center'><Col md={2}>                <AppsIcon /> </Col>  <Col md={4}> 
                    {"Weight"} </Col>
<Col md={6}>         {(props.totalWeight ?(props.totalWeight ) :0 )} {" Kg"} </Col></Row>
<Divider style={{ width: props.matches ? "20rem" : "13rem" }} />

<Row alignItems='center'><Col md={2}>                <MonetizationOnIcon /> </Col>  <Col md={4}> 
                    {"Cost"} </Col>
<Col md={6}>    {"$"}      {(props.totalCost )}  </Col></Row>
<Divider style={{ width: props.matches ? "20rem" : "13rem" }} />

<Row alignItems='center' ><Col md={2}>                <EcoIcon /> </Col>  <Col md={4}> 
                    {"Footprint"} </Col>
<Col md={6}>          {(props.totalFootprint )} {"Kg/CO2"} </Col></Row>
<Divider style={{ width: props.matches ? "20rem" : "13rem" }} />


      
           
          </CardContent>
 
          <Container>
            <Row justify="center" alignItems="center">
            </Row>
          </Container>
        </Card>
      </div>
  </Col>

</Row>

</Container>

  )

}

export default CateringOverview