import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const LoginPage = () => {
    const goToHostedLogin = () => {
        Auth.federatedSignIn();
    }

    return (
        <div>
            <h1>Login Page</h1>
            <button onClick={goToHostedLogin}>Login</button>
        </div>
    )
}

export default LoginPage;