import { Button, Inline, Tabs, Tab, ColorModeProvider, RangeSlider, Rating, Stack, Card, CardActions, CardContent, CardHeader, CardMedia, CardActionArea, CardTitle, IconButton, Container, Col, Content, Row, Toast, Flex, ButtonGroup } from "@airbus/components-react"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Formik } from "formik";
import axios from 'axios'
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
} from "date-fns";


const customStyles = {
  option:() => ({
 
    height:'100%',
    color:'black',
    paddingLeft:'10px',
  }),
  
}

const Filters = ({ filters = [], onSubmit, tabValue }) => {
console.log(tabValue)
  const initialValues = filters.reduce((acc, filter) => {
	return {
  	...acc,
  	[filter.name]:
    filter.initialValue
    ||(	filter.type === "daterange" ? [new Date(), new Date()] : null),
	};
  }, {});

  return (
	<Formik initialValues={initialValues} onSubmit={onSubmit}>
  	{({ values, handleChange, submitForm }) => {
      for(const key in values){
   
        if( !values[key]){

const filter = filters.find(f => f.name===key && f.type === "dropdown");
if(filter){
const options = typeof filter?.options === "function" ? filter.options(values) : filter.options;
if(options.length===1){
  handleChange({ target: { name: key, value: options[0].value } });
}
}
        }
      }
    	return (
      	<div style={{ display: "flex", gap: "0.5rem" }}>
        	{filters.map((filter) => {
          	const { name, options: o } = filter;

          	const options = typeof o === "function" ? o(values) : o;

          	const value = values[name];
          	const details =
            	filter.type === "dropdown" ? (
                <div style={{width: '150px'}}>
                 
              	<Select 
                styles={customStyles}
          holder={filter.label}
                	{...filter}
                	options={options}
                	value={options?.find((o) => o.value === value) || null}
                	onChange={(v) => {
                  	handleChange({ target: { name, value: v.value } });
                    filter.onChange?.(handleChange)
                	}}
              	/>
       
                </div>
            	) : (
            
              	<DatePicker 
                	startDate={value[0]}
                	endDate={value[1]}
			 
                	selectsRange
				

                	onChange={(v) => {

                  	handleChange({ target: { name, value: v } });
                	}}
              	/>
                   
            	);
          	return (
            	<div key={name}>
              	{/* <p>{filter.label}</p> */}
              	{details}
            	</div>
          	);
        	})}
            <ColorModeProvider mode="dark">
        	<Button style={{width:"5rem",height:"2.5rem"}} variant="primary" onClick={submitForm}>Search</Button>
          </ColorModeProvider>
      	</div>
    	);
  	}}
	</Formik>
  );
};



export const GainsTopFilters =({filters,onSubmit, tabValue})=>{


 
  


    return(
<Container style={{ maxWidth: "80%", paddingBottom: "0.8rem" }}>
<Row justify='start' style={{ paddingTop: "1rem" }}>
<Filters filters={filters} onSubmit={onSubmit} tabValue={tabValue} />
</Row>
</Container>
    )

    }