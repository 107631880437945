import React, { useEffect, useState, useRef } from 'react'
// import { SparkLine } from '../../components//SparkLine'
// import { SparkLine2 } from '../../components/backend/dashboard/SparkLine2'
import 'echarts/lib/component/grid';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Grid, Typography } from '@material-ui/core';
// import Widget from '../../components/backend/dashboard/Widget'
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ReactEcharts from "echarts-for-react";
import { Select, CheckboxGroup, Toggle, Accordion, Badge, Chip, Button, Collapsible, Checkbox, Inline, Tabs, Tab, ColorModeProvider, RangeSlider, Rating, Stack, Card, CardActions, CardContent, CardHeader, CardMedia, CardActionArea, CardTitle, IconButton, Container, Col, Content, Row, Toast, Flex } from "@airbus/components-react"
import axios from 'axios'
// import { topWasteCostOverview, topWasteCostOverview2 } from '../../components/Gains/GainsChart'
import { Link, useLocation } from 'react-router-dom';
import { useTheme, LinearProgress } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogHeader, DialogTitle } from "@airbus/components-react"
import { renderScatterChart } from "air-chart";
import { GaugeChart, PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { DatePicker, Divider } from "@airbus/components-react"
import { CheckCircle, Cancel, Error, AirplaneModeActive, LocalCafe, AirlineSeatReclineNormal, AllInbox, Kitchen, SearchIcon, NotificationsIcon, HelpIcon, Notification, AirplanemodeActive, Chevronleft, Chevronright, Check, Clear, Category } from '@airbus/icons/react';
import { ReactTable2, ChipInputCell, ChipInputCellGalley, ProgressInputCell, ChipInputCellTop, ChipInputCellGalleyTop } from '../../components/Gains/ReactTable'
import MaxWidthDialog from '../../components/Gains/MaxWidthDialog';
import ReactTable from '../../components/Gains/ReactTable'
import mainLogo from '../../images/bg_fleet.svg';
import galleyArc from '../../images/galley-arc.svg';
import { GainsTopFilters } from "../../components/Gains/GainsTopFilters"
import _ from 'lodash';
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
  startOfDay,
  endOfDay
} from "date-fns";


echarts.use([GaugeChart, CanvasRenderer]);
process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';



const filters = [
  {
    name: "flightNo",
    label: "Flight No.",
    type: "dropdown",
    initialValue:"AIB8888",
    options: [
      { label: "AIB8888", value: "AIB8888" },
      { label: "MU5100", value: "MU5100" },
      {label: "MU5101", value: "MU5101" },
    ],
     onChange:(handleChange)=>{
    console.log('changing')
    handleChange({target:{name:'origin',value:null}})
    handleChange({target:{name:'destination',value:null}})

  }
  },
  {
    name: "dateRange",
    // label: "Range",
    type: "daterange",
    initialValue:Array(2).fill(new Date('2023-02-08'))
  },
  {
    name: "origin",
    label: "Origin",
    type: "dropdown",
    options: (values) =>
      [
        {  code: "AIB8888",label: "WBGG", value: "WBGG" },
        {  code: "AIB8888",label: "ZGSZ", value: "ZGSZ" },
        {  code: "MU5100",label: "ZGSZ", value: "ZGSZ" },
        {  code: "MU5101",label: "ZBAA", value: "ZBAA" },
      ].filter((item) => item.code === values.flightNo),
  onChange:(handleChange)=>{
    console.log('changing')
    handleChange({target:{name:'destination',value:null}})
  }
    },

  {
    name: "destination",
    label: "Destination",
    type: "dropdown",
    options: (values1) =>
      [
      
        {  code: "WBGG",label: "WMKK", value: "WMKK" },
        {  code: "ZGSZ",label: "ZBAA", value: "ZBAA" },
        {  code: "ZBAA",label: "ZGSZ", value: "ZGSZ" },
      ].filter((item) => item.code === values1.origin),

    },




  ];





const galleyMemo = [{ id:1, galleyname: "Galley 1", connectivitychip: 0, connectivitypercent: 0, working: 0, galleychip:0, details: 1 },
{ id: 2, galleyname: "Galley 2", connectivitychip: 1, connectivitypercent: 100, working: 6, galleychip: 2, details: 2 },
{ id: 3, galleyname: "Galley 3", connectivitychip: 0, connectivitypercent: 0, working: 0, galleychip: 0, details: 3 },
{ id: 4, galleyname: "Galley 4", connectivitychip: 0, connectivitypercent: 0, working: 0, galleychip: 0, details: 4 }
]




function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

export function Gains({ handleListItemClick }) {
  const[healthy,setHealthy]=useState(null)
  const galley1gains = [
    {
      id: 1,
      name: "Oven 1",
      serialNumber: "543393",
      operatingHours: 32,
      lastMaintenance: "2 months ago",
      healthy: 1,
      size: "big",
      onClick: () => { console.log("hello") }
    },
    {
      id: 2,
      name: "Oven 2",
      serialNumber: "0xL",
      operatingHours: 32,
      lastMaintenance: "2 months ago",
      healthy: healthy,
      size: "big"
    },
    {
      id: 3,
      name: "Water heater",
      serialNumber: "543393",
      operatingHours: 32,
      lastMaintenance: "2 months ago",
      healthy: 1,
      size: "small"
    },
    {
      id: 4,
      name: "Nesp. Maker",
      serialNumber: "543393",
      operatingHours: 32,
      lastMaintenance: "2 months ago",
      healthy: 1,
      size: "small"
    },
    {
      id: 5,
      name: "Coffee Maker",
      serialNumber: "543393",
      operatingHours: 32,
      lastMaintenance: "2 months ago",
      healthy: 1,
      size: "small"
    },
    {
      id: 6,
      name: "Oven 3",
      serialNumber: "543393",
      operatingHours: 32,
      lastMaintenance: "2 months ago",
      healthy: 1,
      size: "big"
    },
    {
      id: 7,
      name: "Oven 4",
      serialNumber: "543393",
      operatingHours: 32,
      lastMaintenance: "2 months ago",
      healthy: 1,
      size: "big"
    }
  ];
  const [gainsFinal, setGainsFinal] = useState([]);
  const [gainsCountFinal, setGainsCountFinal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange,setDateRange]=useState([new Date("2023-02-08"),new Date("2023-02-08")]);
  const getEndpoint = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=v1/crew/cargo&topic_extension=/PDU&payload=1&created_at=${createdOn}`

  const onSubmit = async (values = { dateRange: [new Date(), new Date()] }) => {
    setLoading(true)
    try {
  
      const { dateRange } = values;
      setDateRange(dateRange)
      const [start, end] = dateRange;
      const [startYear, endYear] = [start, end].map((d) => d.getFullYear());
      const [startMonth, endMonth] = [start, end].map((d) => format(d, "MM"));
      const [startDay, endDay] = [start, end].map((d) => format(d, "dd"));
      const isSameMonth = startMonth === endMonth;
      const isSameDay = startDay === endDay;
      let createdOn = "";
      if (isSameMonth && isSameDay)
        createdOn = `${startYear}-${startMonth}-${startDay}`;
      else if (isSameMonth) createdOn = `${startYear}-${startMonth}`;
      else {
        const range =
          differenceInMonths(endOfMonth(end), startOfMonth(start)) + 1;
        createdOn = Array(range)
          .fill("")
          .map((_, index) => format(addMonths(start, index), "yyyy-MM"));
      }
      const apiData = [];
  console.log(createdOn)
  // for diff month
      if (Array.isArray(createdOn)) {
        for (const co of createdOn) {
          const { data: response } = await axios.get('gainsjson.json');
  console.log(...response)
          apiData.push(...response || []);
  
         
          
        }
        // const realData = values.filter(
        //   (v) => v.createdOn >= start && v.createdOn <= end
        // );
        //setNestedObjectValues(realData)
      }
      else {
        const { data: response } = await axios.get('gainsjson.json');
  
        apiData.push(...response || []);
  
  
  
      }
      console.log('start',start)
  console.log('end',end)
      const filteredData = apiData.filter(d => {
        const date = new Date(d.created_at)
        return date >= startOfDay(start) && date <= endOfDay(end)
      })
      console.log(filteredData)
      console.log(apiData)
      const cleanData = filteredData.map(d => {
        let payload;
        try {
          payload =d.payload
        }
        catch (e) { }
        return { ...d, payload }
      })
  
      console.log('cleanData :>> ', cleanData);
  

      const changed = cleanData.map(obj=>{
const date= new Date(Date.parse(obj.created_at))
const year = date.getUTCFullYear();
const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
const day = date.getUTCDate().toString().padStart(2, '0');
obj.created_at = `${year}-${month}-${day}`;
return obj

      })


      var output =
  _(changed )
    .groupBy('created_at')
    .map((objs, key) => ({
 
        'date': key,
        'errorCount': _.sumBy(objs, item => Number(item.payload)) }
        
        
        )
        
        )
    .value();

    console.log('output :>> ', output);

console.log('changed :>> ', changed);
      setGainsFinal(changed)
      setGainsCountFinal(output)
  
  
      const mergedGains = galley1gains.map(item => {
        const matchingObj = cleanData.find(obj => obj.topic_extension.includes(item.serialNumber));
        return matchingObj ? { ...item, ...matchingObj } : item;
      });
      console.log('mergedGains :>> ', mergedGains);
     
    if(mergedGains.length>0){
      setHealthy(0)
      return 0
    }
  
    }
    finally {
  
  
      setLoading(false)
    }
  
  
  };
  const filterGalley=(value)=>{
    console.log(value)
    console.log(galleyMemo)
   if(value==0) { return (setFinalGalleyMemo([galleyMemo[0]]))}
   else if (value==1){ return setFinalGalleyMemo ([galleyMemo[1]])}
   else if (value==2){return setFinalGalleyMemo ([galleyMemo[2]])}
   else if (value==3){return setFinalGalleyMemo ([galleyMemo[3]])}
   else return ( setFinalGalleyMemo(galleyMemo))
  }


  const onDateChange = (dates) => {
    const [start, end] = dates;
    console.log(start, end)
    setStartDate(start);
    setEndDate(end);
  };
  { handleListItemClick(null, 1) }

  const [rangeValue, setRangeValue] = useState([new Date(2022, 11, 7), new Date(2022, 11, 13)])
  const options = [
    { label: "Flight Number", value: "flightNumber" },
    { label: "Class", value: "class" },
  ];

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const tabNumber = +(params.get("tab") || 0)


  const handleChange = (event, newValue) => {
    { setTabValue(newValue) }
  };

  const[finalGalleyMemo,setFinalGalleyMemo]= useState(galleyMemo);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedCheck, setSelectedCheck] = useState(0);
  const [dataArray, setDataArray] = useState([]);
  const [dataArray1, setDataArray1] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const handleCloseDialog = () => setShowDialog(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [selectedMode, setSelectedMode] = useState("flightNumber");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const [open, setOpen] = useState(false);
  const [alignment, setAlignment] = React.useState('%');
  const [alignment1, setAlignment1] = React.useState('%');
  const [alignment2, setAlignment2] = React.useState('%');
  const [alignment3, setAlignment3] = React.useState('%');
  const [selectedFilterValue, setSelectedFilterValue] = useState(0);
  const [selectedFlightOption, setSelectedFlightOption] = useState("AIB8888");
  const [selectedOriginValue, setSelectedOriginValue] = useState("WBGG");
  const [name, setName] = useState(null)
  const [selectedDestValue, setSelectedDestValue] = useState("WMKK");
  const [selectedChartsSelectionValue, setSelectedChartsSelectionValue] = useState("overview");
  const [selectedWasteChartsSelectionValue, setSelectedWasteChartsSelectionValue] = useState("errors");


  const [intervalOption, setIntervalOption] = React.useState([
    "datemonthyear",
    "mealsServed",
    "Day",
  ]);


  const flightOptions = [
    { label: "AIB8888", value: "AIB8888" },
    // { label: "Option 2", value: "option2" },
    // { label: "Option 3", value: "option3" },
  ];
  const filterOptions = [
    { label: "All Galleys", value: 0 },
    { label: "Galley 1", value: 1 },
    { label: "Galley 2", value: 2 },
    { label: "Galley 3", value: 3 },
    { label: "Galley 4", value: 4 }
    // { label: "Option 2", value: "option2" },
    // { label: "Option 3", value: "option3" },
  ];
  const originOptions = [
    { label: "WBGG", value: "WBGG" },
    // { label: "Option 2", value: "option2" },
    // { label: "Option 3", value: "option3" },
  ];
  const destOptions = [
    { label: "WMKK", value: "WMKK" },
    // { label: "Option 2", value: "option2" },
    // { label: "Option 3", value: "option3" },
  ];
  const ShowGalleyOptions = [
    { value: 0, label: "All galleys" },
    { value: 1, label: " Galley 1" },
    { value: 2, label: "Galley 2" },
    { value: 3, label: "Galley 3" },
    { value: 4, label: "Galley 4" }
  ]

  const wastageChartSelectionOptions = [
    { label: "Error Status", value: "errors" },
    { label: "Error Count", value: "errorsbar" },
    // { label: "Chart 3", value: null },
    // { label: "Option 2", value: "option2" },
    // { label: "Option 3", value: "option3" },
  ];



  const [openDialogx, setOpenDialogx] = React.useState(null)
  console.log("opendialog", openDialogx)
  const setOpenDialog = (value) => {
    setOpenDialogx(value)
    return 1;
  }
  const Cardx = props => {

    return (
      <Card style={{ backgroundColor: props.gain.healthy == 0 ? "#e57373" : "#757575", marginLeft: "0.1rem", marginTop: "0.3rem", width: props.gain.size == "big" ? "6.75rem" : "8rem", height: props.gain.size == "big" ? "6rem" : "4rem" }}
        onClick={
          () => {
            setOpenDialog(props.gain)
            // onCardClick(props.gain)
            props.gain.onClick?.()
          }}>
        <Inline align="center"><div style={{ fontSize: matches ? "0.60rem" : "0.60rem", fontWeight: "bold", color: "white" }}>
          {props.gain.name}</div>
          <div>{props.gain.healthy == 1 ? <CheckCircle style={{ color: "white" }} /> : <Error style={{ color: "white" }} />}</div>
        </Inline>
        <CardContent>

          {props.gain.size == "big" &&
            <Stack spacing='4-x' align='center'> <div></div>
              {props.gain.healthy == 0 ? <div style={{ fontSize: "0.52rem", fontWeight: "bold", color: "white" }}>{"Possible Failure"}</div> : <div><ChipInputCellGalley value={props.gain.healthy} />
              </div>}</Stack>
          }
          {props.gain.size == "small" &&
            <Stack align='start'>
              <div></div>
              {props.gain.healthy == 0 ? <div style={{ fontSize: "0.52rem", fontWeight: "bold", color: "white" }}>{"Possible Failure"}</div> :
                <ChipInputCellGalley value={props.gain.healthy} />

              }
            </Stack>
          }

        </CardContent>

      </Card>


    );
  };
  const CardListx = () => {
    return (
      <Container>
        <Row alignItems='start' >

          {galley1gains.map(gain => {
            return <Cardx gain={gain} key={gain.id} />;
          })}

        </Row>
      </Container>
    );
  };

  // create an empty array for each series
var NB_ERR_TX = [];
var NB_ERR_RX = [];

// loop through the data and populate the arrays
gainsFinal.forEach(function(d) {
  if (d.topic_extension.includes( 'NB_ERR_TX')) {
    NB_ERR_TX.push({
      name: d.created_at,
      value: [d.created_at, "NB_ERR_TX"]
    });
    console.log('NB_ERR_TX :>> ', NB_ERR_TX);
  } else if (d.topic_extension.includes('NB_ERR_RX')) {
    NB_ERR_RX.push({
      name: d.created_at,
      value: [d.created_at, 'NB_ERR_RX']
    });
  }
});


  const handleSetSelectedFilterValue = (event, filterValue) => {
    console.log( "filterValue", filterValue)

    setSelectedFilterValue(filterValue)
  };
  const handleSetSelectedWasteChartsSelectionValue = (event, wasteChartsSelection) => {
    setSelectedWasteChartsSelectionValue(wasteChartsSelection)
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleAlignment1 = (event, newAlignment1) => {
    setAlignment1(newAlignment1);
  };
  const handleAlignment2 = (event, newAlignment2) => {
    setAlignment2(newAlignment2);
  };
  const handleAlignment3 = (event, newAlignment3) => {
    setAlignment3(newAlignment3);
  };
 



 const topWasteCostOverview = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
    
       
      containLabel: "true"
    },
    legend: {
      // inactiveColor:"white",
      data: ['Error'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{

      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      // dimensions: [ 'created_at','topic_extension'],
  
  
    },
      // {fromDatasetIndex: 0,
      //   transform: {
      //     type: 'sort',
      //     config: { dimension:"servedQuantity", order: 'desc' }
      //   }
      // }
    ],
    yAxis: {
      type: 'category',
      name: 'Error',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    xAxis: {
      type: 'category', 
      name: 'Flight Hours',
 
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      },
      axisLabel: {
     
      
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        },
      }
    },
    series: [{ 
    name: "Series 1", 
    type: 'line',
    step: 'start',
    connectNulls:false,
    data: NB_ERR_TX
   
  },

  { 
    name: "Series 2", 
    type: 'line',
    step: 'start',
connectNulls:false,
    data: NB_ERR_RX
  },


  
  ]
  }


  const topWasteCostOverview2 = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
    
       
      containLabel: "true"
    },
    legend: {
      // inactiveColor:"white",
      data: ['Error'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{

      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: [ 'date','errorCount'],
      source:
      gainsCountFinal
    },
      // {fromDatasetIndex: 0,
      //   transform: {
      //     type: 'sort',
      //     config: { dimension:"servedQuantity", order: 'desc' }
      //   }
      // }
    ],
    yAxis: {
      type: 'value',
      name: 'No. of Errors',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    xAxis: {
      type: 'category', name: 'Flight Hours',
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      },
      axisLabel: {
     
      
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        },
      }
    },
    series: [{ type: 'bar',

    yAxisIndex: 0,
    name: "Error" },


  
  ]
  }

  return (
    // <div style={{ color: '#f9f9f9', marginBottom: 20 }}>
    <div style={{ backgroundColor: 'black' }}>

      <Grid container xs={12} >

        <Grid item xs={12} sm={12} md={12} lg={12} >
          <ColorModeProvider mode="dark">
            <Tabs
              floating={true}
              // className="bg-primaryblue-90"
              size="xlarge"
              onChange={(e, val) => {
                handleChange(null, val)
                // if (val === 0) {
                //   handleSetSelectedFilterValue(null, 0);

                // }
              }}
              aria-label="Tabs purpose label"
              value={tabValue}
              scrollable={true}
              //  variant="fullWidth"
              style={{ borderBottom: '1px solid #D9D9D9' }
              }
            >  <Tab {...a11yProps(0)}>Overview</Tab>
              <Tab {...a11yProps(1)}>GAINS Status</Tab>

            </Tabs>

          </ColorModeProvider>
        </Grid>
      </Grid>

      <Container style={{ maxWidth: "80%" }}>
        {/* <Row alignItems="end" justify="space-evenly"> */}

        <GainsTopFilters filters={filters} onSubmit={onSubmit}  tabValue={tabValue}/>

      </Container>
      <br></br>
      <Inline wrap="true" style={{ paddingBottom: "0.2rem" }} spacing='1-x'>
        {/* <Card style={{ minWidth: "10rem", width: "auto", height: "3rem", backgroundColor: "#1C2747", color: "white", padding: "0.8rem" }}><div style={{ fontSize: matches ? '1.5rem' : "1rem", textAlign: 'center' }}>GAINS: <b>{"4"}{"/"}{"4"}</b>
        </div> </Card> */}
        <Card style={{ minWidth: "10rem", width: "auto", height: "3rem", backgroundColor: "#1C2747", color: "white", padding: "0.8rem" }}>
          <div style={{ fontSize: matches ? '1.5rem' : "1rem", textAlign: 'center' }}>OVENS: <b>{"4"}{"/"}{"4"}</b>
          </div> </Card>
        <Card style={{ minWidth: "10rem", width: "auto", height: "3rem", backgroundColor: "#1C2747", color: "white", padding: "0.8rem" }}>
          <div style={{ fontSize: matches ? '1.5rem' : "1rem", textAlign: 'center' }}>WATER HEATERS: <b>{"1"}{"/"}{"1"}</b>
          </div> </Card>
        <Card style={{ minWidth: "10rem", width: "auto", height: "3rem", backgroundColor: "#1C2747", color: "white", padding: "0.8rem" }}>
          <div style={{ fontSize: matches ? '1.5rem' : "1rem", textAlign: 'center' }}>COFFEE MAKER: <b>{"1"}{"/"}{"1"}</b>
          </div> </Card>
        <Card style={{ minWidth: "10rem", width: "auto", height: "3rem", backgroundColor: "#1C2747", color: "white", padding: "0.8rem" }}>
          <div style={{ fontSize: matches ? '1.5rem' : "1rem", textAlign: 'center' }}>NESP. MAKER: <b>{"1"}{"/"}{"1"}</b>
          </div> </Card>

      </Inline>

      <TabPanel value={tabValue} index={0} style={{ color: "white" }}>
        <Container>
          <Row justify='start' >
            <Col xs={12} xxs={12} sm={12} md={12} lg={12} style={{ paddingTop: "1rem" }} >
              {
                <Box style={{ backgroundColor: "#1C2747", width: matches ? "70rem" : "55rem", height: matches ? "25rem" : "25rem" }}>
                  <CardContent style={{ color: "white" }}>
                    <Row justify="space-between" xxs={12} xs={12} sm={12} md={12} lg={12}>
                      <Col sm={4} md={8}>
                        <CardTitle style={{ fontSize: matches ? "2rem" : "1.5rem" }}>
                 
                          {"CONNECTED GAINS"}
                
                        </CardTitle>
                      </Col>
                      <Col sm={2} md={3} alignSelf="start">
                       
                          <Inline spacing='half-x'>
                          <div>
                          {"1 Galley Connected"} 
                          </div>
                          <Error/>
                          </Inline>
                     
                    
                        </Col>
                     

                    </Row>

                    <Container>
                      {/* <Row justify="center" alignItems="center"><Col>{"GALLEYS CONNECTIVITY STATUS"}</Col><Col>{"GALLEY EQUIPMENT HEALTH"}</Col></Row> */}
                      <br></br>
                      <Row justify="center" alignItems="center">

                        {/* <ReactTable2 handleSetSelectedFilterValue={handleSetSelectedFilterValue} Value handleChange={handleChange} style={{ minWidth: "50rem" }} /> */}
                        <ReactTable filterGalley={filterGalley} galleyMemo={finalGalleyMemo}  handleSetSelectedFilterValue={handleSetSelectedFilterValue} handleChange={handleChange} setOpenDialog={setOpenDialog} selectedFilterValue={selectedFilterValue} />
                        {/* <Button onClick={() => handleChange(null, 1)} style={{ backgroundColor: "white", marginTop: "1rem" }}> View Meals Details </Button> */}
                      </Row>
                    </Container>
                  </CardContent>


                </Box>
              }
            </Col>
          </Row>
        </Container>
      </TabPanel>
      <TabPanel value={tabValue} index={1} style={{ color: "white" }}>
        {openDialogx !== null && <MaxWidthDialog openDialogx={openDialogx} onClose={() => setOpenDialog(null)} />}

        <Container>
          <Row justify='start' >
            <Col xs={12} xxs={12} sm={12} md={12} lg={12} style={{ paddingTop: "0.2rem" }} >
              {(selectedFilterValue == 0) &&
                <Box style={{ backgroundColor: "#1C2747", width: matches ? "57rem" : "57rem", height: matches ? "30rem" : "30rem" }}>
                  <CardContent style={{ color: "white", width: matches ? "55rem" : "55rem", height: matches ? "26rem" : "26rem" }}>
                    <CardTitle style={{ fontSize: matches ? "1.5rem" : "1rem" }}>

                      <Row justify='start'>
                        <Col xs={3} sm={3} md={3} lg={3}>
                          <div style={{ fontSize: "1.2rem" }}>  {"CONNECTED GAINS"}</div></Col>
                        <Col xs={7} sm={7} md={7} lg={7} > <div style={{ fontSize: "0.8rem" }}> {"1" + "/4" + " Galleys Connected"}  <Error style={{ marginLeft: "0.2rem" }} />
                        </div>
                        </Col>


                        <Col xs={2} sm={2} md={2} >
                          <Select
                            name="aircrafts-controlled"
                            value={selectedCheck}
                            options={ShowGalleyOptions}
                            onChange={(e, value) => setSelectedCheck(value)}
                          ><Inline spacing='2-x'>
                            </Inline>
                          </Select>
                        </Col>






                      </Row>
                    </CardTitle>


                    <Row justify='start' alignItems='center'>

                      <div>
                        <img src={mainLogo} style={{ width: "55rem", height: "18rem", position: "absolute" }} />
                      </div>

                    </Row>

                    <br />
                    <Container>
                      <Row justify="center">

                        <Col md={3}>

                          {(selectedCheck == "1" || selectedCheck == "0") && <Card style={{ backgroundColor: "#9e9e9e", width: "7rem", height: "15rem", padding: "1.2rem" }}>

                            <Stack spacing='1-x'>
                              <Inline spacing='2-x'>
                                <Card style={{ width: "2rem", height: "5rem", backgroundColor: "#bdbdbd" }}> </Card> <Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}> </Card>
                              </Inline>
                              <Inline spacing='6-x'>
                                <div></div> <Card style={{ width: "2rem", height: "5rem", backgroundColor: healthy==0?"#AD4E4E" :"#bdbdbd" }}
                                >  <CardActionArea onClick={() => {
                                  setOpenDialog({
                                    id: 2,
                                    name: "Oven 2",
                                    serialNumber: "543393",
                                    operatingHours: 32,
                                    lastMaintenance: "2 months ago",
                                    healthy: 0,
                                    size: "big"
                                  },
                                  )
                                }}>

                                    <CardContent><CardTitle></CardTitle></CardContent>
                                  </CardActionArea>
                                </Card>
                              </Inline>
                              <Inline spacing='2-x'>
                                <Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}> </Card> <Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}> </Card>
                              </Inline>
                            </Stack>
                            <br></br>
                            <br></br>
                          </Card>
                          }

                        </Col>
                        <Col md={3}>

                          {(selectedCheck == "2" || selectedCheck == "0") && <Card style={{ width: "7rem", height: "15rem", paddingTop: "2rem", paddingLeft: "1.3rem", backgroundColor: "#9e9e9e" }}>
                            <Stack spacing='5-x' style={{ paddingTop: "1rem" }}>
                              <Inline spacing='2-x'>
                                <Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}> </Card> <Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}> </Card>
                              </Inline>
                              <Inline spacing='2-x'>
                                <Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}> </Card> <Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}> </Card>
                              </Inline>
                              <Inline spacing='2-x'>
                                <Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}> </Card> <Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}> </Card>
                              </Inline>

                            </Stack>
                          </Card>
                          }

                        </Col>
                        <Col md={3}>

                          {(selectedCheck == "4" || selectedCheck == "0") && <Card style={{ backgroundColor: "#9e9e9e", width: "12rem", height: "15rem", paddingTop: "2rem", paddingLeft: "1.3rem" }}>
                            <Container>
                              <Row justify='space-between'>
                                <Col ><Stack spacing='5-x'
                                  style={{ paddingTop: "1rem" }}><Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}>
                                  </Card>
                                  <Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}>
                                  </Card>
                                  <Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}>
                                  </Card>
                                </Stack>
                                </Col>
                                <Col alignSelf='center' style={{ paddingTop: "1rem", paddingLeft: "1rem" }}><Card style={{ width: "2rem", height: "2rem", backgroundColor: "#bdbdbd" }}>                        </Card> </Col>
                                <Col alignSelf='start'>
                                  <div >
                                    <img src={galleyArc} style={{ width: "3.5rem", height: "18rem", position: "absolute", paddingBottom: "5rem" }} />
                                  </div>
                                  {/* <Card style={{width:"2rem", height:"12rem", backgroundColor:"#bdbdbd"}}/> */}
                                </Col>
                              </Row>

                            </Container>
                          </Card>
                          }

                        </Col>
                      </Row>
                    </Container>
                    <br />
                      <br />
                      <br />

                    <Row alignItems='center' justify='start' style={{ paddingLeft: "7rem" }}>
                     
                  
                    
                      <Inline spacing='8-x'>

                    
                       
                      
                          
                            <div><ChipInputCellGalleyTop value={healthy==0 ? 0: 1} /></div>
                        
                       

                      </Inline>
                    </Row>
                    <br />

                  </CardContent>
                  <Container>

                  </Container>
                </Box>
              }
            </Col>

          </Row>

          <Row justify='start' >
            <Col xs={12} xxs={12} sm={12} md={12} lg={12} style={{ paddingTop: "1rem" }} >
              {(selectedFilterValue == 2 || selectedFilterValue == 1) &&
                <Box style={{ backgroundColor: "#1C2747", width: matches ? "57rem" : "57rem", height: matches ? "20rem" : "12rem" }}>
                  <CardContent style={{ color: "white" }}>
                    <Row justify="space-between" xxs={12} xs={12} sm={12} md={12} lg={12}>
                      <Col sm={9} lg={9} xl={9}>
                        <CardTitle>
                          {/* <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}> */}
                          <Inline spacing='2-x'>
                            <div >{"GALLEY 2"}</div>
                            <ChipInputCellTop value={1}></ChipInputCellTop>
                            {/* <div >{"60" +"%"}</div> */}
                            <ProgressInputCell value={60} />
                          </Inline>
                        </CardTitle>
                      </Col>
                      <Col sm={3} lg={3} xl={3} alignSelf="center">
                        <Inline spacing='2-x'>
                          <div>
                            {"6" + "/" + "7 Working"}
                          </div>
                          <ChipInputCellGalleyTop value={1}></ChipInputCellGalleyTop>
                        </Inline>
                      </Col>

                    </Row>

                    <Container>
                      {/* <Row><Col>{"GALLEYS CONNECTIVITY STATUS"}</Col><Col>{"GALLEY EQUIPMENT HEALTH"}</Col></Row> */}

                      <Row justify="center" alignItems="center">

                        {/* <Button onClick={() => handleChange(null, 1)} style={{ backgroundColor: "white", marginTop: "1rem" }}> View Meals Details </Button> */}
                      </Row>
                    </Container>
                    <CardListx />
                  </CardContent>

                </Box>
              }
            </Col>

          </Row>
          <Row justify='start' >
            <Col xs={12} xxs={12} sm={12} md={12} lg={12} style={{ paddingTop: "0.2rem" }} >
              {(selectedFilterValue == 1 || selectedFilterValue == 2) &&
                <Box style={{ backgroundColor: "#1C2747", width: matches ? "57rem" : "57rem", height: matches ? "25rem" : "20rem" }}>
                  <CardContent style={{ color: "white", width: matches ? "55rem" : "55rem", height: matches ? "26rem" : "26rem" }}>
                    <CardTitle style={{ fontSize: matches ? "1.5rem" : "1rem" }}>

                      <Row justify='space-between' style={{ paddingRight: "1rem" }}>

                        <div style={{ fontSize: "1.2rem" }}>  {"GALLEY 2"}{name ? name.name : ""}  <Error style={{ marginLeft: "0.2rem" }} /></div>



                        <Select style={{ width: "8rem" }}
                          options={wastageChartSelectionOptions}
                          value={selectedWasteChartsSelectionValue}
                          // defaultValue="Overview"
                          onChange={(event, value) => handleSetSelectedWasteChartsSelectionValue(event, value)}

                        ></Select>
                      </Row>
                    </CardTitle>


                    <Row justify='center' alignItems='center'>
                      <ReactEcharts
                        option={selectedWasteChartsSelectionValue == 'errors' ? topWasteCostOverview : topWasteCostOverview2} style={{
                          minWidth: matches ? 700 : 700,
                          height: matches ? 250 : 250,
                        }} />
                      {/* </div> */}
                    </Row>
                  </CardContent>
                  <Container>
                    <Row justify="center" alignItems="center">
                      {/* <Button onClick={() => handleChange(null, 1)} style={{ backgroundColor: "white", marginTop: "1rem" }}> View Meals Details </Button> */}
                    </Row>
                  </Container>
                </Box>
              }
            </Col>

          </Row>

        </Container>

      </TabPanel>
    </div>
  )
}
