import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
        light:"#27293D",
        main:  "#1C2747",
        dark: "#1E1E2E",
    },
     type: 'light',
  },

});

export default theme