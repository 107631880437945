import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTable, useSortBy } from 'react-table'
import {Accordion, Badge, Chip, Button, Collapsible, Checkbox, 
  Inline, Tabs, Tab, ColorModeProvider, RangeSlider, Rating, MenuList,MenuItem,Menu,MenuButton,
  Stack, Card, CardActions, CardContent, CardHeader, CardMedia, CardActionArea, CardTitle, IconButton, Container, Col, Content, 
  Row, Toast, Flex,Link,Input,DataTable, ProgressBar } 
  from "@airbus/components-react" 
  import { Visibility,Delete,Clear,Check,Chevronright, Chevronup,Chevrondown } from '@airbus/icons/react';
  
import { useTheme, LinearProgress } from '@material-ui/core';
  
export const ChipInputCellTable = ({ value }) => {
  const [tag, setTag] = React.useState(value);
  return value ==1 ? (
    <Chip size="medium" label={"Connected"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold",fontSize:"0.8rem"}}variant="success" >  </Chip>
  ) : (
    <div>
      <Chip size="medium" label={"Not Connected"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold",fontSize:"0.8rem"}}variant="error"></Chip>
    </div>
  );
};

export const ChipInputCellTop = ({ value }) => {
  const [tag, setTag] = React.useState(value);
  return value ==1 ? (
    <Chip size="medium" label={"Connected"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold", fontSize:"0.7rem"}}variant="success" >  </Chip>
  ) : (
    <div>
      <Chip size="medium" label={"Not Connected"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold", fontSize:"0.7rem"}}variant="error"></Chip>
    </div>
  );
};


export const ChipInputCell = ({ value }) => {
  const [tag, setTag] = React.useState(value);
  return value ==1 ? (
    <Chip size="small" label={"Connected"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold", fontSize:"0.5rem"}}variant="success" >  </Chip>
  ) : (
    <div>
      <Chip size="small" label={"Not Connected"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold", fontSize:"0.5rem"}}variant="error"></Chip>
    </div>
  );
};

  
  export const ProgressInputCell = ({ value }) => {
    const [tag, setTag] = React.useState(value);
    return  (<Row alignItems='center'>
      <Col sm={4}>
   <div> {value}{"%"}</div>
   </Col>
   <Col sm={8}>
    <ProgressBar value={value} style={{ width: "5rem", textColor: "white" }} ></ProgressBar>
    </Col>
    </Row>
   
    );
  };
  
  export const ChipInputCellGalley = ({ value }) => {
    const [tag, setTag] = React.useState(value);
    return value ==1 ? (
      <Chip size="small" label={"Healthy"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold", fontSize:"0.50rem"}}variant="success" >  </Chip>
    ) : (
      <div>
        <Chip size="small" label={"Possible Failure"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold", fontSize:"0.48rem"}}variant="error"></Chip>
      </div>
    );
  };

  export const ChipInputCellGalleyTable = ({ value,setOpenDialog }) => {

    if(value==1){
      return        <Chip onClick ={()=>setOpenDialog(value)} size="medium" label={"Healthy"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold",fontSize:"0.8rem" }}variant="success" >  </Chip>

    }

else if (value==2){
  return  <Chip size="medium" label={"Possible Failure"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold",fontSize:"0.7rem"}}variant="error"></Chip>

  }
  else {
    return "-"

}
  }

  export const ChipInputCellGalleyTop= ({ value }) => {
    const [tag, setTag] = React.useState(value);
    return value ==1 ? (
      <Chip size="medium" label={"Healthy"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold",fontSize:"0.7rem" }}variant="success" >  </Chip>
    ) : (
      <div>
        <Chip size="medium" label={"Possible Failure"}icon={<Clear style={{color:"red"}} />} style={{  fontWeight:"bold",fontSize:"0.7rem"}}variant="error"></Chip>
      </div>
    );
  };


const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;
   
    tr {

      :last-child {
        td {
   
        }
      }
    }
    th{border-bottom:0px;
      padding: 0.5rem;
    
    },
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;

      :last-child {
        border-bottom:0px
      }
    }
  }
`
const Table=({ columns, data })=> {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )
  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  return (

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps([{style: {
                  minWidth: 100,
                  width: 200,
                  textAlign:"left"
                
                }},column.getSortByToggleProps()])}
                
                >
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <Chevronup/>
                        : <Chevrondown/>
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps({ 
                        style: {
                          textAlign:'left',
                          minWidth:130,
                          width :130,
                      
                        }
                      })}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )}
          )}
        </tbody>
      </table>

  )
}
const ReactTable=({handleChange, handleSetSelectedFilterValue, setOpenDialog, galleyMemo}) =>{

  const data =React.useMemo(() => galleyMemo)
  const columns = React.useMemo(
    () =>  [
      {
        Header: 'GALLEYS CONNECTIVITY STATUS',
        columns: [{
      Header: "Galley Name",
      accessor: "galleyname",
      width: 100,
        },
   
    { Header: "Connectivity Status", 
    accessor: "connectivitychip", 
    width: 100, Cell: ChipInputCellTable, },

    // { Header: "Connectivity ", accessor: "connectivity", width: 100 , Cell: ({ value }) => (value ? value +"%" : "-"), },
    { Header: "Connectivity %", 
    accessor: "connectivitypercent", 
    width: 150,
     Cell: ProgressInputCell,
    }
        ],
      },
    {
      Header: 'GALLEY EQUIPMENT HEALTH',
      columns:[
    { Header: "Working", 
    accessor: "working", 
    width: 100,
    Cell: ({ value }) => (value!=0 ? value +"/"+ "7"+" " +"Working" : "-"), },
    
    { Header: "Galley Equipment", 
    accessor: "galleychip", 
    width: 180 ,
    Cell: ({value}) => <ChipInputCellGalleyTable value={value} setOpenDialog={setOpenDialog}/>
  },
    {
      Header: "View Details",
      accessor: "details",
      width: 200,
      disableSortBy: true,
      Cell: ({value}) => {
       
     
         // the value is 'this is a test'
        return (
          <ColorModeProvider mode="dark">
            <Button onClick={()=>{handleChange(null, 1); handleSetSelectedFilterValue(null,value)}} size={"small"} variant="secondary" icon={<Chevronright />}>View Details</Button>
            </ColorModeProvider>
        )
    },
  }
  ]
  }
  
  ],[])
  return (
    <Styles>
      <Table columns={columns} data={data} />
    </Styles>
  )
}
export default ReactTable