import React, { useEffect, useState } from 'react'
import 'echarts/lib/component/grid';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Backdrop, Grid, Typography, CircularProgress } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Select from "react-select";
import {  Inline, Tabs, Tab, ColorModeProvider, Card, Container, Col, Row, Loading } from "@airbus/components-react"
import axios from 'axios'
import { useTheme } from '@material-ui/core';
import {
  mealsServedByDate,
  reportData,
  gaugeX,
  gaugeY,

} from '../../components/Catering/CateringData'
import { makeStyles } from '@material-ui/core/styles';
// import mainLogo from'../../images/bg_fleet.svg';
import mainLogo from '../../images/cabin-bg.png';
import { GaugeChart, PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { cateringArray } from '../../components/Catering/CateringData';
import CateringReactTable from '../../components/Catering/CateringReactTable'
import MaxWidthDialog1 from '../../components/Catering/MaxWidthDialog1';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { TopFilters } from "../../components/Catering/TopFilters"
import { mockFlightFoodInfo, mockFlightPaxInfo, mockCateringPayload, fetchData } from '../../components/Catering/CateringData';
import { allSeats } from "../../components/Catering/CateringLOPA"
import CateringOverview from '../../components/Catering/CateringOverview';
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
  startOfDay,
  endOfDay
} from "date-fns";
import CateringReport from '../../components/Catering/CateringReport';

echarts.use([GaugeChart, CanvasRenderer]);
process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'black',
    opacity: 0.7
  },
}));
function processData(merged) {


  let matchingElement = {};
  merged.forEach(item => {
    const element = Object.values(item);
    // console.log(element);
    Object.keys(element).forEach(data => {
      const requiredElement = element[data];
      const isMatching = Object.keys(requiredElement).includes('0');
      // console.log(isMatching);
      if (isMatching) {
        matchingElement = requiredElement;
      }
    });
  });

  const seatNumber = matchingElement.seat_number;
  const copy = { ...matchingElement };
  delete matchingElement.seat_number;
  const keys = Object.keys(matchingElement);

  const lastObject = matchingElement[keys[keys.length - 1]]
  return [lastObject];



}



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

const getEndpoint = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/acic/foodscanner/realtimereport&created_at=${createdOn}`

const getEndpoint1 = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/acic/foodscanner/report/statistic&created_at=${createdOn}`

const getEndpoint2 = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/acic/foodscanner/report/daytrend&created_at=${createdOn}`

const getEndpoint3 = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/acic/foodscanner/report/hourtrend&created_at=${createdOn}`

const getEndpoint4 = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/acic/foodscanner/report/fooddistributionrecord&created_at=${createdOn}`

const getEndpoint5 = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/paxinfo&created_at=${createdOn}`
const getEndpoint6 = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/foodinfo&created_at=${createdOn}`

const customStyles = {
  option:() => ({
 
    height:'100%',
    color:'black',
    paddingLeft:'10px',
  }),
  
}


export function Catering({handleListItemClick}) { 
  const [tabValue, setTabValue] = React.useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const [open, setOpen] = useState(false);

  const [selectedFilterValue, setSelectedFilterValue] = useState("all");
  const [name, setName] = useState("Food")
  const [selectedWasteChartsSelectionValue, setSelectedWasteChartsSelectionValue] = useState("cost");
  const [selectedPaxInfo, setSelectedPaxInfo] = useState("all");
  const [openDialogx, setOpenDialogx] = React.useState(null)
  const [loading, setLoading] = useState(false);
  const [cateringData, setCateringData] = useState();
  const [cateringData1, setCateringData1] = useState();
  const [cateringData2, setCateringData2] = useState();
  const [cateringData3, setCateringData3] = useState();
  const [cateringData4, setCateringData4] = useState();
  const [top5Foods, setTop5Foods] = useState([])
  const [top5Foods100, setTop5Foods100] = useState([])
  const [top5Waste, setTop5Waste] = useState([])
  const [servedSDArray, setServedSDArray] = useState([])
  const [wasteSDArray, setWasteSDArray] = useState([])
  const [servedSHArray, setServedSHArray] = useState([])
  const [wasteSHArray, setWasteSHArray] = useState([])
  const [servedArray, setServedArray] = useState([])
  const [selectedChartsSelectionValue, setSelectedChartsSelectionValue] = useState("overview");
  const [paxOrderTable, setPaxOrderTable] = useState([])
  const [finalArraytoTable, setFinalArraytoTable] = useState([])
  const [dayHrValue, setDayHrValue] = useState("day");
const [haveConnect,setHaveConnect]= useState(false);
  const [merged, setMerged] = useState([])

  const [processed, setProcessed] = useState([])

  { handleListItemClick(null, 4) }

  const onSubmit = async (values = { dateRange: [new Date(), new Date()], tabValue }) => {
    const filterByFlightNoAndOriginAndDestination = (item) => {
      if (!item.payload) return false;
      
      const filterFlightNo = values.flightNo ? values.flightNo : null;
      const filterOrigin = values.origin ? values.origin : null;
      const filterDestination = values.destination ? values.destination : null;

      const { flightNo, ori, dest} = item.payload;
      return (
        (filterFlightNo ? flightNo === filterFlightNo : true) &&
        (filterOrigin ? ori === filterOrigin : true) &&
        (filterDestination ? dest === filterDestination : true)
      );
    }

    const filterByFlightNo= (item) => {
      if (!item.payload) return false;
      
      const filterFlightNo = values.flightNo ? values.flightNo : null;


      const { flightNo} = item.payload;
      return (
        (filterFlightNo ? flightNo === filterFlightNo : true) 
  
      );
    }

    setLoading(true)
    console.log(tabValue)
    if (tabValue == 0) {
      console.log('tab0 :>> ', tabValue);
      try {
        const { dateRange } = values;
        console.log('values :>> ', values);
        const [start, end] = dateRange;

        const [startYear, endYear] = [start, end].map((d) => d.getFullYear());
        const [startMonth, endMonth] = [start, end].map((d) => format(d, "MM"));
        const [startDay, endDay] = [start, end].map((d) => format(d, "dd"));
        const isSameMonth = startMonth === endMonth;
        const isSameDay = startDay === endDay;
        let createdOn = "";
        if (isSameMonth && isSameDay)
          createdOn = `${startYear}-${startMonth}-${startDay}`;
        else if (isSameMonth) createdOn = `${startYear}-${startMonth}`;
        else {
          const range =
            differenceInMonths(endOfMonth(end), startOfMonth(start)) + 1;
          createdOn = Array(range)
            .fill("")
            .map((_, index) => format(addMonths(start, index), "yyyy-MM"));
        }
        const apiData = [];
        const apiData1=[]
        const apiData2=[]
        const apiData3=[]
        const apiData4=[]
        const apiData5=[]
        const apiData6=[]
        const apiData7=[]

        if (Array.isArray(createdOn)) {
          for (const co of createdOn) {
            const { data: response } = await axios.get(getEndpoint(co));
            const { data: response1 } = await axios.get(getEndpoint1(co));
            console.log('{ data: response1 } :>> ', { data: response1 });
            console.log("Aa")
            apiData.push(...response || []);

          }
          // const realData = values.filter(
          //   (v) => v.createdOn >= start && v.createdOn <= end
          // );
          //setNestedObjectValues(realData)
        }
        else {
          const { data: response } = await axios.get(getEndpoint(createdOn));
          const { data: response1 } = await axios.get(getEndpoint1(createdOn));
          const { data: response2 } = await axios.get(getEndpoint2(createdOn));
          const { data: response3 } = await axios.get(getEndpoint3(createdOn));
          const { data: response4 } = await axios.get(getEndpoint1(createdOn));
          const { data: response5 } = await axios.get(getEndpoint4(createdOn));
          const { data: response6 } = await axios.get(getEndpoint5(createdOn));
          const { data: response7 } = await axios.get(getEndpoint6(createdOn));
          apiData.push(...response || []);
          apiData1.push(...response1 || []);
          apiData2.push(...response2 || []);
          apiData3.push(...response3 || []);
          apiData4.push(...response4 || []);
          apiData5.push(...response5 || []);
          apiData6.push(...response6 || []);
          apiData7.push(...response6 || []);
        }
        const filteredData = apiData.filter(d => {
          const date = new Date(d.created_at)
          return date >= startOfDay(start) && date <= endOfDay(end)
        })
        const cleanData = filteredData.map(d => {
          let payload;
          try {
            payload = JSON.parse(d.payload?.replace(/'/g, '"'))
          }
          catch (e) { }
          return { ...d, payload }
        }).filter(filterByFlightNo);
        
        console.log("cleandaty", cleanData)
        console.log('values :>> ', values);
        console.log('cleanData.length :>> ', cleanData.length);
        setCateringData(cleanData[cleanData.length - 1]);
        setHaveConnect(true)




        const filteredData1 = apiData1.filter(d => {
          const date = new Date(d.created_at)
          return date >= startOfDay(start) && date <= endOfDay(end)
        })
        const cleanData1 = filteredData1.map(d => {
          let payload;
          try {
            payload = JSON.parse(d.payload?.replace(/'/g, '"'))
          }
          catch (e) { }
          return { ...d, payload }
        }).filter(filterByFlightNoAndOriginAndDestination);
        /// to include route also if available
        console.log('values :>> ', values);
     
        console.log('cleanData.length :>> ', cleanData1.length);
        setCateringData1(cleanData1[cleanData1.length - 1]);
        const cateringDataResult1 = cleanData1[cleanData1.length - 1];
console.log('cateringDataResult1 :>> ', cateringDataResult1);
const servedXArray = cateringDataResult1 ? cateringDataResult1.payload.report.foodServedQuantities : []

const servedPArray = cateringDataResult1 ? cateringDataResult1.payload.report.foodServedPercentage : []
console.log(servedPArray)

const top5FoodsSrc = servedPArray
 .sort((a, b) => b.demandDegree - a.demandDegree) // Sort by demandDegree in descending order
 .filter((food, index) => index < 5); // Filter the first 5 items

 const top5FoodsSrcX = servedXArray
 .sort((a, b) => b.servedQuantity - a.servedQuantity) // Sort by demandDegree in descending order
 .filter((food, index) => index < 5); // Filter the first 5 items

 setTop5Foods(top5FoodsSrcX)

const top5foods100 = top5FoodsSrc.map(obj => ({
 ...obj,
 demandDegree: obj.demandDegree * 100
}));

console.log('top5foods100 :>> ', top5foods100);
setTop5Foods100(top5foods100)

const wasteArray = cateringDataResult1 ? cateringDataResult1.payload.report.wasteStatistics : []

console.log(wasteArray)
setTop5Waste(wasteArray)
/////////

const filteredData2 = apiData2.filter(d => {
  const date = new Date(d.created_at)
  return date >= startOfDay(start) && date <= endOfDay(end)
})
const cleanData2 = filteredData2.map(d => {
  let payload;
  try {
    payload = JSON.parse(d.payload?.replace(/'/g, '"'))
  }
  catch (e) { }
  return { ...d, payload }
}).filter(filterByFlightNoAndOriginAndDestination);
/// to include route also if available
console.log('values :>> ', values);
console.log('cleanData :>> ', cleanData2);



const accumulated_result = cleanData2.reduce((acc, curr) => {
  const flightDate = curr['payload']['flightDate'];
  const flightNo = curr['payload']['flightNo'];
  const dest = curr['payload']['dest'];
  const ori = curr['payload']['ori'];

  const report = curr['payload']['report'];

  const key = `${flightDate}-${flightNo}-${ori}-${dest}`;
  console.log('report :>> ', report);
  if (report.servedTrends.length != 1) {
    return acc
  }

  if (acc[key]) {

    acc[key].servedPercentage += report.servedTrends[0].servedPercentage;
    acc[key].servedQuantity += report.servedTrends[0].servedQuantity;
    acc[key].time = report.servedTrends[0].time;


  }
  else {
    acc[key] = {
   
        
          servedPercentage: report.servedTrends[0].servedPercentage,
          servedQuantity: report.servedTrends[0].servedQuantity,
          time: report.servedTrends[0].time
        
      
 
    }
  }
  console.log("acc", acc)
  return acc;
}, {});

const result = Object.values(accumulated_result);
console.log(result);

const accumulated_result1 = cleanData2.reduce((acc, curr) => {
  const flightDate = curr['payload']['flightDate'];
  const flightNo = curr['payload']['flightNo'];
  const dest = curr['payload']['dest'];
  const ori = curr['payload']['ori'];

  const report = curr['payload']['report'];

  const key = `${flightDate}-${flightNo}-${ori}-${dest}`;
  console.log('report :>> ', report);

  console.log('report.wasteTrends :>> ', report.wasteTrends);
  if (report.wasteTrends===undefined || report.wasteTrends.length < 1) {
    return acc
  }
 console.log('report.wasteTrends :>> ', report.wasteTrends);
  if (acc[key]) {

  

    acc[key].weight = report.wasteTrends.reduce((acc, curr) => acc + curr.weight, 0)
  }
  else {
    acc[key] = {
      
    
        
          weight: report.wasteTrends.reduce((acc, curr) => acc + curr.weight, 0),
          time: report.wasteTrends[0].time
        

     
    }
  }
  console.log("acc", acc)
  return acc;
}, {});


const result1 = Object.values(accumulated_result1);
console.log(result1);

//////////////////
////hour
const filteredData3 = apiData3.filter(d => {
  const date = new Date(d.created_at)
  return date >= startOfDay(start) && date <= endOfDay(end)
})
const cleanData3 = filteredData3.map(d => {
  let payload;
  try {
    payload = JSON.parse(d.payload?.replace(/'/g, '"'))
  }
  catch (e) { }
  return { ...d, payload }
}).filter(filterByFlightNoAndOriginAndDestination);
/// to include route also if available
console.log('values :>> ', values);
console.log('cleanData :>> ', cleanData3);



const accumulated_result3 = cleanData3.reduce((acc, curr) => {
  const flightDate = curr['payload']['flightDate'];
  const flightNo = curr['payload']['flightNo'];
  const dest = curr['payload']['dest'];
  const ori = curr['payload']['ori'];

  const report = curr['payload']['report'];

  const key = `${flightDate}-${flightNo}-${ori}-${dest}`;
  console.log('report :>> ', report);
  if (report.servedTrends.length != 1) {
    return acc
  }

  if (acc[key]) {

    acc[key].servedPercentage += report.servedTrends[0].servedPercentage;
    acc[key].servedQuantity += report.servedTrends[0].servedQuantity;
    acc[key].time = report.servedTrends[0].time;


  }
  else {
    acc[key] = {
   
        
          servedPercentage: report.servedTrends[0].servedPercentage,
          servedQuantity: report.servedTrends[0].servedQuantity,
          time: report.servedTrends[0].time
        
      
 
    }
  }
  console.log("acc", acc)
  return acc;
}, {});

const result3 = Object.values(accumulated_result3);
console.log(result);

const accumulated_result3x = cleanData3.reduce((acc, curr) => {
  const flightDate = curr['payload']['flightDate'];
  const flightNo = curr['payload']['flightNo'];
  const dest = curr['payload']['dest'];
  const ori = curr['payload']['ori'];

  const report = curr['payload']['report'];

  const key = `${flightDate}-${flightNo}-${ori}-${dest}`;
  console.log('report :>> ', report);

  console.log('report.wasteTrends :>> ', report.wasteTrends);
  if (report.wasteTrends===undefined || report.wasteTrends.length < 1) {
    return acc
  }
 console.log('report.wasteTrends :>> ', report.wasteTrends);
  if (acc[key]) {

  

    acc[key].weight = report.wasteTrends.reduce((acc, curr) => acc + curr.weight, 0)
  }
  else {
    acc[key] = {
      
    
        
          weight: report.wasteTrends.reduce((acc, curr) => acc + curr.weight, 0),
          time: report.wasteTrends[0].time
        

     
    }
  }
  console.log("acc", acc)
  return acc;
}, {});


const result13 = Object.values(accumulated_result3x);


///////////


  setServedSDArray(result)


  setWasteSDArray(result1)
 

    setServedSHArray(result3)
    setWasteSHArray(result13)
 ///////////////


const cleanData4 = apiData4.map(d => {
  let payload;
  try {
    payload = JSON.parse(d.payload?.replace(/'/g, '"'))
    console.log('payload :>> ', payload);
  }
  catch (e) { }
  return { ...d, payload }
}).filter(filterByFlightNoAndOriginAndDestination);
/// to include route also if available
console.log('values :>> ', values);
console.log('cleanData.length :>> ', cleanData4.length);
setCateringData3(cleanData4[cleanData4.length - 1]);
console.log('catering :>> ', cateringData4);
const cateringDataResult4 = cleanData4[cleanData4.length - 1]

console.log('object :>> ', cateringData4);
const servedXxArray = cateringDataResult4 ? cateringDataResult4.payload.report.foodServedQuantities : []
console.log(servedXArray)
const servedPxArray = cateringDataResult4 ? cateringDataResult4.payload.report.foodServedPercentage : []
console.log(servedPArray)

const top5FoodsSrcc = servedPxArray
 .sort((a, b) => b.demandDegree - a.demandDegree) // Sort by demandDegree in descending order
 .filter((food, index) => index < 5); // Filter the first 5 items

 const top5FoodsSrcXX = servedXxArray
 .sort((a, b) => b.servedQuantity - a.servedQuantity) // Sort by demandDegree in descending order
 .filter((food, index) => index < 5); // Filter the first 5 items

 let foodCounter = 1;
 const updatedItems1 = top5FoodsSrcXX.map(item => {
   if(/\d/.test(item.foodTypeName)) {
     const newName = `Food ${foodCounter}`;
     foodCounter++;
     return {...item, foodTypeName: newName };
   } else {
     return item;
   }
 });

 let foodCounter2 = 1;
 const updatedItems2 = top5FoodsSrcc.map(item => {
   if(/\d/.test(item.foodTypeName)) {
     const newName = `Food ${foodCounter2}`;
     foodCounter2++;
     return {...item, foodTypeName: newName };
   } else {
     return item;
   }
 });

 
console.log('updatedItems2 :>> ', updatedItems2);

 setTop5Foods(updatedItems1)

const top5foods1002 = top5FoodsSrcc.map(obj => ({
 ...obj,
 demandDegree: obj.demandDegree * 100
}));

console.log('top5foods100 :>> ', top5foods1002);
setTop5Foods100(updatedItems2)

const wasteArray2 = cateringDataResult4 ? cateringDataResult4.payload.report.wasteStatistics : []

console.log(wasteArray2)
const sortedWasteArray = wasteArray2.sort((a, b) => a.weight - b.weight)
console.log('sortedWasteArray :>> ', sortedWasteArray);
setTop5Waste(sortedWasteArray)

/////////////////////////tab2
const cleanData5_ = apiData5.map(d => {
  let payload;
  try {
    // payload = JSON.parse(d.payload?.replace(/'/g, '"'))
    const payload_ = d.payload?.replace(/'/g, '"').replace(/None/g, 'null')

    payload = JSON.parse(payload_)

  }
  catch (e) {
    console.log("error = ", e)
  }
  return { ...d, payload }
})


const cleanData5 = cleanData5_.filter(filterByFlightNoAndOriginAndDestination);


/// to include route also if available

const cateringDataResult5 = cleanData5[cleanData5.length - 1]
console.log('cateringDataResult5 = ', cateringDataResult5)
setCateringData4(cateringDataResult5);

// merge DataResults4 and seatStatic
const merged = seatsStatic.map((subArr) => {
  return subArr.map((obj1) => {
    const obj2 = cateringDataResult5.payload.report?.filter((obj2) => obj1.seat_number === obj2.seatNumber);
    console.log('obj2 :>> ', obj2)
    //   const lastKey = Object.keys(obj2).pop();
    //   const v = obj[lastKey];
    return obj2 ? { ...obj1, food: [...obj2] } : obj1;
  });
});
console.log('merged :>> ', merged);
const processed = processData(merged);
console.log('processed', processed);
setMerged(merged)
setProcessed(processed)
        /////////////// tab 3

        
        const cleanDataxxx = apiData6.map(d => {
          let payload;
          // console.log('payload1 :>> ', payload);
          try {
            //  console.log({stringPayload: d.payload})
            //payload =d.payload?.replace(/'/g, '"').replace(/\[|\]/g,'"')
            payload = d.payload.replaceAll("'", "\"");
            payload = payload.replaceAll("False", "false");
            payload = payload.replaceAll("True", "true");

            //  console.log({newPayload: payload})
            console.log({ parsedNewPayload: JSON.parse(payload) })
            payload = JSON.parse(payload);


            const passengers = payload.paxInfo.passengers;
            const preOrders = payload.paxInfo.preOrder;

            console.log({ passengers, preOrders });

            const combined = passengers.map(passenger => {
              const holdSeatId = passenger.holdSeatId;
              const matchingPreOrder = preOrders.find(preOrder => preOrder.seatId === holdSeatId);

              if (matchingPreOrder) {
                return { ...passenger, ...matchingPreOrder };
              }
              return passenger;
            })

            payload.paxInfo.passengers = combined;

            console.log('combined :>> ', combined);
            console.log('payload :>> ', payload);
          }
          catch (e) { }
          return { ...d, payload }

        }).filter(filterByFlightNoAndOriginAndDestination);


        const cleanDataxxx2 = apiData7.map(d => {
          let payload;
          try {
            payload = JSON.parse(d.payload?.replace(/'/g, '"'))
            // console.log('payload2 :>> ', payload);
          }
          catch (e) { }
          return { ...d, payload }
        }).filter(filterByFlightNoAndOriginAndDestination);
        console.log('cleanDataxxx :>> ', cleanDataxxx[cleanDataxxx.length - 1]);
        console.log('cleanDataxxx2 :>> ', cleanDataxxx2[cleanDataxxx2.length - 1]);


        const cleanDataWithFoodItems = cleanDataxxx.map((cleanData) => {
          const newPassengers = cleanData.payload.paxInfo.passengers.map((passenger) => {
            const foodTypeId = passenger.foodTypeId;

            const matching = cleanDataxxx2
              .map(d => {
                return { created_at: d.created_at, foodInfo: d.payload.foodInfo }
              })
            // .find(f => f.find(foodI =>  foodI.id===foodTypeId))

            var matching1 = []

            for (const m of matching) {
              const fooItem = m.foodInfo.find(foodI => foodI.id === foodTypeId);
              if (fooItem) matching1.push({ fooItem, created_at: m.created_at });
            }


            console.log('matching1 :>> ', matching1);

            if (matching1?.length > 0) {

              // sort matching by created_at
              matching1.sort((a, b) => {
                const aDate = new Date(a.created_at);
                const bDate = new Date(b.created_at);
                return aDate - bDate;
              });
              const matchFooItem = matching1[matching1.length - 1];

              return { ...passenger, ...matchFooItem.fooItem }

            }

            return passenger;

          });
          cleanData.payload.paxInfo.passengers = newPassengers;
          return cleanData;
        });
        console.log('cleanDataWithFoodItems :>> ', cleanDataWithFoodItems);

        const finalArraytoTable1 = cleanDataWithFoodItems ? cleanDataWithFoodItems[cleanDataWithFoodItems.length - 1].payload.paxInfo.passengers : []
        setFinalArraytoTable(finalArraytoTable1)
        console.log('finalArraytoTable1 :>> ', cleanDataWithFoodItems[cleanDataWithFoodItems.length - 1]);

      }


      finally {

        console.log('catering :>> ', cateringData);
        setLoading(false)
      }
    }

    else if (tabValue == 1 && selectedChartsSelectionValue == "overview") {
      console.log('tab11 :>> ', tabValue);
      setLoading(true)
      try {
        const { dateRange } = values;
        const [start, end] = dateRange;
        const [startYear, endYear] = [start, end].map((d) => d.getFullYear());
        const [startMonth, endMonth] = [start, end].map((d) => format(d, "MM"));
        const [startDay, endDay] = [start, end].map((d) => format(d, "dd"));
        const isSameMonth = startMonth === endMonth;
        const isSameDay = startDay === endDay;
        let createdOn = "";
        if (isSameMonth && isSameDay)
          createdOn = `${startYear}-${startMonth}-${startDay}`;
        else if (isSameMonth) createdOn = `${startYear}-${startMonth}`;
        else {
          const range =
            differenceInMonths(endOfMonth(end), startOfMonth(start)) + 1;
          createdOn = Array(range)
            .fill("")
            .map((_, index) => format(addMonths(start, index), "yyyy-MM"));
        }
        const apiData = [];

        if (Array.isArray(createdOn)) {
          for (const co of createdOn) {
            const { data: response } = await axios.get(getEndpoint1(co));

            apiData.push(...response || []);

          }
          // const realData = values.filter(
          //   (v) => v.createdOn >= start && v.createdOn <= end
          // );
          //setNestedObjectValues(realData)
        }
        else {
          const { data: response } = await axios.get(getEndpoint1(createdOn));

          apiData.push(...response || []);
        }
        const filteredData = apiData.filter(d => {
          const date = new Date(d.created_at)
          return date >= startOfDay(start) && date <= endOfDay(end)
        })
        const cleanData1 = filteredData.map(d => {
          let payload;
          try {
            payload = JSON.parse(d.payload?.replace(/'/g, '"'))
          }
          catch (e) { }
          return { ...d, payload }
        }).filter(filterByFlightNoAndOriginAndDestination);
        /// to include route also if available
        console.log('values :>> ', values);
     
        console.log('cleanData.length :>> ', cleanData1.length);
        setCateringData1(cleanData1[cleanData1.length - 1]);
        const cateringDataResult1 = cleanData1[cleanData1.length - 1];
console.log('cateringDataResult1 :>> ', cateringDataResult1);
const servedXArray = cateringDataResult1 ? cateringDataResult1.payload.report.foodServedQuantities : []

const servedPArray = cateringDataResult1 ? cateringDataResult1.payload.report.foodServedPercentage : []
console.log(servedPArray)

const top5FoodsSrc = servedPArray
 .sort((a, b) => b.demandDegree - a.demandDegree) // Sort by demandDegree in descending order
 .filter((food, index) => index < 5); // Filter the first 5 items

 const top5FoodsSrcX = servedXArray
 .sort((a, b) => b.servedQuantity - a.servedQuantity) // Sort by demandDegree in descending order
 .filter((food, index) => index < 5); // Filter the first 5 items

 setTop5Foods(top5FoodsSrcX)

const top5foods100 = top5FoodsSrc.map(obj => ({
 ...obj,
 demandDegree: obj.demandDegree * 100
}));

console.log('top5foods100 :>> ', top5foods100);
setTop5Foods100(top5foods100)

const wasteArray = cateringDataResult1 ? cateringDataResult1.payload.report.wasteStatistics : []

console.log(wasteArray)
setTop5Waste(wasteArray)


   ///////////   


      }


      finally {


        setLoading(false)
      }

    }


    else if (tabValue == 1 && selectedChartsSelectionValue === "served") {
      console.log('tab12 :>> ', tabValue);
      try {
        setLoading(true)
        const { dateRange } = values;
        const [start, end] = dateRange;
        const [startYear, endYear] = [start, end].map((d) => d.getFullYear());
        const [startMonth, endMonth] = [start, end].map((d) => format(d, "MM"));
        const [startDay, endDay] = [start, end].map((d) => format(d, "dd"));
        const isSameMonth = startMonth === endMonth;
        const isSameDay = startDay === endDay;
        let createdOn = "";
        if (isSameMonth && isSameDay)
          createdOn = `${startYear}-${startMonth}-${startDay}`;
        else if (isSameMonth) createdOn = `${startYear}-${startMonth}`;
        else {
          const range =
            differenceInMonths(endOfMonth(end), startOfMonth(start)) + 1;
          createdOn = Array(range)
            .fill("")
            .map((_, index) => format(addMonths(start, index), "yyyy-MM"));
        }
        const apiData = [];

        if (Array.isArray(createdOn)) {
          for (const co of createdOn) {
            const { data: response } = dayHrValue === "day" ? await axios.get(getEndpoint2(co)) : await axios.get(getEndpoint3(co));

            apiData.push(...response || []);

          }
     
        }
        else {
          const { data: response } = dayHrValue === "day" ? await axios.get(getEndpoint2(createdOn)) : await axios.get(getEndpoint3(createdOn));

          apiData.push(...response || []);
        }
        const filteredData = apiData.filter(d => {
          const date = new Date(d.created_at)
          return date >= startOfDay(start) && date <= endOfDay(end)
        })
        const cleanData = filteredData.map(d => {
          let payload;
          try {
            payload = JSON.parse(d.payload?.replace(/'/g, '"'))
          }
          catch (e) { }
          return { ...d, payload }
        }).filter(filterByFlightNoAndOriginAndDestination);
        /// to include route also if available
        console.log('values :>> ', values);
        console.log('cleanData :>> ', cleanData);


        const accumulated_result = cleanData.reduce((acc, curr) => {
          const flightDate = curr['payload']['flightDate'];
          const flightNo = curr['payload']['flightNo'];
          const dest = curr['payload']['dest'];
          const ori = curr['payload']['ori'];

          const report = curr['payload']['report'];

          const key = `${flightDate}-${flightNo}-${ori}-${dest}`;
          console.log('report :>> ', report);
          if (report.servedTrends.length != 1) {
            return acc
          }
       
          if (acc[key]) {

            acc[key].servedPercentage += report.servedTrends[0].servedPercentage;
            acc[key].servedQuantity += report.servedTrends[0].servedQuantity;
            acc[key].time = report.servedTrends[0].time;


          }
          else {
            acc[key] = {
           
                
                  servedPercentage: report.servedTrends[0].servedPercentage,
                  servedQuantity: report.servedTrends[0].servedQuantity,
                  time: report.servedTrends[0].time
                
              
         
            }
          }
          console.log("acc", acc)
          return acc;
        }, {});

        const result = Object.values(accumulated_result);
        console.log(result);
 
        const accumulated_result1 = cleanData.reduce((acc, curr) => {
          const flightDate = curr['payload']['flightDate'];
          const flightNo = curr['payload']['flightNo'];
          const dest = curr['payload']['dest'];
          const ori = curr['payload']['ori'];

          const report = curr['payload']['report'];

          const key = `${flightDate}-${flightNo}-${ori}-${dest}`;
          console.log('report :>> ', report);
    
          console.log('report.wasteTrends :>> ', report.wasteTrends);
          if (report.wasteTrends===undefined || report.wasteTrends.length < 1) {
            return acc
          }
         console.log('report.wasteTrends :>> ', report.wasteTrends);
          if (acc[key]) {

          

            acc[key].weight = report.wasteTrends.reduce((acc, curr) => acc + curr.weight, 0)
          }
          else {
            acc[key] = {
              
            
                
                  weight: report.wasteTrends.reduce((acc, curr) => acc + curr.weight, 0),
                  time: report.wasteTrends[0].time
                

             
            }
          }
          console.log("acc", acc)
          return acc;
        }, {});

        
        const result1 = Object.values(accumulated_result1);
        console.log(result1);

    

        if (dayHrValue === "day") {
        setServedSDArray(result)

     
        setWasteSDArray(result1)
        }
        else {
          setServedSHArray(result)
          setWasteSHArray(result1)
        }
      }

      finally {


        setLoading(false)
      }

    }

    else if (tabValue == 1 && selectedChartsSelectionValue == "statistics") {
      console.log('tab13 :>> ', tabValue);
      try {
        const { dateRange } = values;
        const [start, end] = dateRange;
        const [startYear, endYear] = [start, end].map((d) => d.getFullYear());
        const [startMonth, endMonth] = [start, end].map((d) => format(d, "MM"));
        const [startDay, endDay] = [start, end].map((d) => format(d, "dd"));
        const isSameMonth = startMonth === endMonth;
        const isSameDay = startDay === endDay;
        let createdOn = "";
        if (isSameMonth && isSameDay)
          createdOn = `${startYear}-${startMonth}-${startDay}`;
        else if (isSameMonth) createdOn = `${startYear}-${startMonth}`;
        else {
          const range =
            differenceInMonths(endOfMonth(end), startOfMonth(start)) + 1;
          createdOn = Array(range)
            .fill("")
            .map((_, index) => format(addMonths(start, index), "yyyy-MM"));
        }
        const apiData = [];

        if (Array.isArray(createdOn)) {
          for (const co of createdOn) {
            const { data: response } = await axios.get(getEndpoint1(co));

            apiData.push(...response || []);

          }
          // const realData = values.filter(
          //   (v) => v.createdOn >= start && v.createdOn <= end
          // );
          //setNestedObjectValues(realData)
        }
        else {
          const { data: response } = await axios.get(getEndpoint1(createdOn));

          apiData.push(...response || []);
        }
        const cleanData3 = apiData.map(d => {
          let payload;
          try {
            payload = JSON.parse(d.payload?.replace(/'/g, '"'))
            console.log('payload :>> ', payload);
          }
          catch (e) { }
          return { ...d, payload }
        }).filter(filterByFlightNoAndOriginAndDestination);
        /// to include route also if available
        console.log('values :>> ', values);
        console.log('cleanData.length :>> ', cleanData3.length);
        setCateringData3(cleanData3[cleanData3.length - 1]);
        console.log('catering :>> ', cateringData3);
        const cateringDataResult3 = cleanData3[cleanData3.length - 1]

        console.log('object :>> ', cateringData3);
        const servedXArray = cateringDataResult3 ? cateringDataResult3.payload.report.foodServedQuantities : []
        console.log(servedXArray)
        const servedPArray = cateringDataResult3 ? cateringDataResult3.payload.report.foodServedPercentage : []
        console.log(servedPArray)
       
       const top5FoodsSrc = servedPArray
         .sort((a, b) => b.demandDegree - a.demandDegree) // Sort by demandDegree in descending order
         .filter((food, index) => index < 5); // Filter the first 5 items

         const top5FoodsSrcX = servedXArray
         .sort((a, b) => b.servedQuantity - a.servedQuantity) // Sort by demandDegree in descending order
         .filter((food, index) => index < 5); // Filter the first 5 items

         let foodCounter = 1;
         const updatedItems1 = top5FoodsSrcX.map(item => {
           if(/\d/.test(item.foodTypeName)) {
             const newName = `Food ${foodCounter}`;
             foodCounter++;
             return {...item, foodTypeName: newName };
           } else {
             return item;
           }
         });

         let foodCounter2 = 1;
         const updatedItems2 = top5FoodsSrc.map(item => {
           if(/\d/.test(item.foodTypeName)) {
             const newName = `Food ${foodCounter2}`;
             foodCounter2++;
             return {...item, foodTypeName: newName };
           } else {
             return item;
           }
         });

         
console.log('updatedItems2 :>> ', updatedItems2);

         setTop5Foods(updatedItems1)
     
      const top5foods100 = top5FoodsSrc.map(obj => ({
         ...obj,
         demandDegree: obj.demandDegree * 100
       }));

       console.log('top5foods100 :>> ', top5foods100);
       setTop5Foods100(updatedItems2)

       const wasteArray = cateringDataResult3 ? cateringDataResult3.payload.report.wasteStatistics : []

       console.log(wasteArray)
       const sortedWasteArray = wasteArray.sort((a, b) => a.weight - b.weight)
       console.log('sortedWasteArray :>> ', sortedWasteArray);
       setTop5Waste(sortedWasteArray)

      }



      finally {


        setLoading(false)
      }

    }


    else if (tabValue == 2) {

      console.log('tab13 :>> ', tabValue);
      try {
        const { dateRange } = values;
        const [start, end] = dateRange;
        const [startYear, endYear] = [start, end].map((d) => d.getFullYear());
        const [startMonth, endMonth] = [start, end].map((d) => format(d, "MM"));
        const [startDay, endDay] = [start, end].map((d) => format(d, "dd"));
        const isSameMonth = startMonth === endMonth;
        const isSameDay = startDay === endDay;
        let createdOn = "";
        if (isSameMonth && isSameDay)
          createdOn = `${startYear}-${startMonth}-${startDay}`;
        else if (isSameMonth) createdOn = `${startYear}-${startMonth}`;
        else {
          const range =
            differenceInMonths(endOfMonth(end), startOfMonth(start)) + 1;
          createdOn = Array(range)
            .fill("")
            .map((_, index) => format(addMonths(start, index), "yyyy-MM"));
        }
        const apiData = [];

        if (Array.isArray(createdOn)) {
          for (const co of createdOn) {
            const { data: response } = await axios.get(getEndpoint4(co));

            apiData.push(...response || []);

          }
          // const realData = values.filter(
          //   (v) => v.createdOn >= start && v.createdOn <= end
          // );
          //setNestedObjectValues(realData)
        }
        else {
          const { data: response } = await axios.get(getEndpoint4(createdOn));
          console.log(response)
          console.log(...response)
          apiData.push(...response || []);
          console.log(apiData)
        }




        const cleanData4_ = apiData.map(d => {
          let payload;
          try {
            // payload = JSON.parse(d.payload?.replace(/'/g, '"'))
            const payload_ = d.payload?.replace(/'/g, '"').replace(/None/g, 'null')

            payload = JSON.parse(payload_)

          }
          catch (e) {
            console.log("error = ", e)
          }
          return { ...d, payload }
        })


        const cleanData4 = cleanData4_.filter(filterByFlightNoAndOriginAndDestination);


        /// to include route also if available

        const cateringDataResult4 = cleanData4[cleanData4.length - 1]
        console.log('cateringDataResult4 = ', cateringDataResult4)
        setCateringData4(cateringDataResult4);

        // merge DataResults4 and seatStatic
        const merged = seatsStatic.map((subArr) => {
          return subArr.map((obj1) => {
            const obj2 = cateringDataResult4.payload.report?.filter((obj2) => obj1.seat_number === obj2.seatNumber);
            console.log('obj2 :>> ', obj2)
            //   const lastKey = Object.keys(obj2).pop();
            //   const v = obj[lastKey];
            return obj2 ? { ...obj1, food: [...obj2] } : obj1;
          });
        });
        console.log('merged :>> ', merged);
        const processed = processData(merged);
        console.log('processed', processed);
        setMerged(merged)
        setProcessed(processed)
      }


      finally {


        setLoading(false)
      }
    }
    else if (tabValue == 3) {

      const getEndpoint5 = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/paxinfo&created_at=${createdOn}`
      const getEndpoint6 = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/foodinfo&created_at=${createdOn}`

      console.log('tab3 :>> ', tabValue);
      try {
        const { dateRange } = values;
        const [start, end] = dateRange;
        const [startYear, endYear] = [start, end].map((d) => d.getFullYear());
        const [startMonth, endMonth] = [start, end].map((d) => format(d, "MM"));
        const [startDay, endDay] = [start, end].map((d) => format(d, "dd"));
        const isSameMonth = startMonth === endMonth;
        const isSameDay = startDay === endDay;
        let createdOn = "";
        if (isSameMonth && isSameDay)
          createdOn = `${startYear}-${startMonth}-${startDay}`;
        else if (isSameMonth) createdOn = `${startYear}-${startMonth}`;
        else {
          const range =
            differenceInMonths(endOfMonth(end), startOfMonth(start)) + 1;
          createdOn = Array(range)
            .fill("")
            .map((_, index) => format(addMonths(start, index), "yyyy-MM"));
        }
        const apiData1 = [];
        const apiData2 = [];
        if (Array.isArray(createdOn)) {
          for (const co of createdOn) {
            await axios.all([axios.get(getEndpoint5(co)), axios.get(getEndpoint6(co))])
              .then(axios.spread((response1, response2) => {

                apiData1.push(...response1.data || [])
                apiData2.push(...response1.data || [])


                console.log('apiData :>> ', apiData1);
                console.log('apiData :>> ', apiData2);


              }))
              .catch(error => {
                console.error('Error:', error);
              });
          }
          // const realData = values.filter(
          //   (v) => v.createdOn >= start && v.createdOn <= end
          // );
          //setNestedObjectValues(realData)
        }
        else {
          await axios.all([axios.get(getEndpoint5(createdOn)), axios.get(getEndpoint6(createdOn))])
            .then(axios.spread((response1, response2) => {
              console.log('response1 :>> ', response1.data);
              console.log('response2 :>> ', response2.data);
              apiData1.push(...response1.data || [])
              apiData2.push(...response2.data || [])


              console.log('apiData1 :>> ', apiData1);
              console.log('apiData2 :>> ', apiData2);
            }


            )
            )
            .catch(error => {
              console.error('Error:', error);
            });

        }
        // const filteredData = apiData1.filter(d => {
        //   const date = new Date(d.created_at)
        //   return date >= startOfDay(start) && date <= endOfDay(end)
        // })

        // const filteredData2 = apiData2.filter(d => {
        //   const date = new Date(d.created_at)
        //   return date >= startOfDay(start) && date <= endOfDay(end)
        // })


        const cleanDataxxx = apiData1.map(d => {
          let payload;
          // console.log('payload1 :>> ', payload);
          try {
            //  console.log({stringPayload: d.payload})
            //payload =d.payload?.replace(/'/g, '"').replace(/\[|\]/g,'"')
            payload = d.payload.replaceAll("'", "\"");
            payload = payload.replaceAll("False", "false");
            payload = payload.replaceAll("True", "true");

            //  console.log({newPayload: payload})
            console.log({ parsedNewPayload: JSON.parse(payload) })
            payload = JSON.parse(payload);


            const passengers = payload.paxInfo.passengers;
            const preOrders = payload.paxInfo.preOrder;

            console.log({ passengers, preOrders });

            const combined = passengers.map(passenger => {
              const holdSeatId = passenger.holdSeatId;
              const matchingPreOrder = preOrders.find(preOrder => preOrder.seatId === holdSeatId);

              if (matchingPreOrder) {
                return { ...passenger, ...matchingPreOrder };
              }
              return passenger;
            })

            payload.paxInfo.passengers = combined;

            console.log('combined :>> ', combined);
            console.log('payload :>> ', payload);
          }
          catch (e) { }
          return { ...d, payload }

        }).filter(filterByFlightNoAndOriginAndDestination);


        const cleanDataxxx2 = apiData2.map(d => {
          let payload;
          try {
            payload = JSON.parse(d.payload?.replace(/'/g, '"'))
            // console.log('payload2 :>> ', payload);
          }
          catch (e) { }
          return { ...d, payload }
        }).filter(filterByFlightNoAndOriginAndDestination);
        console.log('cleanDataxxx :>> ', cleanDataxxx[cleanDataxxx.length - 1]);
        console.log('cleanDataxxx2 :>> ', cleanDataxxx2[cleanDataxxx2.length - 1]);


        const cleanDataWithFoodItems = cleanDataxxx.map((cleanData) => {
          const newPassengers = cleanData.payload.paxInfo.passengers.map((passenger) => {
            const foodTypeId = passenger.foodTypeId;

            const matching = cleanDataxxx2
              .map(d => {
                return { created_at: d.created_at, foodInfo: d.payload.foodInfo }
              })
            // .find(f => f.find(foodI =>  foodI.id===foodTypeId))

            var matching1 = []

            for (const m of matching) {
              const fooItem = m.foodInfo.find(foodI => foodI.id === foodTypeId);
              if (fooItem) matching1.push({ fooItem, created_at: m.created_at });
            }


            console.log('matching1 :>> ', matching1);

            if (matching1?.length > 0) {

              // sort matching by created_at
              matching1.sort((a, b) => {
                const aDate = new Date(a.created_at);
                const bDate = new Date(b.created_at);
                return aDate - bDate;
              });
              const matchFooItem = matching1[matching1.length - 1];

              return { ...passenger, ...matchFooItem.fooItem }

            }

            return passenger;

          });
          cleanData.payload.paxInfo.passengers = newPassengers;
          return cleanData;
        });
        console.log('cleanDataWithFoodItems :>> ', cleanDataWithFoodItems);

        const finalArraytoTable1 = cleanDataWithFoodItems ? cleanDataWithFoodItems[cleanDataWithFoodItems.length - 1].payload.paxInfo.passengers : []
        setFinalArraytoTable(finalArraytoTable1)
        console.log('finalArraytoTable1 :>> ', cleanDataWithFoodItems[cleanDataWithFoodItems.length - 1]);

      }

      finally {


        setLoading(false)
      }


    }

  };



  console.log(cateringData?.payload?.report?.mealsCensus?.censusCategories)
  const totalUnits = cateringData ? cateringData.payload.report.mealsCensus.totalUnits : 0
  cateringData && console.log('object :>> ', cateringData.payload.report.wastedCensus.footprint);
  const totalFootPrint = cateringData ? cateringData.payload.report.wastedCensus.footprint : 0
  const totalCost = cateringData ? cateringData.payload.report.wastedCensus.cost : 0
  const totalWeight = cateringData ? cateringData.payload.report.wastedCensus.weight : 0

  const bcTaken = cateringData ? cateringData.payload.report.mealsCensus.bcServed : 0
  const ecTaken = cateringData ? cateringData.payload.report.mealsCensus.ecServed : 0

  // const meatTaken = cateringData ? cateringData.payload.report.mealsCensus.censusCategories[3].quantity : 0
  // const vegTaken = cateringData?.payload.report.mealsCensus.censusCategories[cateringData?.payload.report.mealsCensus.censusCategories.length - 1].censusCategoryName == "Veggie" ? cateringData.payload.report.mealsCensus.censusCategories[cateringData.payload.report.mealsCensus.censusCategories.length - 1].quantity : 0
  // const fruitTaken = cateringData ? cateringData.payload.report.mealsCensus.censusCategories[2].quantity : 0
  // const specialTaken = cateringData ? cateringData.payload.report.mealsCensus.censusCategories[1].quantity : 0
  // const drinkTaken = cateringData?.payload.report.mealsCensus.censusCategories[0].censusCategoryName == "Drink" ? cateringData.payload.report.mealsCensus.censusCategories[0].quantity : 0


  const meatTaken = (cateringData?.payload.report.mealsCensus.censusCategories.find(category => category.censusCategoryName === 'Meat') || {}).quantity? (cateringData.payload.report.mealsCensus.censusCategories.find(category => category.censusCategoryName === 'Meat') || {}).quantity : 0;
  const vegTaken = (cateringData?.payload.report.mealsCensus.censusCategories.find(category => category.censusCategoryName === 'Veggie') || {}).quantity? (cateringData.payload.report.mealsCensus.censusCategories.find(category => category.censusCategoryName === 'Veggie') || {}).quantity : 0;
  const fruitTaken = (cateringData?.payload.report.mealsCensus.censusCategories.find(category => category.censusCategoryName === 'Fruit') || {}).quantity ?(cateringData.payload.report.mealsCensus.censusCategories.find(category => category.censusCategoryName === 'Fruit') || {}).quantity  : 0;
  const specialTaken = (cateringData?.payload.report.mealsCensus.censusCategories.find(category => category.censusCategoryName === 'Special') || {}).quantity ? (cateringData.payload.report.mealsCensus.censusCategories.find(category => category.censusCategoryName === 'Special') || {}).quantity : 0;
  const drinkTaken = (cateringData?.payload.report.mealsCensus.censusCategories.find(category => category.censusCategoryName === 'Drink') || {}).quantity ?(cateringData.payload.report.mealsCensus.censusCategories.find(category => category.censusCategoryName === 'Drink') || {}).quantity  : 0;


  const totalBCHours = bcTaken!==0  ? (cateringData.payload.report.serviceCensus.bcClockDial.first +
    cateringData.payload.report.serviceCensus.bcClockDial.second +
    cateringData.payload.report.serviceCensus.bcClockDial.third +
    cateringData.payload.report.serviceCensus.bcClockDial.fourth

  ) : 0

  console.log('totalBCHours :>> ', totalBCHours);

  const totalECHours = ecTaken!==0 ? (cateringData.payload.report.serviceCensus.ecClockDial.first +
    cateringData.payload.report.serviceCensus.ecClockDial.second +
    cateringData.payload.report.serviceCensus.ecClockDial.third +
    cateringData.payload.report.serviceCensus.ecClockDial.fourth

  ) : 0
  console.log('totalECHours :>> ', totalECHours);

 
  // const totalHours = (bcTaken === 0) ? totalECHours : 0;
  // const totalHours = (ecTaken === 0) ? totalBCHours : 0;

  
  let totalHours = 0;
if (bcTaken === 0) {
  totalHours = totalECHours;
}
else if(ecTaken === 0){
  totalHours = totalBCHours;
  
}else if(bcTaken == 0 && ecTaken == 0){
totalHours=cateringData.payload.report.serviceCensus.totalHours
}

  const seatsStatic = allSeats

  const handleTabsChange = (event, newValue) => {
    setTimeout(() => { setTabValue(newValue) 
 
    if ( selectedChartsSelectionValue==="served") {
      setDayHrValue("day")
    
    }
    else setDayHrValue("hour")
    }, 1)
  };


  const getDefaultFilters = () => {

  
    const filters = [
      {
        name: "flightNo",
        label: "Flight No.",
        type: "dropdown",
        initialValue: "AIB8888",
        options: [
          { label: "AIB8888", value: "AIB8888" },
          // { label: "MU5100", value: "MU5100" },
          // { label: "MU5101", value: "MU5101" },
        ],

        onChange: (handleChange) => {
          console.log('changing')
          handleChange({ target: { name: 'origin', value: null } })
          handleChange({ target: { name: 'destination', value: null } })

        }
      },
      {
        name: "dateRange",
        // label: "Range",
        type: (selectedChartsSelectionValue === "served" && dayHrValue === "day" && tabValue===1) ? ("daterange") : ("date"),
        initialValue: Array(2).fill(new Date()),

      },



      {
        name: "origin",
        label: "Origin",
        type: "dropdown",
        options: (values) =>
          [
            { code: "AIB8888", label: "WBGG", value: "WBGG" },
            { code: "AIB8888", label: "ZGSZ", value: "ZGSZ" },
            { code: "MU5100", label: "ZGSZ", value: "ZGSZ" },
            { code: "MU5101", label: "ZBAA", value: "ZBAA" },
          ].filter((item) => item.code === values.flightNo),
        onChange: (handleChange) => {
          console.log('changing')
          handleChange({ target: { name: 'destination', value: null } })
        }
      },

  {
        name: "destination",
        label: "Destination",
        type: "dropdown",
        options: (values1) =>
          [

            { code: "WBGG", label: "WMKK", value: "WMKK" },
            { code: "ZGSZ", label: "ZBAA", value: "ZBAA" },
            { code: "ZBAA", label: "ZGSZ", value: "ZGSZ" },
          ].filter((item) => item.code === values1.origin),

      }




    ];
return filters
  };

  const [filters, setFilters] = useState(getDefaultFilters());

  useEffect(() => {
    if (selectedChartsSelectionValue === "served") {
      setFilters(getDefaultFilters());
    }
  }, [selectedChartsSelectionValue]);

  useEffect(() => {
    setFilters(getDefaultFilters());
  }, [ dayHrValue]);


  // useEffect(() => {
  //   onSubmit()
  // }, [filters]);



  const setOpenDialog = ({ xvalue: xvalue, food: food, type: type, collectedTime: collectedTime, distributedTime: distributedTime }) => {

    setOpenDialogx({ xvalue: xvalue, food: food, type: type, collectedTime: collectedTime, distributedTime: distributedTime })
  }



  const chartSelectionOptions = [
    { label: "Overview", value: "overview" },
    { label: "Food trends", value: "served" },
    { label: "Food statistics", value: "statistics" },
  ];


  const paxInfoOptions = [
    { label: "All", value: "all" },
    { label: "Business class", value: "BC" },
    { label: "Economy class", value: "EC" },


  ]


  const handleSetSelectedChartsSelectionValue = (event, chartsSelection) => {
    setSelectedChartsSelectionValue(chartsSelection)

    if ( chartsSelection==="served") {
      setDayHrValue("day")
    
    }
    else setDayHrValue("hour")
 
  };
  const handleSetSelectedFilterValue = (event, filterValue) => {
    setSelectedFilterValue(filterValue)
  };
  const handleSetSelectedWasteChartsSelectionValue = (event, wasteChartsSelection) => {
    setSelectedWasteChartsSelectionValue(wasteChartsSelection)
  };


  const handleSetPaxInfo = (event, value) => {
    setSelectedPaxInfo(value)


  };

  const onChartClick = (params) => {
    console.log('Chart clicked', params);
    console.log(params.name)
    setOpen(true)
    setName(params.name)
  };
  const onEvents = {
    click: onChartClick,
  };



  // const merged = seatsStatic.map((subArr) => {
  //   return subArr.map((obj1) => {
  //     const obj2 = seatsStatic?.find((obj2) => obj1.seat_number === obj2.seatName);
  //     return obj2 ? { ...obj1, ...obj2 } : obj1;
  //   });
  // });

  const classes = useStyles();
  return (
    // <div style={{ color: '#f9f9f9', marginBottom: 20 }}>
    <div style={{ backgroundColor: 'black' }}>



      <Grid container xs={12} >
        <Grid item xs={12} sm={12} md={12} lg={12} >
          <ColorModeProvider mode="dark">
            <Tabs
              floating={true}
              // className="bg-primaryblue-90"
              size="xlarge"
              onChange={
                (e, val) => {
                  handleTabsChange(null, val)
                  if (val === 0) {
                    handleSetSelectedChartsSelectionValue(null, "overview");

                  }
                }

              }
              aria-label="Tabs purpose label"
              value={tabValue}
              scrollable={true}
              //  variant="fullWidth"
              style={{ borderBottom: '1px solid #D9D9D9' }
              }
            >  <Tab {...a11yProps(0)}>Overview</Tab>
              <Tab {...a11yProps(1)}>Reports</Tab>
              <Tab {...a11yProps(2)}>Catering Zone</Tab>
              <Tab {...a11yProps(3)}>Contacts</Tab>
              {/* <Tab {...a11yProps(4)}>Meals Served</Tab> */}
              {/* <Divider paddingTop/> */}
            </Tabs>
            {/* <Divider style={{color:"#D9D9D9", width: matches ?"100%":"100%"}} /> */}
          </ColorModeProvider>
        </Grid>
      </Grid>
      <Container style={{ maxWidth: "80%", paddingBottom: "0.8rem" }}>
        {/* <Row alignItems="end" justify="space-evenly"> */}
        <Inline>
          <TopFilters filters={filters} onSubmit={onSubmit} tabValue={tabValue}  />

        
        </Inline>
      </Container>
      <Container>
      <Row justify='start'>
      <Col md={12} xl={7} lg={7} >
        { tabValue === 0 && 
      <Inline wrap="true" style={{ paddingBottom: "0.2rem" }} spacing='1-x'>
        <Card style={{ minWidth: "10rem", width: "auto", height: "3rem", backgroundColor: "#1C2747", color: "white", padding: "0.7rem" }}>
          <div style={{ fontSize: matches ? '1.5rem' : "1rem", textAlign: 'center' }}>MEALS: <b>{totalUnits} Units</b>
        </div> 
        </Card>
        <Card style={{ minWidth: "10rem", width: "auto", height: "3rem", backgroundColor: "#1C2747", color: "white", padding: "0.7rem" }}>
          <div style={{ fontSize: matches ? '1.5rem' : "1rem", textAlign: 'center' }}> COST: <b> $ {totalCost.toFixed(2)} </b></div>
          </Card>
        <Card style={{ minWidth: "10rem", width: "auto", height: "3rem", backgroundColor: "#1C2747", color: "white", padding: "0.7rem" }}>  
                <div style={{ fontSize: matches ? '1.5rem' : "1rem", textAlign: 'center' }}> FOOTPRINT: <b>{totalFootPrint} Kg/CO2</b></div>
          </Card>

      </Inline>
}
      </Col>
      <Col md={2} xl={1} lg={1} style={{marginLeft: matches ? "30rem" :"42rem"}} >
      {tabValue == 1 &&
            <div >

              <Select style={{ width: "6.5rem" }}
                      styles={customStyles}
                options={chartSelectionOptions}
                defaultValue={chartSelectionOptions.find(({ value }) => value === 'overview')}
                value={chartSelectionOptions.find(({ value }) => value === value.value)}
           
                //  holder={"Overview"}
                onChange={( value) => {
                  
                  handleSetSelectedChartsSelectionValue(null, value.value)}}
              ></Select>
            </div>
          }
           </Col>
          </Row>
          </Container>
      <TabPanel value={tabValue} index={0} style={{ color: "white" }}>

        {

          <CateringOverview loading={loading} reportData={reportData} handleTabsChange={handleTabsChange} matches={matches} totalUnits={totalUnits}
            handleSetSelectedChartsSelectionValue={handleSetSelectedChartsSelectionValue} gaugeX={gaugeX} gaugeY={gaugeY}
            meatTaken={meatTaken} fruitTaken={fruitTaken} vegTaken={vegTaken} specialTaken={specialTaken}
            ecTaken={ecTaken} bcTaken={bcTaken} drinkTaken={drinkTaken} totalHours={totalHours}  totalWeight={totalWeight} totalCost={totalCost.toFixed(2)}
           totalBCHours={totalBCHours} totalECHours={totalECHours}
            totalFootprint={totalFootPrint} cateringData={cateringData}
haveConnect={haveConnect}
          />

        }


      </TabPanel>
      <TabPanel value={tabValue} index={1} style={{ color: "white" }}>
        <CateringReport matches={matches} top5Foods100={top5Foods100} top5Foods={top5Foods} top5Waste={top5Waste}
          wasteSDArray={wasteSDArray} servedSDArray={servedSDArray} servedArray={servedArray}
          selectedChartsSelectionValue={selectedChartsSelectionValue} servedSHArray={servedSHArray}
          wasteSHArray={wasteSHArray} loading={loading}
          onSetDayHrValue={setDayHrValue}
          />
      </TabPanel>

      <TabPanel value={tabValue} index={2} style={{ color: "white" }}>
        <Backdrop open={loading} className={classes.backdrop}>
          <CircularProgress color="inherit" /></Backdrop>

        {openDialogx &&

          <MaxWidthDialog1 open={openDialogx} onClose={() => setOpenDialogx(null)} />
        }


        <TransformWrapper>
          <TransformComponent>

            <Col xs={12} xxs={12} sm={12} md={12} lg={12} style={{ paddingTop: "1rem" }} >
              {
                <Box style={{ backgroundColor: "#1C2747", width: matches ? "55rem" : "55rem", height: matches ? "25rem" : "25rem" }}>
                  <Container ><Row justify="center" style={{ paddingLeft: "10rem" }}><Col md={6} style={{ paddingTop: "1rem" }} > {"BC"}</Col><Col md={6} style={{ paddingTop: "1rem" }}> {"EC"}</Col></Row></Container>
                  <img src={mainLogo} style={{  width: matches ? "55rem":"51rem", height: "20rem", position: "absolute" }} />  <Container ><Row>
                    <Col md={5}>

                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ marginTop: "6rem", paddingLeft: "6rem" }}>
                        {
                          merged.length !== 0 ? merged[0].map((i) =>

                            <Box key={i}
                              onClick={() => {

                                const finalprop = i.food[i.food.length - 1]
                                console.log('finalprop :>> ', finalprop);
                                console.log(i.seat_number)
                                // console.log("i", i)
                                setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                              }}
                              style={{
                                backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>) : seatsStatic[0].map((i) =>

                              <Box key={i}

                                onClick={() => {
                                  console.log(i.seat_number)
                                  setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                                }}
                                style={{
                                  backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                                }}

                              ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)


                        }


                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "2rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[1].map((i) =>

                          <Box key={i}

                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}


                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>) : seatsStatic[1].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)


                      }



                      </Row>

                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.4rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[2].map((i) =>
                          <Box key={i}
                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}


                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)
                          : seatsStatic[2].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)
                      }
                      </Row>

                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "2rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[3].map((i) =>


                          <Box key={i}
                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}


                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>) : seatsStatic[3].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)

                      }
                      </Row>
                    </Col>

                    <Col md={6}>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ marginTop: "5rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[4].map((i) =>


                          <Box key={i}
                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}


                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)
                          : seatsStatic[4].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)
                      }


                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.5rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[5].map((i) =>


                          <Box key={i}
                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}


                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)

                          : seatsStatic[5].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.5rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[6].map((i) =>


                          <Box key={i}
                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}


                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)
                          : seatsStatic[6].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.7rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[7].map((i) =>

                          <Box key={i}
                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}

                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>) : seatsStatic[7].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.4rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[8].map((i) =>


                          <Box key={i}
                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}


                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>) : seatsStatic[8].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>


                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.4rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[9].map((i) =>

                          <Box key={i}
                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}


                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)
                          : seatsStatic[9].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.7rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[10].map((i) =>

                          <Box key={i}
                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}


                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)
                          : seatsStatic[10].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.4rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[11].map((i) =>

                          <Box key={i}
                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}


                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)
                          : seatsStatic[11].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.4rem", paddingLeft: "6rem" }}>{
                        merged.length !== 0 ? merged[12].map((i) =>

                          <Box key={i}
                            onClick={() => {

                              const finalprop = i.food[i.food.length - 1]
                              console.log('finalprop :>> ', finalprop);
                              console.log(i.seat_number)
                              // console.log("i", i)
                              setOpenDialog({ xvalue: i.seat_number, food: finalprop ? finalprop.foodName : "-", type: finalprop ? finalprop.categoryName : '-', distributedTime: finalprop ? finalprop.distributedTime : '' })
                            }}
                            style={{
                              backgroundColor: i.food.length ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                            }}


                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)
                          : seatsStatic[12].map((i) =>

                            <Box key={i}

                              onClick={() => {
                                console.log(i.seat_number)
                                setOpenDialog({ collectedTime: i.preorder.collectedTime, distributedTime: i.preorder.distributedTime, xvalue: i.seat_number, food: i.foodOrder ? i.foodOrder.description : "-", type: i.preorder ? i.preorder.orderType : '-' })
                              }}
                              style={{
                                backgroundColor: i.preorder ? "#2e7d32" : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                              }}

                            ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>

                    </Col>
                  </Row>
                  </Container>
                </Box>
              }
            </Col>
          </TransformComponent>
        </TransformWrapper>
      </TabPanel>

      <TabPanel value={tabValue} index={3} style={{ color: "white" }}>

        <Col xs={12} xxs={12} sm={12} md={12} lg={12} style={{ paddingTop: "1rem" }} >
          {
            <Box p={2} style={{ backgroundColor: "#1C2747", width: matches ? "70rem" : "55rem", height: matches ? "30rem" : "33rem" }}>
              <Row justify='space-around' >
                <Col md={10}><div style={{ paddingLeft: "2rem", fontSize: "1.5rem" }}> Pax Order table</div></Col>
                <Col style={{marginRight:"0.2rem"}}> 
                
                <Select style={{ width: "8rem" }}
                  options={paxInfoOptions}
                  styles={customStyles}
                value={paxInfoOptions.find(({ value }) => value === value.value)}

                 defaultValue={paxInfoOptions.find(({ value }) => value === 'all')}

                  onChange={( value) => handleSetPaxInfo(null, value.value)}
                ></Select>
                </Col>
              </Row>
              <Row>
                <Col md={11}>
                  <CateringReactTable loading={loading} cateringArray={finalArraytoTable} selectedPaxInfo={selectedPaxInfo} handleSetSelectedFilterValue={handleSetSelectedFilterValue} handleTabsChange={handleTabsChange} />
                </Col>
                <Col md={1}>
                  <br />
                  {/* <Select style={{ width: "8rem" }}
           value={"All"}
           defaultValue="All"/> */}
                </Col>
              </Row>
            </Box>
          }
        </Col>

      </TabPanel>

    </div>
  )
}
