import {
  Button,
  ColorModeProvider,
  Row,Inline
} from "@airbus/components-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./cargo.css";
import _ from "lodash";
import Select from "react-select";
import { Formik } from "formik";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  Select as MuiSelect,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "black",
    opacity: 0.7,
  },
}));

const highlightedDates = [new Date(2023, 3, 19), new Date(2023, 1, 8)];
axios.defaults.timeout = 40000;

const customStyles = {
  option: (styles,{ isFocused, isSelected }) => {
    return{
    height: "100%",
    color: isFocused ? "white": "black",
    paddingLeft: "10px",
    backgroundColor: isFocused ? "#01579b" : undefined
  }
}
};

const Filters = ({  filters = [], onSubmit, tabValue, selectedFilterValue,onDataReceived,isSubmitClicked }) => {
  console.log('isSubmitClicked :>> ', isSubmitClicked);
console.log('filtersxxx :>> ', filters[1]);
 const classes = useStyles();
 const defaultOption = filters[1].options.find(option => option.label == 'Process 1');


  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const [defaultMonth, setDefaultMonth] = useState(
    String(currentDate.getMonth() + 1).padStart(2, "0")
  );
  const [highDate, setHighDate] = useState(null);
  const getEndpointx = `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/cargo&created_at=2023-${defaultMonth}&topic_extension=/PDU&payload=1`;

  const asyncGetDates = async () => {
    const apiDatax = [];
    const { data: response } = await axios.get(getEndpointx);
    // console.log(...response);
   // Using a Set to store unique created_at values
   const uniqueCreatedDates = new Set();
   const dateObjects = [];
 
   // Loop through each object in apiDatax
   for (const obj of apiDatax) {
     // Check if the created_at value is not in the Set
     if (!uniqueCreatedDates.has(obj.created_at)) {
       // Add the created_at value to the Set
       uniqueCreatedDates.add(obj.created_at);
       // Create a Date object from the created_at value and add it to dateObjects
       dateObjects.push(new Date(obj.created_at));
     }
   }
 
   // Update the high date using the optimized dateObjects array
   setHighDate(dateObjects);
 
   // Return the array of date objects
   return dateObjects;
  };

  const asyncGetMonth = async (date) => {
    setLoading(true);
    const currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    const apiDatax = [];
    try{
    const { data: response } = await axios.get(
      `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/cargo&created_at=2023-${currentMonth}&topic_extension=/PDU_covered&payload=1`
    , {timeout: 20000},
    {responseType:"stream"});
    console.log(...response);
    console.log("response :>> ", response);

    if (response.length > 1) {
      apiDatax.push(...(response || []));
      const uniqueCreatedDates = _.uniqBy(apiDatax, "created_at").map(
        (obj) => obj.created_at
      );
      console.log(uniqueCreatedDates);
      const dateObjects = uniqueCreatedDates.map(
        (dateString) => new Date(dateString)
      );
      console.log("dateObjects :>> ", dateObjects);
      setHighDate(dateObjects);
      setLoading(false);
      return dateObjects;
    }
  }
    catch(error){console.log('error :>> ', error);
    setLoading(false);
  }
  
  
     
  setLoading(false);
    return 1;
  };

  const handleOnChange = (option) => {
    console.log(
      "🚀 ~ file: CargoFilters.js:135 ~ handleOnChange ~ option:",
      option
    );
    console.log('option.value :>> ', option.value);
	selectedFilterValue(option.value);
  };

 
  const sendDataToParent = (v,x,y) => {
  

    // Call the callback function to send data to the parent
    onDataReceived(v,x,y);
  };
  const initialValues = filters.reduce((acc, filter) => {
    return {
      ...acc,
      [filter.name]:
        filter.initialValue ||
        (filter.type === "daterange" ? [null, null] : null),
    };
  }, {});

 

  return (

    <Formik initialValues={initialValues} onSubmit={(v) =>{
      const firstProcess = filters?.find(f => f.name === "process")?.options?.[0];
      console.log('firstProcess :>> ', firstProcess);
      if(firstProcess){
        sendDataToParent(firstProcess.value, firstProcess.startTime, firstProcess.endTime)
      }
      onSubmit(v)}}>
  	{({ values, handleChange, submitForm }) => {
      for(const key in values){
   
        if( !values[key]){

const filter = filters.find(f => f.name===key && f.type === "dropdown");
if(filter){
const options = typeof filter?.options === "function" ? filter.options(values) : filter.options;
if(options.length===1){
  handleChange({ target: { name: key, value: options[0].value } });
}

}
        }
      }
  
    	return (

      	<Inline spacing="half-x">
        	{filters.map((filter) => {
          	const { name, options: o } = filter;

          	const options = typeof o === "function" ? o(values) : o;

          	const value = values[name];
   
          	const details =
            	filter.type === "dropdown" ? (
                (
                <div style={{width: '150px'}}>
          
              	<Select 
                styles={customStyles}
           
          // holder={filter.label}
              	
          {...filter}
                	options={options}
					  placeholder= {options.length >0 ?"Process 1": "Process" }
            
            // Placeholder text
            
                	onChange={(v) => {
                  
                    console.log('v :>> ', v);
                  	handleChange({ target: { name, value: v.value, startTime:v.startTime, endTime:v.endTime } });
                    // filter.onChange?.(handleChange)
                    handleOnChange(v)
                console.log('vbvbb :>> ', v);
            sendDataToParent(v.value, v.startTime, v.endTime)
                	}}
              	/>
                
                </div>)
            	) : (
            <div> 
				     <Backdrop open={loading} className={classes.backdrop} style={{ opacity: "0.8" }}>
        <CircularProgress color="inherit" /></Backdrop>
              	<DatePicker 
                	// startDate={value[0]}
                	// endDate={value[1]}
					onMonthChange={asyncGetMonth}
					onCalendarOpen={asyncGetDates}
					 highlightDates={highDate? highDate: []}			
							isSingleDate
                	//selectsRange   //uncomment if want to change to date range for datepicker
					placeholderText="Date"
					 selected={Array.isArray(value)?value[0]:value}
                	onChange={(v) => {
                  	// handleChange({ target: { name, value: v } }); //uncomment if want to change to date range for datepicker
					  const newValue = [v, v];
					  handleChange({ target: { name, value: newValue} });
                	}}
              	/>
                   </div>
            	);
          	return (
            	<div key={name}>
              	{/* <p>{filter.label}</p> */}
              	{details}
            	</div>
          	);
        	})}
            <ColorModeProvider mode="light">
        	<Button style={{width:"5rem",height:"2.5rem",backgroundColor: "#1C2747"}} variant="primary" onClick={submitForm}>Search</Button>
          </ColorModeProvider>
      	</Inline>
    	);
  	}}
	</Formik>
  );
};

export const CargoFilters = ({
  filters,
  onSubmit,
  tabValue,
  onDataReceived,
  isSubmitClicked,
  selectedFilterValue,
setFieldValue
}) => {
  return (
    <div style={{ maxWidth: "80%", paddingBottom: "0.8rem" }}>
      <Row justify="start" style={{ paddingTop: "1rem" }}>
        <Filters
        onDataReceived={onDataReceived}
          filters={filters}
          onSubmit={onSubmit}
          tabValue={tabValue}
          isSubmitClicked={isSubmitClicked}
          setFieldValue={setFieldValue}
          selectedFilterValue={selectedFilterValue}

        />
      </Row>
    </div>
  );
};
