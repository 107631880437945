import Appbar from './components/Appbar'
import { makeStyles } from '@material-ui/core/styles'
import { HashRouter, Switch, Route } from 'react-router-dom'
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css"
import awsExports from "./aws-export";
import { useHistory } from 'react-router-dom';
import Login from './views/Auth/SignIn';
import SignedOut from './views/Auth/SignedOut';

const useStyles = makeStyles((theme) => ({
  app: {
    background: theme.palette.primary.main,
    minHeight: '90vh'
  }
}))

Amplify.configure(awsExports);

function App() {
  // const [user, setUser] = useState(null);
 

  const classes = useStyles();


  return (
    <div className={classes.app}>
      <HashRouter>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/signedout" component={SignedOut} exact />
          <Route path="*" component={Appbar} exact />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default (App);
