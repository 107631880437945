import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Toggle, Accordion, Badge, Chip, Button, Collapsible, Checkbox, Inline, Tabs,Toast, Tab, ColorModeProvider, RangeSlider, Rating, Stack, Card, CardActions, CardContent, CardHeader, CardMedia, CardActionArea, CardTitle, IconButton, Container, Col, Content, Row, Flex } from "@airbus/components-react"
import { ReactTable2, ChipInputCell, ChipInputCellGalleyTop, ProgressInputCell } from '../../components/Gains/ReactTable'
import { CheckCircle,Cancel}from '@airbus/icons/react'
import {Slide,Box} from '@material-ui/core';


  const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function MaxWidthDialog(props) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');


  const handleClose = () => {
  
    props.onClose?.()
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };



  return (
<div>
  
      <Dialog style={{color:"#1C2747"}}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.openDialogx !==null}
        onClose={handleClose}
        keepMounted
        TransitionComponent={Transition}
    
      >
       
        <DialogTitle style={{ backgroundColor:"#1C2747",color:"white"}} id="max-width-dialog-title">
        <Container>
        <Row><div style={{color:"white",fontSize:"1.5rem"}}>{"Galley 1"}</div></Row>
        <Row  alignItems='center'><Col sm={8} md={8} lg={8} xl={8}><div style={{color:"white"}}>{props.openDialogx.name}</div></Col><Col sm={3} md={3} lg={3} xl={3}>  <div>{props.openDialogx.healthy ==0 ?<Button variant="error" style={{backgroundColor:"#AD4E4E"}}>{"Possible failure Detected"}</Button>:<Button variant="success">{"Healthy"}</Button>}</div></Col> <Col sm={1} xs={1} xl={1}><ColorModeProvider mode="dark">
        <IconButton variant="ghost" onClick={handleClose}>
          <Cancel style={{color:"white"}}/></IconButton><ColorModeProvider/></ColorModeProvider></Col></Row>
        <Row><div style={{color:"white"}}>{"Last Recorded Activity: "}{props.openDialogx.created_at}</div></Row>
        </Container>
        </DialogTitle>
        <DialogContent style={{backgroundColor:"#1C2747", color:"#1C2747"}}>
          <DialogContentText style={{backgroundColor:"#1C2747", color:"white"}}>
         
         
            <br></br>
           
            < Container >
            <Row justify='start'>
              <Col md={6} sm={6}>
          <Box style={{width:"22.5rem", height:"19rem", border: '4px solid grey',}}>
            
<Inline justify='center' spacing='1-x'>

<Card style={{width:"4rem", height:"4rem"}}>      <CardTitle style={{fontColor:"black"}}>{"G1"}</CardTitle></Card>



<Card style={{width:"4rem", height:"4rem", backgroundColor:"#D8D8D8"}}>      <CardTitle style={{fontColor:"black"}}>G2</CardTitle></Card>
<Card style={{width:"1rem", height:"4rem", backgroundColor:"#D8D8D8"}}>   <div style={{transform:"rotate(90deg)",fontColor:"black", fontSize:"0.8rem"}}>{"MISC."}</div></Card>
<Card style={{width:"2.5rem", height:"4rem", backgroundColor:"#D8D8D8"}}>      <CardTitle style={{fontColor:"black",fontSize:"0.8rem"}}>Elec. Panel</CardTitle></Card>
<Card style={{width:"4rem", height:"4rem", backgroundColor:"#D8D8D8"}}>      <CardTitle style={{fontColor:"black"}}>G5</CardTitle></Card>
<Card style={{width:"4rem", height:"4rem", backgroundColor:"#D8D8D8"}}>      <CardTitle style={{fontColor:"black"}}>G6</CardTitle></Card>


</Inline>
<Inline justify='center' spacing='1-x'>

<Card style={{width:"4rem", height:"6rem"  ,backgroundColor: (props.openDialogx.healthy==0) &&(props.openDialogx.id==1)  ? "#AD4E4E":"#D8D8D8"}}>      <CardTitle style={{fontColor:"black"}}>G1</CardTitle></Card>
<Card style={{width:"4rem", height:"6rem",backgroundColor: (props.openDialogx.healthy==0) &&(props.openDialogx.id==2) ? "#AD4E4E": "#D8D8D8"}}>      <CardTitle style={{fontColor:"black"}}>G2</CardTitle></Card>
<Stack spacing='1-x'>
  <Inline spacing='half-x'>
  <Card style={{width:"1.2rem", height:"3rem" ,backgroundColor: (props.openDialogx.healthy==0) &&(props.openDialogx.id==3)  ? "D4E4E":"#D8D8D8"}}>      G3</Card>
  <Card style={{width:"1.2rem", height:"3rem" ,backgroundColor: (props.openDialogx.healthy==0) &&(props.openDialogx.id==4)  ? "#AD4E4E":"#D8D8D8"}}>     G4</Card>
  <Card style={{width:"1.2rem", height:"3rem",backgroundColor: (props.openDialogx.healthy==0) &&(props.openDialogx.id==5)  ? "#AD4E4E":"#D8D8D8"}}>      G5</Card>
  </Inline>
<Card style={{width:"4rem", height:"2rem",backgroundColor:"#B8B8B8"}}>      GWDU</Card>
</Stack>
<Card style={{width:"4rem", height:"6rem",backgroundColor: (props.openDialogx.healthy==0) &&(props.openDialogx.id==6)  ? "#AD4E4E":"#D8D8D8"}}>      <CardTitle style={{fontColor:"black"}}>G6</CardTitle></Card>
<Card style={{width:"4rem", height:"6rem",backgroundColor: (props.openDialogx.healthy==0) &&(props.openDialogx.id==7)  ? "#AD4E4E":"#D8D8D8"}}>      <CardTitle style={{fontColor:"black"}}>G7</CardTitle></Card>




</Inline>
<Inline justify='center' spacing='1-x'>

<Card style={{width:"4rem", height:"8rem",backgroundColor:"#8B8B8B"}}>      <CardTitle style={{fontColor:"black"}}>G12</CardTitle></Card>
<Card style={{width:"4rem", height:"8rem",backgroundColor:"#8B8B8B"}}>      <CardTitle style={{fontColor:"black"}}>G13</CardTitle></Card>
<Card style={{width:"3.8rem", height:"8rem",backgroundColor:"#8B8B8B",marginLeft:"0.2rem"}}>      <CardTitle style={{fontColor:"black"}}>G14</CardTitle></Card>
<Card style={{width:"4rem", height:"8rem",backgroundColor:"#8B8B8B"}}>      <CardTitle style={{fontColor:"black"}}>G15</CardTitle></Card>
<Card style={{width:"4rem", height:"8rem",backgroundColor:"#8B8B8B"}}>      <CardTitle style={{fontColor:"black"}}>G16</CardTitle></Card>




</Inline>




</Box>
            
            </Col>
<Col md={6} sm={6}>
<Row>
  <ColorModeProvider mode='dark'>
<Toast style={{minWidth:"20rem", backgroundColor:"#1C2747", font:"white"   }}title="Recommended Actions" time={new Date().toLocaleTimeString('en-gb')}>
  Check equipment
    {/* <Link href="#" onClick={(e) => e.preventDefault()}> */}
 <br></br>
 <br></br>
    <a
    style={{ cursor:"pointer" ,color:"purple"}}
    href={null}
    onClick={() =>
                  this.toggleModal("Rental Objects With Current Rent")
                }
 >
{"Refresh"}
</a>
  </Toast>
  </ColorModeProvider>
  </Row>
  <br></br>
  

<Row>
  <Col md={4}>

 {"Serial Number"}
 <Row>
{props.openDialogx.serialNumber}
</Row>
</Col>
<Col md={4}>
{"Last Maintenance"}
<Row>
  {props.openDialogx.lastMaintenance} 
  </Row>
  </Col>
  </Row>
<br></br>

<br></br>

{"Operating Hours"}
<Row>
{props.openDialogx.operatingHours}</Row>
</Col>

            </Row>
            </Container>
            
          </DialogContentText>
 <br></br>
 <br></br>
 <br></br>
         
        </DialogContent>
        <DialogActions style={{backgroundColor:"#1C2747",color:"#1C2747"}}>
          <ColorModeProvider mode="dark">
          {/* <Button onClick={handleClose} variant="primary" >
            Close
          </Button> */}
          </ColorModeProvider>
        </DialogActions>
      </Dialog>
</div>
  );
}
