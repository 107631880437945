import React, { useEffect } from 'react';

import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const SignedOut = () => {
    const goToHostedLogin = () => {
        Auth.federatedSignIn();
    }

    useEffect(() => {
        goToHostedLogin();
    }, []);

    return (
        <div>
            <h1>Bye!</h1>
        </div>
    )
}

export default SignedOut;