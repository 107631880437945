import React, { useState,useEffect } from "react";
import Box from '@material-ui/core/Box';
import { Grid,Typography, makeStyles, useTheme, useMediaQuery,Badge } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { MdEventSeat} from 'react-icons/md';
import DialogContent from '@material-ui/core/DialogContent';
import ReactEcharts from "echarts-for-react";
import Exit from './Exit'
import mainLogo1 from '../../images/bg_fleet.svg';
import mainLogo from'../../images/cabin-bg.png';
// import mainLogo from'../../images/lopa.svg';
// import mainLogo from'../../images/finalseatslopa.png';
import mainLogo2 from'../../images/Seats/seat-issue.svg';
import mainLogo3 from'../../images/Seats/seat-not-connected.svg';
import "./lopa.css"
import { Toast, Tab, ColorModeProvider,  IconButton, Container, Col, Row ,Chip,Inline} from "@airbus/components-react"
import { CheckCircle,Cancel}from '@airbus/icons/react'
import { AirlineSeatLegroomNormal, AirlineSeatReclineNormal, SettingsPhoneTwoTone } from "@material-ui/icons";
import EventSeatIcon from '@material-ui/icons/EventSeat';
import {  Error} from '@airbus/icons/react';

import armRestLeft from '../../images/Seats/seats-issue/armRestLeft.PNG'
import armRestRight from '../../images/Seats/seats-issue/armRestRight.PNG'
import seatBack from '../../images/Seats/seats-issue/seatBack.PNG'
import seatPan from '../../images/Seats/seats-issue/seatPan.PNG'
import seatTable from '../../images/Seats/seats-issue/seatTable.PNG'
import armRestLeftok from '../../images/Seats/seats-issue/armRestLeftok.PNG'
import armRestRightok from '../../images/Seats/seats-issue/armRestRightok.PNG'
import seatBackok from '../../images/Seats/seats-issue/seatBackok.PNG'
import seatPanok from '../../images/Seats/seats-issue/seatPanok.PNG'
import seatTableok from '../../images/Seats/seats-issue/seatTableok.PNG'


const seatsElecOption1= {
  title: {
    text: 'Seats Health'
  },
  tooltip: {},
  // legend: {
  //   data: ['预算分配（Allocated Budget）', '实际开销（Actual Spending）']
  // },
  radar: {
    // shape: 'circle',
    indicator: [
        { name: 'headrest', max: 10000},
        { name: 'armrest', max: 10000},
        { name: 'ottoman', max: 10000},
        { name: 'bottom', max: 10000},
        { name: 'legrest', max: 10000}
    ]
  },
  series: [{
    name: 'Health Index',
    type: 'radar',
    // areaStyle: {normal: {}},
    data : [
      {
        value : [10000,10000,10000,10000,10000],
        name : 'Expected Health',
        lineStyle: {
          type: 'dashed'
        },
      },
        {
        value : [5000, 5000,5000,5000, 5000],
        name : 'Current Health',
        areaStyle: {
          color: '#ADD8E6'}
      }
    ]
  }]
};

const seatsElecOption = {
  title: {
    text: 'Actuator Electrical Consumption (Watt)',

  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  toolbox: {
    show: true,
    feature: {
      saveAsImage: {}
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    // prettier-ignore
    // data: ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45']
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '{value} W'
    },
    axisPointer: {
      snap: true
    }
  },
  visualMap: {
    show: false,
    dimension: 0,
    pieces: [
      {
        lte: 6,
        color: 'green'
      },
      {
        gt: 6,
        lte: 8,
        color: 'red'
      },
      {
        gt: 8,
        lte: 14,
        color: 'green'
      },
      {
        gt: 14,
        lte: 17,
        color: 'red'
      },
      {
        gt: 17,
        color: 'green'
      }
    ]
  },
  dataset: [{
    dimensions: ["timestamp","value"],
    source: [
      {timestamp:"2021-04-09 10:18:48",id:18,id:1},
      {timestamp:"2021-04-09 10:18:49",id:102,id:2},
      {timestamp:"2021-04-09 10:18:53",id:23,id:3},
      {timestamp:"2021-04-09 10:19:07",id:63,id:4},
      {timestamp:"2021-04-09 10:23:16",id:131,id:5},
      {timestamp:"2021-04-09 10:33:59",id:92,id:6},
      {timestamp:"2021-04-09 10:34:16",id:101,id:7},
      {timestamp:"2021-04-09 10:47:50",id:19,id:8},
      {timestamp:"2021-04-09 10:18:43",id:89,id:9},
      {timestamp:"2021-04-09 10:18:45",id:148,id:10},
      {timestamp:"2021-04-09 10:18:46",id:42,id:11},
      {timestamp:"2021-04-09 10:23:17",id:38,id:12},
      {timestamp:"2021-04-09 10:26:21",id:27,id:13},
      {timestamp:"2021-04-09 10:34:00",id:71,id:14},
      {timestamp:"2021-04-09 10:43:23",id:74,id:15},
      {timestamp:"2021-04-09 10:47:50",id:107,id:16},
      {timestamp:"2021-04-09 10:54:08",id:10,id:17},
      {timestamp:"2021-04-09 11:00:37",id:49,id:18},
      {timestamp:"2021-04-09 11:14:21",id:88,id:19},
      {timestamp:"2021-04-09 10:18:46",id:58,id:20},
      {timestamp:"2021-04-09 10:23:53",id:26,id:21},
      {timestamp:"2021-04-09 10:26:20",id:86,id:22},
      {timestamp:"2021-04-09 10:29:37",id:59,id:23},
      {timestamp:"2021-04-09 10:34:00",id:29,id:24},
      {timestamp:"2021-04-09 10:39:38",id:115,id:25},
      {timestamp:"2021-04-09 10:43:04",id:66,id:26},
      {timestamp:"2021-04-09 10:54:08",id:39,id:27},
      {timestamp:"2021-04-09 11:00:37",id:28,id:28},
      {timestamp:"2021-04-09 11:14:21",id:79,id:29},
      {timestamp:"2021-04-09 11:33:33",id:57,id:30},
      {timestamp:"2021-04-09 10:19:07",id:102,id:31},
      {timestamp:"2021-04-09 10:29:37",id:140,id:32},
      {timestamp:"2021-04-09 10:43:04",id:144,id:33},
      {timestamp:"2021-04-09 10:53:46",id:36,id:34},
      {timestamp:"2021-04-09 11:14:21",id:49,id:35},
      {timestamp:"2021-04-09 11:33:33",id:126,id:36},
      {timestamp:"2021-04-09 10:26:21",id:75,id:37},
      {timestamp:"2021-04-09 10:34:16",id:61,id:38},
      {timestamp:"2021-04-09 10:43:23",id:3,id:39},
      {timestamp:"2021-04-09 11:00:37",id:137,id:40},
      {timestamp:"2021-04-09 11:14:21",id:16,id:41},
      {timestamp:"2021-04-09 10:18:45",id:61,id:42},
      {timestamp:"2021-04-09 10:18:49",id:89,id:43},
      {timestamp:"2021-04-09 10:19:07",id:143,id:44}
    ]
  },
  {fromDatasetIndex: 0,

    transform: {
      type: 'sort',
      config: { dimension:"timestamp", order: 'asc',parser:'time' }
    }
  }
  ],

  series: [
    {
      name: 'Electricity',
      type: 'line',
      datasetIndex: 1,
      smooth: true,
      // prettier-ignore
      // data: [300, 280, 250, 260, 270, 300, 550, 500, 400, 390, 380, 390, 400, 500, 600, 750, 800, 700, 600, 400],
      markArea: {
        itemStyle: {
          color: 'rgba(255, 173, 177, 0.4)'
        },
        data: [
          [
            {
              name: 'Morning Peak',
              xAxis: '2021-04-09 10:34:00'
            },
            {
              xAxis: '2021-04-09 10:43:04'
            }
          ],
          // [
          //   {
          //     name: 'Evening Peak',
          //     xAxis: '17:30'
          //   },
          //   {
          //     xAxis: '21:15'
          //   }
          // ]
        ]
      }
    }
  ],
  dataZoom: [
    {
      type: 'inside',
      show: true,
       //start: 94,
      // end: 100
    },
    {


    },
    {
      type: 'inside',
     show: true,
      yAxisIndex: 0,
      filterMode: 'empty',
      width: 30,
      height: '80%',
      showDataShadow: false,
      left: '93%'
    }
  ],
};

const mainOption2 = {
  title: {
    text: 'Seats Health'
  },
  tooltip: {},
  // legend: {
  //   data: ['预算分配（Allocated Budget）', '实际开销（Actual Spending）']
  // },
  radar: {
    // shape: 'circle',
    indicator: [
        { name: 'headrest', max: 10000},
        { name: 'armrest', max: 10000},
        { name: 'ottoman', max: 10000},
        { name: 'bottom', max: 10000},
        { name: 'legrest', max: 10000}
    ]
  },
  series: [{
    name: 'Health Index',
    type: 'radar',
    // areaStyle: {normal: {}},
    data : [
      {
        value : [10000,10000,10000,10000,10000],
        name : 'Expected Health',
        lineStyle: {
          type: 'dashed'
        },
      },
        {
        value : [5000, 5000,5000,5000, 5000],
        name : 'Current Health',
        areaStyle: {
          color: '#ADD8E6'}
      }
    ]
  }]
};

const seatsElecOptionx = {
  title: {
    text: 'Seat Recline',

  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  toolbox: {
    show: true,
    feature: {
      saveAsImage: {}
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    // prettier-ignore
    // data: ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45']
  },
  yAxis: {
    type: 'value',
    name: 'BITE code',
    nameLocation:'center',
    nameTextStyle:{padding:10},
    axisLabel: {
      formatter: '{value}'
    },
    axisPointer: {
      snap: true
    }
  },
  // visualMap: {
  //   show: false,
  //   dimension: 0,
  //   pieces: [
  //     {
  //       lte: 6,
  //       color: 'green'
  //     },
  //     {
  //       gt: 6,
  //       lte: 8,
  //       color: 'red'
  //     },
  //     {
  //       gt: 8,
  //       lte: 14,
  //       color: 'green'
  //     },
  //     {
  //       gt: 14,
  //       lte: 17,
  //       color: 'red'
  //     },
  //     {
  //       gt: 17,
  //       color: 'green'
  //     }
  //   ]
  // },
  dataset: [{
    dimensions: ["timestamp","value"],
    source: [
      {timestamp:"2021-04-09 10:18:48",id:0,id:1},
      {timestamp:"2021-04-09 10:18:49",id:0,id:2},
      {timestamp:"2021-04-09 10:18:53",id:0,id:3},
      {timestamp:"2021-04-09 10:19:07",id:0,id:4},
      {timestamp:"2021-04-09 10:23:16",id:0,id:5},
      {timestamp:"2021-04-09 10:33:59",id:0,id:6},
      {timestamp:"2021-04-09 10:34:16",id:0,id:7},
      {timestamp:"2021-04-09 10:47:50",id:0,id:8},
      {timestamp:"2021-04-09 10:18:43",id:0,id:9},
      {timestamp:"2021-04-09 10:18:45",id:0,id:10},
      {timestamp:"2021-04-09 10:18:46",id:0,id:11},
      {timestamp:"2021-04-09 10:23:17",id:0,id:12},
      {timestamp:"2021-04-09 10:26:21",id:0,id:13},
      {timestamp:"2021-04-09 10:34:00",id:0,id:14},
      {timestamp:"2021-04-09 10:43:23",id:0,id:15},
      {timestamp:"2021-04-09 10:47:50",id:0,id:16},
      {timestamp:"2021-04-09 10:54:08",id:0,id:17},
      {timestamp:"2021-04-09 11:00:37",id:0,id:18},
      {timestamp:"2021-04-09 11:14:21",id:0,id:19},
      {timestamp:"2021-04-09 10:18:46",id:0,id:20},
      {timestamp:"2021-04-09 10:23:53",id:0,id:21},
      {timestamp:"2021-04-09 10:26:20",id:0,id:22},
      {timestamp:"2021-04-09 10:29:37",id:0,id:23},
      {timestamp:"2021-04-09 10:34:00",id:0,id:24},
      {timestamp:"2021-04-09 10:39:38",id:30,id:25},
      {timestamp:"2021-04-09 10:43:04",id:30,id:26},
      {timestamp:"2021-04-09 10:54:08",id:30,id:27},
      {timestamp:"2021-04-09 11:00:37",id:30,id:28},
      {timestamp:"2021-04-09 11:14:21",id:30,id:29},
      {timestamp:"2021-04-09 11:33:33",id:30,id:30},
      {timestamp:"2021-04-09 10:19:07",id:30,id:31},
      {timestamp:"2021-04-09 10:29:37",id:30,id:32},
      {timestamp:"2021-04-09 10:43:04",id:30,id:33},
      {timestamp:"2021-04-09 10:53:46",id:30,id:34},
      {timestamp:"2021-04-09 11:14:21",id:30,id:35},
      {timestamp:"2021-04-09 11:33:33",id:30,id:36},
      {timestamp:"2021-04-09 10:26:21",id:30,id:37},
      {timestamp:"2021-04-09 10:34:16",id:30,id:38},
      {timestamp:"2021-04-09 10:43:23",id:30,id:39},
      {timestamp:"2021-04-09 11:00:37",id:30,id:40},
      {timestamp:"2021-04-09 11:14:21",id:0,id:41},
      {timestamp:"2021-04-09 10:18:45",id:0,id:42},
      {timestamp:"2021-04-09 10:18:49",id:0,id:43},
      {timestamp:"2021-04-09 10:19:07",id:0,id:44},
      {timestamp:"2021-04-09 10:23:16",id:0,id:45},
      {timestamp:"2021-04-09 10:29:30",id:0,id:46},
      {timestamp:"2021-04-09 10:33:59",id:0,id:47},
      {timestamp:"2021-04-09 10:47:50",id:0,id:48},
      {timestamp:"2021-04-09 10:53:46",id:0,id:49},
      {timestamp:"2021-04-09 11:14:21",id:0,id:50},
      {timestamp:"2021-04-09 10:18:48",id:0,id:51},
      {timestamp:"2021-04-09 10:25:59",id:0,id:52},
      {timestamp:"2021-04-09 11:06:38",id:0,id:53},
      {timestamp:"2021-04-09 11:21:48",id:0,id:54},
      {timestamp:"2021-04-09 10:23:15",id:0,id:55},
      {timestamp:"2021-04-09 10:42:35",id:0,id:56},
      {timestamp:"2021-04-09 11:06:02",id:0,id:57},
      {timestamp:"2021-04-09 11:20:57",id:0,id:58},
      {timestamp:"2021-04-09 10:36:45",id:0,id:59},
      {timestamp:"2021-04-09 10:42:35",id:0,id:60},
      {timestamp:"2021-04-09 11:06:38",id:0,id:61},
      {timestamp:"2021-04-09 11:21:06",id:0,id:62},
      {timestamp:"2021-04-09 10:18:48",id:0,id:63},
      {timestamp:"2021-04-09 10:33:57",id:0,id:64},
      {timestamp:"2021-04-09 11:06:38",id:0,id:65},
      {timestamp:"2021-04-09 11:21:48",id:0,id:66},
      {timestamp:"2021-04-09 10:25:59",id:0,id:67},
      {timestamp:"2021-04-09 11:06:38",id:0,id:68},
      {timestamp:"2021-04-09 11:21:06",id:0,id:69},
      {timestamp:"2021-04-09 10:18:59",id:0,id:70},
      {timestamp:"2021-04-09 10:23:55",id:0,id:71},
      {timestamp:"2021-04-09 10:27:01",id:0,id:72},
      {timestamp:"2021-04-09 10:34:16",id:0,id:73},
      {timestamp:"2021-04-09 10:44:11",id:0,id:74},
      {timestamp:"2021-04-09 11:00:48",id:0,id:75},
      {timestamp:"2021-04-09 11:08:49",id:0,id:76},
      {timestamp:"2021-04-09 11:23:41",id:0,id:77},
      {timestamp:"2021-04-09 10:18:49",id:0,id:78},
      {timestamp:"2021-04-09 10:18:59",id:0,id:79},
      {timestamp:"2021-04-09 10:23:19",id:0,id:80},
      {timestamp:"2021-04-09 10:24:01",id:0,id:81},
      {timestamp:"2021-04-09 10:39:45",id:0,id:82},
      {timestamp:"2021-04-09 11:01:19",id:0,id:83},
      {timestamp:"2021-04-09 11:15:10",id:0,id:84},
      {timestamp:"2021-04-09 11:24:19",id:0,id:85},
      {timestamp:"2021-04-09 10:18:49",id:0,id:86},
      {timestamp:"2021-04-09 10:29:52",id:0,id:87},
      {timestamp:"2021-04-09 10:39:45",id:0,id:88},
      {timestamp:"2021-04-09 10:44:11",id:0,id:89},
      {timestamp:"2021-04-09 11:01:19",id:0,id:90},
      {timestamp:"2021-04-09 11:08:50",id:0,id:91},
      {timestamp:"2021-04-09 11:14:33",id:0,id:92},
      {timestamp:"2021-04-09 10:18:44",id:0,id:93},
      {timestamp:"2021-04-09 10:18:46",id:0,id:94},
      {timestamp:"2021-04-09 10:18:49",id:0,id:95},
      {timestamp:"2021-04-09 10:24:01",id:0,id:96},
      {timestamp:"2021-04-09 10:27:01",id:0,id:97},
      {timestamp:"2021-04-09 10:29:52",id:0,id:98},
      {timestamp:"2021-04-09 10:34:19",id:0,id:99},
      {timestamp:"2021-04-09 11:01:19",id:0,id:100}
    ]
  },
  {fromDatasetIndex: 0,

    transform: {
      type: 'sort',
      config: { dimension:"timestamp", order: 'asc',parser:'time' }
    }
  }
  ],

  series: [
    {
      name: 'Electricity',
      type: 'line',
      datasetIndex: 1,
      smooth: true,
      // prettier-ignore
      // data: [300, 280, 250, 260, 270, 300, 550, 500, 400, 390, 380, 390, 400, 500, 600, 750, 800, 700, 600, 400],
      // markArea: {
      //   itemStyle: {
      //     color: 'rgba(255, 173, 177, 0.4)'
      //   },
      //   data: [
      //     [
      //       {
      //         name: 'Morning Peak',
      //         xAxis: '2021-04-09 10:34:00'
      //       },
      //       {
      //         xAxis: '2021-04-09 10:43:04'
      //       }
      //     ],
      //     // [
      //     //   {
      //     //     name: 'Evening Peak',
      //     //     xAxis: '17:30'
      //     //   },
      //     //   {
      //     //     xAxis: '21:15'
      //     //   }
      //     // ]
      //   ]
      // }
    }
  ],
  dataZoom: [
    {
      type: 'inside',
      show: true,
       //start: 94,
      // end: 100
    },
    {


    },
    {
      type: 'inside',
     show: true,
      yAxisIndex: 0,
      filterMode: 'empty',
      width: 30,
      height: '80%',
      showDataShadow: false,
      left: '93%'
    }
  ],
};

const SeatsLOPA = props => {

  // const [selectedSeat, setSelectedSet] = useState();
  const [seats, setSeats] = useState([
    [
      { id: "K1" },
      { id: "K2" },
      { id: "K3" },
      { id: "K4" },
      { id: "K5" },
      { id: "K6" },
      { id: "K7" },
      {},
      {},
      { id: "K8" },
      { id: "K9" },
      { id: "K10" },
      { id: "K11" },
    ],
    [], //space between each row
    [],
    [
      { id: "G1" },
      { id: "G2" },
      { id: "G3" },
      { id: "G4" },
      { id: "G5" },
      { id: "G6" },
      { id: "G7" },
      {},
      {},
      { id: "G8" },
      { id: "G9" },
      { id: "G10" },
      { id: "G11" },
    ], 
    [],
    [{ id: "D1" },
      { id: "D2" },
      { id: "D3" },
      { id: "D4" },
      { id: "D5" },
      { id: "D6" },
      { id: "D7" },
      {},
      {},
      { id: "D8" },
      { id: "D9" },
      { id: "D10" },
      { id: "D11" },
    ]
      , [],
      [],
      [{ id: "A1"  },
        { id: "A2" },
        { id: "A3" },
        { id: "A4" },
        { id: "A5" },
        { id: "A6" },
        { id: "A7" },
        {},
        {},
        { id: "A8" },
        { id: "A9" },
        { id: "A10" },
        { id: "A11" },
      ]
  ]);

  const [seats1, setSeats1] = useState([
    [
      { id: "K21" },
      { id: "K22" },
  
      { id: "K23" },
      { id: "K24" },
      { id: "K25" },
      //space between each seat
      { id: "K26" },
 
    ],[],
    [
      { id: "J21" },
      { id: "J22" },
      { id: "J23" },
      { id: "J24"},
      { id: "J25" },
      //space between each seat
      { id: "J26" },
 
    ],[],[{ id: "H21" },
      { id:"H22" },
  
      { id: "H23" },
      { id: "H24" },
      { id: "H25" },
      //space between each seat
      { id: "H26" },],

    [], //space between each row
    [],
    [
      { id: "G21" },
      { id: "G22" },
      { id: "G23" },
      { id: "G24" },
      { id: "G25" },
      { id: "G26" },
      { id: "G27" },
      { id: "G28" },
      { id: "G29" },

      
    ],[],
    [
      { id: "D21" },
      { id: "D22" },
      { id: "D23" },
      { id: "D24" },
      { id: "D25" },
      { id: "D26" },
      { id: "D27" },
      { id: "D28" },
      { id: "D29" },
      {},
      {},
      {},
      { id: "D30" },
      [],
      
 
    ],
    [],
    [
      { id: "E21" },
      { id: "E22" },
      { id: "E23" },
      { id: "E24" },
      { id: "E25" },
      { id: "E26" },
      { id: "E27" },
      { id: "E28" },
      { id: "E29" },
    
    ],
      [],[],
      [
        { id: "C21" },
        { id: "C22" },
    
        { id: "C23" },
        { id: "C24" },
        { id: "C25" },
        //space between each seat
        { id: "C26" },
        
      ],[],
      [
        { id: "b21" },
      { id: "B22" },
  
      { id: "B23" },
      { id: "B24" },
      { id: "B25" },
      //space between each seat
      { id: "B26" },
        [],
        
   
      ],
      [],
      [ 
        { id: "A21" },
        { id: "A22" },
    
        { id: "A23" },
        { id: "A24" },
        { id: "A25" },
        //space between each seat
        { id: "A26" },
      ]
  ]);

  const useStyles = makeStyles(theme => ({
    seat: {
  
      backgroundColor: 'blue',
      [theme.breakpoints.up('sm')]: {
        backgroundColor: 'red',
      },
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'green',
      },
      [theme.breakpoints.up('lg')]: {
        backgroundColor: 'orange',
      },
      [theme.breakpoints.up('xl')]: {
        backgroundColor: 'cyan',
      },
    },
  }));


  const firstHandle = { x: 250, y:10 };
  const secondHandle = { x: 250, y: 10 };
  const width = 800;
  const height = 350;
  const [selectedS, setS] = useState(null);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedBITETab, setSelectedBITETab] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const testfunction=()=>{
    setS(true)
    console.log("aa")
      return 1
    }

  const handleClickClose = () => {
    return setOpen(false);
    // setSelectedSeat(null)
  };

  const handleClickOpen = (seat, seatInfo) => {
console.log('seat :>> ', seat);
console.log('seatInfo :>> ', seatInfo);
    // console.log('seat :>> ', seatInfo);
    // console.log(seat)
     setOpen(true);
    //   setSeats((prev) =>
    //     prev.map((r) =>
    //       r.map((s) => ({
    //         ...s,
    //         taken: s.id === seat.id ? !s.taken : s.taken,
    //       }))
    //     )
    //   );
    //   console.log(seat.id)
      setSelectedSeat({"selectedSeat":seat.id, "time":seatInfo.time,"seatObject":seatInfo})
    }
 
    useEffect(() => {
      if (!open) {
        setSelectedSeat(null)
      }
    }, [open])

    const handleClickOpen1 = (seat) => {
      setOpen(true);
        setSeats1((prev) =>
          prev.map((r) =>
            r.map((s) => ({
              ...s,
              taken: s.id === seat.id ? !s.taken : s.taken,
            }))
          )
        );
        console.log(seat.id)
        setSelectedSeat(seat.id)
      }
   
      useEffect(() => {
        if (!open) {
          setSelectedSeat(null)
        }
      }, [open])
  
  const path = (
    <path
      d={`M20, ${height / 2}
        C${firstHandle.x},${firstHandle.y} ${secondHandle.x},${
        secondHandle.y
      } ${width / 2},20
        C${width - firstHandle.x},${firstHandle.y} ${width - secondHandle.x},${
        secondHandle.y
      } ${width - 20},${height / 2}
        C${width - firstHandle.x},${height - firstHandle.y} ${
        width - secondHandle.x
      },${height - secondHandle.y} ${width / 2},${height - 20}
        C${firstHandle.x},${height - firstHandle.y} ${secondHandle.x},${
        height - secondHandle.y
      } 20,${height / 2}
        `}
      stroke="black"
      fill="none"
    />
  );

  return (
    <div>
    <div> 

      <Dialog open={open} onClose={handleClickClose} fullWidth={true} maxWidth="md" hideBackdrop={false}>    

      <DialogTitle style={{ backgroundColor:"#1C2747",color:"white"}} id="max-width-dialog-title">
        <Container>
          {/* {console.log(selectedSeat)} */}
        <Row><div style={{color:"white",fontSize:"1.3rem"}}>{"Economy Class "}{"Seat "}{selectedSeat?.selectedSeat}</div></Row>
        
        <Row  alignItems='center'> <Col sm={11} md={11} lg={8} xl={8}><div style={{color:"white",fontSize:"1.3rem"}}> {selectedSeat=="G5"? "Connectivity Issue":"Seat Issue"}</div></Col> <Col sm={1} xs={1} xl={1}><ColorModeProvider mode="dark">
        
        
        <IconButton variant="ghost" onClick={handleClickClose}>
          <Cancel style={{color:"white"}}/></IconButton><ColorModeProvider/></ColorModeProvider></Col></Row>
        <Row><div style={{color:"white"}}>{"Last Recorded Activity: "}{(new Date(selectedSeat?.time)).toUTCString()}</div></Row>
        </Container>
        </DialogTitle>



        <DialogContent style={{backgroundColor:"#1C2747", color:"#1C2747"}}>
          <DialogContentText style={{backgroundColor:"#1C2747", color:"white"}}>
         
         
            <br></br>
     {/* <Container>
     <Row justify="center">
<Col md={2}>
<Row>Armrest</Row>
<Row>111</Row>
</Col>
<Col md={2}>
<Row>No. of Parts</Row>
<Row>1</Row>
</Col>
<Col md={2}>
<Row>Seat Pan</Row>
<Row>Occupied</Row>
</Col >
<Col md={2}>
<Row>Seat Table</Row>
<Row>Stowed</Row>
</Col>

<Col md={2}>
<Row>Seat Back</Row>
<Row>Upright</Row>
</Col>
<Col md={2}>
<Row>Seat Belt</Row>
<Row>Buckled</Row>
</Col>
     </Row>
   
      </Container>    */}
      <br></br>   
            < Container >
   
            <Row justify="left" >
         <Col alignSelf="center"  md={4}>
          <Box style={{   width:"15rem", height:"15rem"}}>
      

      {/* {selectedSeat?.seatObject?.seatPan==1 && <img  src={seatPanError} style={{width:"12rem",height:"15rem"}}/>}
      {selectedSeat?.seatObject?.armRest==1 && <img  src={armRestError} style={{width:"12rem",height:"15rem"}}/>}
      {selectedSeat?.seatObject?.seatBack==1 && <img  src={backRestError} style={{width:"12rem",height:"15rem"}}/>}
      {selectedSeat?.seatObject?.seatTable==1 && <img  src={seatTableEror} style={{width:"12rem",height:"15rem"}}/>} */}
<Container>

 
  <Col>
  <Row style={{paddingLeft:"2.5rem"}} >{ selectedSeat?.seatObject?.armRest==1  ? <img src = {armRestRight}/> : <img src = {armRestRightok}/>  } </Row>
  <Row  style={{maxHeight:"7.8rem"}} > <Col style={{paddingTop:"0.5rem"}}>

    { selectedSeat?.seatObject?.seatTable==1 ? <img src = {seatTable}/> : <img src = {seatTableok}/> }</Col> 

  <Col> {selectedSeat?.seatObject?.seatPan==1 ?<img  src={seatPan}/> : <img  src={seatPanok}/> }  </Col> <Col style={{marginBottom:"2rem"}}> 
  {selectedSeat?.seatObject?.seatBack==1 ?  <img style={{width:"2rem",height:"7.5rem"}} src = {seatBack}/>:<img style={{width:"2rem",height:"7.5rem"}} src = {seatBackok}/>}</Col></Row>
  <Row style={{paddingLeft:"2.7rem"}}> { selectedSeat?.seatObject?.armRest==1  ?<img src = {armRestLeft} />  : <img src = {armRestLeftok} />}</Row>

  </Col>
  <Col>

  </Col>


</Container>










          {/* {selectedSeat != "G5" && <img  src={mainLogo2} style={{width:"15rem",height:"15rem"}}/>}
          {selectedSeat == "G5" && <img  src={mainLogo3} style={{width:"15rem",height:"15rem"}}/>} */}
    

         {/* { selectedS==true &&<img  src={mainLogo2} style={{width:"10rem",height:"13rem"}}/>}
         { selectedS==null &&<div style={{width:"10rem",height:"13rem"}}> <Box style={{width:"10rem",height:"13rem"}} /></div>} */}
   {/* <Row>
    <Col>     
    <ColorModeProvider mode="dark">
     <IconButton variant="ghost" aria-label="Edit" onClick={testfunction}>
       <img  src={mainLogo2}  style={{  width:"1.3rem", height:"1.3rem"}} />  </IconButton></ColorModeProvider></Col>
       
       <Col style={{paddingLeft:"0.2rem"}}>     
    <ColorModeProvider mode="dark">
     <IconButton variant="ghost" aria-label="Edit">
       <MdEventSeat  style={{   width:"1.3rem", height:"1.3rem"}} />  </IconButton></ColorModeProvider></Col>


       <Col>     
    <ColorModeProvider mode="dark">
     <IconButton variant="ghost" aria-label="Edit">
       <AirlineSeatReclineNormal  style={{   width:"1.3rem", height:"1.3rem",transform:'scaleX(-1)'}} />  </IconButton></ColorModeProvider></Col>


       <Col>     
    <ColorModeProvider mode="dark">
     <IconButton variant="ghost" aria-label="Edit">
       <AirlineSeatReclineNormal style={{   width:"1.3rem", height:"1.3rem"}} />  </IconButton></ColorModeProvider></Col>
   </Row> */}
        </Box>
        </Col>
            
       

{/* <Col md={7} alignSelf={"end"}>
<ColorModeProvider mode='dark'>
<Toast style={{minWidth:"30rem", backgroundColor:"#1C2747", font:"white"   }}title="Recommended Actions" time={new Date().toLocaleTimeString('en-gb')}>
  { selectedSeat== "G5"?"Check connectivity":"Check Backrest Equipment"}
  
    <Link href="#" onClick={(e) => e.preventDefault()}>
 <br></br>
 <br></br>
    <a
    style={{ cursor:"pointer" ,color:"purple"}}
    href={null}
    onClick={() =>
                  this.toggleModal("Rental Objects With Current Rent")
                }
 >
{"Refresh"}
</a>
  </Toast>
  </ColorModeProvider>

</Col> */}

<Col>
{selectedSeat?.seatObject?.seatPan==1 &&
<Toast style={{minWidth:"30rem", backgroundColor:"#1C2747", font:"white" , color:"white"  }}title="Recommended Actions" >
  {"Check Seat Pan "}
  </Toast>}

<br/>
{selectedSeat?.seatObject?.armRest==1 &&
<Toast style={{minWidth:"30rem", backgroundColor:"#1C2747", font:"white" , color:"white"  }}title="Recommended Actions" >
  {"Check Arm Rest "}
  </Toast>}
  <br/>
  {selectedSeat?.seatObject?.seatBack==1 &&
<Toast style={{minWidth:"30rem", backgroundColor:"#1C2747", font:"white" , color:"white"  }}title="Recommended Actions" >
  {"Check Seat Back"}
  </Toast>}
  <br/>
  {selectedSeat?.seatObject?.seatTable==1 &&
<Toast style={{minWidth:"30rem", backgroundColor:"#1C2747", font:"white" , color:"white"  }}title="Recommended Actions" >
  {"Check Seat Table"}
  </Toast>}
</Col>


            </Row>
            
            </Container>
            
          </DialogContentText>
 <br></br>

 <br></br>
         
        </DialogContent>
       {/* <DialogActions>

          
          <Button onClick={handleClickClose} color="primary">
            Cancel
          </Button>   </DialogActions> */}
          </Dialog>
          
          </div>
       
                
         
          <div >
     
          { props.showEconomy=="Filters" &&     

      <Box style={{width:"57rem", height:"47rem"}}>
                                          <img  src={mainLogo1} style={{width:"53rem",height:"20rem" ,  position: "absolute"}} />

        <Row justify="center" style={{marginLeft:"2.5rem"}}> 

        <Col md={6}>  
            <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ marginTop: "5rem",marginLeft:"8rem" }}>{
                            [...Array(
                           
                              { id: "K1" },
                              { id: "K2" },
                              { id: "K3" },
                              { id: "K4" },
                              { id: "K5" },
                              { id: "K6" },
                              { id: "K7" },
                              { id: "K8" },
                              { id: "K9" },
                              { id: "K10" },
                              { id: "K11" },
                        
                            )].map((i) =><div>              <Box key={i} 
                            onClick= {()=>{  handleClickOpen(i)}}
                            style={{
                              backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                     

                            >      <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{i.id}</div></Box>
                             {/* <Error style={{color:"red" }}/> */}
            </div>
                            )}
                          
                    
                          </Row>

                          
                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "2rem",paddingLeft:"8rem" }}>{
                            [...Array(
                           
                              { id: "G1" },
                              { id: "G2" },
                              { id: "G3" },
                              { id: "G4" },
                              { id: "G5" },
                              { id: "G6" },
                              { id: "G7" },
                              { id: "G8" },
                              { id: "G9" },
                              { id: "G10" },
                              { id: "G11" },
                           
                        
                            )].map((i) => <Box  style={{
                              backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            onClick= {()=>{  handleClickOpen(i)}}

                            >   <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{i.id}</div></Box>)}
                          </Row>
                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop:"0.5rem" ,paddingLeft:"8rem" }}>{
                            [...Array(
                           
                              { id: "D1" },
                              { id: "D2" },
                              { id: "D3"},
                              { id: "D4" },
                              { id: "D5" },
                              { id: "D6" },
                              { id: "D7" },
                              { id: "D8" },
                              { id: "D9" },
                              { id: "D10" },
                              { id: "D11" },
                        
                            )].map((id) => <Box  style={{
                              backgroundColor: id.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            onClick= {()=>{  handleClickOpen(id)}}

                            >   <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{id.id}</div></Box>)}
                          </Row>

                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "2rem",paddingLeft:"8rem" }}>{
                            [...Array(
                           
                              { id: "A1" },
                              { id: "A2" },
                              { id: "A3" },
                              { id: "A4" },
                              { id: "A5" },
                              { id: "A6" },
                              { id: "A7" },
                              { id: "A8" },
                              { id: "A9" },
                              { id: "A10" },
                              { id: "A11" },
                           
                        
                            )].map((id) => <Box  style={{
                              backgroundColor: id.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            onClick= {()=>{  handleClickOpen(id)}}

                            >   <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{id.id}</div></Box>)}
                          </Row>
                          </Col>
                          <Col md={4} style={{paddingLeft:"2rem"}}> 
    
                     
                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "7.5rem"}}>{
                            [...Array(
                 
                              { id: "01" },
                            )].map((id) => <Box key={id} style={{
                              backgroundColor: id.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            onClick= {()=>{   handleClickOpen(id,props.mockData["0101"])
                            console.log(props.mockData["0101"]);
                              
                              }}

                            >  <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{id.id}</div></Box>)}
                          </Row>
                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop:"0.3rem"  }}>{
                            [...Array(
                           
                       
                              { id: "02" },
                           
                        
                            )].map((id) => <Box key={id} style={{
                              backgroundColor: id.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            
                            onClick= {()=>{  handleClickOpen(id,props.mockData["0102"])
                            console.log(props.mockData["0102"]);
                              
                              }}
                            >  <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{id.id}</div></Box>)}
                          </Row>
                          <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop:"0.3rem" }}>{
                            [...Array(
                           
                      
                              { id: "03" },
                   
                           
                        
                            )].map((id) => <Box key={id} style={{
                              backgroundColor: id.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "1.3rem", height: "1.3rem", marginLeft: "0.5rem"
                            }}
                            
                            onClick= {()=>{ console.log('props.mockData[0] :>> ', props.mockData);
                              handleClickOpen(id,props.mockData["0103"])
                              console.log(props.mockData["0103"]);
                              
                              }}
                            >  <div style={{fontSize:"0.7rem",color:"#424242", fontWeight:"bold"}}>{id.id}</div></Box>)}
                          </Row>

                        
                  
                          </Col>
                          </Row>
                          </Box>}


                         
                        
                          {/* <Box style={{ width:"55rem", height:"15rem"}}> */}

    
   



 </div>
   
{/* { props.showEconomy=='Business'&& 
     <div>
    <Box style={{backgroundColor:"grey", width:"40rem", height:"15rem"}}>
<div style={{marginLeft:"6rem"}}>  
<Row  sm={12} md={12} lg={5} xs={12} style={{ paddingTop:"1rem"}}>{
                [...Array(
               
                  { id: "K1" },
                  { id: "K2" },
                  { id: "K3", error: 1 },
                  { id: "K4" },
                  { id: "K5" },
                  { id: "K6" },
                  { id: "K7" },
                  { id: "K8" },
                  { id: "K9" },
                  { id: "K10" },
                  { id: "K11" },
            
                  )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                    backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                  }}
                  
  
                  >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
              
        
              </Row>

              
              <Row sm={12} md={12} lg={5} xs={12} style={{ marginTop: "2rem" }}>{
                [...Array(
               
                  { id: "G1" },
                  { id: "G2" },
                  { id: "G3", error: 1 },
                  { id: "G4" },
                  { id: "G5" },
                  { id: "G6" },
                  { id: "G7" },
                  { id: "G8" },
                  { id: "G9" },
                  { id: "G10" },
                  { id: "G11" },
               
            
                )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                  backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                }}
                
                onClick= {()=>{  handleClickOpen(i)}}
                >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
              </Row>
              <Row  sm={12} md={12} lg={5} xs={12} style={{ paddingTop:"0.5rem"  }}>{
                [...Array(
               
                  { id: "D1" },
                  { id: "D2" },
                  { id: "D3", error: 1 },
                  { id: "D4" },
                  { id: "D5" },
                  { id: "D6" },
                  { id: "D7" },
                  { id: "D8" },
                  { id: "D9" },
                  { id: "D10" },
                  { id: "D11" },
            
                  )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                    backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                  }}
                  
                  onClick= {()=>{  handleClickOpen(i)}}
                  >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
              </Row>

              <Row  sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "2rem" }}>{
                [...Array(
               
                  { id: "A1" },
                  { id: "A2" },
                  { id: "A3", error: 1 },
                  { id: "A4" },
                  { id: "A5" },
                  { id: "A6" },
                  { id: "A7" },
                  { id: "A8" },
                  { id: "A9" },
                  { id: "A10" },
                  { id: "A11" },
               
            
                  )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                    backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                  }}
                  
                  onClick= {()=>{  handleClickOpen(i)}}
                  >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
              </Row>
              </div>
    


   

 

 

  </Box>  </div>} */}




  { props.showEconomy=='Economy'&& 
   <Box style={{width:"57rem", height:"50rem"}}>
   <img  src={mainLogo1} style={{width:"55rem",height:"18rem" ,  position: "absolute"}} />

     <Container style={{paddingLeft:"7rem",paddingTop:"3rem"}}>
    <Row justify="center">
        <Col xxs={4} md= {4}>
        <Box style={{   display: 'flex', marginTop:"2rem", backgroundColor:"blue", width:"15rem", height:"7rem",  justifyContent: 'center',
    alignItems: 'center', textAlign:"center"}}><p> BC</p></Box>
        </Col>
        <Col md={4}>
        {/* <Row justify='start' sm={2} md={2} lg={2} xs={2} style={{marginLeft:"2rem", marginTop:"2.2rem"}}>{
                            [...Array(
                           
                              // { id: "G21" },
                              // { id: "G22" },
                              // { id: "G23" },
                              // { id: "G24" },
                              // { id: "G25" },
                              // { id: "G26" },
                              // { id: "G27" },
                              // { id: "G28" },
                              // { id: "0101" },
                              )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                                backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                              }}
                              onClick= {()=>{  handleClickOpen(i,props?.formattedDataArray[0])
                              console.log('formattedDataArray[0] :>> ', props?.formattedDataArray[0]);
                              
                              }}
              
                              >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
                          </Row> */}
                          {/* <Row justify='start' sm={2} md={2} lg={2} xs={2} style={{ paddingTop:"0.3rem", marginLeft:"2rem"  }}>{
                            [...Array(
                           
                              // { id: "D21" },
                              // { id: "D22" },
                              // { id: "D23"},
                              // { id: "D24" },
                              // { id: "D25" },
                              // { id: "D26" },
                              // { id: "D27" },
                              // { id: "D28" },
                              // { id: "0102" },
                           
                              )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                                backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                              }}
                              onClick= {()=>{
                                
                                console.log('formattedDataArray[1] :>> ', props?.formattedDataArray[1]);
                                handleClickOpen(i)}}
              
                              >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
                          </Row> */}
                          {/* <Row justify='start' sm={2} md={2} lg={2} xs={2} style={{ paddingTop:"0.3rem" , marginLeft:"2rem"}}>{
                            [...Array(
                           
                              // { id: "E21" },
                              // { id: "E22" },
                              // { id: "E23", error: 1 },
                              // { id: "E24" },
                              // { id: "E25" },
                              // { id: "E26" },
                              // { id: "E27" },
                              // { id: "E28" },
                              // { id: "0103" },
               
                              )].map((i) => <div><Badge badgeContent={ i.error == 1 ?<Error style={{color:"#AD4E4E",fontSize:"1.3rem", fontColor:"white"}}/>:null}><Box key={i} style={{
                                backgroundColor: i.error == 1 ? "#AD4E4E" : "#9e9e9e", width: "2rem", height: "2rem", marginLeft: "0.5rem"
                              }}
                              
                              onClick= {()=>{ 
                               
                                handleClickOpen(i,props.mockData[2])}}
                              >    <div style={{fontSize:"1.1rem", color:"#424242",fontWeight:"bold"}}>{i.id}</div> </Box></Badge></div>)}
                          </Row> */}
        </Col>
      </Row>

      </Container>
      </Box>
      
      }








      </div>
  );
};

// const Seat1 = ({ seat, rowIndex, seatIndex, height, width, fill, onClick ,fill1,matches},props) => {
//   // console.log(matches)
//   // console.log(props.showEconomy)
//   const initialX = ( matches)?250:250;
//   const initialY = 28;
//   const xOffset = seatIndex * 30;
//   const yOffset = rowIndex * 13;
//   return !seat.id ? null : (
//     <>
//       <svg
      
//         x={initialX + xOffset}
//         y={initialY + yOffset}
//         height={30}
//         width={45}
//         onClick={onClick}
//         style={{ cursor: "pointer", position:"absolute" }}
//       >
//        {/* <MdEventSeat className={`seat-icon-${fill}`}size={14} y={"0.4em"} x={"0.2em"}  style={{ transform:'rotate(90deg) translate(20% ,-65%)'}} /> */}
//         <rect x={0} y={0} width={24} height={24} stroke={fill1} fill="grey"  >
 
//  </rect>
 

//         <text
//         fill={fill1}
//           x={0}
//           y={8}
//           textAnchor="start"
//           style={{ fontSize: "1.1rem", fontWeight: "bold" }}

//         >
//           {seat.id}
//         </text>
//       </svg>

//       {/* <rect x = '2' y = "5" width = "50" height = "100" fill = "none" stroke = "lightgreen" stroke-width = "2" transform = "rotate(90 90 90)"/> */}
      
//     </>
//   );
// };


// const Seat = ({ seat, rowIndex, seatIndex, height, width, fill, onClick ,fill1,matches}) => {
//   const initialX = ( matches)?250:200
//   const initialY = 50;
//   const xOffset = seatIndex * 30;
//   const yOffset = rowIndex * 15;
//   return !seat.id ? null : 
  
//   (
//     <>
//       <svg
      
//         x={initialX + xOffset}
//         y={initialY + yOffset}
//         height={200}
//         width={80}
//         onClick={onClick}
//         style={{ cursor: "pointer" }}
//       >
//        {/* <MdEventSeat className={`seat-icon-${fill}`}size={14} y={"0.4em"} x={"0.2em"}  style={{ transform:'rotate(90deg) translate(20% ,-65%)'}} /> */}
      
//         <rect x={0} y={0} width={24} height={24} stroke={fill1} fill="grey" >
 
//  </rect>
//         <text
//         fill={fill1}
//           x={12}
//           y={15}
//           textAnchor="middle"
//           style={{ fontSize: "1.1rem", fontWeight: "bold" }}

//         >
        
//           {seat.id}
//                </text>
//       </svg>

     
      
//     </>
//   );
// };

export default SeatsLOPA;
