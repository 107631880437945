import React, { useRef, useEffect, useState } from 'react';
import "echarts/lib/component/grid";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Inline,
  Tabs,
  Tab,
  ColorModeProvider,
  CardContent,
  CardTitle,
  Container,
  Col,
  Row,
} from "@airbus/components-react";
import axios from "axios";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { Marker,Popup } from 'mapbox-gl';
import { useTheme } from "@material-ui/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ReactEcharts from "echarts-for-react";
import { CargoBottomElements } from "../../components/Cargo/CargoBottomElements";
import { AircraftFilters } from "../../components/Aircraft/AircraftFilters";
import StopIcon from "@material-ui/icons/Stop";
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
  startOfDay,
  endOfDay,
} from "date-fns";
import _ from "lodash";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import './Aircraft.css'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "black",
    opacity: 0.7,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}
mapboxgl.accessToken = 'pk.eyJ1IjoiZHQtYWlyYnVzLWFwYWMiLCJhIjoiY2xtaDRmaTdlMGZwMzNkbzl5bDc3bnl6NiJ9.-n478X6tlZWY8IG2LEniuw';

export function Aircraft({ handleListItemClick }) {


  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(1.36428);
  const [lat, setLat] = useState(43.6161);
  const [zoom, setZoom] = useState(12);
 
  const [loading, setLoading] = useState(false);
  // const [open, setOpen] = useState(false)
  const [selectedCheck, setSelectedCheck] = useState(2);
  const [tabValue, setTabValue] = React.useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const [selectedFilterValue, setSelectedFilterValue] = useState("all");
  const [dateRange, setDateRange] = useState([
    new Date("2023-02-08"),
    new Date("2023-02-08"),
  ]);
  const [selectedChartsSelectionValue, setSelectedChartsSelectionValue] =
    useState("overview");
  const classes = useStyles();
  const [cargoData, setCargoData] = useState();



  {
    handleListItemClick(null, 5);
  }


  const handleChange = (event, newValue) => {
    setTimeout(() => {
      setTabValue(newValue);
    }, 1);
  };

  const handleSetSelectedChartsSelectionValue = (event, chartsSelection) => {
    setSelectedChartsSelectionValue(chartsSelection);
  };


  // call for cargo data for a particular date
  const getEndpoint = (createdOn) =>
 ` https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=v1/aircraft/navigation&topic_extension=/latitude&created_at=${createdOn}&topic_action=data`
  const getEndpoint2 = (createdOn) =>
  ` https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=v1/aircraft/navigation&topic_extension=/longitude&created_at=${createdOn}&topic_action=data`
  //movelater

  const onSubmit = async (values = { dateRange: [new Date(), new Date()] }) => {
    setLoading(true);
  
    try {
      const { dateRange } = values;
      setDateRange(dateRange);
      const [start, end] = dateRange;
      const [startYear, endYear] = [start, end].map((d) => d.getFullYear());
      const [startMonth, endMonth] = [start, end].map((d) => format(d, "MM"));
      const [startDay, endDay] = [start, end].map((d) => format(d, "dd"));
      const isSameMonth = startMonth === endMonth;
      const isSameDay = startDay === endDay;
      let createdOn = "";
      if (isSameMonth && isSameDay)
        createdOn = `${startYear}-${startMonth}-${startDay}`;
      else if (isSameMonth) createdOn = `${startYear}-${startMonth}`;
      else {
        const range =
          differenceInMonths(endOfMonth(end), startOfMonth(start)) + 1;
        createdOn = Array(range)
          .fill("")
          .map((_, index) => format(addMonths(start, index), "yyyy-MM"));
      }
      const apiData = [];
      
      console.log(createdOn);
      // for diff month
      if (Array.isArray(createdOn)) {
        for (const co of createdOn) {
          const { data: response } = await axios.get(getEndpoint(co));
          const { data: response1 } = await axios.get(getEndpoint2(co));

          // console.log(...response);
          // console.log(...response1);
          apiData.push(...response, ...(response1 || []));
        }
      } else {
        const { data: response } = await axios.get(getEndpoint(createdOn));
        const { data: response1 } = await axios.get(getEndpoint2(createdOn));

        // console.log("...response :>> ", ...response);
        // console.log("...response1 :>> ", ...response1);
        apiData.push(...response, ...(response1 || []));
      }
 
      const filteredData = apiData.filter((d) => {
        const date = new Date(d.created_at);
        return date >= startOfDay(start) && date <= endOfDay(end);
      });
      console.log(filteredData);
      // console.log(apiData);
    

      const filteredNoInvalidData = apiData.filter(item => item.payload !== "invalid");
      console.log("cleanData :>> ", filteredNoInvalidData);
      setCargoData(apiData?filteredNoInvalidData :[]);
     
      const latestLatitude = filteredNoInvalidData ?.filter(item => item.topic_extension === "/flight_env/position/latitude")
      .reduce((latest, item) => {
        const createdAt = new Date(item.created_at);
        if (!latest || createdAt > latest.createdAt) {
          return { payload: item.payload, createdAt };
        }
        return latest;
      }, null);


const latestLongitude = filteredNoInvalidData 
  ?.filter(item => item.topic_extension === "/flight_env/position/longitude")
  .reduce((latest, item) => {
    const createdAt = new Date(item.created_at);
    if (!latest || createdAt > latest.createdAt) {
      return { payload: item.payload, createdAt };
    }



    return latest;
  }, null);

  
console.log('latestLatitude  :>> ', latestLatitude?.payload );
console.log('latestLong  :>> ', latestLongitude?.payload );
setLng( latestLongitude.payload);
setLat(latestLatitude.payload);
    } finally {
      setLoading(false);
    }
  };
 
  //  console.log("cargoData :>> ", cargoData);

  const filters = [
    {
      name: "dateRange",
      // label: "Range",
      type: "daterange",
      initialValue: null,
      // initialValue:Array(2).fill(new Date('2023-02-08'))
    },
  
  
  ];



  useEffect(() => {
    // if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom,
      projection: {name: 'equirectangular'},
      attributionControl: false
    });
  
    // Add a marker
    const marker = new mapboxgl.Marker()
      .setLngLat([lng, lat])
      .addTo(map.current);
  // Get country information from coordinates and show in a popup
  axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`)
  .then(response => {
    const features = response.data.features;
    if (features.length > 0) {
      console.log('feature.place_type :>> ', features);
      const countryInfo = features.find(feature => feature.place_type.includes('place'));
      if (countryInfo) {
        const countryName = countryInfo.place_name;
        marker.setPopup(new mapboxgl.Popup().setHTML(`
        <style>
            .tooltip-text {
                color: black;
                font-weight: bold;
            }
        </style>
        <p class="tooltip-text">Airspace Explorer A350 was at ${countryName}</p>
    `));
          } else {
        console.log('No country information found for the given coordinates.');
      }
    } else {
      console.log('No results found for the given coordinates.');
    }
  })
  .catch(error => {
    console.error('An error occurred:', error);
  });

    map.current.on('move', () => {
      // setLng(map.current.getCenter().lng.toFixed(4));
      // setLat(map.current.getCenter().lat.toFixed(4));
      // setZoom(map.current.getZoom().toFixed(2));
    });
  },[lat,lng,loading,cargoData]);


  return (
    // <div style={{ color: '#f9f9f9', marginBottom: 20 }}>
    <div style={{ backgroundColor: "black" }}>
      <Backdrop
        open={loading}
        className={classes.backdrop}
        style={{ opacity: "0.8" }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        {/* {loading && <div>Loading</div>} */}
        {!loading && <div>{/* {data.map(item => (console.log(item)))} */}</div>}
      </div>

      <Grid container xs={12}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ColorModeProvider mode="dark">
            <Tabs
              floating={true}
              // className="bg-primaryblue-90"
              size="xlarge"
              onChange={(e, val) => {
                handleChange(null, val);
                if (val === 0) {
                  handleSetSelectedChartsSelectionValue(null, "overview");
                }
              }}
              aria-label="Tabs purpose label"
              value={tabValue}
              scrollable={true}
              //  variant="fullWidth"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <Tab {...a11yProps(0)}>Aircraft Location</Tab>
              
            </Tabs>
            {/* <Divider style={{color:"#D9D9D9", width: matches ?"100%":"100%"}} /> */}
          </ColorModeProvider>
        </Grid>
      </Grid>
      <Container style={{ maxWidth: "80%", paddingBottom: "0.8rem" }}>
        {/* <Row alignItems="end" justify="space-evenly"> */}
        <AircraftFilters
          filters={filters}
          onSubmit={onSubmit}
          tabValue={tabValue}

        />
      </Container>

   
      <TabPanel value={tabValue} index={0} style={{ color: "white" }}>

        <Container>
          <Row justify="start" alignItems="center">
            <Col
              xs={12}
              xxs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "0.2rem" }}
            >
              {
                <Box
                  style={{
                    borderRadius: "0.8rem",
                    backgroundColor: "#1C2747",
                    width: matches ? "54rem" : "54rem",
                    height: matches ? "30rem" : "30rem",
                  }}
                >
                  <CardContent
                    style={{
                      color: "white",
                      width: matches ? "55rem" : "53rem",
                      height: matches ? "26rem" : "26rem",
                    }}
                  >
                    <CardTitle
                      style={{ fontSize: matches ? "1.5rem" : "1rem" }}
                    >
                      <Row justify="start" alignItems="center">
                        <Col xs={3} sm={2} md={7} lg={6}>
                          <div
                            style={{ fontSize: "1.2rem"}}
                          >
                            {" "}
                            {"Aircraft Location"}
                          </div>
                        </Col>

                        <Col>
                      
                        </Col>
                      </Row>
                    </CardTitle>
                    <br></br>
                 
                        {selectedCheck == 2 && (
                        
                        
                            <div>
      <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div>
      <div  ref={mapContainer} className="map-container" />
    </div>
                      
                        )}
                

                    <br></br>
                  </CardContent>
                </Box>
              }
            </Col>
          </Row>
        </Container>
      </TabPanel>
     
    </div>
  );
}
