export const bc1  = [


    { seat_number: "1K" },
    { seat_number: "2K" },
    { seat_number: "3K", error: 1,  },
    { seat_number: "4K" },
    { seat_number: "5K" },
    { seat_number: "6K" },
    { seat_number: "7K" },
    { seat_number: "8K" },
    { seat_number: "9K" },
    { seat_number: "10K" },
    { seat_number: "11K" },
  
   ]
  
   export const bc2 = [
  
    { seat_number: "1G" },
    { seat_number: "2G" },
    { seat_number: "3G", error: 1 },
    { seat_number: "4G" },
    { seat_number: "5G" },
    { seat_number: "6G" },
    { seat_number: "7G" },
    { seat_number: "8G" },
    { seat_number: "9G" },
    { seat_number: "10G" },
    { seat_number: "11G" },
  
   ]
  
   export const bc3 =[
  
    { seat_number: "1D" },
    { seat_number: "2D" },
    { seat_number: "3D", error: 1 },
    { seat_number: "4D" },
    { seat_number: "5D" },
    { seat_number: "6D" },
    { seat_number: "7D" },
    { seat_number: "8D", error: 1, food: "Chicken Noodles", type: "Special Meal" },
    { seat_number: "9D" },
    { seat_number: "10D" },
    { seat_number: "11D" },
  
  
   ]
  
   export const bc4 =      [
    { seat_number: "1A", error: 1, food: "Salad A", type: "Pre-Ordered" },
    { seat_number: "2A" },
    { seat_number: "3A", error: 1 },
    { seat_number: "4A", error: 1 },
    { seat_number: "5A" },
    { seat_number: "6A" },
    { seat_number: "7A", error: 1 },
    { seat_number: "8A" },
    { seat_number: "9A" },
    { seat_number: "10A" },
    { seat_number: "11A", error: 1 },
  
  
   ]
  
   export const ec1=      [
    { seat_number: "21K" },
    { seat_number: "22K" },
    { seat_number: "23K" },
    { seat_number: "24K" },
    { seat_number: "25K" },
    { seat_number: "26K" },
  
  
   ]
  export const ec2=  [
  
  
    { seat_number: "21J" },
    { seat_number: "22J" },
    { seat_number: "23J" },
    { seat_number: "24J" },
    { seat_number: "25J" },
    { seat_number: "26J" },
  
  
  
   ]
  
   export const ec3=  [
  
  
    { seat_number: "21H" },
    { seat_number: "22H" },
    { seat_number: "23H" },
    { seat_number: "24H" },
    { seat_number: "25H" },
    { seat_number: "26H" },
  
  
  
   ]
  
   export const ec4 = [
  
  
    { seat_number: "21G" },
    { seat_number: "22G" },
    { seat_number: "23G", error: 1 },
    { seat_number: "24G" },
    { seat_number: "25G" },
    { seat_number: "26G" },
    { seat_number: "27G" },
    { seat_number: "28G" },
    { seat_number: "29G" },
  
  
  
   ]
  
   export const ec5 =       [
  
    { seat_number: "21E" },
    { seat_number: "22E" },
    { seat_number: "23E" },
    { seat_number: "24E" },
    { seat_number: "25E" },
    { seat_number: "26E" },
    { seat_number: "27E" },
    { seat_number: "28E" },
    { seat_number: "29E" },
  
  
  
   ]
  
   export const  ec6=  [
  
    { seat_number: "21D" },
    { seat_number: "22D" },
    { seat_number: "23D" },
    { seat_number: "24D" },
    { seat_number: "25D" },
    { seat_number: "26D" },
    { seat_number: "27D" },
    { seat_number: "28D" },
    { seat_number: "29D" },
  
  
  
   ]
  
   export const ec7= [
    { seat_number: "21C" },
    { seat_number: "22C" },
    { seat_number: "23C", error: 1 },
    { seat_number: "24C" },
    { seat_number: "25C" },
    { seat_number: "26C" },
  
  
   ]
   export const ec8= [
    { seat_number: "21B" },
    { seat_number: "22B" },
    { seat_number: "23B", error: 1 },
    { seat_number: "24B" },
    { seat_number: "25B" },
    { seat_number: "26B" },
  
  
   ]
  
   export const ec9 = [
  
    { seat_number: "21A" },
    { seat_number: "22A" },
    { seat_number: "23A", error: 1 },
    { seat_number: "24A" },
    { seat_number: "25A" },
    { seat_number: "26A" },
  
  
   ]

   export const allSeats =[
    [


      { seat_number: "1K" },
      { seat_number: "2K" },
      { seat_number: "3K", error: 1,  },
      { seat_number: "4K" },
      { seat_number: "5K" },
      { seat_number: "6K" },
      { seat_number: "7K" },
      { seat_number: "8K" },
      { seat_number: "9K" },
      { seat_number: "10K" },
      { seat_number: "11K" },
    
     ],
     [
  
      { seat_number: "1G" },
      { seat_number: "2G" },
      { seat_number: "3G", error: 1 },
      { seat_number: "4G" },
      { seat_number: "5G" },
      { seat_number: "6G" },
      { seat_number: "7G" },
      { seat_number: "8G" },
      { seat_number: "9G" },
      { seat_number: "10G" },
      { seat_number: "11G" },
    
     ],
     [
  
      { seat_number: "1D" },
      { seat_number: "2D" },
      { seat_number: "3D", error: 1 },
      { seat_number: "4D" },
      { seat_number: "5D" },
      { seat_number: "6D" },
      { seat_number: "7D" },
      { seat_number: "8D", error: 1, food: "Chicken Noodles", type: "Special Meal" },
      { seat_number: "9D" },
      { seat_number: "10D" },
      { seat_number: "11D" },
    
    
     ],
     [
      { seat_number: "1A" },
      { seat_number: "2A" },
      { seat_number: "3A", error: 1 },
      { seat_number: "4A", error: 1 },
      { seat_number: "5A" },
      { seat_number: "6A" },
      { seat_number: "7A", error: 1 },
      { seat_number: "8A" },
      { seat_number: "9A" },
      { seat_number: "10A" },
      { seat_number: "11A", error: 1 },
    
    
     ],


      [
      { seat_number: "21K" },
      { seat_number: "22K" },
      { seat_number: "23K" },
      { seat_number: "24K" },
      { seat_number: "25K" },
      { seat_number: "26K" },
    
    
     ],
  [
    
    
      { seat_number: "21J" },
      { seat_number: "22J" },
      { seat_number: "23J" },
      { seat_number: "24J" },
      { seat_number: "25J" },
      { seat_number: "26J" },
    
    
    
     ],
    
     [
    
    
      { seat_number: "21H" },
      { seat_number: "22H" },
      { seat_number: "23H" },
      { seat_number: "24H" },
      { seat_number: "25H" },
      { seat_number: "26H" },
    
    
    
     ],
    
     [
    
    
      { seat_number: "21G" },
      { seat_number: "22G" },
      { seat_number: "23G", error: 1 },
      { seat_number: "24G" },
      { seat_number: "25G" },
      { seat_number: "26G" },
      { seat_number: "27G" },
      { seat_number: "28G" },
      { seat_number: "29G" },
    
    
    
     ],
    
     [
    
      { seat_number: "21E" },
      { seat_number: "22E" },
      { seat_number: "23E" },
      { seat_number: "24E" },
      { seat_number: "25E" },
      { seat_number: "26E" },
      { seat_number: "27E" },
      { seat_number: "28E" },
      { seat_number: "29E" },
    
    
    
     ],
    
    [
    
      { seat_number: "21D" },
      { seat_number: "22D" },
      { seat_number: "23D" },
      { seat_number: "24D" },
      { seat_number: "25D" },
      { seat_number: "26D" },
      { seat_number: "27D" },
      { seat_number: "28D" },
      { seat_number: "29D" },
    
    
    
     ],
    
   [
      { seat_number: "21C" },
      { seat_number: "22C" },
      { seat_number: "23C", error: 1 },
      { seat_number: "24C" },
      { seat_number: "25C" },
      { seat_number: "26C" },
    
    
     ],
     [
      { seat_number: "21B" },
      { seat_number: "22B" },
      { seat_number: "23B", error: 1 },
      { seat_number: "24B" },
      { seat_number: "25B" },
      { seat_number: "26B" },
    
    
     ],
    
   [
    
      { seat_number: "21A" },
      { seat_number: "22A" },
      { seat_number: "23A", error: 1 },
      { seat_number: "24A" },
      { seat_number: "25A" },
      { seat_number: "26A" },
    
    
     ]
     

   ]