import {
    addMonths,
    differenceInMonths,
    endOfMonth,
    format,
    startOfMonth,
  } from "date-fns";
  import React, { useEffect, useState } from 'react'
  import axios from 'axios'
  import Box from '@material-ui/core/Box';
  import { Select, Inline, Tabs, Tab, ColorModeProvider, Card, Container, Col, Row} from "@airbus/components-react"
  import MaxWidthDialog1 from './MaxWidthDialog1';
  import { TopFilters } from "../../components/Catering/TopFilters"
  import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
  import useMediaQuery from '@material-ui/core/useMediaQuery'
  import { useTheme, Backdrop,CircularProgress  } from '@material-ui/core';
import mainLogo from'../../images/cabin-bg.png';
import { allSeats } from "../../components/Catering/CateringLOPA"
import { mockFlightFoodInfo, mockFlightPaxInfo, mockCateringPayload, fetchData } from '../../components/Catering/CateringData';
import { makeStyles } from '@material-ui/core/styles';

const seatsStatic = allSeats
const data = fetchData()
const merged = seatsStatic.map((subArr) => {
    return subArr.map((obj1) => {
      const obj2 = data?.find((obj2) => obj1.seat_number === obj2.seatName);
      return obj2 ? { ...obj1, ...obj2 } : obj1;
    });
  });
export const CateringZone = ({ totalCost,totalFootPrint,totalUnits}) => {
  const classes = useStyles();
  const useStyles = makeStyles((theme) => ({
  
  
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'grey',
      opacity: 0.7
    },
  
  
  }));
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('xl'));
    const [cateringData, setCateringData] = useState();
    const [loading, setLoading] = useState(false);

    const filters = [
        {
          name: "flightNo",
          label: "Flight No.",
          type: "dropdown",
          initialValue: "AIB8888",
          options: [
            { label: "AIB8888", value: "AIB8888" },
            { label: "MU5100", value: "MU5100" },
            { label: "MU5101", value: "MU5101" },
          ],
         
          onChange: (handleChange) => {
            console.log('changing')
            handleChange({ target: { name: 'origin', value: null } })
            handleChange({ target: { name: 'destination', value: null } })
    
          }
        },
        {
          name: "dateRange",
          // label: "Range",
          type: 'date',
          initialValue: new Date('2023-03-02')
        },
        {
          name: "origin",
          label: "Origin",
          type: "dropdown",
          options: (values) =>
            [
              { code: "AIB8888", label: "WBGG", value: "WBGG" },
           
              { code: "MU5100", label: "ZGSZ", value: "ZGSZ" },
              { code: "MU5101", label: "ZBAA", value: "ZBAA" },
            ].filter((item) => item.code === values.flightNo),
          onChange: (handleChange) => {
            console.log('changing')
            handleChange({ target: { name: 'destination', value: null } })
          }
        },
    
        {
          name: "destination",
          label: "Destination",
          type: "dropdown",
          options: (values1) =>
            [
    
              { code: "WBGG", label: "WMKK", value: "WMKK" },
              { code: "ZGSZ", label: "ZBAA", value: "ZBAA" },
              { code: "ZBAA", label: "ZGSZ", value: "ZGSZ" },
            ].filter((item) => item.code === values1.origin),
    
        },
    
    
    
    
      ];
      const getEndpoint = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/acic/foodscanner/report/fooddistributionrecord&created_at=${createdOn}`

    
      const onSubmit = async (values = { dateRange: [new Date(), new Date()] }) => {
        setLoading(true)

       
        try {
          const { dateRange } = values;
          const [start, end] = dateRange;
          const [startYear, endYear] = [start, end].map((d) => d.getFullYear());
          const [startMonth, endMonth] = [start, end].map((d) => format(d, "MM"));
          const [startDay, endDay] = [start, end].map((d) => format(d, "dd"));
          const isSameMonth = startMonth === endMonth;
          const isSameDay = startDay === endDay;
          let createdOn = "";
          if (isSameMonth && isSameDay)
            createdOn = `${startYear}-${startMonth}-${startDay}`;
          else if (isSameMonth) createdOn = `${startYear}-${startMonth}`;
          else {
            const range =
              differenceInMonths(endOfMonth(end), startOfMonth(start)) + 1;
            createdOn = Array(range)
              .fill("")
              .map((_, index) => format(addMonths(start, index), "yyyy-MM"));
          }
          const apiData = [];
    
          if (Array.isArray(createdOn)) {
            for (const co of createdOn) {
              const { data: response } = await axios.get(getEndpoint(co));
      
              apiData.push(...response || []);
              
            }
            // const realData = values.filter(
            //   (v) => v.createdOn >= start && v.createdOn <= end
            // );
            //setNestedObjectValues(realData)
          }
          else {
            const { data: response } = await axios.get(getEndpoint(createdOn));
      
            apiData.push(...response || []);
          }
          const cleanData = apiData.map(d => {
            let payload;
            try {
              payload = JSON.parse(d.payload?.replace(/'/g, '"'))
            }
            catch (e) { }
            return { ...d, payload }
          }).filter(c => {
            const { payload } = c;
            if (!payload) return false;
            const { flightNo } = payload;
            const flightNoFilter = values.flightNo ? flightNo === values.flightNo : flightNo?.toLowerCase() !== 'invalid'
            return flightNoFilter
          })
          console.log('values :>> ', values);
          console.log('cleanData.length :>> ', cleanData.length);
          setCateringData(cleanData[cleanData.length - 1]);
          console.log('catering :>> ', cateringData);
    
          //make proper structure to manage response(s) if multiple endpoints are used
          // make filter by flight route if ori/dest manually populated
        }
    
       
        finally {
    
    
          setLoading(false)
        }
      
    
    
    
      };
    



    const [openDialogx, setOpenDialogx] = React.useState(null)

    const setOpenDialog = ({ xvalue: xvalue, food: food, type: type, collectedTime: collectedTime, distributedTime: distributedTime }) => {

        setOpenDialogx({ xvalue: xvalue, food: food, type: type, collectedTime: collectedTime, distributedTime: distributedTime })
      }
    return<>  
        <Backdrop open={loading} className={classes.backdrop} style={{opacity:"0.8"}}>
  <CircularProgress color="inherit" /></Backdrop>
    {openDialogx &&

        <MaxWidthDialog1 open={openDialogx} onClose={() => setOpenDialogx(null)} />
      }

<TopFilters filters={filters} onSubmit={onSubmit} />
<Inline wrap="true" style={{ paddingBottom: "0.2rem" }} spacing='1-x'>
        <Card style={{ minWidth: "10rem", width: "auto", height: "3rem", backgroundColor: "#1C2747", color: "white", padding: "0.7rem" }}><div style={{ fontSize: matches ? '1.5rem' : "1rem", textAlign: 'center' }}>MEALS: <b>{totalUnits ? totalUnits: "-"} Units</b>
        </div> </Card>
        <Card style={{ minWidth: matches ? "20rem" : "10rem", width: "auto", height: "3rem", color: "white", backgroundColor: "#1C2747", padding: "0.7rem" }}>
          <div style={{ fontSize: matches ? '1.5rem' : "1rem", textAlign: 'center' }}> COST: <b> $ {totalCost? totalCost.toFixed(2): "-"} </b></div></Card>
        <Card style={{ width: "auto", minWidth: matches ? "20rem" : "10rem", height: "3rem", backgroundColor: "#1C2747", color: "white", padding: "0.7rem" }}><div style={{ fontSize: matches ? '1.5rem' : "1rem", textAlign: 'center' }}> FOOTPRINT: <b>{totalFootPrint? totalFootPrint *1000 : "-"} Kg/CO2</b></div></Card>
      
      </Inline>
      <TransformWrapper>
        <TransformComponent>

        <Col xs={12} xxs={12} sm={12} md={12} lg={12} style={{ paddingTop: "1rem" }} >
              {
                <Box style={{ backgroundColor: "#1C2747", width: matches ? "55rem" : "55rem", height: matches ? "25rem" : "25rem" }}>
                  <Container ><Row justify="center" style={{ paddingLeft: "10rem" }}><Col md={6} style={{ paddingTop: "1rem" }} > {"BC"}</Col><Col md={6} style={{ paddingTop: "1rem" }}> {"EC"}</Col></Row></Container>
                  <img src={mainLogo} style={{ width: "55rem", height: "20rem", position: "absolute" }} />  <Container ><Row>
                    <Col md={5}>

                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ marginTop: "6rem", paddingLeft: "6rem" }}>{
                        merged[0].map((i) =>

                          <Box key={i}

                          onClick={() => {
                            console.log(i.seat_number)
                            setOpenDialog({  collectedTime: i.preorder.collectedTime , distributedTime: i.preorder.distributedTime,xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                         }}
                         style={{
                           backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                         }}

                          ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)


                      }


                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "2rem", paddingLeft: "6rem" }}>{
                        merged[1].map((i) =>

                        <Box key={i}

                          onClick={() => {
                             console.log(i.seat_number)
                             setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                          }}
                          style={{
                            backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                          }}


                        ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)


                    }


                 
                      </Row>
                      
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.4rem", paddingLeft: "6rem" }}>{
                        merged[2].map((i) => <Box key={i}
                        onClick={() => {
                          console.log(i.seat_number)
                          setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                       }}
                       style={{
                         backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                       }}


                        ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>

                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "2rem", paddingLeft: "6rem" }}>{
                        merged[3].map((i) => <Box key={i}
                        onClick={() => {
                          console.log('i :>> ', i);
                          console.log(i.seat_number)
                          setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                       }}
                       style={{
                         backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                       }}


                        ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>
                    </Col>

                    <Col md={6}>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ marginTop: "5rem", paddingLeft: "6rem" }}>{
                    
                        merged[4].map((i) => <Box key={i}
                        onClick={() => {
                          console.log('i :>> ', i);
                          console.log(i.seat_number)
                          setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                       }}
                       style={{
                         backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                       }}


                        ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}


                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.5rem", paddingLeft: "6rem" }}>{
                        merged[5].map((i) => <Box key={i}
                        onClick={() => {
                          console.log(i.seat_number)
                          setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                       }}
                       style={{
                         backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                       }}


                        ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.5rem", paddingLeft: "6rem" }}>{
                       merged[6].map((i) => <Box key={i}
                       onClick={() => {
                        console.log(i.seat_number)
                        setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                     }}
                     style={{
                       backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                     }}


                        ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.7rem", paddingLeft: "6rem" }}>{
                        merged[7].map((i) => <Box key={i}
                        onClick={() => {
                          console.log(i.seat_number)
                          setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                       }}
                       style={{
                         backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                       }}


                        ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.4rem", paddingLeft: "6rem" }}>{
                       merged[8].map((i) => <Box key={i}
                       onClick={() => {
                        console.log(i.seat_number)
                        setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                     }}
                     style={{
                       backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                     }}


                        ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>


                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.4rem", paddingLeft: "6rem" }}>{
                        merged[9].map((i) => <Box key={i}
                        onClick={() => {
                          console.log(i.seat_number)
                          setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                       }}
                       style={{
                         backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                       }}
  

                        ><div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.7rem", paddingLeft: "6rem" }}>{
                        merged[10].map((i) => <div><Box key={i}
                        onClick={() => {
                          console.log(i.seat_number)
                          setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                       }}
                       style={{
                         backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                       }}
  


                        >    <div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box></div>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.4rem", paddingLeft: "6rem" }}>{
                        merged[11].map((i) => <div><Box key={i}
                        onClick={() => {
                          console.log(i.seat_number)
                          setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                       }}
                       style={{
                        backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                       }}
  


                        >    <div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box></div>)}
                      </Row>
                      <Row justify='start' sm={12} md={12} lg={5} xs={12} style={{ paddingTop: "0.4rem", paddingLeft: "6rem" }}>{
                        merged[12].map((i) => <div><Box key={i}
                        onClick={() => {
                          console.log(i.seat_number)
                          setOpenDialog({ xvalue: i.seat_number, food: i.foodOrder? i.foodOrder.description: "-" , type: i.preorder ? i.preorder.orderType :'-'})
                       }}
                       style={{
                         backgroundColor: i.preorder  ? "#2e7d32"  : "#9e9e9e", width: "1rem", height: "1rem", marginLeft: "0.5rem"
                       }}
  


                        >    <div style={{ fontSize: "0.55rem" }}>{i.seat_number}</div> </Box></div>)}
                      </Row>
                    </Col>
                  </Row>
                  </Container>
                </Box>
              }
            </Col>
        </TransformComponent>
      </TransformWrapper></>
}