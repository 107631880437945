import { React, useState,useEffect } from 'react'
// import { SparkLine } from '../../components/Gains/SparkLine'
// import { SparkLine2 } from '../../components/Gains/SparkLine2'
import Box from '@material-ui/core/Box';
import { Grid, Typography } from '@material-ui/core';
// import Widget from '../../components/Widget'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { withAuthenticator } from '@aws-amplify/ui-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Inline,
  Stack,
  Toast,
  Card, CardActions, CardContent, CardHeader, CardMedia, CardActionArea, CardTitle, IconButton, Container, Col, Content, Row, ColorModeProvider
} from "@airbus/components-react";
import { Badge,Divider } from "@airbus/components-react"
import { RangeSlider } from "@airbus/components-react"
import { Chip } from "@airbus/components-react"
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import AirlineSeatReclineNormalIcon from '@material-ui/icons/AirlineSeatReclineNormal';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import KitchenIcon from '@material-ui/icons/Kitchen';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { Rating } from "@airbus/components-react"
import { Link } from 'react-router-dom';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import {AirplaneModeActive,Wifi,Error,CheckCircle,LocalCafe,AirlineSeatReclineNormal,AllInbox,Notification, AirplanemodeActive,Chevronleft, Chevronright, SettingsCellOutline} from '@airbus/icons/react';
import { ProgressBar } from "@airbus/components-react"
import { Visibility,Delete,Clear,Check, Chevronup,Chevrondown } from '@airbus/icons/react';
import {cateringMemo } from '../../components/Catering/CateringData'
import {galleyMemo } from '../../components/Gains/GainsData'
import LinearProgress from '@material-ui/core/LinearProgress';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import { EventSeat } from '@material-ui/icons';
import bcSeatIcon from '../../images/Seats/BC_Seat.png';
import ecSeatIcon from '../../images/Seats/EC_Seat.png';
import { mockecSeatsData } from '../../components/Seats/SeatsData';
import { mockbcSeatsData } from '../../components/Seats/SeatsData';
import { mockMainCargoData } from '../../components/Cargo/CargoData';
import { mockLowerCargoData } from '../../components/Cargo/CargoData';
import { Amplify } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css"
import awsExports from "../../aws-export";
import axios from 'axios';
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
  startOfDay,
  endOfDay
} from "date-fns";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    width:"5rem",
    height: 10,
    borderRadius: 12,
    fontColor: "white",
    color:"white",
   marginTop:"0.2rem",
   paddingLeft:"5rem"
  },
  colorPrimary: {
    backgroundColor: "grey",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: 'white',
  },

  // width: "5rem", fontColor: "white", paddingLeft:"1rem" ,paddingTop:"0.5rem", color:"white"


}))(LinearProgress);


const useStyles = makeStyles((theme) => ({
  widgetContainer: {
    marginTop: 50,
    display: "static",
    justifyContent: "space-between",
    gap: 10,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: "center",
      alignItems: 'flex-end',
      gap: 50,
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      gap: 50,
    }
  },
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    }

  }
}))



const Home =({ handlelistItemClick}) =>{
  const [showDialog, setShowDialog] = useState(false);
  const handleCloseDialog = () => setShowDialog(false);
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:960px)');
  const [bcMeal, setBCMeal] = useState(65)
  const [ecMeal, setECMeal] = useState(98)
  const [toastStatus, setToastStatus] = useState(false)
const [loading, setLoading] = useState(false);
const [cateringData, setCateringData] = useState([]);
const [bcTaken, setBCTaken] = useState(0);
const [ecTaken, setECTaken] = useState(0);
const [totalUnits, setTotalUnits] = useState(0);
const[totalHours, setTotalHours] = useState(0);
const[totalWeight, setTotalWeight] = useState(0);
const [freight, setFreight] = useState(0);
const [movement, setMovement] = useState(0);

  useEffect(() => {
    getFromAPI();
    handleDateRangeApi();
}, []);


  const getFromAPI = async (values = { dateRange: [new Date(), new Date()] }) => {

    // const getEndpoint = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/acic/foodscanner/realtimereport&created_at=${createdOn}`
    const getEndpoint = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/acic/foodscanner/realtimereport&created_at=2023-03-02`

    const filterByFlightNo= (item) => {
      if (!item.payload) return false;
      
      const filterFlightNo = values.flightNo ? values.flightNo : null;


      const { flightNo} = item.payload;
      return (
        (filterFlightNo ? flightNo === filterFlightNo : true) 
  
      );
    }

    setLoading(true)


      try {
           const apiData = [];
           const date = new Date();
         
           date.setMonth(date.getMonth() - 1); // set the month to the previous month
           const year = date.getFullYear();
           const month = date.getMonth()  // add 1 to get month number from 1-12
           const createdOnDate = `${year}-${month.toString().padStart(2, '0')}`;
           
           console.log(createdOnDate); // e.g. "2022-03"

        if (createdOnDate) {
       
            const { data: response } = await axios.get(getEndpoint( createdOnDate));
            console.log("Aa")
            apiData.push(...response || []);

          }
        
        else {
          const { data: response } = await axios.get(getEndpoint( createdOnDate));
          console.log("bb")
          apiData.push(...response || []);
        }
  
        const cleanData = apiData.map(d => {
          let payload;
          try {
            payload = JSON.parse(d.payload?.replace(/'/g, '"'))
          }
          catch (e) { }
          return { ...d, payload }
        }).filter(filterByFlightNo);
        
        console.log("cleandaty", cleanData)
        console.log('values :>> ', values);
        console.log('cleanData.length :>> ', cleanData.length);
        console.log('cleanData[cleanData.length - 1] :>> ', cleanData[cleanData.length - 1]);
        setCateringData(cleanData[cleanData.length - 1]);

        console.log('cateringData :>> ', cateringData);
        const bcTaken = cleanData[cleanData.length - 1]?cleanData[cleanData.length - 1].payload.report?.mealsCensus.bcServed : 0
        setBCTaken(bcTaken)
        const ecTaken = cleanData[cleanData.length - 1]? cleanData[cleanData.length - 1].payload.report.mealsCensus.ecServed : 0
        setECTaken(ecTaken)
        const totalUnits = cleanData[cleanData.length - 1] ? cleanData[cleanData.length - 1].payload.report.mealsCensus.totalUnits : 0
        setTotalUnits(totalUnits)
        const totalHours = cleanData[cleanData.length - 1] ? cleanData[cleanData.length - 1].payload.report.serviceCensus.totalHours : 0
        setTotalHours(totalHours)
        const totalWeight = cleanData[cleanData.length - 1] ? cleanData[cleanData.length - 1].payload.report.wastedCensus.weight : 0
        setTotalWeight(totalWeight)



      }


      finally {

        console.log('cateringathoome :>> ', cateringData);
        setLoading(false)
        return 1
      }
    }

    const handleDateRangeApi = async ( ) => {
      // const getEndpoint2 = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=v1/crew/cargo&topic_extension=/PDU&payload=1&created_at=${createdOn}`
      const getEndpoint2 = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=v1/crew/cargo&topic_extension=/PDU&payload=1&created_at=2023-06-19`

      try{
  
      // setLoading(true);
       
 
      
        const date = new Date();
         
        date.setMonth(date.getMonth() - 2); // set the month to the previous month
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // add 1 to get month number from 1-12
        const createdOnDate = `${year}-${month.toString().padStart(2, '0')}`;
        
        console.log(createdOnDate); // e.g. "2022-03"
        const apiData = [];
     if (createdOnDate) {
        //fetching the data
  
       
            const { data: response } = await axios.get(getEndpoint2(createdOnDate));
            // console.log(...response)
            apiData.push(...response || []);
  
        }
        else {
     
          const { data: response } = await axios.get(getEndpoint2(createdOnDate));
          apiData.push(...response || []);
        }
      ;
        
        // Count logic for Cargo Card
      function filterAndCount(data, filterString) {
        const filteredData = data.filter(x => x.topic_extension.includes(filterString));
        const uniqueFilteredData = filteredData.filter((v, i, a) => a.findIndex(v2 => v2.topic_extension === v.topic_extension) === i);
        return uniqueFilteredData.length;
    }

        if (apiData) {
          const pduOG = filterAndCount(apiData, "/long_trans/1/PDU_covered");
          const pduUOG = filterAndCount(apiData, "/long_trans/1/PDU_uncovered");
          // const pduHOG = filterAndCount(apiData, "PDU_Hold"); // You can uncomment this if needed
      
          const cargoLane1Final = filterAndCount(apiData, "1405/proxy/0/long_trans/1/PDU_covered");
          const cargoLane2Final = filterAndCount(apiData, "1505/proxy/0/long_trans/1/PDU_covered");
          const finalNoOfULD = cargoLane1Final + cargoLane2Final || 0;
          setFreight(finalNoOfULD);
      
          const pduAftOrIn = filterAndCount(apiData, "/long_trans/1/PDU_Aft_or_In");
          const pduFwdOrOut = filterAndCount(apiData, "/long_trans/1/PDU_Forward_or_Out");
          const combinedMovement = pduAftOrIn + pduFwdOrOut || 0;
          setMovement(combinedMovement);
      
          setLoading(false);
      }

      
        return apiData
      }
      catch(e){
        console.error(e)
        setLoading(false)
        return 1
      }
      finally {
        setLoading(false)
        return 1
      }
      
    }
  



  const connectError = galleyMemo.some(item => item.connectivitychip ==0);
  console.log(connectError)
  const equipmentError = galleyMemo.some(item => item.galleychip ==0);
  console.log(equipmentError)

 const recMessage =  galleyMemo.map((i)=>{
return i.galleyMessage


 }

 )
console.log(recMessage[0])


const cateringInfo =  cateringMemo.map((i)=>{
  return i
  
  
   }
  
   )
   const cateringObject = cateringInfo[0]


   const testSeat = mockbcSeatsData.some(item => item.seatError ==1);
   const testSeat1 = mockecSeatsData.some(item => item.seatError ==0);

   const seatMessage =  mockbcSeatsData.map((i)=>{
    return i.seatsMessage
    
    
     }
    
     )
    console.log(seatMessage[0])
   console.log(testSeat)



   const testCargo = mockMainCargoData.some(item => item.cargoError ==1);
   const testCargo1 = mockLowerCargoData.some(item => item.cargoError ==1);

   const cargoMessage =  mockMainCargoData.map((i)=>{
    return i.cargoMessage
    
    
     }
    
     )
     const cargoMessage1 =  mockLowerCargoData.map((i)=>{
      return i.cargoMessage
      
      
       }
      
       )

       const cargoMovement1 =  mockLowerCargoData.map((i)=>{
        return i.movement
        
        
         }
       
         )

         const cargoWeight =  mockMainCargoData.map((i)=>{
          return i.cargoWeight
          
          
           }
         
           )
           console.log(cargoWeight[0])
         console.log(cargoMovement1[0])
    console.log(cargoMessage[0])
   console.log(testCargo)
  return (
    <>
   
          <div style={({paddingTop:"1.5rem",height:"100rem"})}>
    
          <Container >
          <Row  alignItems= "start" justify= "center" >

          <Col md={6} xl={4} lg={4}>
          <Card style={{minWidth:"27rem", height:"20rem", backgroundColor:"#1C2747", fontColor:"white"}}>
          <CardActionArea style={{backgroundColor:"#1C2747"}} >
                  <Link to="/catering" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                {/* <CardHeader
                  icon={<KitchenIcon />}
                  title="Connected Catering"
                  action={
                    <IconButton variant="ghost">
                      <MoreHorizIcon />
                    </IconButton>
                  }
                /> */}
             
              <CardContent>
              <CardTitle style={{color:"white"}}>
                      <Container> 
                  
                
                  
                   <Row> <Col md={11}> <div style={{fontWeight:"bold"}}>{"Smart Trolley"}</div> </Col><Col md={1}><CheckCircle style={{color:"green"}}/></Col></Row> 
                  
            
                    
                     
                
                      </Container></CardTitle>
              {/* <RangeSlider defaultValue={[25]} />d
                  Food Rating  <Rating defaultValue={2.5} /> */}
               
          
               <br></br>
                      <div style={{ fontSize: matches ? "1.5rem" : "1.2rem", marginTop: "1rem", color:"white" }}>
                        <Container>

                          <Row>
                            <Col md={2}><div>{"BC"}</div></Col>   
                             <Col md={4}><div> {console.log(bcTaken)}{bcTaken * totalUnits} </div></Col>
                            
                     <Col md={2}>
                            <div >{bcTaken *100}%</div>
                            
                            </Col>
                            <Col md={2}>
                               <BorderLinearProgress variant="determinate"  value={bcTaken*100} /> 

                               </Col>
                          
                 
                      </Row>
                    <Row> <Divider style={{ width: matches ? "20rem" : "22rem" }} /></Row> 
                <Row>
                        
                 <Col md={2}><div>{"EC"}</div></Col>    
                 <Col md={4}><div> {(ecTaken * totalUnits).toPrecision(2)} </div></Col>
                 <Col md={2}> 
                      
                        <div >{ecTaken*100}%</div>
                        </Col>
                        <Col md={2}>
                         <BorderLinearProgress variant="determinate" value={ecTaken*100} />
                         </Col>
                      
                  
                  
                        </Row>
                        <Row> <Divider style={{ width: matches ? "20rem" : "22rem" }} /></Row> 
                 
                        <Row>
                        <Col>  <div>{"Service Hours"}</div></Col>
                           <Col><div >{(totalHours*60).toPrecision(2)} {" "}{"Mins"}</div>  </Col>
                   
                            {/* <ProgressBar value={100} style={{ width: "5rem", textColor: "white" }} /> */}
                 
                            </Row>
                            <Row> <Divider style={{ width: matches ? "20rem" : "22rem" }} /></Row> 
                            <Row>
                        <Col>  <div>{"Waste"}</div></Col>
                           <Col><div style={{color:"#AD4E4E"}}>{totalWeight}{" "}{"Kg"}</div>  </Col>
                     
                            {/* <ProgressBar value={100} style={{ width: "5rem", textColor: "white" }} /> */}
                 
                            </Row>
                  
                        </Container>
                        </div>
                        <br></br>
                     

                </CardContent>
                  </Link>
                </CardActionArea>
              </Card>
  



              </Col>
          <Col md={6} xl={4} lg={4}>
            
          <Card style={{minWidth:"27rem", height:"20rem", backgroundColor:"#1C2747", fontColor:"white"}}>

          <CardActionArea style={{backgroundColor:"#1C2747"}} >
                  {/* <Link to="/gains?tab=1" style={{ color: 'inherit', textDecoration: 'inherit' }}> */}
                    {/* <CardHeader style={{fontColor:"#1C2747"}}
                      icon={<LocalCafeIcon />}
                      title="Connected GAINS"
                      action={
                        <IconButton variant="ghost">
                          <MoreHorizIcon />
                        </IconButton>
                      }
                    /> */}
                    <CardContent> 
                    <CardTitle style={{color:"white"}}>
                      <Container> 
                  
                
                  
                   <Row> <Col md={11}> <div style={{fontWeight:"bold"}}>{"GAINs"}</div> </Col><Col md={1}><Error/></Col></Row> 
                  
            
                    
                     
                
                      </Container></CardTitle>

            
              
<br/>
{/* <Container>
  <Row justify='space-evenly'>
{galleyMemo.slice(0,4).map((item, index) => (
    <div key={index}>
      <Col md={3}>
       <Card style={{width:"5rem", height:"10rem"}}> 
   
       <Row alignItems='center'justify='center'> <Error style={{paddingTop:"1rem",width:"4rem", height:"4rem" ,color: item.galleychip== 0 ?"#AD4E4E":"green" }}/></Row>
<Row justify='center'> {item.galleychip== 0 ? <div style={{fontSize:"0.65rem", fontWeight:"bold"}} >{"Possible Failure"}</div>:<div style={{fontWeight:"bold",fontSize:"0.65rem"}}>{"Healthy"}</div>}</Row>
      <br/>  
<Row justify='center'> <div  style={{fontSize:"0.7rem", fontWeight:"bold"}} >{item.galleyname}</div></Row>

        </Card>
        </Col>
    </div>
    ))}
</Row>
</Container> */}

                      {/* <p>
                        GAINS HEALTH
                        <RangeSlider defaultValue={[25]} />
                        <SentimentVeryDissatisfiedIcon style={{ paddingTop: "0.5rem" }} /><InsertEmoticonIcon style={{ paddingTop: "0.5rem", marginLeft: "13rem" }} />
                      </p> */}
                     <div style={{ fontSize: matches ? "1.5rem" : "1.2rem", marginTop: "1rem", color:"white" }}>    
<Container>


<Row alignItems="center">  <Col md ={7}>{"Total Gains"}</Col><Col md ={2}>{
// galleyMemo.length
"-"
}</Col>   </Row>
<br/>

<Row alignItems="center"><Col md ={7}>{"Galley Connectivity"}</Col><Col md ={2}>{!connectError?  (
      <div>
        <Chip size="medium" label={"Not Connected"}icon={<Clear style={{color:"#AD4E4E"}} />} style={{  fontWeight:"bold", fontSize:"0.7rem"}}variant="error"></Chip>
      </div>
    ):
    (
      <Chip size="medium" label={"Healthy"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold", fontSize:"0.7rem"}}variant="success" >  </Chip>
    ) }</Col></Row>
<br/>
<Row alignItems="center"><Col md ={7}>{"Equipment Health"}</Col><Col md ={2}>
{
equipmentError ?  (
      <div>
        <Chip size="medium" label={"No Gains Detected"}icon={<Clear style={{color:"#AD4E4E"}} />} style={{  fontWeight:"bold", fontSize:"0.7rem"}}variant="error"></Chip>
      </div>
    ):
    (
      <Chip size="medium" label={"Healthy"} icon={<Check style={{color:"green"}}/>}  style={{  fontWeight:"bold", fontSize:"0.7rem"}}variant="success" >  </Chip>
    )
    
    }
    
    </Col></Row>


<br/>

{/* <Row alignItems="center"><Col md ={7}>
  <ColorModeProvider mode='dark'>
<Toast variant ={equipmentError? "error":"info"} style={{minWidth:"22rem", backgroundColor:"#8D8B8B", fontColor:"white", color:"white", fontSize:"0.8rem"  }}

title={recMessage[0]} >
 
    <Link href="#" onClick={(e) => e.preventDefault()}>


    <a
    style={{ cursor:"pointer" ,color:"purple"}}
    href={null}
    onClick={() =>
                  this.toggleModal("Rental Objects With Current Rent")
                }
 >

</a>
  </Toast>
  </ColorModeProvider>
  </Col><Col md ={2}>


</Col></Row> */}

</Container>
</div>
                    </CardContent>
                  {/* </Link> */}
                </CardActionArea>
              </Card>
              </Col>
        
     
       
      </Row>

<Row alignItems= "start"  justify="center" style={{paddingTop:"1rem"}}>
       
        
<Col md={6} xl={4} lg={4}>
          <Card style={{minWidth:"27rem", height:"20rem", backgroundColor:"#1C2747", fontColor:"white"}}>
             
              <CardActionArea style={{backgroundColor:"#1C2747"}} >
                  <Link to="/cargo" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                  {/* <CardHeader
                    icon={<AirlineSeatReclineNormalIcon />}
                    title="Connected Seats"
                    action={
                      <IconButton variant="ghost">
                        <MoreHorizIcon />
                      </IconButton>
                    }
                  /> */}
                 
                  <CardContent>
                  <CardTitle style={{color:"white"}}>
                      <Container> 
                  
                
                  
                   <Row> <Col md={11}> <div style={{fontWeight:"bold"}}>{"CARGO"}</div> </Col><Col md={1}><CheckCircle style={{color:"green"}}/></Col></Row> 
                  
            
                    
                     
                
                      </Container></CardTitle>             
                      <br></br>
                      <Container>
                    <Row >
                      <Col md= {6}>
                        <Card style={{width:"10.5rem", minHeight:"9rem",backgroundColor:"rgba(255,255,255,.2)", fontColor:"white"}}>
                       
                          <CardTitle style={{fontColor:"white", color:"white", padding:"0.5rem",fontSize:"1rem"}} >
                           <div > {"No. of Cargo ULDs"}</div>
                          </CardTitle>
                        
                          <CardContent style={{fontColor:"white", color:"white", padding:"0.5rem",fontSize:"1rem"}}>
                          <Inline spacing="1-x"><div><CheckCircle style={{color:"green"}}/></div> <div> {freight?freight:0} {"ULDs"}</div> </Inline>
                            <br/>
                            {/* <Row> { testCargo?<div style={{color:"#AD4E4E"}}>{"Need Attn" }</div>:<div style={{color:"white"}}>{"In Progress" }</div>}</Row> */}
                        </CardContent>
                        
                        </Card></Col>
                      <Col md= {6}>
                        <Card style={{width:"10.5rem", minHeight:"9rem",backgroundColor:"rgba(255,255,255,.2)"}}>
                        <CardTitle style={{fontColor:"white", color:"white", padding:"0.5rem",fontSize:"1rem"}} >
                           <div > {"Cargo Movements"}</div>
                          </CardTitle>
                          <CardContent style={{fontColor:"white", color:"white", padding:"0.5rem",fontSize:"1rem"}}>
                            <Inline spacing="1-x"><div><CheckCircle style={{color:"green"}}/></div> <div>{movement?movement:0} {"Movements"}</div> </Inline>
                            <br/>
                           
                            {/* <Row> { testCargo1?<div style={{color:"#AD4E4E"}}>{"Need Attn" }</div>:<div style={{color:"white"}}>{"In Progress" }</div>}</Row> */}
                        </CardContent>
                     
                            <br/>
                          </Card></Col>
                    </Row>
                    <br/>
{/* <Row>
                    <ColorModeProvider mode='dark'>
<Toast variant ={equipmentError? "error":"info"} style={{minWidth:"22rem", backgroundColor:"#8D8B8B", fontColor:"white", color:"white", fontSize:"0.8rem"  }}

title={cargoMessage[0]} >
 
    <Link href="#" onClick={(e) => e.preventDefault()}>


    <a
    style={{ cursor:"pointer" ,color:"purple"}}
    href={null}
    onClick={() =>
                  this.toggleModal("Rental Objects With Current Rent")
                }
 >

</a>
  </Toast>
  </ColorModeProvider>
  </Row> */}
                  </Container>
                  </CardContent>
                  </Link>
                </CardActionArea>
              </Card>
          
              </Col>
       
       <Col md={6} xl={4} lg={4}>
            <div>
            <Card style={{minWidth:"27rem", height:"20rem", backgroundColor:"#1C2747", fontColor:"white"}}>
            <CardActionArea style={{backgroundColor:"#1C2747"}} >
                  <Link to="/seats" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                  {/* <CardHeader
                    icon={<LocalMallIcon />}
                    title="Connected Cargo"
                    action={
                      <IconButton variant="ghost">
                        <MoreHorizIcon />
                      </IconButton>
                    }
                  /> */}
          
                     <CardContent>
                     <CardTitle style={{color:"white"}}>
                      <Container> 
                  
                
                  
                   <Row> <Col md={11}> <div style={{fontWeight:"bold"}}>{"SEATS"}</div> </Col><Col md={1}><Error/></Col></Row> 
                  
            
                    
                     
                
                      </Container></CardTitle>{/*                
                    <Toast title="Last Cargo Status" time="2:35 pm" variant="error" style={{ height: 10, width: 250 }}>
                      Cargo capacity reached{" "}

                    </Toast> */}
  
                 

                  <Container>
                    <Row style={{paddingTop:"1rem"}}>
                    <Col md= {6}>
                        <Card style={{width:"10.5rem", height:"9rem",backgroundColor:"rgba(255,255,255,.2)", fontColor:"white"}}>
                       
                          <CardTitle style={{fontColor:"white", color:"white", padding:"0.5rem",fontSize:"1rem"}} >
                           <div > {"Business Class"}</div>
                          </CardTitle>
                        
                          <CardContent style={{fontColor:"white", color:"white", padding:"0.5rem",fontSize:"1rem"}}>
                          <Row alignItems='center' justify='center' >
                            <img src={bcSeatIcon} style={{ height: '30px', width: '25px', marginRight: "1rem" }} alt="fireSpot" />

                             {"-/-"} </Row>

                                                    
                            <Row style={{paddingTop:"1.1rem"}} justify='center' >                           
 <Card > <Row alignSelf="center"alignItems="center"> 
 <Wifi style={{ width: "1.2rem" }} />
 <div style={{ fontSize: matches ? '1.5rem' : "0.75rem", fontWeight:"bold" }}> {"Not Connected"} 
 </div><Error style={{ fontSize: "1.5rem" ,color:testSeat? "#AD4E4E":"white" }} /></Row></Card>
</Row>
                        </CardContent>
                        
                        </Card></Col>


                        <Col md= {6}>
                        <Card style={{width:"10.5rem", height:"9rem",backgroundColor:"rgba(255,255,255,.2)", fontColor:"white"}}>
                       
                          <CardTitle style={{fontColor:"white", color:"white", padding:"0.5rem",fontSize:"1rem"}} >
                           <div > {"Economy Class"}</div>
                          </CardTitle>
                        
                          <CardContent style={{fontColor:"white", color:"white", padding:"0.5rem",fontSize:"1rem"}}>
                          <Row alignItems='center' justify='center' >
                            <img src={ecSeatIcon} style={{ height: '25px', width: '25px', marginRight: "1rem" }} alt="fireSpot" />

                             {"-/-"} </Row>

                                                    
                            <Row style={{paddingTop:"1.4rem"}} justify='center' >                           
 <Card > <Row alignSelf="center"alignItems="center"> 
 <Wifi style={{ width: "1.2rem" }} />
 <div style={{ fontSize: matches ? '1.5rem' : "0.75rem", fontWeight:"bold" }}> {"Not Connected"} 
 </div><Error style={{ fontSize: "1.5rem" ,color:testSeat? "#AD4E4E":"white" }} /></Row></Card>
</Row>
                        </CardContent>
                        
                        </Card></Col>

                     
                    </Row>
                    <br/>
                    <br></br>
{/* <Row>
                    <ColorModeProvider mode='dark'>
<Toast variant ={equipmentError? "error":"info"} style={{minWidth:"22rem", backgroundColor:"#8D8B8B", fontColor:"white", color:"white", fontSize:"0.8rem"  }}

title={seatMessage[0]} >
 
    <Link href="#" onClick={(e) => e.preventDefault()}>


    <a
    style={{ cursor:"pointer" ,color:"purple"}}
    href={null}
    onClick={() =>
                  this.toggleModal("Rental Objects With Current Rent")
                }
 >

</a>
  </Toast>
  </ColorModeProvider>
  </Row> */}
                  </Container>
                  </CardContent>
                  </Link>
                </CardActionArea>
              </Card>
            </div>
          {/* </Col> */}
          {/* <Col xxs={3}> */}


          </Col>
        
     
      </Row>
</Container>

</div>
    
      <Dialog open={showDialog} title="Title" onClose={handleCloseDialog}>
        <DialogContent>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button variant="primary" onClick={handleCloseDialog}>
            Action
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );


}


export default (Home)