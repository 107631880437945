import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import _ from "lodash";
import {
    Inline,
    Tabs,
    Tab,
    ColorModeProvider,
    CardContent,
    CardTitle,
    Container,
    Col,
    Row,
  } from "@airbus/components-react";
  import Box from "@material-ui/core/Box";
  import {
    Grid,
    Typography,
    Backdrop,
    CircularProgress,
  } from "@material-ui/core";
  import useMediaQuery from "@material-ui/core/useMediaQuery";
  import { useTheme } from "@material-ui/styles";
  import { makeStyles, withStyles } from "@material-ui/core/styles";
  import {
    cargoAFTLayout,
    cargoAFTLayoutLatch,
  } from "../../components/Cargo/CargoLayout";

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "black",
      opacity: 0.7,
    },
  }));



function CargoCharts ({cargoData  }) {
  const pduOG = cargoData
  ? cargoData.filter((x) => x.topic_extension.includes("PDU_covered"))
  : [];
  const pduUnique = pduOG.filter(
    (v, i, a) =>
      a.findIndex((v2) => v2.topic_extension === v.topic_extension) === i
  );


  const pduUOG = cargoData
  ? cargoData.filter((x) => x.topic_extension.includes("PDU_uncovered"))
  : [];
console.log("pduOG :>> ", pduOG);
const pduHOG = cargoData
? cargoData.filter((x) => x.topic_extension.includes("PDU_Hold"))
: [];
const pduHUUnique = pduHOG.filter(
  (v, i, a) =>
    a.findIndex((v2) => v2.topic_extension === v.topic_extension) === i
);
const pduHold = cargoData ? pduHUUnique.length : 0;

const pduUUnique = pduUOG.filter(
  (v, i, a) =>
    a.findIndex((v2) => v2.topic_extension === v.topic_extension) === i
);
const pduUncovered = cargoData ? pduUUnique.length : 0;
const xxx = cargoData
  ? cargoData.filter((x) => x.topic_extension.includes("PDU_covered"))
  : [];
const pduUncoveredToo = cargoData
? cargoData.filter((x) => x.topic_extension.includes("PDU_uncovered"))
: [];
console.log("pduUncoveredToo :>> ", pduUncoveredToo);
const zzz = xxx.length < 1 ? [...pduUncoveredToo] : [...xxx];
const yyy = _.uniqBy(zzz, (obj) => obj.topic_extension);
const countPDUcovered = _.uniqBy(xxx, (obj) => obj.topic_extension).length;

const countPDUUncovered = _.uniqBy(
  pduUncoveredToo,
  (obj) => obj.topic_extension
).length;
const pduCovered = pduOG.length ? pduUnique.length : 0;

const pduAftOrIn = cargoData
? cargoData.filter((x) => x.topic_extension.includes("PDU_Aft_or_In"))
    .length
: 0;
const pduFwdOrOut = cargoData
? cargoData.filter((x) => x.topic_extension.includes("PDU_Forward_or_Out"))
    .length
: 0;
 
    console.log('cargoData :>> ', cargoData);
    const categorizedz = {};
    const mergedArrayz = mergeArrays(pduUnique, cargoAFTLayout);
    function mergeArrays(pduUnique, data) {
      const mergedArray = [];
  
      data.forEach((obj) => {
        const pduUniqueObj = pduUnique.find(
          (pduObj) => pduObj.topic_extension === obj.topic_extension
        );
  
        if (pduUniqueObj) {
          mergedArray.push({ ...obj, ...pduUniqueObj });
        } else {
          mergedArray.push({ ...obj, created_at: null });
        }
      });
  
      return mergedArray;
    }
    mergedArrayz.forEach((obj) => {
      if (obj.created_at) {
        // console.log('obj.created_at :>> ', obj.created_at);
        const createdAt = obj.created_at; // Extracting the date part
  
        if (!categorizedz.hasOwnProperty(createdAt)) {
          categorizedz[createdAt] = 0;
        }
  
        categorizedz[createdAt]++;
      }
    });


  function formatHourlyInterval(date) {
    const hour = date.getUTCHours().toString().padStart(2, "0");
    return `${hour}:00${hour >= 12 ? "pm" : "am"}`;
  }
    const testOnly = [
        { name: "PDU (Covered)", count: pduCovered },
        { name: "PDU (Uncovered)", count: pduUncovered },
      ];
      const testOnly2 = [
        { name: "PDU (Aft or In)", count: pduAftOrIn },
        { name: "PDU (Fwd or Out)", count: pduFwdOrOut },
        { name: "PDU (Hold)", count: pduHold },
      ];
    let sumByHour = [{ time: "11:00am", count: 0 }];

  


  function groupSumByHour(data) {
    const result = [];

    for (const entry of data) {
      for (const timestamp in entry) {
        const dateObj = new Date(timestamp);
        const formattedInterval = formatHourlyInterval(dateObj);

        const existingItem = result.find(
          (item) => item.time === formattedInterval
        );
        if (existingItem) {
          existingItem.count += entry[timestamp];
        } else {
          result.push({ time: formattedInterval, count: entry[timestamp] });
        }
      }
    }

    return result;
  }
  const catt = [categorizedz].length > 0 ? [categorizedz] : [];
  console.log("catt", catt)
    if (catt) sumByHour = groupSumByHour(catt);
    console.log('sumByHour :>> ', sumByHour);
    let compareTimes = (timeA, timeB) => {
      let [hourA, minuteA] = timeA?.split(/:|(?=[ap]m)/i);
      let [hourB, minuteB] = timeB?.split(/:|(?=[ap]m)/i);
  
      if (timeA.includes("pm")) hourA = (parseInt(hourA, 10) + 12).toString();
      if (timeB.includes("pm")) hourB = (parseInt(hourB, 10) + 12).toString();
  
      let timeInMinutesA = parseInt(hourA, 10) * 60 + parseInt(minuteA, 10);
      let timeInMinutesB = parseInt(hourB, 10) * 60 + parseInt(minuteB, 10);
  
      return timeInMinutesA - timeInMinutesB;
    };
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("xl"));



   
  const cargoPDU = {
        dataZoom: [
          {
            type: "inside",
            show: true,
            xAxisIndex: 0,
            filterMode: "empty",
            width: 30,
            height: "80%",
            showDataShadow: false,
            left: "93%",
          },
        ],
        grid: {
          right: 100,
          containLabel: true,
        },
        legend: {
          // inactiveColor:"white",
          data: ["PDU usage trend"],
          textStyle: {
            color: "white",
          },
        },
        tooltip: {},
        dataset: [
          {
            // Define the dimension of array. In cartesian coordinate system,
            // if the type of x-axis is category, map the first dimension to
            // x-axis by default, the second dimension to y-axis.
            // You can also specify 'series.encode' to complete the map
            // without specify dimensions. Please see below.
            dimensions: ["time", "count"],
            source:
              sumByHour.length > 0
                ? sumByHour.sort((a, b) => compareTimes(a.time, b.time))
                : [{ time: "11:00am", count: 0 }],
          },
          {
            transform: [
              {
                type: "sort",
                config: { dimension: "time", order: "desc" },
              },
            ],
          },
        ],
        xAxis: {
          type: "category",
          name: "Time",
          nameLocation: "center",
          nameTextStyle: {
            align: "center",
            verticalAlign: "top",
            /**
             * the top padding will shift the name down so that it does not overlap with the axis-labels
             * t-l-b-r
             */
            padding: [20, 0, 0, 0],
          },
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
        },
        yAxis: {
          type: "value",
          name: "PDU covered",
          min: 0, // Set the minimum value for the Y-axis
          max: 100, // Set the maximum value for the Y-axis
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
        },
        series: [{ type: "line", name: "PDU count" }],
      };
      const cargoMovement1 = {
        dataZoom: [
          {
            type: "inside",
            show: true,
            xAxisIndex: 0,
            filterMode: "empty",
            width: 30,
            height: "80%",
            showDataShadow: false,
            left: "93%",
          },
        ],
        grid: {
          right: 100,
          containLabel: true,
        },
        legend: {
          // inactiveColor:"white",
          data: ["No. of Movements"],
          textStyle: {
            color: "white",
          },
        },
        tooltip: {},
        dataset: [
          {
            // Define the dimension of array. In cartesian coordinate system,
            // if the type of x-axis is category, map the first dimension to
            // x-axis by default, the second dimension to y-axis.
            // You can also specify 'series.encode' to complete the map
            // without specify dimensions. Please see below.
            dimensions: ["name", "count"],
            source: testOnly2 ? testOnly2 : [],
          },
          {
            transform: [
              {
                type: "sort",
                config: { dimension: "quantity", order: "desc" },
              },
            ],
          },
        ],
        xAxis: {
          type: "value",
          name: "No. of Movements",
          nameLocation: "center",
          nameTextStyle: {
            align: "center",
            verticalAlign: "top",
            /**
             * the top padding will shift the name down so that it does not overlap with the axis-labels
             * t-l-b-r
             */
            padding: [20, 0, 0, 0],
          },
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
        },
        yAxis: {
          type: "category",
          name: "Movement",
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
        },
        series: [{ type: "bar", name: "No. of Movements" }],
      };


    return (  
        <Container>
        <Row style={{ paddingTop: "1rem" }}>
          <Col xs={6} xxs={6} sm={6} md={6} alignSelf={"start"}>
            {
              <Box
                style={{
                  background: "#1C2747",
                  height: matches ? "40rem" : "25rem",
                }}
              >
                <Box p={3}>
                  <Typography variant="h5" style={{ color: "white" }}>
                    Cargo PDU trend
                  </Typography>
                </Box>
                <Row
                  justify="end"
                  style={{ paddingRight: "1rem", paddingBottom: "0.7rem" }}
                ></Row>

                <Row justify="center">
                  <ReactEcharts
                    testOnly={testOnly}
                    testOnly2={testOnly2}
                    option={cargoPDU}
                    style={{
                      width: matches ? 600 : 750,
                      height: matches ? 500 : 300,
                    }}
                  />
                  {/* </div> */}
                </Row>
              </Box>
            }
          </Col>

        
        </Row>
      </Container>


    );
}

export default CargoCharts;