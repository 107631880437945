import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Toggle, Accordion, Badge, Chip, Button, Collapsible, Checkbox, Inline, Tabs,Toast, Tab, ColorModeProvider, RangeSlider, Rating, Stack, Card, CardActions, CardContent, CardHeader, CardMedia, CardActionArea, CardTitle, IconButton, Container, Col, Content, Row, Flex } from "@airbus/components-react"
import { ReactTable2, ChipInputCell, ChipInputCellGalleyTop, ProgressInputCell } from '../../components/Gains/ReactTable'
import { CheckCircle,Cancel, Error}from '@airbus/icons/react'
import {Slide,Box} from '@material-ui/core';

  const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MaxWidthDialog1(props) {
  const [open, setOpen] = React.useState(props.open);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose?.()
  };



 
 

 // process distributed time based on datetimestring and converted to UTC
const distributedTime = (new Date(parseInt(props.open.distributedTime))).toUTCString()

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open max-width dialog
      </Button>
      <Dialog style={{color:"#1C2747"}}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
       
        <DialogTitle dividers={false} style={{ backgroundColor:"#1C2747",color:"white"}} id="max-width-dialog-title">
        <Container>
        <Row><div style={{color:"white",fontSize:"1.5rem"}}>{"Catering Zones"}</div></Row>

        <Row  alignItems='center'>
          <Col sm={11} md={11} lg={11} xl={8}><div style={{color:"white"}}>{"Seat "}{props.open.xvalue}  <Error/></div> <div style={{color:"white"}}>{"Last Service Time: "}{props.open.distributedTime? distributedTime: " - "}</div></Col>
          <Col sm={1} md={1} lg={1} xl={1}>  
          
          <ColorModeProvider mode="dark">
        <IconButton variant="ghost" onClick={handleClose}>
          <Cancel style={{color:"white"}}/></IconButton><ColorModeProvider/></ColorModeProvider></Col></Row>
          <br></br>
          {/* <Row><div style={{color:"white"}}><b>{"Container flipped "}</b></div></Row> */}

          <Row><div style={{color:"white"}}>{"Meal Type: " + (props.open.type? props.open.type: " -")  }</div></Row>
          <br/>
        <Row><div style={{color:"white"}}>{"Meal Ordered: " + (props.open.food? props.open.food: " -")}</div></Row>
        <br/>
        <Row></Row>
        </Container>

      
    
        <br/>
        </DialogTitle>
   
       
 

  
      </Dialog>
    </React.Fragment>
  );
}
