import React, { useEffect, useState } from 'react'
import 'echarts/lib/component/grid';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ReactEcharts from "echarts-for-react";
import { Inline, Tabs, Tab, ColorModeProvider, Card, Container, Col, Row} from "@airbus/components-react"
import axios from 'axios'
import { useTheme, Backdrop,CircularProgress  } from '@material-ui/core';
import Select from "react-select";
// import mainLogo from'../../images/bg_fleet.svg';
import mainLogo from '../../images/cabin-bg.png';
import { GaugeChart, PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { cateringArray } from '../../components/Catering/CateringData';
import CateringReactTable from '../../components/Catering/CateringReactTable'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MaxWidthDialog1 from '../../components/Catering/MaxWidthDialog1';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { TopFilters } from "../../components/Catering/TopFilters"
import { mockFlightFoodInfo, mockFlightPaxInfo, mockCateringPayload, fetchData } from '../../components/Catering/CateringData';
import { allSeats } from "../../components/Catering/CateringLOPA"
import CateringOverview from '../../components/Catering/CateringOverview';
import {CateringZone} from'../../components/Catering/CateringZone'
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
} from "date-fns";
import { makeStyles } from '@material-ui/core/styles';


const dayHrSelectionOptions = [
  { label: "Day", value: "day" },
  { label: "Hour", value: "hr" },

];
const filters = [
  {
    name: "flightNo",
    label: "Flight No.",
    type: "dropdown",
    initialValue: "AIB8888",
    options: [
      { label: "AIB8888", value: "AIB8888" },
      { label: "MU5100", value: "MU5100" },
      { label: "MU5101", value: "MU5101" },
    ],
   
    onChange: (handleChange) => {
      console.log('changing')
      handleChange({ target: { name: 'origin', value: null } })
      handleChange({ target: { name: 'destination', value: null } })

    }
  },
  {
    name: "dateRange",
    // label: "Range",
    type: 'date',
    initialValue:  Array(2).fill(new Date('2023-03-02'))
  },
  {
    name: "origin",
    label: "Origin",
    type: "dropdown",
    options: (values) =>
      [
        { code: "AIB8888", label: "WBGG", value: "WBGG" },
     
        { code: "MU5100", label: "ZGSZ", value: "ZGSZ" },
        { code: "MU5101", label: "ZBAA", value: "ZBAA" },
      ].filter((item) => item.code === values.flightNo),
    onChange: (handleChange) => {
      console.log('changing')
      handleChange({ target: { name: 'destination', value: null } })
    }
  },

  {
    name: "destination",
    label: "Destination",
    type: "dropdown",
    options: (values1) =>
      [

        { code: "WBGG", label: "WMKK", value: "WMKK" },
        { code: "ZGSZ", label: "ZBAA", value: "ZBAA" },
        { code: "ZBAA", label: "ZGSZ", value: "ZGSZ" },
      ].filter((item) => item.code === values1.origin),

  },




];

const getEndpoint = createdOn => `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=/smarttrolley&topic_extension=/realtimereport&created_at=${createdOn}`


export const CateringReport =({loading, selectedChartsSelectionValue, matches,top5Foods,top5Foods100,top5Waste,wasteSDArray,servedSDArray,servedArray,wasteSHArray,servedSHArray,onSetDayHrValue})=>



{
  const useStyles = makeStyles((theme) => ({
  
  
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'black',
      opacity: 0.7
    },
  
  
  }));
  const classes = useStyles();
  const customStyles = {
    option:() => ({
   
      height:'100%',
      color:'black',
      paddingLeft:'10px',
    }),
    
  }

  const [selectedDayHrValue,setSelectedDayHrValue]= React.useState("day")
  const handleSetDayHrValue = (event,value) => {
    setSelectedDayHrValue(value)
    onSetDayHrValue(value)
    setAlignment("%")
  };
  const topWastedFoodyy = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 50,
      containLabel: true
    },
    legend: {
      // inactiveColor:"white",
      data: ['Quantity'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['time', 'weight'],
      source: wasteSHArray.length !== 0 ? wasteSHArray : [{
        "categoryName": "Fruit",
        "time": "-",
        "weight": 0.0
      }]

    },
      // {fromDatasetIndex: 0,
      //   transform: {
      //     type: 'sort',
      //     config: { dimension:"servedQuantity", order: 'desc' }
      //   }
      // }
    ],
    yAxis: {
      type: 'value', name: 'Quantity',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    xAxis: {
      type: 'category', name: 'Hour',
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar', name: 'Quantity', }]
  }

  





  const topWastedFoodzz = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 60,
      containLabel: true
    },
    legend: {
      // inactiveColor:"white",
      data: ['Quantity'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['time', 'servedQuantity'],
      source: servedSHArray.length !== 0 ? servedSHArray : [{
       
        "servedQuantity": 0,
        "time": "-"
      }]
      //[
      //   {
      //     "censusFoodName": "Chicken",
      //     "censusCategoryName": "Meat",
      //     "quantity": (30 / 5700) * 100
      //   }, {
      //     "censusFoodName": "Noodles",
      //     "censusCategoryName": "Special",
      //     "quantity": (40 / 5700) * 100
      //   }, {
      //     "censusFoodName": "Brocolli",
      //     "censusCategoryName": "Vegetables",
      //     "quantity": (20 / 5700) * 100
      //   }
      //   , {
      //     "censusFoodName": "Fruit with Apple",
      //     "censusCategoryName": "Fruit",
      //     "quantity": (10 / 5700) * 100
      //   }
      // ]
    },
    ],
    yAxis: {
      type: 'value', name: 'Quantity (%)',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    xAxis: {
      type: 'category', name: 'Hour',
      // nameLocation: 'center',
      // nameTextStyle: {
      //   padding:["20rem",0,0,0]
      // },
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      },
      axisLabel: {
        interval: 0,
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar', name: "Quantity" }]
  }
  const finalArray = servedSHArray.map(obj => {
    const newObj = { ...obj };
    newObj.servedPercentage *= 100


    return newObj
  })
  const topServedFoodzzx100 = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 60,
      containLabel: true
    },
    legend: {
      // inactiveColor:"white",
      data: ['Quantity'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['time', 'servedPercentage'],
      source: servedSDArray.length !== 0 ? servedSDArray : [{
        "servedPercentage": 0.0,
        "servedQuantity": 0,
        "time": "-"
      }]
      //[
      //   {
      //     "censusFoodName": "Chicken",
      //     "censusCategoryName": "Meat",
      //     "quantity": (30 / 5700) * 100
      //   }, {
      //     "censusFoodName": "Noodles",
      //     "censusCategoryName": "Special",
      //     "quantity": (40 / 5700) * 100
      //   }, {
      //     "censusFoodName": "Brocolli",
      //     "censusCategoryName": "Vegetables",
      //     "quantity": (20 / 5700) * 100
      //   }
      //   , {
      //     "censusFoodName": "Fruit with Apple",
      //     "censusCategoryName": "Fruit",
      //     "quantity": (10 / 5700) * 100
      //   }
      // ]
    },
    ],
    yAxis: {
      type: 'value', name: 'Quantity (%)',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    xAxis: {
      type: 'category', name: 'Date',
      // nameLocation: 'center',
      // nameTextStyle: {
      //   padding:["20rem",0,0,0]
      // },
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      },
      axisLabel: {
        interval: 0,
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar', name: "Quantity" }]
  }

  const topWastedFoodzzWeight = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 60,
      containLabel: true
    },
    legend: {
      data: ['Quantity'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['time', 'servedPercentage'],

      source: servedSHArray.length !== 0 ? finalArray : [{
        "servedPercentage": 0.0,
        "servedQuantity": 0,
        "time": "-"
      }]
      //   {
      //     "censusFoodName": "Chicken",
      //     "censusCategoryName": "Meat",
      //     "quantity": 30
      //   }, {
      //     "censusFoodName": "Noodles",
      //     "censusCategoryName": "Special",
      //     "quantity": 40
      //   }, {
      //     "censusFoodName": "Brocolli",
      //     "censusCategoryName": "Vegetables",
      //     "quantity": 20
      //   },
      //   {
      //     "censusFoodName": "Fruit with Apple",
      //     "censusCategoryName": "Fruit",
      //     "quantity": 10
      //   },
      // ]
    }, {
      transform: [
        {
          type: 'sort',
          config: { dimension: 'quantity', order: 'desc' }
        }
      ],
    }
    ],
    yAxis: {
      type: 'value', name: 'Quantity',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    xAxis: {
      type: 'category', name: 'Hour',
      axisLabel: {
        interval: 0,
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar', name: "Quantity" }]
  }
  const topWasteWeightOverview = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 60,
      containLabel: true
    },
    legend: {
      // inactiveColor:"white",
      data: ['Weight'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['categoryName', 'weight'],
      source:
        top5Waste.length !== 0 ? top5Waste : [
          {
            "categoryName": "Special",
            "materialName": "Breads",
            "weight": 0
          },
          {
            "categoryName": "Meat",
            "materialName": "Chicken",
            "weight": 0
          },
          {
            "categoryName": "Fruit",
            "materialName": "Fruit",
            "weight": 0
          },
          {
            "categoryName": "Special",
            "materialName": "Noodle",
            "weight": 0
          },
          {
            "categoryName": "Drink",
            "materialName": "Water",
            "weight": 0
          },
          {
            "categoryName": "Vegetables",
            "materialName": "Veg1",
            "weight": 0
          }
        ]
    },
      // {fromDatasetIndex: 0,
      //   transform: {
      //     type: 'sort',
      //     config: { dimension:"servedQuantity", order: 'desc' }
      //   }
      // }
    ],
    yAxis: {
      name: 'Weight (Kg)',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    xAxis: {
      type: 'category', name: 'Food Name',
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      },
      axisLabel: {
        interval: 0,
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar', name: "Weight" }]
  }

  const topWasteWeightWeight = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 60,
      containLabel: true
    },
    legend: {
      // inactiveColor:"white",
      data: ['Weight'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['materialName', 'weight'],
      source:
        top5Waste.length !== 0 ? top5Waste : [
          {
            "categoryName": "Special",
            "materialName": "Breads",
            "weight": 0
          },
          {
            "categoryName": "Meat",
            "materialName": "Chicken",
            "weight": 0
          },
          {
            "categoryName": "Fruit",
            "materialName": "Fruit",
            "weight": 0
          },
          {
            "categoryName": "Special",
            "materialName": "Noodle",
            "weight": 0
          },
          {
            "categoryName": "Drink",
            "materialName": "Water",
            "weight": 0
          },
          {
            "categoryName": "Vegetables",
            "materialName": "Vegetables",
            "weight": 0
          }
        ]
    },
      // {fromDatasetIndex: 0,
      //   transform: {
      //     type: 'sort',
      //     config: { dimension:"servedQuantity", order: 'desc' }
      //   }
      // }
    ],
    yAxis: {
      name: 'Weight (Kg)',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    xAxis: {
      type: 'category', name: 'Food Name',
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      },
      axisLabel: {
        interval: 0,
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar', name: "Weight" }]
  }
  
  
  const topServedFoodyy = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 50,
      containLabel: true
    },
    legend: {
      // inactiveColor:"white",
      data: ['Quantity'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['time', 'weight'],
      source: wasteSDArray.length !== 0 ? wasteSDArray : [{
        "categoryName": "Fruit",
        "time": "-",
        "weight": 0.0
      }]
  
    },
      // {fromDatasetIndex: 0,
      //   transform: {
      //     type: 'sort',
      //     config: { dimension:"servedQuantity", order: 'desc' }
      //   }
      // }
    ],
    yAxis: {
      type: 'value', name: 'Quantity',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    xAxis: {
      type: 'category', name: 'Date',
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar', name: 'Quantity', }]
  }
  const topServedFoodzz = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 60,
      containLabel: true
    },
    legend: {
      data: ['Quantity'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['time', 'servedQuantity'],
      source:  
      servedSDArray.length !== 0 ? servedSDArray : [{
        "servedPercentage": 0.1,
        "servedQuantity": 0,
        "time": "-"
      }]
      //   {
      //     "censusFoodName": "Chicken",
      //     "censusCategoryName": "Meat",
      //     "quantity": 30
      //   }, {
      //     "censusFoodName": "Noodles",
      //     "censusCategoryName": "Special",
      //     "quantity": 40
      //   }, {
      //     "censusFoodName": "Brocolli",
      //     "censusCategoryName": "Vegetables",
      //     "quantity": 20
      //   },
      //   {
      //     "censusFoodName": "Fruit with Apple",
      //     "censusCategoryName": "Fruit",
      //     "quantity": 10
      //   },
      // ]
    }, {
      transform: [
        {
          type: 'sort',
          config: { dimension: 'quantity', order: 'desc' }
        }
      ],
    }
    ],
    yAxis: {
      type: 'value', name: 'Quantity',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    xAxis: {
      type: 'category', name: 'Date',
      axisLabel: {
        interval: 0,
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar', name: "Quantity" }]
  }
  
  
  const topServedMealOverviewx100 = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 60,
      containLabel: true
    },
    legend: {
      // inactiveColor:"white",
      data: ['Quantity'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['foodCategoryName', 'demandDegree'],
      source: top5Foods100.length !== 0 ? top5Foods100 : [
        {
          "censusFoodName": "Chicken",
          "foodCategoryName": "Meat",
          "demandDegree": 0
        }, {
          "censusFoodName": "Noodles",
          "foodCategoryName": "Special",
          "demandDegree": 0
        }, {
          "censusFoodName": "Brocolli",
          "foodCategoryName": "Vegetables",
          "demandDegree": 0
        }
        , {
          "censusFoodName": "Fruit with Apple",
          "foodCategoryName": "Fruit",
          "demandDegree": 0
        },
         {
          "censusFoodName": "Drinka",
          "foodCategoryName": "Drink",
          "demandDegree": 0
        },
      
      ] 
   
      
    }
    
    ],
    xAxis: {
      type: 'value', name: 'Quantity (%)', nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      }, axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    yAxis: {
      type: 'category', name: 'Food Name', axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar', name: 'Quantity' }]
  }
  
  
  const topServedMealOverview = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 100,
      containLabel: true
    },
    legend: {
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['foodCategoryName', 'offeredCount'],
      source: top5Foods.length !== 0 ? top5Foods : [
        {
          "censusFoodName": "Chicken",
          "foodCategoryName": "Meat",
          "offeredCount": 0
        }, {
          "censusFoodName": "Noodles",
          "foodCategoryName": "Special",
          "offeredCount": 0
        }, {
          "censusFoodName": "Brocolli",
          "foodCategoryName": "Vegetables",
          "offeredCount": 0
        }
        , {
          "censusFoodName": "Fruit with Apple",
          "foodCategoryName": "Fruit",
          "offeredCount": 0
        }
        , {
          "censusFoodName": "Drinksa",
          "foodCategoryName": "Drink",
          "offeredCount": 0
        }
      ]
    }, {
      transform: [
        {
          type: 'sort',
          config: { dimension: 'quantity', order: 'desc' }
        }
      ],
    }
    ],
    xAxis: {
      type: 'value', name: 'Quantity',
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    yAxis: {
      type: 'category', name: 'Food Name',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar' }]
  }

  const topServedMealOverviewx100Stats = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 60,
      containLabel: true
    },
    legend: {
      // inactiveColor:"white",
      data: ['Quantity'],
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{

  
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['foodTypeName', 'demandDegree'],
      source: top5Foods100.length !== 0 ? top5Foods100 : [
        {
          "censusFoodName": "Chicken",
          "foodCategoryName": "Meat",
          "demandDegree": 0
        }, {
          "censusFoodName": "Noodles",
          "foodCategoryName": "Special",
          "demandDegree": 0
        }, {
          "censusFoodName": "Brocolli",
          "foodCategoryName": "Vegetables",
          "demandDegree": 0
        }
        , {
          "censusFoodName": "Fruit with Apple",
          "foodCategoryName": "Fruit",
          "demandDegree": 0
        }
      ] ,
   
      
    }
    , {
      transform: [
        {
          type: 'sort',
          config: { dimension: 'demandDegree', order: 'desc' }
        }
      ],
    }
    ],
    xAxis: {
      type: 'value', name: 'Quantity (%)', nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      }, axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    yAxis: {
      type: 'category', name: 'Meal Name', axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar',datasetIndex:1 }]
  }
  
  
  const topServedMealOverviewStats = {
    dataZoom: [
      {
        type: "inside",
        show: true,
        xAxisIndex: 0,
        filterMode: "empty",
        width: 30,
        height: "80%",
        showDataShadow: false,
        left: "93%",
      },
    ],
    grid: {
      right: 60,
      containLabel: true
    },
    legend: {
      textStyle: {
        color: 'white'
      }
    },
    tooltip: {},
    dataset: [{
      // Define the dimension of array. In cartesian coordinate system,
      // if the type of x-axis is category, map the first dimension to
      // x-axis by default, the second dimension to y-axis.
      // You can also specify 'series.encode' to complete the map
      // without specify dimensions. Please see below.
      dimensions: ['foodTypeName', 'offeredCount'],
      source: top5Foods.length !== 0 ? top5Foods : [
        {
          "censusFoodName": "Chicken",
          "foodCategoryName": "Meat",
          "offeredCount": 0
        }, {
          "censusFoodName": "Noodles",
          "foodCategoryName": "Special",
          "offeredCount": 0
        }, {
          "censusFoodName": "Brocolli",
          "foodCategoryName": "Vegetables",
          "offeredCount": 0
        }
        , {
          "censusFoodName": "Fruit with Apple",
          "foodCategoryName": "Fruit",
          "offeredCount": 0
        }
      ]
    }, {
      transform: [
        {
          type: 'sort',
          config: { dimension: 'offeredCount', order: 'desc' }
        }
      ],
    }
    ],
    xAxis: {
      type: 'value', name: 'Quantity',
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'top',
        /**
         * the top padding will shift the name down so that it does not overlap with the axis-labels
         * t-l-b-r
         */
        padding: [20, 0, 0, 0],
      },
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    yAxis: {
      type: 'category', name: 'Meal Name',
      axisLine: {
        lineStyle: {
          color: 'white'
        }
      }
    },
    series: [{ type: 'bar',datasetIndex: 0 }]
  }
  const [alignment, setAlignment] = React.useState('%');

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
    setAlignment(newAlignment);
    }
  }

 
 return(
  <Container>
    <Backdrop open={loading} className={classes.backdrop} style={{opacity:"0.8"}}>
  <CircularProgress color="inherit" /></Backdrop>
  <Row style={{ paddingTop: "1rem" }}>
    <Col xs={6} xxs={6} sm={6} md={6} alignSelf={"start"}>
      {selectedChartsSelectionValue == 'overview' &&
              <Box style={{ background: '#1C2747', height: matches? '40rem':'28rem' }}>
          <Box p={3}><Typography variant="h5" style={({ color: "white" })} >Top Served Food</Typography></Box>
          <Row justify="end" style={{ paddingRight: "1rem", paddingBottom: "0.7rem" }}>
            {/* <ColorModeProvider mode="dark"> */}
              <ToggleButtonGroup
                style={{ maxWidth: "5rem", maxHeight: "2.4rem",backgroundColor: 'grey' }}
                value={alignment}
                exclusive
              
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton value="%" aria-label="left aligned"  style={{ color: 'white'}} >
                  %
                </ToggleButton >
                <ToggleButton value="No" aria-label="centered"style={{ color: 'white'}}  >
                  No.
                </ToggleButton >
              </ToggleButtonGroup>
            {/* </ColorModeProvider> */}
          </Row>
          {/* <div style={{marginRight: matches ?"25rem":"25rem"}}> */}
          <Row justify='center'>
            <ReactEcharts
              option={alignment == '%' ? topServedMealOverviewx100 : topServedMealOverview} style={{
                width: matches ? 600 : 450,
                height: matches ? 500 : 300,
              }} />
            {/* </div> */}
          </Row>
        </Box>
      }
    </Col>
    <Col xs={6} xxs={6} sm={6} md={6} style={{ paddingLeft: "0.5rem" }}>
      <Row >
        {selectedChartsSelectionValue == 'overview' &&
          <Box style={{ background: '#1C2747', height: matches? '40rem':'28rem' }}>
            <Box p={3}><Typography variant="h5" style={({ color: "white" })}>Top Food Waste </Typography></Box>
            <Row justify='end' style={{ paddingRight: "1rem" }}>
              {/* <Select style={{ width: "6rem" }}
                options={wastageChartSelectionOptions}
                value={selectedWasteChartsSelectionValue}
                defaultValue="Overview"
                onChange={(event, value) => handleSetSelectedWasteChartsSelectionValue(event, value)}
              ></Select> */}
            </Row>
            <Row justify='center'> {
              //  <ReactEcharts
              //   option={selectedWasteChartsSelectionValue == 'cost' ? topWasteCostOverview : selectedWasteChartsSelectionValue == 'weight' ? topWasteWeightOverview : topWasteTrolleyOverview} style={{
              //     width: matches ? 750 : 350,
              //     height: matches ? 510 : 310,
              //   }} />


              <ReactEcharts
                option={topWasteWeightOverview}
                style={{
                  width: matches ? 750 : 370,
                  height: matches ? 510 : 350,
                }} />

            }   </Row>        </Box>
        }
      </Row>

    </Col>

{    selectedChartsSelectionValue == 'served' &&   <Box style={{ backgroundColor: "#1C2747", width: matches ? "90rem" : "50rem", height: matches ? "42rem" : "30rem" }}>
    <Row justify='end'>
       <div style={{ padding: "0.5rem" }}>

<Select style={{  width: "7rem"}}
   options={dayHrSelectionOptions}
   value={dayHrSelectionOptions.find(({ value }) => value === value.value)}
styles={customStyles}
   defaultValue={dayHrSelectionOptions.find(({ value }) => value === 'day')}

   onChange={( value) => handleSetDayHrValue(null, value.value)}

></Select>
</div></Row>
    <Row alignItems='center'>
    <Col xs={6} xxs={6} sm={6} md ={6} alignSelf={"start"}>

      {selectedChartsSelectionValue == 'served' && selectedDayHrValue == 'day' &&
      
        // <Box style={{ background: '#1C2747', height: 'auto', minHeight: "20rem" }}>
          <div>
          <Box p={3}><Typography variant="h5" style={({ color: "white" })}>Food Served Quantity(Day)</Typography></Box>
          <Row justify='end' style={{ paddingRight: "1rem", paddingBottom: "0.73rem" }}>
            <ColorModeProvider mode="dark">
              <ToggleButtonGroup
                  style={{ maxWidth: "5rem", maxHeight: "2.4rem",backgroundColor: 'grey' }}

                value={alignment}
                exclusive
                color='primary'
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton  value="%" aria-label="left aligned" style={({ color: "white" })} color='primary'>
                  %
                </ToggleButton >
                <ToggleButton value="No" aria-label="centered" style={({ color: "white" })} color='primary'>
                  No.
                </ToggleButton >
              </ToggleButtonGroup>
            </ColorModeProvider>
          </Row>
          <Row justify='center'>
            <ReactEcharts
              option={alignment == '%' ? topServedFoodzzx100 : topServedFoodzz} style={{
                width: matches ? 700 : 650,
                height: matches ? 500 : 300,
              }} />
          </Row>
        {/* </Box> */}
        </div>
      }
    </Col>
    <Col md ={6} xs={6} xxs={6} sm={6} style={{ paddingLeft: "0.5rem" }}>
      {selectedChartsSelectionValue == 'served' && selectedDayHrValue == 'day' &&
      <div>
        {/* <Box style={{ background: '#1C2747', height: 'auto' }}> */}
          <Box p={3}><Typography variant="h5" style={({ color: "white" })}>Food Waste Quantity(Day)</Typography></Box>
          <br></br>
          <br></br>
          {selectedChartsSelectionValue == 'served' &&
            <Row justify='center'>
              <ReactEcharts
                option={topServedFoodyy} style={{
                  width: matches ? 700 : 700,
                  height: matches ? 505 : 300,
                }} />
            </Row>
          }            
          {/* </Box> */}
          </div>
      }
    </Col>

    <Col md ={6} xs={6} xxs={6} sm={6} alignSelf={"start"}>
      {selectedChartsSelectionValue == 'served' &&  selectedDayHrValue == 'hr' &&
   <div>
          <Box p={3}><Typography variant="h5" style={({ color: "white" })}>Food Served Quantity (hour)</Typography></Box>
          <Row justify="end" style={{ paddingRight: "1rem", paddingBottom: '0.70rem' }}>
            <ColorModeProvider mode="dark">
              <ToggleButtonGroup
                            style={{ maxWidth: "5rem", maxHeight: "2.4rem",backgroundColor: 'grey' }}

                value={alignment}
                exclusive
                color='primary'
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton  value="%" aria-label="left aligned" style={({ color: "white" })} color='primary'>
                  %
                </ToggleButton >
                <ToggleButton value="No" aria-label="centered" style={({ color: "white" })} color='primary'>
                  No.
                </ToggleButton >
              </ToggleButtonGroup>
            </ColorModeProvider>
          </Row>
          <Row justify="center">
            <ReactEcharts
              option={alignment == '%' ?   topWastedFoodzz :topWastedFoodzzWeight} style={{
                width: matches ? 600 : 400,
                height: matches ? 500 : 300,
              }} />
          </Row>
          </div>
      }
    </Col>
    <Col md ={6} xs={6} xxs={6} sm={6} style={{ paddingLeft: "0.5rem" }}>
      {selectedChartsSelectionValue == 'served' &&  selectedDayHrValue == 'hr' &&
        <Box style={{ background: '#1C2747', height: 'auto' }}>
          <Box p={3}><Typography variant="h5" style={({ color: "white" })}>Food Waste Quantity (hour)</Typography></Box>
          <Row justify="center">
            {selectedChartsSelectionValue == 'served' && <ReactEcharts
              option={topWastedFoodyy} style={{
                width: matches ? 580 : 400,
                height: matches ? 548 : 350,
              }} />}
          </Row>
        </Box>
      }
    </Col>
    </Row>
    </Box>

}

    <Col xs={6} xxs={6} sm={6} md={6} alignSelf={"start"}>
      {selectedChartsSelectionValue == 'statistics' &&
         <Box style={{ background: '#1C2747', height: matches? '40rem':'28rem' }}>
          <Box p={3}><Typography variant="h5" style={({ color: "white" })} >Top Meals Statistics</Typography></Box>
          <Row justify="end" style={{ paddingRight: "1rem", paddingBottom: "0.7rem" }}>
            <ColorModeProvider mode="light">
              <ToggleButtonGroup
                           style={{ maxWidth: "5rem", maxHeight: "2.4rem",backgroundColor: 'grey' }}

                value={alignment}
                exclusive
                color='primary'
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton value="%" aria-label="left aligned" style={({ color: "white" })} color='primary'>
                  %
                </ToggleButton >
                <ToggleButton value="No" aria-label="centered" style={({ color: "white" })} color='primary'>
                  No.
                </ToggleButton >
              </ToggleButtonGroup>
            </ColorModeProvider>
          </Row>
          {/* <div style={{marginRight: matches ?"25rem":"25rem"}}> */}
          <Row justify='center'>
            <ReactEcharts
              option={alignment == '%' ? topServedMealOverviewx100Stats : topServedMealOverviewStats} style={{
                width: matches ? 600 : 450,
                height: matches ? 500 : 300,
              }} />
            {/* </div> */}
          </Row>
        </Box>
      }
    </Col>
    <Col xs={6} xxs={6} sm={6} md={6} style={{ paddingLeft: "0.5rem" }}>
      <Row >
        {selectedChartsSelectionValue == 'statistics' &&
                <Box style={{ background: '#1C2747', height: matches? '40rem':'28rem' }}>
            <Box p={3}><Typography variant="h5" style={({ color: "white" })}>Top Waste Statistics </Typography></Box>
            <Row justify='end' style={{ paddingRight: "1rem" }}>
              {/* <Select style={{ width: "6rem" }}
                options={wastageChartSelectionOptions}
                value={selectedWasteChartsSelectionValue}
                defaultValue="Overview"
                onChange={(event, value) => handleSetSelectedWasteChartsSelectionValue(event, value)}
              ></Select> */}
            </Row>
            <Row justify='center'> {
              //  <ReactEcharts
              //   option={selectedWasteChartsSelectionValue == 'cost' ? topWasteCostOverview : selectedWasteChartsSelectionValue == 'weight' ? topWasteWeightOverview : topWasteTrolleyOverview} style={{
              //     width: matches ? 750 : 350,
              //     height: matches ? 510 : 310,
              //   }} />


              <ReactEcharts
                option={topWasteWeightWeight}
                style={{
                  width: matches ? 750 : 380,
                  height: matches ? 510 : 350,
                }} />

            }   </Row>        </Box>
        }
      </Row>

    </Col>

  </Row>
</Container>
)}

export default CateringReport