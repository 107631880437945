export const fetchData =  () => {


  
  const jsoned = mockFlightPaxInfo;
  const jsoned2 = mockFlightFoodInfo;
  const ddate = mockFlightPaxInfo[0].flightDate;
  const arr1 = jsoned[0].paxInfo.passengers;
  const arr2 = jsoned[0].paxInfo.preOrder;
  const arr3 = jsoned2[0].foodInfo;

  //let merged = [];
  const merged = arr1.map((paxInfo) => {
    const preorder = arr2.find((p) => p.seatId === paxInfo.holdSeatId);
    const foodOrder =
      arr3.find((food) => food.id === preorder?.foodTypeId) || null;
    return { ...paxInfo, preorder, foodOrder,orderType:preorder?.orderType };
  });

  return merged;
};

export const cateringMemo=[{id:0, bcCateringPercent: 65,  ecCateringPercent: 98 ,serviceHours :124, waste: 10.4},
]

export const mealsServedByDate = [
  { timestamp: "2021-05-06T11:41:00.000Z", value: 155, id: 1 },
  { timestamp: "2021-05-06T11:42:00.000Z", value: 310, id: 2 },
  { timestamp: "2021-05-09T10:18:00.000Z", value: 250, id: 3 },
  { timestamp: "2021-04-09T10:19:00.000Z", value: 286, id: 4 },
  { timestamp: "2021-04-09T10:23:00.000Z", value: 440, id: 5 },
  { timestamp: "2021-04-09T10:33:00.000Z", value: 365, id: 6 },
  { timestamp: "2021-04-09T10:34:00.000Z", value: 381, id: 7 },
  { timestamp: "2021-04-09T10:47:00.000Z", value: 399, id: 8 },
  { timestamp: "2021-04-09T10:26:00.000Z", value: 172, id: 9 },
  { timestamp: "2021-04-09T10:43:00.000Z", value: 72, id: 10 },
  { timestamp: "2021-04-09T10:54:00.000Z", value: 412, id: 11 },
  { timestamp: "2021-04-09T11:00:00.000Z", value: 391, id: 12 },
  { timestamp: "2021-04-09T11:14:00.000Z", value: 101, id: 13 },
  { timestamp: "2021-04-09T10:29:00.000Z", value: 86, id: 14 },
  { timestamp: "2021-04-09T10:39:00.000Z", value: 26, id: 15 },
  { timestamp: "2021-04-09T11:33:00.000Z", value: 228, id: 16 },
  { timestamp: "2021-04-09T10:53:00.000Z", value: 207, id: 17 },
  { timestamp: "2021-04-09T10:25:00.000Z", value: 195, id: 18 },
  { timestamp: "2021-04-09T11:06:00.000Z", value: 309, id: 19 },
  { timestamp: "2021-04-09T11:21:00.000Z", value: 23, id: 20 },
  { timestamp: "2021-04-09T10:42:00.000Z", value: 309, id: 21 },
  { timestamp: "2021-04-09T11:20:00.000Z", value: 35, id: 22 },
  { timestamp: "2021-04-09T10:36:00.000Z", value: 171, id: 23 },
  { timestamp: "2021-04-09T10:27:00.000Z", value: 108, id: 24 },
  { timestamp: "2021-04-09T10:44:00.000Z", value: 389, id: 25 },
  { timestamp: "2021-04-09T11:08:00.000Z", value: 259, id: 26 },
  { timestamp: "2021-04-09T11:23:00.000Z", value: 85, id: 27 },
  { timestamp: "2021-04-09T10:24:00.000Z", value: 313, id: 28 },
  { timestamp: "2021-04-09T11:01:00.000Z", value: 255, id: 29 },
  { timestamp: "2021-04-09T11:15:00.000Z", value: 444, id: 30 },
  { timestamp: "2021-04-09T11:24:00.000Z", value: 408, id: 31 },
  { timestamp: "2021-04-09T10:40:00.000Z", value: 442, id: 32 },
  { timestamp: "2021-04-09T11:34:00.000Z", value: 455, id: 33 },
  { timestamp: "2021-04-09T10:37:00.000Z", value: 346, id: 34 },
  { timestamp: "2021-04-09T10:48:00.000Z", value: 36, id: 35 },
  { timestamp: "2021-04-08T08:28:00.000Z", value: 353, id: 36 },
  { timestamp: "2021-04-08T08:40:00.000Z", value: 464, id: 37 },
  { timestamp: "2021-04-08T08:50:00.000Z", value: 255, id: 38 },
  { timestamp: "2021-04-08T09:08:00.000Z", value: 59, id: 39 },
  { timestamp: "2021-04-08T10:33:00.000Z", value: 173, id: 40 },
  { timestamp: "2021-04-08T10:39:00.000Z", value: 143, id: 41 },
  { timestamp: "2021-04-08T11:17:00.000Z", value: 439, id: 42 },
  { timestamp: "2021-04-08T11:19:00.000Z", value: 160, id: 43 },
  { timestamp: "2021-04-08T11:35:00.000Z", value: 28, id: 44 },
  { timestamp: "2021-04-08T12:01:00.000Z", value: 238, id: 45 },
  { timestamp: "2021-04-08T12:02:00.000Z", value: 264, id: 46 },
  { timestamp: "2021-04-08T12:09:00.000Z", value: 138, id: 47 },
  { timestamp: "2021-04-08T12:10:00.000Z", value: 226, id: 48 },
  { timestamp: "2021-04-08T12:11:00.000Z", value: 270, id: 49 },
  { timestamp: "2021-04-08T12:14:00.000Z", value: 170, id: 50 },
  { timestamp: "2021-04-08T12:18:00.000Z", value: 135, id: 51 },
  { timestamp: "2021-04-08T12:26:00.000Z", value: 37, id: 52 },
  { timestamp: "2021-04-08T12:46:00.000Z", value: 157, id: 53 },
  { timestamp: "2021-04-08T13:11:00.000Z", value: 203, id: 54 },
  { timestamp: "2021-04-08T13:12:00.000Z", value: 424, id: 55 },
  { timestamp: "2021-04-08T13:13:00.000Z", value: 200, id: 56 },
  { timestamp: "2021-04-08T13:44:00.000Z", value: 114, id: 57 },
  { timestamp: "2021-04-08T13:45:00.000Z", value: 305, id: 58 },
  { timestamp: "2021-04-08T13:46:00.000Z", value: 13, id: 59 },
  { timestamp: "2021-04-08T14:57:00.000Z", value: 410, id: 60 },
  { timestamp: "2021-04-08T15:09:00.000Z", value: 20, id: 61 },
  { timestamp: "2021-04-08T15:15:00.000Z", value: 493, id: 62 },
  { timestamp: "2021-04-09T10:52:00.000Z", value: 230, id: 63 },
  { timestamp: "2021-04-09T11:37:00.000Z", value: 416, id: 64 },
  { timestamp: "2021-04-08T11:18:00.000Z", value: 318, id: 65 },
  { timestamp: "2021-04-08T11:20:00.000Z", value: 453, id: 66 },
  { timestamp: "2021-04-08T12:15:00.000Z", value: 492, id: 67 },
  { timestamp: "2021-04-08T14:58:00.000Z", value: 393, id: 68 },
  { timestamp: "2021-04-09T11:02:00.000Z", value: 298, id: 69 },
  { timestamp: "2021-04-08T08:51:00.000Z", value: 435, id: 70 },
  { timestamp: "2021-04-08T12:13:00.000Z", value: 63, id: 71 },
  { timestamp: "2021-04-08T12:23:00.000Z", value: 35, id: 72 },
  { timestamp: "2021-04-08T14:59:00.000Z", value: 172, id: 73 },
  { timestamp: "2021-04-08T15:07:00.000Z", value: 107, id: 74 },
  { timestamp: "2021-04-08T15:08:00.000Z", value: 284, id: 75 },
  { timestamp: "2021-04-09T09:41:00.000Z", value: 258, id: 76 },
  { timestamp: "2021-04-09T09:42:00.000Z", value: 246, id: 77 },
  { timestamp: "2021-04-08T11:02:00.000Z", value: 303, id: 78 },
  { timestamp: "2021-04-08T11:34:00.000Z", value: 491, id: 79 },
  { timestamp: "2021-04-08T12:12:00.000Z", value: 85, id: 80 },
  { timestamp: "2021-04-08T12:21:00.000Z", value: 258, id: 81 },
  { timestamp: "2021-04-08T15:13:00.000Z", value: 290, id: 82 },
  { timestamp: "2021-04-09T11:25:00.000Z", value: 221, id: 83 },
  { timestamp: "2021-04-08T12:22:00.000Z", value: 153, id: 84 },
  { timestamp: "2021-04-09T11:03:00.000Z", value: 475, id: 85 },
  { timestamp: "2021-04-09T11:26:00.000Z", value: 362, id: 86 },
  { timestamp: "2021-04-08T15:14:00.000Z", value: 165, id: 87 },
  { timestamp: "2021-04-08T12:20:00.000Z", value: 340, id: 88 },
  { timestamp: "2021-04-08T12:17:00.000Z", value: 53, id: 89 },
  { timestamp: "2021-04-08T12:03:00.000Z", value: 135, id: 90 },
  { timestamp: "2021-04-08T12:24:00.000Z", value: 349, id: 91 },
  { timestamp: "2021-04-08T16:11:00.000Z", value: 299, id: 92 },
  { timestamp: "2021-04-08T09:51:00.000Z", value: 394, id: 93 },
  { timestamp: "2021-04-08T12:19:00.000Z", value: 30, id: 94 },
  { timestamp: "2021-04-08T10:56:00.000Z", value: 85, id: 95 },
  { timestamp: "2021-04-08T11:21:00.000Z", value: 171, id: 96 },
  { timestamp: "2021-04-08T12:27:00.000Z", value: 223, id: 97 },
  { timestamp: "2022-04-08T16:13:00.000Z", value: 433, id: 98 },
  { timestamp: "2022-04-08T12:47:00.000Z", value: 391, id: 99 },
  { timestamp: "2022-04-08T13:47:00.000Z", value: 410, id: 100 },
];


export const reportData = {
	"flightNo": "AIB8888",
	"flightDate": "2022-12-13",
	"report": {
		"mealsCensus": {
			"bcNotTaken": 1,
			"bcServed": 0,
			"censusCategories": [{
				"censusCategoryName": "Meat",
				"quantity": 1
			}, {
				"censusCategoryName": "Special",
				"quantity": 5
			}, {
				"censusCategoryName": "Veggie",
				"quantity": 4
			}],
			"ecNotTaken": 0.9968,
			"ecServed": 0.0032,
			"totalUnits": 5700
		},
		"serviceCensus": {
			"bcClockDial": {
				"first": 2,
				"fourth": 5,
				"second": 3,
				"speed": 0,
				"third": 4
			},
			"ecClockDial": {
				"first": 1,
				"fourth": 4,
				"second": 2,
				"speed": 0,
				"third": 3
			},
			"totalHours": 0.0095
		},
		"wastedCensus": {
			"cost": 47,
			"footprint": 0.43,
			"materialNameWeights": [{
				"categoryName": "Fruit",
				"materialName": "Salad",
				"materialWeight": 0.137
			}, {
				"categoryName": "Meat",
				"materialName": "Chicken",
				"materialWeight": 0.038
			}, {
				"categoryName": "Meat",
				"materialName": "Shrimp",
				"materialWeight": 0.102
			}, {
				"categoryName": "Special",
				"materialName": "Noodle",
				"materialWeight": 0.218
			}, {
				"categoryName": "Veggie",
				"materialName": "Broccoli",
				"materialWeight": 0.064
			}],
			"weight": 0.559
		}
	}
}


export const flightWasteDuration= {
	"flightNo": "AIB8888",
	"flightDate": "2022-12-13",
	"ori": "WBGG",
	"dest": "WMKK",
	"statisticWay": "DURATION",
	"report": {
		"flightDurationWastePercentages": [{
			"flightDuration": "2",
			"flightDurationSubs": [{
				"flightNum": "AIB8888",
				"wastePercentage": 0.1863
			}],
			"wastePercentage": 0.1863
		}],
		"flightNumberWastePercentages": [],
		"foodMaterialWastes": [{
			"categoryName": "Veggie",
			"materialName": "Broccoli",
			"materialPercentage": 1
		}, {
			"categoryName": "Meat",
			"materialName": "Chicken",
			"materialPercentage": 1
		}, {
			"categoryName": "Special",
			"materialName": "Noodle",
			"materialPercentage": 0.545
		}, {
			"categoryName": "Special",
			"materialName": "Rice",
			"materialPercentage": 0
		}, {
			"categoryName": "Fruit",
			"materialName": "Salad",
			"materialPercentage": 0.0856
		}, {
			"categoryName": "Meat",
			"materialName": "Shrimp",
			"materialPercentage": 0.1275
		}]
	}
}

export const flightWasteFlightNo ={
	"flightNo": "AIB8888",
	"flightDate": "2022-12-13",
	"ori": "WBGG",
	"dest": "WMKK",
	"statisticWay": "FLIGHT_NUMBER",
	"report": {
		"flightDurationWastePercentages": [],
		"flightNumberWastePercentages": [{
			"flightNum": "AIB8888",
			"wastePercentage": 0.1863
		}],
		"foodMaterialWastes": [{
			"categoryName": "Veggie",
			"materialName": "Broccoli",
			"materialPercentage": 1
		}, {
			"categoryName": "Meat",
			"materialName": "Chicken",
			"materialPercentage": 1
		}, {
			"categoryName": "Special",
			"materialName": "Noodle",
			"materialPercentage": 0.545
		}, {
			"categoryName": "Special",
			"materialName": "Rice",
			"materialPercentage": 0
		}, {
			"categoryName": "Fruit",
			"materialName": "Salad",
			"materialPercentage": 0.0856
		}, {
			"categoryName": "Meat",
			"materialName": "Shrimp",
			"materialPercentage": 0.1275
		}]
	}
}
export const dayTrend ={
	"flightNo": "AIB8888",
	"flightDate": "2022-12-13",
	"ori": "WBGG",
	"dest": "WMKK",
	"statisticWay": "DAY",
	"report": {
		"servedTrends": [{
			"servedPercentage": 0.0017,
			"servedQuantity": 10,
			"time": "2022-12-07"
		}],
		"wasteTrends": [{
			"categoryName": "Fruit",
			"time": "2022-12-07",
			"weight": 0.137
		}, {
			"categoryName": "Meat",
			"time": "2022-12-07",
			"weight": 0.14
		}, {
			"categoryName": "Special",
			"time": "2022-12-07",
			"weight": 0.218
		}, {
			"categoryName": "Veggie",
			"time": "2022-12-07",
			"weight": 0.064
		}]
	}
}


export const hourTrend=   {
  "flightNo": "AIB8888",
  "flightDate": "2022-12-13",
  "ori": "WBGG",
  "dest": "WMKK",
  "statisticWay": "HOUR",
  "report": {
    "servedTrends": [{
      "servedPercentage": 0.0017,
      "servedQuantity": 10,
      "time": "8"
    }],
    "wasteTrends": [{
      "categoryName": "Fruit",
      "time": "8",
      "weight": 0.137
    }, {
      "categoryName": "Meat",
      "time": "8",
      "weight": 0.14
    }, {
      "categoryName": "Special",
      "time": "8",
      "weight": 0.218
    }, {
      "categoryName": "Veggie",
      "time": "8",
      "weight": 0.064
    }]
  }
}


export const abcwastepercent1 = [{
  categoryName: "Fruit",
  time: "2022-12-07",
  weight: (0.137 / 0.559) * 100
}, {
  categoryName: "Meat",
  time: "2022-12-07",
  weight: (0.14 / 0.559) * 100
}, {
  categoryName: "Special",
  time: "2022-12-07",
  weight: (0.218 / 0.559) * 100
}, {
  categoryName: "Vegetables",
  time: "2022-12-07",
  weight: (0.064 / 0.559) * 100
}]
export const doughnutChart =
{
  tooltip: {
    trigger: 'item'
  },
  legend: {
    left: "2%",
    top: '18%',
    // left:"0.08%",
    orient: 'vertical',
    align: 'left',
    height: '180px', //example
    // backgroundColor: '#ccc',
    textStyle: {
      color: 'white'
      // ...
    }
  },
  dataset: {
    // Provide a set of data.
    source: abcwastepercent1
  },
  // Declare an x-axis (category axis).
  // The category map the first column in the dataset by default.
  // Declare a y-axis (value axis).
  // Declare several 'bar' series,
  // every series will auto-map to each column by default.
  series: [{
    type: 'pie',
    label: {
      show: false,
      position: 'center'
    },
    labelLine: {
      show: true
    },
    radius: ['40%', '70%'],
    avoidLabelOverlap: false,
  }]
};
export const gaugeX = {
  title: {
    show: false,
  },
  series: [
    {
      min: 0,
      max: 10,
      type: 'gauge',
      startAngle: 180,
      endAngle: 0,
      progress: {
        show: false,
        width: 5,
      },
      data: [
        {
          value: 0,
          // name: 'BC',
        },
      ],
      axisLine: {
        roundCap: false,
        lineStyle: {
          width: 12,
          color: [
            [0.3, '#F44336'],
            [0.66, '#FFD600'],
            [1, '#8BC34A'],
          ],
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        length: 15,
        lineStyle: {
          width: 0,
          color: '#999',
        },
      },
      axisLabel: {
        show: false,
        distance: -70,
        color: '#999',
        fontSize: 14,
        formatter: function (value) {
          if (value === 2) {
            return 'Slower';
          } else if (value === 5) {
            return 'Planned';
          } else if (value === 8) {
            return 'Faster';
          }
          return '';
        },
      },
      pointer: {
        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
        length: '85%',
        width: 6,
        offsetCenter: [0, '0'],
      },
      anchor: {
        show: true,
        showAbove: true,
        size: 10,
        itemStyle: {
          borderWidth: 3,
        },
      },
      detail: {
        show: false,
        // valueAnimation: true,
        // fontSize: 30,
        // offsetCenter: [0, '20%'],
      },
    },
  ],
};
export const gaugeY = {
  title: {
    left: 'center',
  },
  series: [
    {
      min: 0,
      max: 10,
      type: 'gauge',
      startAngle: 180,
      endAngle: 0,
      progress: {
        show: false,
        width: 5,
      },
      data: [
        {
          value: 0,
          // name: 'EC',
        },
      ],
      axisLine: {
        roundCap: false,
        lineStyle: {
          width: 12,
          color: [
            [0.3, '#F44336'],
            [0.66, '#FFD600'],
            [1, '#8BC34A'],
          ],
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        length: 15,
        lineStyle: {
          width: 0,
          color: '#999',
        },
      },
      axisLabel: {
        show: true,
        distance: -70,
        color: '#999',
        fontSize: 14,
        formatter: function (value) {
          if (value === 2) {
            return 'Slower';
          } else if (value === 5) {
            return 'Planned';
          } else if (value === 8) {
            return 'Faster';
          }
          return '';
        },
      },
      pointer: {
        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
        length: '85%',
        width: 6,
        offsetCenter: [0, '0'],
      },
      anchor: {
        show: true,
        showAbove: true,
        size: 10,
        itemStyle: {
          borderWidth: 3,
        },
      },
      detail: {
        show: false,
        valueAnimation: true,
        fontSize: 30,
        offsetCenter: [0, '20%'],
      },
    },
  ],
};

export const abcwastenum = 

[{'categoryName': 'Veggie', 'materialName': 'Broccoli', 'weight': 0.04},
 {'categoryName': 'Meat', 'materialName': 'Chicken', 'weight': 0.068}, 
{'categoryName': 'Fruit', 'materialName': 'Fruit', 'weight': 0.112},
 {'categoryName': 'Special', 'materialName': 'Noodle', 'weight': 0.168},
 {'categoryName': 'Meat', 'materialName': 'Shrimp', 'weight': 0.016}, 
{'categoryName': 'Drink', 'materialName': 'Waster', 'weight': 0.06}]


const abcwastepercent = abcwastenum.map(item => {
  var modifiedItem = Object.assign({}, item);
  modifiedItem.weight = (modifiedItem.weight/0.559)*100 ;

  return modifiedItem;
});


export const topWastedFoodzzWeight = {
  grid: {
    right: 60,
    containLabel: true
  },
  legend: {
    data: ['Weight'],
    textStyle: {
      color: 'white'
    }
  },
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['categoryName', 'weight'],
    source: abcwastenum
  },
    // {fromDatasetIndex: 0,
    //   transform: {
    //     type: 'sort',
    //     config: { dimension:"Quantity", order: 'desc' }
    //   }
    // }
  ],
  yAxis: { type: 'value', name: 'Weight (kg)' },
  xAxis: {
    type: 'category', name: 'Food Category',
  },
  series: [{ type: 'bar', name:'Weight' }]
}
export const topWastedFoodzz = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    // right: 40,
    containLabel: true
  },
  legend: {
    data: ['Weight'],
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['categoryName', 'weight'],
    source: abcwastepercent
  },
    // {fromDatasetIndex: 0,
    //   transform: {
    //     type: 'sort',
    //     config: { dimension:"Quantity", order: 'desc' }
    //   }
    // }
  ],
  yAxis: {
    type: 'value', name: 'Weight (%)',
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  xAxis: {
    type: 'category', name: 'Food Category',
    nameLocation: 'center',
    nameTextStyle: {
      align: 'center',
      verticalAlign: 'top',
      /**
       * the top padding will shift the name down so that it does not overlap with the axis-labels
       * t-l-b-r
       */
      padding: [20, 0, 0, 0],
    },
    axisLine: {
      lineStyle: {
        color: 'white'
      },
      axisLabel: {
        interval: 0,
      },
    }
  },
  series: [{ type: 'bar', name:'Weight' }]
}

export const sumTotalFoodCategoryDemand=
[{'foodCategoryName': 'Drink', 'foodTypeName': '20286767', 'offeredCount': 2}, 
{'foodCategoryName': 'Drink', 'foodTypeName': '4056489010104', 'offeredCount': 2}, 
{'foodCategoryName': 'Drink', 'foodTypeName': '4056489032984', 'offeredCount': 2}, 
{'foodCategoryName': 'Drink', 'foodTypeName': '4311501793572', 'offeredCount': 1}, 
{'foodCategoryName': 'Special', 'foodTypeName': 'CakeBlackWalnut', 'offeredCount': 6}, 
{'foodCategoryName': 'Meat', 'foodTypeName': 'Chicken Noodles', 'offeredCount': 6},
 {'foodCategoryName': 'Fruit', 'foodTypeName': 'Fruit with grape', 'offeredCount': 10},
 {'foodCategoryName': 'Special', 'foodTypeName': 'Noodles with Shrim', 'offeredCount': 4},
 {'foodCategoryName': 'Veggie', 'foodTypeName': 'Salad with Blueberry', 'offeredCount': 4}, 
{'foodCategoryName': 'Meat', 'foodTypeName': 'Steak', 'offeredCount': 4}]

const sums = {};
for (const { foodCategoryName, offeredCount } of sumTotalFoodCategoryDemand) {
  sums[foodCategoryName] = (sums[foodCategoryName] || 0) + offeredCount;
}
const output = Object.entries(sums).map(([foodCategoryName, total]) => ({ foodCategoryName, total }));
// console.log(output);



const topservedFoodP = output.map(item => {
  var modifiedItem = Object.assign({}, item);
  modifiedItem.total = (modifiedItem.total/5700)*100 ;

  return modifiedItem;
});


export const topServedFoodzzx100 = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    right: 60,
    containLabel: true
  },
  legend: {
    // inactiveColor:"white",
    data: ['Quantity'],
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['foodCategoryName', 'total'],
    source:topservedFoodP
     //[
    //   {
    //     "censusFoodName": "Chicken",
    //     "censusCategoryName": "Meat",
    //     "quantity": (30 / 5700) * 100
    //   }, {
    //     "censusFoodName": "Noodles",
    //     "censusCategoryName": "Special",
    //     "quantity": (40 / 5700) * 100
    //   }, {
    //     "censusFoodName": "Brocolli",
    //     "censusCategoryName": "Vegetables",
    //     "quantity": (20 / 5700) * 100
    //   }
    //   , {
    //     "censusFoodName": "Fruit with Apple",
    //     "censusCategoryName": "Fruit",
    //     "quantity": (10 / 5700) * 100
    //   }
    // ]
  },
  ],
  yAxis: {
    type: 'value', name: 'Quantity (%)',
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  xAxis: {
    type: 'category', name: 'Food Category',
    // nameLocation: 'center',
    // nameTextStyle: {
    //   padding:["20rem",0,0,0]
    // },
    nameLocation: 'center',
    nameTextStyle: {
      align: 'center',
      verticalAlign: 'top',
      /**
       * the top padding will shift the name down so that it does not overlap with the axis-labels
       * t-l-b-r
       */
      padding: [20, 0, 0, 0],
    },
    axisLabel: {
      interval: 0,
    },
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  series: [{ type: 'bar', name: "Quantity" }]
}
export const topServedFoodzz = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    right: 120,
    containLabel: true
  },
  legend: {
    data: ['Quantity'],
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['foodCategoryName', 'total'],
    source: output
    //   {
    //     "censusFoodName": "Chicken",
    //     "censusCategoryName": "Meat",
    //     "quantity": 30
    //   }, {
    //     "censusFoodName": "Noodles",
    //     "censusCategoryName": "Special",
    //     "quantity": 40
    //   }, {
    //     "censusFoodName": "Brocolli",
    //     "censusCategoryName": "Vegetables",
    //     "quantity": 20
    //   },
    //   {
    //     "censusFoodName": "Fruit with Apple",
    //     "censusCategoryName": "Fruit",
    //     "quantity": 10
    //   },
    // ]
  }, {
    transform: [
      {
        type: 'sort',
        config: { dimension: 'quantity', order: 'desc' }
      }
    ],
  }
  ],
  yAxis: {
    type: 'value', name: 'Quantity',
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  xAxis: {
    type: 'category', name: 'Food Category',
    axisLabel: {
      interval: 0,
    },
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  series: [{ type: 'bar', name: "Quantity" }]
}
export const topServedFoodOverviewx100 = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    right: 60,
    containLabel: true
  },
  legend: {
    // inactiveColor:"white",
    data: ['Quantity'],
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['censusFoodName', 'quantity'],
    source: [
      {
        "censusFoodName": "Chicken",
        "censusCategoryName": "Meat",
        "quantity": (30 / 5700) * 100
      }, {
        "censusFoodName": "Noodles",
        "censusCategoryName": "Special",
        "quantity": (40 / 5700) * 100
      }, {
        "censusFoodName": "Brocolli",
        "censusCategoryName": "Vegetables",
        "quantity": (20 / 5700) * 100
      }
      , {
        "censusFoodName": "Fruit with Apple",
        "censusCategoryName": "Fruit",
        "quantity": (10 / 5700) * 100
      }
    ]
  },
  ],
  xAxis: {
    type: 'value', name: 'Quantity (%)', nameLocation: 'center',
    nameTextStyle: {
      align: 'center',
      verticalAlign: 'top',
      /**
       * the top padding will shift the name down so that it does not overlap with the axis-labels
       * t-l-b-r
       */
      padding: [20, 0, 0, 0],
    }, axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  yAxis: {
    type: 'category', name: 'Meal Name', axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  series: [{ type: 'bar', name: 'Quantity' }]
}
export const topServedFoodOverview = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    right: 100,
    containLabel: true
  },
  legend: {
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['censusFoodName', 'quantity'],
    source: [
      {
        "censusFoodName": "Chicken",
        "censusCategoryName": "Meat",
        "quantity": 30
      }, {
        "censusFoodName": "Noodles",
        "censusCategoryName": "Special",
        "quantity": 40
      }, {
        "censusFoodName": "Brocolli",
        "censusCategoryName": "Vegetables",
        "quantity": 20
      },
      {
        "censusFoodName": "Fruit with Apple",
        "censusCategoryName": "Fruit",
        "quantity": 10
      },
    ]
  }, {
    transform: [
      {
        type: 'sort',
        config: { dimension: 'quantity', order: 'desc' }
      }
    ],
  }
  ],
  xAxis: {
    type: 'value', name: 'Quantity',
    nameLocation: 'center',
    nameTextStyle: {
      align: 'center',
      verticalAlign: 'top',
      /**
       * the top padding will shift the name down so that it does not overlap with the axis-labels
       * t-l-b-r
       */
      padding: [20, 0, 0, 0],
    },
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  yAxis: {
    type: 'category', name: 'Meal Name',
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  series: [{ type: 'bar' }]
}

export const topWastedFoodyy = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    containLabel: true
  },
  legend: {
    data: ['Quantity'], textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['time', 'weight'],
    source:
      [{
        "categoryName": "Fruit",
        "time": "0",
        "weight": 0
      },
      {
        "categoryName": "Fruit",
        "time": "2",
        "weight": 0.137
      }, {
        "categoryName": "Meat",
        "time": "4",
        "weight": 0.14
      }, {
        "categoryName": "Special",
        "time": "6",
        "weight": 0.218
      }, {
        "categoryName": "Veggie",
        "time": "8",
        "weight": 0.064
      }]
  },
    // {fromDatasetIndex: 0,
    //   transform: {
    //     type: 'sort',
    //     config: { dimension:"servedQuantity", order: 'desc' }
    //   }
    // }
  ],
  yAxis: {
    type: 'value', name: 'Weight', axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  xAxis: {
    type: 'value', name: 'Time (hrs)',
    nameLocation: 'center',
    nameTextStyle: {
      align: 'center',
      verticalAlign: 'top',
      /**
       * the top padding will shift the name down so that it does not overlap with the axis-labels
       * t-l-b-r
       */
      padding: [20, 0, 0, 0],
    },
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  series: [{ type: 'line', name: 'Quantity', stack: 'total', }]
}

export const topWasteCostOverview = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    right: 50,
    containLabel: "true"
  },
  legend: {
    // inactiveColor:"white",
    data: ['Cost'],
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['flightDate', 'cost'],
    source:
      [
        {
          "cost": 21.2,
          "equivalencePerDay": 0.0527,
          "weight": 0.559,
          "flightDate": "2022-12-07",
        },
        {
          "cost": 30,
          "equivalencePerDay": 0.0527,
          "weight": 0.559
          ,
          "flightDate": "2022-12-08",
        },
        {
          "cost": 30,
          "equivalencePerDay": 0.0527,
          "weight": 0.559
          ,
          "flightDate": "2022-12-13",
        }
      ],
  },
    // {fromDatasetIndex: 0,
    //   transform: {
    //     type: 'sort',
    //     config: { dimension:"servedQuantity", order: 'desc' }
    //   }
    // }
  ],
  yAxis: {
    name: 'Cost',
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  xAxis: {
    type: 'category', name: 'Flight Date',
    nameLocation: 'center',
    nameTextStyle: {
      align: 'center',
      verticalAlign: 'top',
      /**
       * the top padding will shift the name down so that it does not overlap with the axis-labels
       * t-l-b-r
       */
      padding: [20, 0, 0, 0],
    },
    axisLabel: {
      interval: 0,
    },
    axisLine: {
      lineStyle: {
        color: 'white'
      },
    }
  },
  series: [{ type: 'line', name: "Cost" }]
}
export const topWasteTrolleyOverview = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    right: 50,
    containLabel: true
  },
  legend: {
    // inactiveColor:"white",
    data: ['Trolley'],
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['flightDate', 'equivalencePerDay'],
    source:
      [
        {
          "cost": 21.2,
          "equivalencePerDay": 0.0527,
          "weight": 0.559,
          "flightDate": "2022-12-07",
        },
        {
          "cost": 30,
          "equivalencePerDay": 0.0527,
          "weight": 0.559
          ,
          "flightDate": "2022-12-08",
        },
        {
          "cost": 30,
          "equivalencePerDay": 0.0527,
          "weight": 0.559
          ,
          "flightDate": "2022-12-13",
        }
      ],
  },
    // {fromDatasetIndex: 0,
    //   transform: {
    //     type: 'sort',
    //     config: { dimension:"servedQuantity", order: 'desc' }
    //   }
    // }
  ],
  yAxis: {
    name: 'Trolleys',
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  xAxis: {
    type: 'category', name: 'Flight Date',
    nameLocation: 'center',
    nameTextStyle: {
      align: 'center',
      verticalAlign: 'top',
      /**
       * the top padding will shift the name down so that it does not overlap with the axis-labels
       * t-l-b-r
       */
      padding: [20, 0, 0, 0],
    },
    axisLabel: {
      interval: 0,
    },
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  series: [{ type: 'bar', name: "Trolley" }]
}
export const topWasteWeightOverview = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    right: 50,
    containLabel: true
  },
  legend: {
    // inactiveColor:"white",
    data: ['Weight'],
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['flightDate', 'weight'],
    source:
      [
        {
          "cost": 21.2,
          "equivalencePerDay": 0.0527,
          "weight": 0.559,
          "flightDate": "2022-12-07",
        },
        {
          "cost": 30,
          "equivalencePerDay": 0.0527,
          "weight": 0.559
          ,
          "flightDate": "2022-12-08",
        },
        {
          "cost": 30,
          "equivalencePerDay": 0.0527,
          "weight": 0.559
          ,
          "flightDate": "2022-12-13",
        }
      ],
  },
    // {fromDatasetIndex: 0,
    //   transform: {
    //     type: 'sort',
    //     config: { dimension:"servedQuantity", order: 'desc' }
    //   }
    // }
  ],
  yAxis: {
    name: 'Weight',
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  xAxis: {
    type: 'category', name: 'Flight Date',
    nameLocation: 'center',
    nameTextStyle: {
      align: 'center',
      verticalAlign: 'top',
      /**
       * the top padding will shift the name down so that it does not overlap with the axis-labels
       * t-l-b-r
       */
      padding: [20, 0, 0, 0],
    },
    axisLabel: {
      interval: 0,
    },
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  series: [{ type: 'bar', name: "Weight" }]
}
export const topServedFoodyy = {
  dataZoom: [
    {
      type: "inside",
      show: true,
      xAxisIndex: 0,
      filterMode: "empty",
      width: 30,
      height: "80%",
      showDataShadow: false,
      left: "93%",
    },
  ],
  grid: {
    right: 50,
    containLabel: true
  },
  legend: {
    // inactiveColor:"white",
    data: ['Quantity'],
    textStyle: {
      color: 'white'
    }
  },
  tooltip: {},
  dataset: [{
    // Define the dimension of array. In cartesian coordinate system,
    // if the type of x-axis is category, map the first dimension to
    // x-axis by default, the second dimension to y-axis.
    // You can also specify 'series.encode' to complete the map
    // without specify dimensions. Please see below.
    dimensions: ['time', 'servedQuantity'],
    source:
      [
        {
          "servedPercentage": 0.00,
          "servedQuantity": 0,
          "time": "0"
        },
        {
          "servedPercentage": 0.00,
          "servedQuantity": 0,
          "time": "2"
        },
        {
          "servedPercentage": 0.00,
          "servedQuantity": 0,
          "time": "4"
        },
        {
          "servedPercentage": 0.00,
          "servedQuantity": 0,
          "time": "6"
        },
        {
          "servedPercentage": 0.0017,
          "servedQuantity": 10,
          "time": "8"
        },
      ],
  },
    // {fromDatasetIndex: 0,
    //   transform: {
    //     type: 'sort',
    //     config: { dimension:"servedQuantity", order: 'desc' }
    //   }
    // }
  ],
  yAxis: {
    type: 'value', name: 'Quantity',
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  xAxis: {
    type: 'value', name: 'Time (hrs)',
    nameLocation: 'center',
    nameTextStyle: {
      align: 'center',
      verticalAlign: 'top',
      /**
       * the top padding will shift the name down so that it does not overlap with the axis-labels
       * t-l-b-r
       */
      padding: [20, 0, 0, 0],
    },
    axisLine: {
      lineStyle: {
        color: 'white'
      }
    }
  },
  series: [{ type: 'line', name: 'Quantity', }]
}

export const mockCateringPayload = 
[
  {
    "id": "574",
    "topic_prefix": "v1/crew/smarttrolley",
    "topic_action": "data",
    "topic_extension": "/acic/foodscanner/report/statistic",
    "header": "{'user_properties': {'noSendToGround': '0', 'noStore': '0', 'userTimestamp': 'user_time.stamp-01234', 'privateData': '0'}}",
    "payload": "{'flightNo': 'MU5101', 'flightDate': '2023-02-26', 'ori': 'ZLXY', 'dest': 'ZGSZ', 'report': {'foodServedPercentage': [], 'foodServedQuantities': [], 'wastedData': {'cost': 0, 'equivalencePerDay': 0, 'weight': 0}, 'wasteStatistics': []}}",
    "tag_no_send_to_ground": "False",
    "transfer_status": "transfer_status_enum.in_transfer_queue",
    "tag_no_store": "False",
    "tag_user_timestamp": "user_time.stamp-01234",
    "tag_private_data": "0",
    "dump_file_name": "ODM-DATA_2023-02-26_08h35m43s_0000000005",
    "tag_custom": "None",
    "created_at": "2023-02-26 08:31:33.976984"
},
  {
      "id": "691",
      "topic_prefix": "v1/crew/smarttrolley",
      "topic_action": "data",
      "topic_extension": "/acic/foodscanner/report/flightwaste/duration",
      "header": "{'user_properties': {'noSendToGround': '0', 'noStore': '0', 'userTimestamp': 'user_time.stamp-01234', 'privateData': '0'}}",
      "payload": "{'flightNo': 'AIB8888', 'flightDate': '2023-02-26', 'ori': 'ZGSZ', 'dest': 'ZBAA', 'statisticWay': 'DURATION', 'report': {'flightDurationWastePercentages': [{'flightDuration': '2', 'flightDurationSubs': [{'flightNum': 'AIB8888', 'wastePercentage': 0.0741}], 'wastePercentage': 0.0741}], 'flightNumberWastePercentages': [], 'foodMaterialWastes': [{'categoryName': 'Veggie', 'materialName': 'Beans', 'materialPercentage': 0}, {'categoryName': 'Veggie', 'materialName': 'Broccoli', 'materialPercentage': 1}, {'categoryName': 'Special', 'materialName': 'Cakes', 'materialPercentage': 0}, {'categoryName': 'Meat', 'materialName': 'Chicken', 'materialPercentage': 1}, {'categoryName': 'Fruit', 'materialName': 'Fruit', 'materialPercentage': 0.028}, {'categoryName': 'Special', 'materialName': 'Noodle', 'materialPercentage': 0.07}, {'categoryName': 'Fruit', 'materialName': 'Salad', 'materialPercentage': 0}, {'categoryName': 'Meat', 'materialName': 'Shrimp', 'materialPercentage': 0.01}, {'categoryName': 'Drink', 'materialName': 'Waster', 'materialPercentage': 0.3333}]}}",
      "tag_no_send_to_ground": "False",
      "transfer_status": "transfer_status_enum.in_transfer_queue",
      "tag_no_store": "False",
      "tag_user_timestamp": "user_time.stamp-01234",
      "tag_private_data": "0",
      "dump_file_name": "ODM-DATA_2023-02-26_08h58m23s_0000000007",
      "tag_custom": "None",
      "created_at": "2023-02-26 08:58:10.604105"
  },
  {
      "id": "690",
      "topic_prefix": "v1/crew/smarttrolley",
      "topic_action": "data",
      "topic_extension": "/acic/foodscanner/realtimereport",
      "header": "{'user_properties': {'noSendToGround': '0', 'noStore': '0', 'userTimestamp': 'user_time.stamp-01234', 'privateData': '0'}}",
      "payload": "{'flightNo': 'AIB8888', 'flightDate': '2023-02-26', 'report': {'mealsCensus': {'bcNotTaken': 1, 'bcServed': 0, 'censusCategories': [{'censusCategoryName': 'Drink', 'quantity': 7}, {'censusCategoryName': 'Fruit', 'quantity': 10}, {'censusCategoryName': 'Meat', 'quantity': 10}, {'censusCategoryName': 'Special', 'quantity': 10}, {'censusCategoryName': 'Veggie', 'quantity': 4}], 'ecNotTaken': 0.9872, 'ecServed': 0.0128, 'totalUnits': 5700}, 'serviceCensus': {'bcClockDial': {'first': 2, 'fourth': 5, 'second': 3, 'speed': 0, 'third': 4}, 'ecClockDial': {'first': 1, 'fourth': 4, 'second': 2, 'speed': 1, 'third': 3}, 'totalHours': 1.0134}, 'wastedCensus': {'cost': 44.1, 'footprint': 0.7723, 'materialNameWeights': [{'categoryName': 'Drink', 'materialName': 'Waster', 'materialWeight': 0.6}, {'categoryName': 'Fruit', 'materialName': 'Fruit', 'materialWeight': 0.112}, {'categoryName': 'Meat', 'materialName': 'Chicken', 'materialWeight': 0.068}, {'categoryName': 'Meat', 'materialName': 'Shrimp', 'materialWeight': 0.016}, {'categoryName': 'Special', 'materialName': 'Noodle', 'materialWeight': 0.168}, {'categoryName': 'Veggie', 'materialName': 'Broccoli', 'materialWeight': 0.04}], 'weight': 1.004}}}",
      "tag_no_send_to_ground": "False",
      "transfer_status": "transfer_status_enum.in_transfer_queue",
      "tag_no_store": "False",
      "tag_user_timestamp": "user_time.stamp-01234",
      "tag_private_data": "0",
      "dump_file_name": "ODM-DATA_2023-02-26_08h58m23s_0000000007",
      "tag_custom": "None",
      "created_at": "2023-02-26 08:58:10.590242"
  }]

  export const mockFlightPaxInfo= 

  [
    {
      "flightNo": "AIB8888",
      "flightDate": "2023-03-20",
      "flightId": "6417f5180c5374a4d72b4b5f",
      "paxInfo": {
        "passengers": [
          {
            "age": 37,
            "gender": "Woman",
            "holdSeatId": "63282c0952dba06a20b52d72",
            "id": "6417f55d0c5374a4d72b50fd",
            "PAXName": "Kangrou Fu",
            "seatName": "10A",
            "seatType": "BC",
            "vip": false
          },
          {
            "age": 40,
            "gender": "Woman",
            "holdSeatId": "6417f5440c5374a4d72b4fd4",
            "id": "6417f55d0c5374a4d72b50ff",
            "PAXName": "Huishao Wu",
            "seatName": "21A",
            "seatType": "EC",
            "vip": false
          },
          {
            "age": 41,
            "gender": "Woman",
            "holdSeatId": "6417f5440c5374a4d72b4fe6",
            "id": "6417f55d0c5374a4d72b5101",
            "PAXName": "Jianmin Hui",
            "seatName": "22A",
            "seatType": "EC",
            "vip": false
          },
          {
            "age": 44,
            "gender": "Woman",
            "holdSeatId": "63282c0952dba06a20b52d79",
            "id": "6417f55d0c5374a4d72b5103",
            "PAXName": "Pei Li",
            "seatName": "10K",
            "seatType": "BC",
            "vip": false
          },
          {
            "age": 32,
            "gender": "Man",
            "holdSeatId": "63282c0952dba06a20b52d2b",
            "id": "6417f55d0c5374a4d72b5105",
            "PAXName": "Lei Li",
            "seatName": "1A",
            "seatType": "BC",
            "vip": true
          },
          {
            "age": 23,
            "gender": "Woman",
            "holdSeatId": "63282c0952dba06a20b52d33",
            "id": "6417f55d0c5374a4d72b5107",
            "PAXName": "Meimei Han",
            "seatName": "3A",
            "seatType": "BC",
            "vip": true
          }
        ],
        "preOrder": [
          {
            "containerId": "6417f5180c5374a4d72b4b63",
            "foodTypeId": "632000e0cdcfde6b6088001a",
            "id": "6417f56a0c5374a4d72b5325",
            "locationId": "6417f5180c5374a4d72b4b66",
            "orderType": "Pre_Ordered",
            "collectedTime": "1679626299623", 
            "distributedTime": "1679626102035",
            "qty": 1,
            "seatId": "63282c0952dba06a20b52d72"
          },
          {
            "containerId": "6417f5180c5374a4d72b4b63",
            "foodTypeId": "6295bb887115c2151edef87d",
            "id": "6417f56a0c5374a4d72b5327",
            "locationId": "6417f5180c5374a4d72b4b71",
            "orderType": "Special_Meal",
            "collectedTime": "1679626299623", 
            "distributedTime": "1679626102035",
            "qty": 1,
            "seatId": "6417f5440c5374a4d72b4fd4"
          },
          {
            "containerId": "6417f5180c5374a4d72b4b63",
            "foodTypeId": "632000e0cdcfde6b6088001a",
            "id": "6417f56a0c5374a4d72b5329",
            "locationId": "6417f5180c5374a4d72b4b66",
            "orderType": "Pre_Ordered",
            "collectedTime": "1679626299623", 
            "distributedTime": "1679626102035",
            "qty": 1,
            "seatId": "6417f5440c5374a4d72b4fe6"
          },
          {
            "containerId": "6417f5180c5374a4d72b4b63",
            "foodTypeId": "6295bb887115c2151edef87d",
            "id": "6417f56a0c5374a4d72b532b",
            "locationId": "6417f5180c5374a4d72b4b71",
            "orderType": "Special_Meal",
            "collectedTime": "1679626299623", 
            "distributedTime": "1679626102035",
            "qty": 1,
            "seatId": "63282c0952dba06a20b52d79"
          },
          {
            "containerId": "6417f5180c5374a4d72b4b63",
            "foodTypeId": "632000e0cdcfde6b6088001a",
            "id": "6417f56a0c5374a4d72b532d",
            "locationId": "6417f5180c5374a4d72b4b66",
            "orderType": "Pre_Ordered",
            "collectedTime": "1679626299623", 
            "distributedTime": "1679626102035",
            "qty": 1,
            "seatId": "63282c0952dba06a20b52d2b"
          },
          {
            "containerId": "6417f5180c5374a4d72b4b63",
            "foodTypeId": "6295bcf77115c2151edef9ff",
            "id": "6417f56a0c5374a4d72b532f",
            "locationId": "6417f5180c5374a4d72b4b66",
            "orderType": "Pre_Ordered",
            "collectedTime": "1679626299623", 
            "distributedTime": "1679626102035",
            "qty": 1,
            "seatId": "63282c0952dba06a20b52d33"
          }
        ]
      }
    }
  ]


export const mockFlightFoodInfo=[{
  "flightNo": "AIB8888",
  "flightDate": "2023-03-20",
  "flightId": "6417f5180c5374a4d72b4b5f",
  "foodInfo": [
    {
      "categoryId": "629481212eebc502d0e6582a",
      "cost": 1,
      "description": "42116455",
      "foodCategoryDescription": "Drink",
      "foodCategoryName": "Drink",
      "foodTypeMaterialIds": [
        "63295a9f4a66be483cd771ab"
      ],
      "fullName": "42116455",
      "id": "62a1bedb6eed1d07342ddbe1",
      "partNumber": "42116455",
      "placeOfProduction": "HAM",
      "seatType": "EC",
      "serialNumber": "42116455",
      "shortName": "42116455",
      "weight": 500
    },
    {
      "categoryId": "629481212eebc502d0e6582a",
      "cost": 1,
      "description": "5000112547689",
      "foodCategoryDescription": "Drink",
      "foodCategoryName": "Drink",
      "foodTypeMaterialIds": [
        "63295ae54a66be483cd771ae"
      ],
      "fullName": "5000112547689",
      "id": "62a1bedb6eed1d07342ddc14",
      "partNumber": "5000112547689",
      "placeOfProduction": "HAM",
      "seatType": "EC",
      "serialNumber": "5000112547689",
      "shortName": "5000112547689",
      "weight": 500
    },
    {
      "categoryId": "629481212eebc502d0e657c1",
      "cost": 5,
      "description": "Fruit with grape",
      "foodCategoryDescription": "Fruits",
      "foodCategoryName": "Fruit",
      "foodTypeMaterialIds": [
        "632ac0f85212da2fbffbdddd"
      ],
      "fullName": "Fruit with grape",
      "id": "6295bdbe7115c2151edefa5c",
      "partNumber": "Fruit_A",
      "placeOfProduction": "HAM",
      "seatType": "EC",
      "serialNumber": "Fruit_A",
      "shortName": "Fruit with grape",
      "weight": 300
    },
    {
      "categoryId": "629481212eebc502d0e6582a",
      "cost": 1,
      "description": "4311501793572",
      "foodCategoryDescription": "Drink",
      "foodCategoryName": "Drink",
      "foodTypeMaterialIds": [
        "63295ad74a66be483cd771ad"
      ],
      "fullName": "4311501793572",
      "id": "62a1bedb6eed1d07342ddbae",
      "partNumber": "4311501793572",
      "placeOfProduction": "HAM",
      "seatType": "BC",
      "serialNumber": "4311501793572",
      "shortName": "4311501793572",
      "weight": 350
    },
    {
      "categoryId": "629481212eebc502d0e657c1",
      "cost": 10,
      "description": "Fruit with Apple",
      "foodCategoryDescription": "Fruits",
      "foodCategoryName": "Fruit",
      "foodTypeMaterialIds": [
        "632ac22c5212da2fbffbdded"
      ],
      "fullName": "Fruit with Apple",
      "id": "6295be5c7115c2151edefa8d",
      "partNumber": "Fruit_B",
      "placeOfProduction": "HAM",
      "seatType": "BC",
      "serialNumber": "Fruit_B",
      "shortName": "Fruit with Apple",
      "weight": 300
    },
    {
      "categoryId": "629481202eebc502d0e656ef",
      "cost": 20,
      "description": "Rice with Fire Pork",
      "foodCategoryDescription": "Meal with Meat",
      "foodCategoryName": "Meat",
      "foodTypeMaterialIds": [
        "632ac1005212da2fbffbddde"
      ],
      "fullName": "Rice with Fire Pork",
      "id": "6295bbf17115c2151edef8e7",
      "partNumber": "Pork_Rice",
      "placeOfProduction": "HAM",
      "seatType": "EC",
      "serialNumber": "Pork_Rice",
      "shortName": "Rice with Fire Pork",
      "weight": 300
    },
    {
      "categoryId": "6294811f2eebc502d0e65685",
      "cost": 20,
      "description": "Salad with Blueberry",
      "foodCategoryDescription": "Vegetables",
      "foodCategoryName": "Veggie",
      "foodTypeMaterialIds": [
        "632ac1185212da2fbffbdde0"
      ],
      "fullName": "Salad with Blueberry",
      "id": "6295ba8c7115c2151edef772",
      "partNumber": "Salad_B",
      "placeOfProduction": "HAM",
      "seatType": "BC",
      "serialNumber": "Salad_B",
      "shortName": "Salad with Blueberry",
      "weight": 300
    },
    {
      "categoryId": "629481202eebc502d0e656ef",
      "cost": 40,
      "description": "Steak",
      "foodCategoryDescription": "Meal with Meat",
      "foodCategoryName": "Meat",
      "foodTypeMaterialIds": [
        "632ac1b65212da2fbffbdde7"
      ],
      "fullName": "Steak",
      "id": "6295baf27115c2151edef7dc",
      "partNumber": "Steak",
      "placeOfProduction": "HAM",
      "seatType": "BC",
      "serialNumber": "Steak",
      "shortName": "Steak",
      "weight": 300
    },
    {
      "categoryId": "629481202eebc502d0e656ef",
      "cost": 20,
      "description": "Chicken Noodles",
      "foodCategoryDescription": "Meal with Meat",
      "foodCategoryName": "Meat",
      "foodTypeMaterialIds": [
        "632ac0ed5212da2fbffbdddc"
      ],
      "fullName": "Chicken Noodles",
      "id": "6295bb887115c2151edef87d",
      "partNumber": "Chicken_Noodles",
      "placeOfProduction": "HAM",
      "seatType": "EC",
      "serialNumber": "Chicken_Noodles",
      "shortName": "Chicken Noodles",
      "weight": 300
    },
    {
      "categoryId": "629481212eebc502d0e6578c",
      "cost": 40,
      "description": "Noodles with Shrim",
      "foodCategoryDescription": "Special Meal",
      "foodCategoryName": "Special",
      "foodTypeMaterialIds": [
        "632ac1cd5212da2fbffbdde8"
      ],
      "fullName": "Noodles with Shrim",
      "id": "6295bcf77115c2151edef9ff",
      "partNumber": "Shrim_Noodles",
      "placeOfProduction": "HAM",
      "seatType": "BC",
      "serialNumber": "Shrim_Noodles",
      "shortName": "Noodles with Shrim",
      "weight": 300
    }
  ]
}]


const preOrderedPayload = [
  {
      "age": 37,
      "gender": "Woman",
      "holdSeatId": "63282c0952dba06a20b52d72",
      "id": "6417f56a0c5374a4d72b5325",
      "PAXName": "Kangrou Fu",
      "seatName": "10A",
      "seatType": "BC",
      "vip": false,
      "containerId": "6417f5180c5374a4d72b4b63",
      "foodTypeId": "632000e0cdcfde6b6088001a",
      "locationId": "6417f5180c5374a4d72b4b66",
      "orderType": "Pre_Ordered",
      "qty": 1,
      "seatId": "63282c0952dba06a20b52d72"
  },
  {
      "age": 40,
      "gender": "Woman",
      "holdSeatId": "6417f5440c5374a4d72b4fd4",
      "id": "6417f56a0c5374a4d72b5327",
      "PAXName": "Huishao Wu",
      "seatName": "21A",
      "seatType": "EC",
      "vip": false,
      "containerId": "6417f5180c5374a4d72b4b63",
      "foodTypeId": "6295bb887115c2151edef87d",
      "locationId": "6417f5180c5374a4d72b4b71",
      "orderType": "Special_Meal",
      "qty": 1,
      "seatId": "6417f5440c5374a4d72b4fd4",
      "description": "Chicken Noodles",
      "foodCatergoryDescription": "Meal with Meat",
      "fullName": "Chicken Noodles"
  },
  {
      "age": 41,
      "gender": "Woman",
      "holdSeatId": "6417f5440c5374a4d72b4fe6",
      "id": "6417f56a0c5374a4d72b5329",
      "PAXName": "Jianmin Hui",
      "seatName": "22A",
      "seatType": "EC",
      "vip": false,
      "containerId": "6417f5180c5374a4d72b4b63",
      "foodTypeId": "632000e0cdcfde6b6088001a",
      "locationId": "6417f5180c5374a4d72b4b66",
      "orderType": "Pre_Ordered",
      "qty": 1,
      "seatId": "6417f5440c5374a4d72b4fe6"
  },
  {
      "age": 44,
      "gender": "Woman",
      "holdSeatId": "63282c0952dba06a20b52d79",
      "id": "6417f56a0c5374a4d72b532b",
      "PAXName": "Pei Li",
      "seatName": "10K",
      "seatType": "BC",
      "collectedTime": "1679626299623", 
      "distributedTime": "1679626102035",
      "vip": false,
      "containerId": "6417f5180c5374a4d72b4b63",
      "foodTypeId": "6295bb887115c2151edef87d",
      "locationId": "6417f5180c5374a4d72b4b71",
      "orderType": "Special_Meal",
      "qty": 1,
      "seatId": "63282c0952dba06a20b52d79",
      "description": "Chicken Noodles",
      "foodCatergoryDescription": "Meal with Meat",
      "fullName": "Chicken Noodles"
  },
  {
      "age": 32,
      "gender": "Man",
      "holdSeatId": "63282c0952dba06a20b52d2b",
      "id": "6417f56a0c5374a4d72b532d",
      "PAXName": "Lei Li",
      "seatName": "1A",
      "seatType": "BC",
      "collectedTime": "1679626299623", 
      "distributedTime": "1679626102035",
      "vip": true,
      "containerId": "6417f5180c5374a4d72b4b63",
      "foodTypeId": "632000e0cdcfde6b6088001a",
      "locationId": "6417f5180c5374a4d72b4b66",
      "orderType": "Pre_Ordered",
      "qty": 1,
      "seatId": "63282c0952dba06a20b52d2b"
  },
  {
      "age": 23,
      "gender": "Woman",
      "holdSeatId": "63282c0952dba06a20b52d33",
      "id": "6417f56a0c5374a4d72b532f",
      "PAXName": "Meimei Han",
      "seatName": "3A",
      "seatType": "BC",
      "vip": true,
      "containerId": "6417f5180c5374a4d72b4b63",
      "foodTypeId": "6295bcf77115c2151edef9ff",
      "locationId": "6417f5180c5374a4d72b4b66",
      "orderType": "Pre_Ordered",
      "qty": 1,
      "seatId": "63282c0952dba06a20b52d33",
      "description": "Noodles with Shrim",
      "foodCatergoryDescription": "Special Meal",
      "fullName": "Noodles with Shrim"
  }
]

export const tunedPreorder = JSON.parse(JSON.stringify(preOrderedPayload))

export const cateringArray=  [{
  "age": 37,
  "gender": "Woman",
  "holdSeatId": "63282c0952dba06a20b52d72",
  "id": "6417f56a0c5374a4d72b5325",
  "PAXName": "Kangrou Fu",
  "seatName": "10A",
  "seatType": "BC",
  "vip": false,
  "containerId": "6417f5180c5374a4d72b4b63",
  "foodTypeId": "632000e0cdcfde6b6088001a",
  "locationId": "6417f5180c5374a4d72b4b66",
  "orderType": "Pre_Ordered",
  "qty": 1,
  "seatId": "63282c0952dba06a20b52d72"
},
{
  "age": 40,
  "gender": "Woman",
  "holdSeatId": "6417f5440c5374a4d72b4fd4",
  "id": "6417f56a0c5374a4d72b5327",
  "PAXName": "Huishao Wu",
  "seatName": "21A",
  "seatType": "EC",
  "vip": false,
  "containerId": "6417f5180c5374a4d72b4b63",
  "foodTypeId": "6295bb887115c2151edef87d",
  "locationId": "6417f5180c5374a4d72b4b71",
  "orderType": "Special_Meal",
  "qty": 1,
  "seatId": "6417f5440c5374a4d72b4fd4",
  "description": "Chicken Noodles",
  "foodCatergoryDescription": "Meal with Meat",
  "fullName": "Chicken Noodles"
},
{
  "age": 41,
  "gender": "Woman",
  "holdSeatId": "6417f5440c5374a4d72b4fe6",
  "id": "6417f56a0c5374a4d72b5329",
  "PAXName": "Jianmin Hui",
  "seatName": "22A",
  "seatType": "EC",
  "vip": false,
  "containerId": "6417f5180c5374a4d72b4b63",
  "foodTypeId": "632000e0cdcfde6b6088001a",
  "locationId": "6417f5180c5374a4d72b4b66",
  "orderType": "Pre_Ordered",
  "qty": 1,
  "seatId": "6417f5440c5374a4d72b4fe6"
},
{
  "age": 44,
  "gender": "Woman",
  "holdSeatId": "63282c0952dba06a20b52d79",
  "id": "6417f56a0c5374a4d72b532b",
  "PAXName": "Pei Li",
  "seatName": "10K",
  "seatType": "BC",
  "vip": false,
  "containerId": "6417f5180c5374a4d72b4b63",
  "foodTypeId": "6295bb887115c2151edef87d",
  "locationId": "6417f5180c5374a4d72b4b71",
  "orderType": "Special_Meal",
  "qty": 1,
  "seatId": "63282c0952dba06a20b52d79",
  "description": "Chicken Noodles",
  "foodCatergoryDescription": "Meal with Meat",
  "fullName": "Chicken Noodles"
},
{
  "age": 32,
  "gender": "Man",
  "holdSeatId": "63282c0952dba06a20b52d2b",
  "id": "6417f56a0c5374a4d72b532d",
  "PAXName": "Lei Li",
  "collectedTime": "1679626299623",
  "distributedTime": "1679626116374",
  "seatName": "1A",
  "seatType": "BC",
  "vip": true,
  "containerId": "6417f5180c5374a4d72b4b63",
  "foodTypeId": "632000e0cdcfde6b6088001a",
  "locationId": "6417f5180c5374a4d72b4b66",
  "orderType": "Pre_Ordered",
  "qty": 1,
  "seatId": "63282c0952dba06a20b52d2b"
},
{
  "age": 23,
  "gender": "Woman",
  "holdSeatId": "63282c0952dba06a20b52d33",
  "id": "6417f56a0c5374a4d72b532f",
  "PAXName": "Meimei Han",
  "seatName": "3A",
  "seatType": "BC",
  "vip": true,
  "containerId": "6417f5180c5374a4d72b4b63",
  "foodTypeId": "6295bcf77115c2151edef9ff",
  "locationId": "6417f5180c5374a4d72b4b66",
  "orderType": "Pre_Ordered",
  "qty": 1,
  "seatId": "63282c0952dba06a20b52d33",
  "description": "Noodles with Shrim",
  "foodCatergoryDescription": "Special Meal",
  "fullName": "Noodles with Shrim"
}


]