import React, { useState,useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles,withStyles, useTheme } from '@material-ui/core/styles';
import MuiListItem from "@material-ui/core/ListItem";
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';
import AppsIcon from '@material-ui/icons/Apps';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Route, Switch, Link } from 'react-router-dom';
// import airbusLogo from'../../images/AIRBUS_White.png';
import aeiotLogo from'../images/Airspace_logo_web_black.png'
import  {Gains}  from '../views/Gains/GAINS'
import  Home from '../views/Home/Home'
import {Seats} from '../views/Seats/Seats not used/Seats'
import  {Cargo} from '../views/Cargo/Cargo'
import  {Aircraft} from '../views/Aircraft/Aircraft'
import Login from '../views/Auth/SignIn';
import { Header,Chip,Button,Badge,Inline ,MenuButton,MenuList,MenuItem, Menu} from "@airbus/components-react"
import { Catering } from '../views/Catering/Catering';
// import { Catering2 } from '../views/Catering/Catering2';
import {AirplaneModeActive, LocalCafe,AirlineSeatReclineNormal,AllInbox,Notification, AirplanemodeActive,Chevronleft, Chevronright} from '@airbus/icons/react';
import IconButton from '@material-ui/core/IconButton';
// import { ListItem } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { Auth } from 'aws-amplify';
import FlightIcon from '@material-ui/icons/Flight';


const drawerWidth = 210;

const ListItem1 = withStyles({
  root: {
    paddingTop:"1rem",
    "&$selected": {
     
      backgroundColor: "#566ca7",

      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&$selected:hover": {
      backgroundColor: "#566ca7",
 
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&:hover": {
      backgroundColor: "#566ca7",

      "& .MuiListItemIcon-root": {
        color: "white"
      }
    }
  },
  selected: {}
})(MuiListItem);

const useStyles = makeStyles((theme) => ({
  white: {
    color: theme.palette.common.white,
  },

  root: {
     display: 'flex',
  },
  appBar: {
    // background: theme.palette.primary.dark,
    backgroundColor: "#1C2747",
    color:"white",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    backgroundColor: "#1C2747",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  // menuButton: {
  //   // marginRight: 36,
  //   color:"white"
  // },
  // hide: {
  //   color:"white"
  // },
  drawer: {
    backgroundColor:'#1C2747',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    marginTop:"5rem",
    backgroundColor:'#1C2747',
    color:"white",
    // background: theme.palette.primary.main,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    marginTop:"5rem",
    backgroundColor:'#1C2747',
    fontColor:"white",
    // background: theme.palette.primary.main,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    backgroundColor:'#1C2747',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
     padding: theme.spacing(0, 1),
  
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    height:"100%",
    width:"80%",
    // flexGrow:2,
    minWidth:"80%",
    minHeight:"120rem",

        // display: 'flex',
    // width:"100rem",
    flexGrow: 1,
    position:'relative',
    padding: theme.spacing(3),
    color: '#ffffff',
    backgroundColor:'black', //theme color
  
  },
  // link: {
  //   color: '#FFFFFF'
  // }
}));

const Clock =()=>{
  const [date, setDate] = useState(new Date().toUTCString());
  
 const refreshClock=()=> {
    setDate(new Date().toUTCString())
  }
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
 return (<div>{date}</div>)
}



export default function Appbar() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

 
  useEffect(() => {
    checkAuth();
  }, []);

  const goToLogin = () => {
    history.push('/login');
  }

  const doSignOut = () => {
    Auth.signOut()
  }

  const [familyName, setFamilyName] = useState('');

  async function checkAuth() {
    try {
      const user = await Auth.currentAuthenticatedUser();
    
      if (user && user.signInUserSession) {
        console.log('user :>> ', user);

        const userAttributes = {};
        user.getUserAttributes((err, result) => {
          if (err) {
            console.log(err);
            return;
          }

          console.log('user :>> ', result)

          result.forEach((attribute) => {
            userAttributes[attribute.getName()] = attribute.getValue();
          });
const combined = userAttributes['name'] + " " + userAttributes['family_name']
          setFamilyName( userAttributes['name']?combined : "AEIOT");
        });

        console.log('SIGNED IN');
        // User is authenticated, do something with the user
      } else {
        // User is not authenticated, redirect to hosted UI login
        goToLogin();
        console.log('NOT SIGNED IN');
      }
    } catch (error) {
      console.log(error);
      goToLogin();
      // history.push('/');
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };



  const history = useHistory();
   const handleOnClick = () => {
    setSelectedIndex(0)
    history.push('/');}

  return (
    <div>

     <Header   onHomeClick={handleOnClick} style ={{display:"flex"   , backgroundColor:'#1C2747',
}} appName="Airspace Explorer Dashboard - AEIOT">
    <IconButton variant="ghost" aria-label="Search">
      {/* <SearchIcon /> */}
    </IconButton>
    <IconButton variant="ghost" aria-label="Notifications">
      {/* <NotificationsIcon /> */}
    </IconButton>
  
   <Clock/>
    {/* <IconButton variant="ghost" aria-label="Help">
      <AirplanemodeActive style={{color:"white"}}/>
    </IconButton> */}
    {/* <div>{familyName}</div> */}
    <IconButton variant="primary" aria-label="Edit">
     
      
      <Notification style={{color:"white"}} />
      <Badge variant="error" >
      </Badge>
    </IconButton>

    <Menu>
    <MenuButton variant="ghost" size="medium" component={IconButton}>
    <Chip>{familyName? familyName: "AEIOT"}</Chip>
    </MenuButton>

    <MenuList>
      <MenuItem onClick={doSignOut}>Log Out</MenuItem>

   
    </MenuList>
  </Menu>

  </Header>

 
     <div className={classes.root}>

        <Drawer
 
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <List component="nav">
        <ListItem1 button 
                  selected={selectedIndex == 0}
                  onClick={(event) => handleListItemClick(event, 0)}
        component={Link} to="/" className={classes.link}>
              <ListItemIcon><AppsIcon className={classes.white}  style={{width:"2rem", height:"2rem"}}/></ListItemIcon>
              <ListItemText primary="OVERVIEW" />
            </ListItem1>
        <ListItem1 
                 selected={selectedIndex === 1}
           onClick={(event) => handleListItemClick(event, 1)}
        button component={Link} to="/gains?tab=0" className={classes.link}>
              <ListItemIcon><LocalCafe className={classes.white}  style={{width:"2rem", height:"2rem"}}/></ListItemIcon>
              <ListItemText primary="GAINS" />
              </ListItem1>
              <ListItem1 
                    selected={selectedIndex === 2}
                 onClick={(event) => handleListItemClick(event, 2)}
              button component={Link} to="/seats" className={classes.link}>
              <ListItemIcon>< AirlineSeatReclineNormal   className={classes.white} style={{width:"2rem", height:"2rem"}} /></ListItemIcon>
              <ListItemText primary="SEATS" />
              </ListItem1>
              <ListItem1 
                 selected={selectedIndex === 3}
                 onClick={(event) => handleListItemClick(event, 3)}
              button component={Link} to="/cargo" className={classes.link}>
              <ListItemIcon><  AllInbox className={classes.white} style={{width:"2rem", height:"2rem"}} /></ListItemIcon>
              <ListItemText primary="CARGO" />
              </ListItem1>
              <ListItem1
                              selected={selectedIndex === 4}
                              onClick={(event) => handleListItemClick(event, 4)}
              button component={Link} to="/catering" className={classes.link}>
              <ListItemIcon>< LocalDiningIcon className={classes.white} style={{width:"2rem", height:"2rem"}}/></ListItemIcon>
              <ListItemText primary="SMART TROLLEY" />
              </ListItem1>
              <ListItem1
                              selected={selectedIndex === 5}
                              onClick={(event) => handleListItemClick(event, 5)}
              button component={Link} to="/aircraft" className={classes.link}>
              <ListItemIcon>< FlightIcon className={classes.white} style={{width:"2rem", height:"2rem"}}/></ListItemIcon>
              <ListItemText primary="AIRCRAFT" />
              </ListItem1>
         
        </List>

        {open &&  <Inline style={{position:'absolute', bottom:'20%'}}>
  <IconButton onClick={()=>setOpen(!open)}>
    <Chevronleft style={{marginLeft:"0.6rem", color:"white"}}/> <div style={{color:"white", paddingLeft:"0.1rem", fontSize:"1rem"}}>Collapse Menu</div>
    </IconButton>
    </Inline>}

    {!open && <Inline style={{position:'absolute', bottom:'15%'}}>
  <IconButton onClick={()=>setOpen(true)}>
    <Chevronright style={{marginLeft:"1rem", color:"white"}}/>
    </IconButton>
    </Inline>}
      </Drawer>
      <main className={classes.content}>
       
            <Container maxWidth="xl">
              <Switch>
                <Route path="/" component={Home} exact >
             
                  </Route>
                <Route path="/gains"  exact >
                { <Gains handleListItemClick={handleListItemClick} /> }
                </Route>
                <Route path="/gains2"  exact >
                { <Gains xvalue={1}  handleListItemClick={handleListItemClick} /> }
                </Route>
                <Route path="/seats" component={Seats} exact>
                { <Seats handleListItemClick={handleListItemClick} /> }
                </Route>
                <Route path="/home" component={Home} exact />
                <Route path="/cargo" component={Cargo} exact >
                { <Cargo handleListItemClick={handleListItemClick} /> }
                </Route>
                <Route path="/catering" component={Catering} exact>
                { <Catering handleListItemClick={handleListItemClick} /> }
                </Route>
                <Route path="/aircraft" component={Aircraft} exact>
                { <Aircraft handleListItemClick={handleListItemClick} /> }
                </Route>
                {/* <Route path="/catering2" component={Catering2} exact /> */}
              </Switch>
            </Container>
      </main>
    </div>
    </div>
  );
}
