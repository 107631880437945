import {
  Button,
  Inline,
  Tabs,
  Tab,
  ColorModeProvider,
  RangeSlider,
  Rating,
  Stack,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CardActionArea,
  CardTitle,
  IconButton,
  Container,
  Col,
  Content,
  Row,
  Toast,
  Flex,
  ButtonGroup,
} from "@airbus/components-react";
import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/el"; // the locale you want


import "react-datepicker/dist/react-datepicker.css";
import "./aircraft.css";
import _ from "lodash";
import Select, { components, PlaceholderProps } from "react-select";
import { Formik } from "formik";
import axios from "axios";
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
} from "date-fns";
import { da } from "date-fns/locale";
import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  Select as MuiSelect,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";


registerLocale("el", el); // register it with the name you want
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "black",
    opacity: 0.7,
  },
}));

const highlightedDates = [new Date(2023, 3, 19), new Date(2023, 1, 8)];

const customStyles = {
  option: () => ({
    height: "100%",
    color: "black",
    paddingLeft: "10px",
  }),
};

const Filters = ({ filters = [], onSubmit, tabValue, selectedFilterValue }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const [defaultMonth, setDefaultMonth] = useState(
    String(currentDate.getMonth() + 1).padStart(2, "0")
  );
  const [highDate, setHighDate] = useState(null);
  const getEndpointx = `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=aircraft/navigation&created_at=2023-${defaultMonth}&topic_extension=/longitude&topic_action=data&payload=1`;

  const asyncGetDates = async () => {
    const apiDatax = [];
    const { data: response } = await axios.get(getEndpointx);
    // console.log(...response);
  apiDatax.push(...(response || []));
  const filteredCargoData =  apiDatax.filter((item) => item.payload !== "invalid");
  console.log('filteredCargoData :>> ', filteredCargoData);
    const uniqueCreatedDates = _.uniqBy(filteredCargoData , "created_at").map(
      (obj) => obj.created_at
    );
    // console.log(uniqueCreatedDates);
    const dateObjects = uniqueCreatedDates.map(
      (dateString) => new Date(dateString)
    );
    const localHighlightedDates =  dateObjects.map((utcDate) => new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000));
    console.log("dateObjects :>> ", localHighlightedDates);
    setHighDate(localHighlightedDates);
    return dateObjects;
  };

  const asyncGetMonth = async (date) => {
    setLoading(true);
    const currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    const apiDatax = [];
    try{
    const { data: response } = await axios.get(
      `https://2uc57f9rcd.execute-api.eu-west-1.amazonaws.com/prod/aeiot-airbus-proxy?topic_prefix=aircraft/navigation&created_at=2023-${currentMonth}&topic_extension=/longitude&topic_action=data&payload=1.36`   , {
        responseType: 'stream', // Set the responseType to 'stream'
      });
    //   console.log(...response);
    // console.log("response :>> ", response);

    if (response.length > 1) {
      apiDatax.push(...(response || []));
     
   const filteredCargoData =  apiDatax.filter((item) => item.payload !== "invalid");
   console.log('filteredCargoData :>> ', filteredCargoData);
      const uniqueCreatedDates = _.uniqBy(filteredCargoData, "created_at").map(
        (obj) => obj.created_at
      );
      console.log(uniqueCreatedDates);
      const dateObjects = uniqueCreatedDates.map(
        (dateString) => new Date(dateString)
      );
      
      const localHighlightedDates =  dateObjects.map((utcDate) => new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000));
      console.log("dateObjects :>> ", localHighlightedDates);
      setHighDate(localHighlightedDates);
      setLoading(false);
      return dateObjects;
    }
  }
  catch(error){console.log('error :>> ', error); 
  setLoading(false);
}   
setLoading(false);
    return 1;
  };


  const initialValues = filters.reduce((acc, filter) => {
    return {
      ...acc,
      [filter.name]:
        filter.initialValue ||
        (filter.type === "daterange" ? [null, null] : null),
    };
  }, {});

  const handleOnChange = (option) => {
    console.log(
      "🚀 ~ file: CargoFilters.js:135 ~ handleOnChange ~ option:",
      option
    );
	selectedFilterValue(option.value);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, handleChange, submitForm }) => {
        for (const key in values) {
          if (!values[key]) {
            const filter = filters.find(
              (f) => f.name === key && f.type === "dropdown"
            );
            if (filter) {
              const options =
                typeof filter?.options === "function"
                  ? filter.options(values)
                  : filter.options;
              if (options.length === 1) {
                handleChange({
                  target: { name: key, value: options[0].value },
                });
              }
            }
          }
        }
        return (
          <div style={{ display: "flex", gap: "0.5rem" }}>
            {filters.map((filter) => {
              const { name, options: o } = filter;

              const options = typeof o === "function" ? o(values) : o;

              const value = values[name];
              const details =
                filter.type === "dropdown" ? (
                  <div style={{ width: "180px" }}>
                    <Select
                      styles={customStyles}
                      holder={filter.label}
                      {...filter}
                      options={options}
                      placeholder="Cargo Process" // Placeholder text
                      // value={options?.find((o) => o.value === value) || null}
                      //   onChange={(v) => {
                      //     handleChange({ target: { name, value: v.value } });
                      //     filter.onChange?.(handleChange);
                      // 	console.log("v" +v)
                      // 	selectedFilterValue(v.value);
                      //   }}
                      onChange={handleOnChange}
                    />
                  </div>
                ) : (
                  <div>
                    <Backdrop
                      open={loading}
                      className={classes.backdrop}
                      style={{ opacity: "0.8" }}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                    <DatePicker
                      // startDate={value[0]}
                      // endDate={value[1]}
              
                 
                   
                      onMonthChange={asyncGetMonth}
                      onCalendarOpen={asyncGetDates}
                      highlightDates={highDate ? highDate : []}
                      isSingleDate
                      //selectsRange   //uncomment if want to change to date range for datepicker
                      placeholderText="Date"
                      selected={Array.isArray(value) ? value[0] : value}
                      onChange={(v) => {
                        // handleChange({ target: { name, value: v } }); //uncomment if want to change to date range for datepicker
                        const newValue = [v, v];
                        handleChange({ target: { name, value: newValue } });
                      }}
                    />
                  </div>
                );
              return (
                <div key={name}>
                  {/* <p>{filter.label}</p> */}
                  {details}
                </div>
              );
            })}
            <ColorModeProvider mode="light">
              <Button
                style={{
                  width: "5rem",
                  height: "2.5rem",
                  backgroundColor: "#1C2747",
                }}
                variant="primary"
                onClick={submitForm}
              >
                Search
              </Button>
            </ColorModeProvider>
          </div>
        );
      }}
    </Formik>
  );
};

export const AircraftFilters = ({
  filters,
  onSubmit,
  tabValue,
  selectedFilterValue,
}) => {
  return (
    <div style={{ maxWidth: "80%", paddingBottom: "0.8rem" }}>
      <Row justify="start" style={{ paddingTop: "1rem" }}>
        <Filters
          filters={filters}
          onSubmit={onSubmit}
          tabValue={tabValue}
          selectedFilterValue={selectedFilterValue}
        />
      </Row>
    </div>
  );
};
